import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_mobile_design_pro',
  title: 'Дизайн мобильных и кросс-платформенных приложений. Продвинутый',
  description: 'На курсе вы погрузитесь в проектирование мобильных приложений, изучите гайдлайны iOS и Android и научитесь адаптировать интерфейс для смартфонов и планшетов, десктопа, ТВ, умных часов и других устройств. Вы пройдёте весь цикл разработки дизайна: от прототипирования до презентации проекта. В конце сможете оформить кейс в портфолио.',
  school: 'yandex',
  category: 'design',
  price: 88000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmobile-app-design%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продвинутый мобильный дизайн',
    'Кросс-платформенная разработка',
    'Сложные интерфейсы',
    'Системный подход',
    'Управление проектами'
  ],
  program: [
    {
      title: 'Углубленный мобильный дизайн',
      topics: [
        'Сложные интерфейсы',
        'Микроанимация',
        'Жесты и взаимодействия',
        'Системный подход'
      ]
    },
    {
      title: 'Кросс-платформенность',
      topics: [
        'Адаптация под устройства',
        'Особенности платформ',
        'Единая дизайн-система',
        'Консистентность интерфейсов'
      ]
    },
    {
      title: 'Управление проектами',
      topics: [
        'Ведение проектов',
        'Работа с командой',
        'Защита решений',
        'Документация'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Сложные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат дизайнера'
  ]
};