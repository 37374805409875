import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_clinical_pathopsychology',
  title: 'Клиническая психология со специализацией в патопсихологии',
  description: 'Дистанционное обучение по программе Клиническая психология со специализацией в патопсихологии с присвоением квалификации Клинический психолог. Патопсихолог (1960 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 68900,
  currency: 'RUR',
  duration: '1960 часов',
  imageUrl: 'https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fklinicheskaya_psikhologiya_psikhologicheskaya_diagnostika_i_psikhoterapiya_v_klinicheskoy%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Патопсихологическая диагностика',
    'Клиническое интервью',
    'Нейропсихологическое обследование',
    'Психокоррекция',
    'Реабилитация'
  ],
  program: [
    {
      title: 'Основы клинической психологии',
      topics: [
        'Патопсихология',
        'Нейропсихология',
        'Психосоматика',
        'Психиатрия'
      ]
    },
    {
      title: 'Диагностика и обследование',
      topics: [
        'Методы диагностики',
        'Патопсихологическое обследование',
        'Нейропсихологическая диагностика',
        'Составление заключений'
      ]
    },
    {
      title: 'Терапия и реабилитация',
      topics: [
        'Методы психотерапии',
        'Психокоррекция',
        'Реабилитационные программы',
        'Работа с семьей'
      ]
    }
  ],
  features: [
    'Углубленная специализация',
    'Практическая подготовка',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};