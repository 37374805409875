import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_healthcare_management',
  title: 'Организация здравоохранения и общественное здоровье',
  description: 'Онлайн-курс «Организация здравоохранения и общественное здоровье» от Нетологии: для врачей и ординаторов, желающих повысить квалификацию. Всего 4 месяца обучения с практикой и цифровыми технологиями. Получите диплом, улучшите навыки управления и откройте новые карьерные перспективы в медицине!',
  school: 'netology',
  category: 'medicine',
  price: 90000,
  oldPrice: 90000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmedicine-ozz&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3750,
  skills: [
    'Организация здравоохранения',
    'Управление медучреждением',
    'Медицинское право',
    'Экономика здравоохранения',
    'Качество медпомощи'
  ],
  program: [
    {
      title: 'Основы управления',
      topics: [
        'Система здравоохранения',
        'Нормативная база',
        'Управление персоналом',
        'Экономика клиники'
      ]
    },
    {
      title: 'Организация работы',
      topics: [
        'Медицинская статистика',
        'Контроль качества',
        'Документооборот',
        'Лицензирование'
      ]
    },
    {
      title: 'Развитие учреждения',
      topics: [
        'Стратегическое планирование',
        'Маркетинг услуг',
        'Цифровизация',
        'Оптимизация процессов'
      ]
    }
  ],
  features: [
    'Практические занятия',
    'Работа с кейсами',
    'Персональный наставник',
    'Доступ к материалам',
    'Диплом о профессиональной переподготовке'
  ]
};