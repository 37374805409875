import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_project_manager',
  title: 'Офлайн-курс Менеджер проектов',
  description: 'Курс-интенсив для тех, кто хочет получить профессию быстро. Научитесь управлять, собирать идеи и превращать их в требования, планировать, коммуницировать с командой и клиентами',
  school: 'brunoyam',
  category: 'management',
  price: 29900,
  currency: 'RUB',
  duration: '5 недель',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-management%2Fproject-manager&m=5&erid=LdtCK4uLs',
  skills: [
    'Управление проектами',
    'Agile методологии',
    'Работа с командой',
    'Управление рисками',
    'Планирование'
  ],
  program: [
    {
      title: 'Основы управления проектами',
      topics: [
        'Жизненный цикл проекта',
        'Методологии управления',
        'Планирование',
        'Оценка рисков'
      ]
    },
    {
      title: 'Работа с командой',
      topics: [
        'Формирование команды',
        'Коммуникации',
        'Мотивация',
        'Конфликты'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Управление изменениями',
        'Отчетность',
        'Завершение проекта'
      ]
    }
  ],
  features: [
    '48 академических часов',
    '12 занятий',
    'Практические проекты',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};