import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_graphpro',
  title: 'Профессия Графический дизайнер PRO',
  description: 'Углубленное обучение графическому дизайну. Станьте экспертом в создании визуальных коммуникаций и освойте все аспекты современного графического дизайна.',
  school: 'contented',
  category: 'design',
  price: 186114,
  oldPrice: 338389,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://static.tildacdn.com/stor6163-3137-4632-a363-373334333130/60743933.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fgraphic-designer-pro&m=5&erid=LdtCKHL5u',
  monthlyPayment: 5170,
  discountPercent: 45,
  skills: [
    'Создание комплексных дизайн-проектов',
    'Разработка брендинга',
    'Дизайн упаковки',
    'Типографика и верстка',
    'Веб-дизайн',
    'Иллюстрация',
    'Арт-директинг'
  ],
  program: [
    {
      title: 'Профессиональный дизайн',
      topics: [
        'Теория дизайна',
        'Композиция и цвет',
        'Типографика',
        'Современные тренды'
      ]
    },
    {
      title: 'Брендинг и айдентика',
      topics: [
        'Стратегия бренда',
        'Разработка логотипа',
        'Фирменный стиль',
        'Гайдлайны'
      ]
    },
    {
      title: 'Digital-дизайн',
      topics: [
        'UX/UI основы',
        'Адаптивный дизайн',
        'Анимация интерфейсов',
        'Веб-графика'
      ]
    },
    {
      title: 'Управление проектами',
      topics: [
        'Ведение проектов',
        'Работа с клиентами',
        'Управление командой',
        'Оценка и планирование'
      ]
    }
  ],
  features: [
    'Индивидуальное наставничество',
    'Работа над реальными проектами',
    'Стажировка в агентстве',
    'Помощь в трудоустройстве',
    'Доступ к профессиональному сообществу'
  ]
};