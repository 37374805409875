import { Course } from '../../../../types';

export const course: Course = {
  id: 'abakus_reading_72',
  title: 'Чтение Абакус - обучение и курсы для детей | 72 урока',
  description: 'Наша авторская методика, направленная на развитие навыков осознанного чтения и улучшение техники и скорости чтения',
  school: 'abakus',
  category: 'russian',
  price: 70560,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?q=80&w=1200',
  url: 'https://go.redav.online/86fdf99551e92c21?dl=https%3A%2F%2Fabakus-center.ru%2Feducation%2Freading&m=5&erid=2Vfnxx8cgzL',
  monthlyPayment: 7840,
  skills: [
    'Профессиональная техника чтения',
    'Сверхскоростное чтение',
    'Глубокий анализ текста',
    'Развитая память',
    'Богатейший словарный запас',
    'Работа с любыми текстами'
  ],
  program: [
    {
      title: 'Профессиональный уровень',
      topics: [
        'Экспертные техники',
        'Скорочтение',
        'Сложные тексты',
        'Развитие интеллекта'
      ]
    },
    {
      title: 'Мастерство',
      topics: [
        'Максимальная скорость',
        'Полное понимание',
        'Сложные упражнения',
        'Работа с информацией'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Полная программа',
    'Лучшие преподаватели',
    'Сложные задания',
    'Детальный мониторинг прогресса'
  ]
};