import { Course } from '../../../../types';

// Import all course modules
import { course as pythonDeveloper } from './python-developer';
import { course as motionDesigner } from './motion-designer';
import { course as devops } from './devops';
import { course as javaDeveloper } from './java-developer';
import { course as frontendDeveloper } from './frontend-developer';
import { course as gameDeveloper } from './game-developer';
import { course as dataScientist } from './data-scientist';
import { course as dataAnalyst } from './data-analyst';
import { course as businessAnalyst } from './business-analyst';
import { course as uxuiDesigner } from './uxui-designer';
import { course as webDesigner } from './web-designer';
import { course as interiorDesigner } from './interior-designer';
import { course as gameDesigner } from './game-designer';
import { course as threeDGeneralist } from './3d-generalist';
import { course as landscapeDesigner } from './landscape-designer';
import { course as smmSpecialist } from './smm-specialist';
import { course as graphicDesigner } from './graphic-designer';
import { course as projectManager } from './project-manager';
import { course as internetMarketer } from './internet-marketer';
import { course as productManager } from './product-manager';
import { course as qaAutomation } from './qa-automation';
import { course as cybersecurity } from './cybersecurity';
import { course as interiorLandscape } from './interior-landscape';
import { course as personalStylist } from './personal-stylist';
import { course as copywriter } from './copywriter';
import { course as eventManager } from './event-manager';
import { course as fashionDesigner } from './fashion-designer';
import { course as excel } from './excel';
import { course as machineLearning } from './machine-learning';
import { course as financeManager } from './finance-manager';
import { course as methodist } from './methodist';
import { course as aiSpecialist } from './ai-specialist';
import { course as aiIntegration } from './ai-integration';
import { course as developerSchool } from './developer-school';
import { course as threeDArtist } from './3d-artist';
import { course as photographer } from './photographer';
import { course as wildberriesSales } from './wildberries-sales';
import { course as unrealEngine } from './unreal-engine';
import { course as screenwriter } from './screenwriter';
import { course as filmEditor } from './film-editor';
import { course as droneRobots } from './drone-robots';
import { course as droneEngineer } from './drone-engineer';
import { course as autocad } from './autocad';
import { course as hrPartner } from './hr-partner';
import { course as pythonDeveloperPro } from './python-developer-pro';
import { course as frontendDeveloperPro } from './frontend-developer-pro';

// Export all courses
export const geekbrainsCourses: Course[] = [
  pythonDeveloper,
  motionDesigner,
  devops,
  javaDeveloper,
  frontendDeveloper,
  gameDeveloper,
  dataScientist,
  dataAnalyst,
  businessAnalyst,
  uxuiDesigner,
  webDesigner,
  interiorDesigner,
  gameDesigner,
  threeDGeneralist,
  landscapeDesigner,
  smmSpecialist,
  graphicDesigner,
  projectManager,
  internetMarketer,
  productManager,
  qaAutomation,
  cybersecurity,
  interiorLandscape,
  personalStylist,
  copywriter,
  eventManager,
  fashionDesigner,
  excel,
  machineLearning,
  financeManager,
  methodist,
  aiSpecialist,
  aiIntegration,
  developerSchool,
  threeDArtist,
  photographer,
  wildberriesSales,
  unrealEngine,
  screenwriter,
  filmEditor,
  droneRobots,
  droneEngineer,
  autocad,
  hrPartner,
  pythonDeveloperPro,
  frontendDeveloperPro
];