import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_graphic',
  title: 'Офлайн-курс графического дизайна',
  description: 'За 16 занятий научитесь работать с растровой и векторной графикой. Занятия в мини-группе, поддержка преподавателя и доступ к онлайн-курсам по Photoshop и Illustrator',
  school: 'brunoyam',
  category: 'design',
  price: 21600,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fgraficheskiy-dizainer&m=5&erid=LdtCK4uLs',
  skills: [
    'Adobe Photoshop',
    'Adobe Illustrator',
    'Композиция',
    'Типографика',
    'Брендинг'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория цвета',
        'Композиция',
        'Типографика',
        'Работа с формой'
      ]
    },
    {
      title: 'Adobe Photoshop',
      topics: [
        'Инструменты программы',
        'Работа со слоями',
        'Ретушь',
        'Эффекты'
      ]
    },
    {
      title: 'Adobe Illustrator',
      topics: [
        'Векторная графика',
        'Работа с кривыми',
        'Создание логотипов',
        'Подготовка к печати'
      ]
    }
  ],
  features: [
    '64 академических часа',
    '16 занятий',
    'Практические проекты',
    'Создание портфолио',
    'Сертификат дизайнера'
  ]
};