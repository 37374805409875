import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_presentations',
  title: 'Курс по созданию и оформлению презентаций',
  description: 'Курс для тех, кому нужно доносить мысли и идеи до команды или заказчика. Поможем специалистам из разных профессий создавать визуальные презентации',
  school: 'brunoyam',
  category: 'analytics',
  price: 9900,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-analitika-dannyh%2Fvisual-presentation&m=5&erid=LdtCK4uLs',
  skills: [
    'Создание презентаций',
    'Визуальное оформление',
    'Структурирование информации',
    'Подача материала',
    'Работа с аудиторией'
  ],
  program: [
    {
      title: 'Основы презентаций',
      topics: [
        'Структура презентации',
        'Визуальная иерархия',
        'Работа с текстом',
        'Подбор изображений'
      ]
    },
    {
      title: 'Дизайн презентаций',
      topics: [
        'Цветовые схемы',
        'Типографика',
        'Композиция слайдов',
        'Инфографика'
      ]
    },
    {
      title: 'Подача материала',
      topics: [
        'Сторителлинг',
        'Работа с аудиторией',
        'Выступление',
        'Ответы на вопросы'
      ]
    }
  ],
  features: [
    '24 академических часа',
    '6 занятий',
    'Практические задания',
    'Создание презентаций',
    'Сертификат по окончании'
  ]
};