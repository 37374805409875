import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_motion',
  title: 'Профессия Моушн-дизайнер в 2D и 3D',
  description: 'Обучение моушн-дизайну и анимации',
  school: 'contented',
  category: '3d',
  price: 122364,
  oldPrice: 222480,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551269901-5c5e14c25df7?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fmotion-3d-designer%2F&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3399,
  discountPercent: 45,
  skills: [
    'Создание анимации',
    'Работа с 3D-графикой',
    'Визуальные эффекты',
    'Моушн-дизайн',
    'Композитинг',
    'Работа со звуком'
  ],
  program: [
    {
      title: 'Основы анимации',
      topics: [
        'Принципы анимации',
        'Тайминг и спейсинг',
        'Работа с кривыми',
        'Основы композиции'
      ]
    },
    {
      title: '2D анимация',
      topics: [
        'After Effects',
        'Анимация персонажей',
        'Визуальные эффекты',
        'Типографика в движении'
      ]
    },
    {
      title: '3D анимация',
      topics: [
        'Cinema 4D',
        'Моделирование',
        'Текстурирование',
        'Рендеринг'
      ]
    },
    {
      title: 'Продакшн',
      topics: [
        'Работа над проектами',
        'Композитинг',
        'Постпродакшн',
        'Подготовка портфолио'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Доступ к онлайн-библиотеке',
    'Сертификат моушн-дизайнера'
  ]
};