import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_unit_economics',
  title: 'Юнит-экономика',
  description: 'Курс для тех, кто хочет разобраться, как пользователи приносят деньги в продукт и какими способами на это можно влиять.',
  school: 'yandex',
  category: 'analytics',
  price: 25000,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Funit-economics&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Юнит-экономика',
    'Анализ метрик',
    'Оценка эффективности',
    'Прогнозирование',
    'Оптимизация показателей'
  ],
  program: [
    {
      title: 'Основы юнит-экономики',
      topics: [
        'Базовые метрики',
        'Экономические модели',
        'Расчет показателей',
        'Анализ данных'
      ]
    },
    {
      title: 'Анализ и оптимизация',
      topics: [
        'LTV и CAC',
        'Retention',
        'Conversion',
        'Revenue'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Построение моделей',
        'Прогнозирование',
        'Оптимизация метрик',
        'Принятие решений'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в обучении',
    'Сертификат аналитика'
  ]
};