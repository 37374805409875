import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_automation',
  title: 'Инженер по автоматизации',
  description: 'Профессия «Инженер по автоматизации» от Нетологии: с нуля освоите программирование контроллеров и SCADA-систем, создадите автоматизированные системы управления и станете востребованным специалистом в таких сферах, как энергетика и промышленность.',
  school: 'netology',
  category: 'programming',
  price: 101200,
  oldPrice: 168666,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581092446327-9b52bd1570c2?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fautomation-engineer&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2811,
  skills: [
    'Программирование контроллеров',
    'SCADA системы',
    'Промышленные сети',
    'Автоматизация процессов',
    'Системы управления'
  ],
  program: [
    {
      title: 'Основы автоматизации',
      topics: [
        'Теория управления',
        'Программирование ПЛК',
        'Промышленные сети',
        'SCADA системы'
      ]
    },
    {
      title: 'Практика автоматизации',
      topics: [
        'Разработка систем',
        'Интеграция оборудования',
        'Настройка контроллеров',
        'Визуализация'
      ]
    },
    {
      title: 'Проектная работа',
      topics: [
        'Проектирование систем',
        'Внедрение решений',
        'Тестирование',
        'Документация'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};