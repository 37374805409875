import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_indesign',
  title: 'Офлайн-курс Adobe InDesign',
  description: 'Практический курс для начинающих дизайнеров, и для всех, кто хочет освоить InDesign. Разберёте самую популярную программу для вёрстки печатных изданий',
  school: 'brunoyam',
  category: 'design',
  price: 11400,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1626785774625-ddcddc3445e9?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fadobe-indesign&m=5&erid=LdtCK4uLs',
  skills: [
    'Adobe InDesign',
    'Верстка',
    'Работа с текстом',
    'Макетирование',
    'Подготовка к печати'
  ],
  program: [
    {
      title: 'Основы InDesign',
      topics: [
        'Интерфейс программы',
        'Настройка документа',
        'Мастер-страницы',
        'Работа с текстом'
      ]
    },
    {
      title: 'Верстка',
      topics: [
        'Работа с макетами',
        'Стили абзацев',
        'Работа с таблицами',
        'Графика и изображения'
      ]
    },
    {
      title: 'Публикация',
      topics: [
        'Подготовка к печати',
        'Экспорт в PDF',
        'Пакеты публикации',
        'Интерактивные документы'
      ]
    }
  ],
  features: [
    '32 академических часа',
    'Практические задания',
    'Работа с реальными макетами',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};