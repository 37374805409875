import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_neural',
  title: 'Онлайн-курс Работа с нейросетями',
  description: 'Узнаете, какие возможности есть у нейросетей, как решать задачи с их помощью и в каких сферах применять',
  school: 'brunoyam',
  category: 'analytics',
  price: 15900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fnejronnye-seti&m=5&erid=LdtCK4uLs',
  skills: [
    'Работа с нейросетями',
    'Prompt Engineering',
    'Генерация контента',
    'Обработка данных',
    'AI-инструменты'
  ],
  program: [
    {
      title: 'Основы ИИ',
      topics: [
        'Введение в нейросети',
        'Типы моделей',
        'Архитектуры ИИ',
        'Применение в бизнесе'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'ChatGPT',
        'Midjourney',
        'Stable Diffusion',
        'DALL-E'
      ]
    },
    {
      title: 'Применение',
      topics: [
        'Генерация контента',
        'Обработка текста',
        'Создание изображений',
        'Автоматизация'
      ]
    }
  ],
  features: [
    '12 проектов',
    'Практические задания',
    'Работа с AI-инструментами',
    'Персональный наставник',
    'Сертификат по окончании'
  ]
};