import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_presentation_design',
  title: 'Дизайн презентаций',
  description: 'Обучение дизайну презентаций: курсы от Нетологии помогут вам освоить основы и продвинутые техники создания презентаций, подготовят к профессии дизайнера презентаций.',
  school: 'netology',
  category: 'design',
  price: 38710,
  oldPrice: 55300,
  currency: 'RUB',
  duration: '9 недель',
  imageUrl: 'https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdesigner-presentation&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3225,
  discountPercent: 30,
  skills: [
    'Дизайн презентаций',
    'Визуальное оформление',
    'Работа с контентом',
    'Типографика',
    'Подача материала'
  ],
  program: [
    {
      title: 'Основы дизайна презентаций',
      topics: [
        'Принципы дизайна',
        'Композиция слайдов',
        'Цветовые решения',
        'Типографика'
      ]
    },
    {
      title: 'Визуализация данных',
      topics: [
        'Инфографика',
        'Графики и диаграммы',
        'Иллюстрации',
        'Иконки'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание шаблонов',
        'Анимация',
        'Подготовка к выступлению',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    '52 практических занятия',
    'Создание портфолио',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};