import { Course } from '../../../../types';

export const course: Course = {
  id: 'abakus_math_8',
  title: 'Ментальная арифметика онлайн - обучение и курсы для детей | 8 уроков',
  description: 'Индивидуальные онлайн-занятия ментальной арифметикой с опытным педагогом на интерактивной платформе Абакус',
  school: 'abakus',
  category: 'math',
  price: 10800,
  currency: 'RUB',
  duration: '1 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1518133910546-b6c2fb7d79e3?q=80&w=1200',
  url: 'https://go.redav.online/86fdf99551e92c21?dl=https%3A%2F%2Fabakus-center.ru%2Feducation%2Fonline&m=5&erid=2Vfnxx8cgzL',
  monthlyPayment: 10800,
  skills: [
    'Устный счет',
    'Концентрация внимания',
    'Развитие памяти',
    'Логическое мышление',
    'Воображение'
  ],
  program: [
    {
      title: 'Основы ментальной арифметики',
      topics: [
        'Знакомство с абакусом',
        'Простое сложение и вычитание',
        'Работа с числами',
        'Визуализация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Решение примеров',
        'Ментальные вычисления',
        'Развивающие игры',
        'Работа с флеш-картами'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивная платформа',
    'Опытные преподаватели',
    'Домашние задания',
    'Отслеживание прогресса'
  ]
};