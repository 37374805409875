import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_context_ads',
  title: 'Специалист по контекстной рекламе',
  description: 'Курс по контекстной рекламе в Яндекс.Директ и Google Ads в Нетологии. Обучение с нуля для будущих специалистов: теория, практика, сертификация. Получите навыки настройки и оптимизации кампаний, а также помощь в трудоустройстве.',
  school: 'netology',
  category: 'marketing',
  price: 80520,
  oldPrice: 134200,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fcontext-target&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3355,
  skills: [
    'Контекстная реклама',
    'Яндекс.Директ',
    'Google Ads',
    'Аналитика рекламы',
    'Оптимизация кампаний'
  ],
  program: [
    {
      title: 'Основы контекстной рекламы',
      topics: [
        'Принципы работы',
        'Настройка кампаний',
        'Подбор ключевых слов',
        'Создание объявлений'
      ]
    },
    {
      title: 'Продвинутые настройки',
      topics: [
        'Ретаргетинг',
        'Автоматизация',
        'Стратегии показов',
        'Работа со ставками'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Анализ эффективности',
        'A/B тестирование',
        'Оптимизация бюджета',
        'Отчетность'
      ]
    }
  ],
  features: [
    '19 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};