import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_graphic_designer_plus',
  title: 'Графический дизайнер расширенный',
  description: 'Расширенный курс для тех, кто хочет с нуля освоить дизайн, разобраться в разных инструментах и сделать больше проектов для портфолио.',
  school: 'yandex',
  category: 'design',
  price: 189000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fgraphic-designer%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Графический дизайн',
    'Adobe Creative Suite',
    'Типографика',
    'Брендинг',
    'Создание портфолио'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Цветоведение',
        'Типографика'
      ]
    },
    {
      title: 'Инструменты дизайнера',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe InDesign',
        'Figma'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание брендинга',
        'Дизайн упаковки',
        'Веб-графика',
        'Подготовка портфолио'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Больше практики',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат дизайнера'
  ]
};