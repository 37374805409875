import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_copywriter',
  title: 'Профессия Копирайтер с нуля до PRO',
  description: 'Онлайн курс «Копирайтер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 6 месяцев. Помощь в трудоустройстве! Обучение на копирайтера с нуля онлайн.',
  school: 'geekbrains',
  category: 'marketing',
  price: 46264,
  oldPrice: 92528,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/Qf9WU1EE127YYx6QKIN6OYqL8TaD2E6tDspvtRqR.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmarketing%2Fcopywriter-spec&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Создание продающих текстов',
    'SEO-копирайтинг',
    'Контент-маркетинг',
    'Редактура текстов',
    'Работа с заказчиками',
    'Продвижение услуг'
  ],
  program: [
    {
      title: 'Основы копирайтинга',
      topics: [
        'Типы текстов',
        'Структура текста',
        'Стилистика',
        'Редактирование'
      ]
    },
    {
      title: 'Продающие тексты',
      topics: [
        'Маркетинговые приемы',
        'Триггеры продаж',
        'Целевая аудитория',
        'Оферы и УТП'
      ]
    },
    {
      title: 'SEO и контент',
      topics: [
        'SEO-оптимизация',
        'Контент-план',
        'Информационные статьи',
        'Лендинги'
      ]
    },
    {
      title: 'Работа и продвижение',
      topics: [
        'Поиск клиентов',
        'Портфолио',
        'Ценообразование',
        'Личный бренд'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор реальных кейсов',
    'Персональный наставник',
    'Помощь в поиске заказов',
    'Сертификат копирайтера'
  ]
};