import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_finance',
  title: 'Очный курс Финансовый менеджмент',
  description: 'За 4 недели научитесь работать с финансами, чтобы управлять бизнес-процессами на основе данных',
  school: 'brunoyam',
  category: 'management',
  price: 29900,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-6726b3ff858f?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Ffinancial-management&m=5&erid=LdtCK4uLs',
  skills: [
    'Финансовый анализ',
    'Бюджетирование',
    'Управление затратами',
    'Инвестиционный анализ',
    'Финансовое планирование'
  ],
  program: [
    {
      title: 'Основы финансов',
      topics: [
        'Финансовый анализ',
        'Финансовые показатели',
        'Отчетность',
        'Бюджетирование'
      ]
    },
    {
      title: 'Управление финансами',
      topics: [
        'Управление затратами',
        'Инвестиции',
        'Риск-менеджмент',
        'Финансовая стратегия'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Финансовое планирование',
        'Оценка проектов',
        'Управленческие решения',
        'Оптимизация процессов'
      ]
    }
  ],
  features: [
    '64 практических задания',
    '3 проекта',
    'Работа с реальными данными',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};