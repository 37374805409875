import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_qa',
  title: 'Инженер по тестированию',
  description: 'Профессия «Инженер по тестированию» от Нетологии: с нуля освоите ручное, автоматизированное, мобильное и нагрузочное тестирование и сможете начать работать через 2 месяца. Поддержка при обучении и трудоустройстве поможет вам развить необходимые навыки для успешной карьеры в тестировании.',
  school: 'netology',
  category: 'programming',
  price: 103800,
  oldPrice: 173000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fqa&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2883,
  skills: [
    'Ручное тестирование',
    'Автоматизация тестирования',
    'API тестирование',
    'Нагрузочное тестирование',
    'Мобильное тестирование'
  ],
  program: [
    {
      title: 'Основы тестирования',
      topics: [
        'Теория тестирования',
        'Тест-дизайн',
        'Тестовая документация',
        'Работа с багами'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Selenium WebDriver',
        'Python для тестирования',
        'API тестирование',
        'CI/CD'
      ]
    },
    {
      title: 'Специальные виды',
      topics: [
        'Мобильное тестирование',
        'Нагрузочное тестирование',
        'Безопасность',
        'Performance'
      ]
    }
  ],
  features: [
    '23 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};