import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_soft_skills_pm',
  title: 'Мягкие навыки менеджера проектов',
  description: 'Обучение мягким навыкам от сервиса онлайн-образования Яндекс Практикум. Курс по развитию soft skills онлайн. Прокачайте свои софт скилы, чтобы повысить свою эффективность, улучшить лидерские качества, научиться грамотно выходить из конфликтов.',
  school: 'yandex',
  category: 'management',
  price: 42000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fsoft-skills-project-manager&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Коммуникация',
    'Лидерство',
    'Управление конфликтами',
    'Эмоциональный интеллект',
    'Тайм-менеджмент'
  ],
  program: [
    {
      title: 'Личная эффективность',
      topics: [
        'Управление временем',
        'Целеполагание',
        'Принятие решений',
        'Стресс-менеджмент'
      ]
    },
    {
      title: 'Коммуникация',
      topics: [
        'Деловое общение',
        'Ведение переговоров',
        'Публичные выступления',
        'Обратная связь'
      ]
    },
    {
      title: 'Управление командой',
      topics: [
        'Лидерство',
        'Мотивация',
        'Делегирование',
        'Разрешение конфликтов'
      ]
    }
  ],
  features: [
    'Практические упражнения',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Групповые занятия',
    'Сертификат о повышении квалификации'
  ]
};