import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_corel',
  title: 'Офлайн-курс CorelDraw',
  description: 'Практический курс идеально подойдет для дизайнеров, маркетологов и художников. Вы научитесь создавать логотипы, макеты, календари и широкоформатную продукцию. В конце обучения вы получите два сертификата: о прохождении курса и официальный сертификат от Corel Corporation.',
  school: 'brunoyam',
  category: 'design',
  price: 11400,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1626785774625-ddcddc3445e9?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fcoreldraw&m=5&erid=LdtCK4uLs',
  skills: [
    'CorelDraw',
    'Векторная графика',
    'Создание логотипов',
    'Работа с текстом',
    'Подготовка к печати'
  ],
  program: [
    {
      title: 'Основы CorelDraw',
      topics: [
        'Интерфейс программы',
        'Инструменты рисования',
        'Работа с объектами',
        'Эффекты'
      ]
    },
    {
      title: 'Работа с макетами',
      topics: [
        'Создание логотипов',
        'Верстка макетов',
        'Работа с текстом',
        'Подготовка к печати'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание визиток',
        'Разработка баннеров',
        'Дизайн упаковки',
        'Полиграфия'
      ]
    }
  ],
  features: [
    '32 академических часа',
    '8 занятий',
    'Практические проекты',
    'Два сертификата',
    'Помощь в трудоустройстве'
  ]
};