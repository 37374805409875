import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_clinical',
  title: 'Клиническая психология. Особенности диагностики и психотерапии в клинической и психолого-педагогической практике',
  description: 'Курсы повышения квалификации по программе Клиническая психология. Особенности диагностики и психотерапии в клинической и психолого-педагогической практике (144 ч.) в дистанционном формате.',
  school: 'appkk',
  category: 'psychology',
  price: 11000,
  currency: 'RUR',
  duration: '144 часа',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fklinicheskaya_psikhologiya_osobennosti_diagnostiki_i_psikhoterapii_v_klinicheskoy_i_psikhologo_pedag%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Клиническая диагностика',
    'Психотерапевтические методы',
    'Работа с патологиями',
    'Психокоррекция',
    'Реабилитация'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'Теории личности',
        'Психодиагностика',
        'Патопсихология',
        'Нейропсихология'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Методы диагностики',
        'Коррекционные техники',
        'Психотерапия',
        'Реабилитация'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Работа с детьми',
        'Геронтопсихология',
        'Психиатрия',
        'Девиантология'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};