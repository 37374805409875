import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_psychosomatic_therapy_full',
  title: 'Психолог-консультант с дополнительной специализацией в области психосоматики и телесной психотерапии',
  description: 'Дистанционное обучение по программе Психолог-консультант с дополнительной специализацией в области психосоматики и телесной психотерапии с присвоением квалификации Психолог-консультант(1800 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 98000,
  currency: 'RUR',
  duration: '1800 часов',
  imageUrl: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpsikholog_konsultant_s_dopolnitelnoy_spetsializatsiey_v_oblasti_psikhosomatiki_i_telesnoy_psikhotera%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Психосоматическая диагностика',
    'Телесная психотерапия',
    'Работа с симптомами',
    'Релаксационные техники',
    'Психокоррекция'
  ],
  program: [
    {
      title: 'Основы психосоматики',
      topics: [
        'Теории психосоматики',
        'Диагностика',
        'Механизмы развития',
        'Классификация расстройств'
      ]
    },
    {
      title: 'Телесная психотерапия',
      topics: [
        'Методы работы с телом',
        'Дыхательные практики',
        'Телесно-ориентированные техники',
        'Работа с напряжением'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Ведение клиентов',
        'Групповая работа',
        'Профилактика',
        'Реабилитация'
      ]
    }
  ],
  features: [
    'Углубленная специализация',
    'Практическая подготовка',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};