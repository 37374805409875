import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_dataanalyst',
  title: 'Профессия Data Analyst',
  description: 'Онлайн курс «Data Analyst» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на аналитика данных (Data Analyst) с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 111154,
  oldPrice: 222307,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/B1Y7zmIkjnlvjA4k0OR4HlA9jorTFgyniWdH4WOs.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fit-specialist%2Fanalyst-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Анализ данных',
    'SQL и базы данных',
    'Python для анализа',
    'Визуализация данных',
    'Статистический анализ',
    'Business Intelligence'
  ],
  program: [
    {
      title: 'Основы анализа данных',
      topics: [
        'Введение в аналитику',
        'Методы анализа',
        'Сбор и подготовка данных',
        'Excel для аналитика'
      ]
    },
    {
      title: 'Базы данных и SQL',
      topics: [
        'Основы SQL',
        'Работа с базами данных',
        'Сложные запросы',
        'Оптимизация запросов'
      ]
    },
    {
      title: 'Python для анализа',
      topics: [
        'Pandas',
        'NumPy',
        'Matplotlib',
        'Jupyter Notebook'
      ]
    },
    {
      title: 'Визуализация и BI',
      topics: [
        'Power BI',
        'Tableau',
        'Построение дашбордов',
        'Презентация результатов'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат аналитика данных'
  ]
};