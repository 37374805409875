import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_smm_telegram',
  title: 'SMM-продвижение в Телеграме',
  description: 'Освоите базовые навыки SMM-продвижения в Телеграме: от создания канала до аналитики рекламы.',
  school: 'yandex',
  category: 'marketing',
  price: 16000,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1611606063065-ee7946f0787a?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fsmm-telegram&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Ведение Telegram-канала',
    'Создание контента',
    'Продвижение канала',
    'Работа с аудиторией',
    'Аналитика'
  ],
  program: [
    {
      title: 'Основы Telegram',
      topics: [
        'Создание канала',
        'Оформление',
        'Контент-стратегия',
        'Форматы постов'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Реклама',
        'Взаимопиар',
        'Работа с блогерами',
        'Органический рост'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'Метрики канала',
        'Анализ аудитории',
        'Оценка эффективности',
        'Оптимизация'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальным каналом',
    'Персональный наставник',
    'Помощь в продвижении',
    'Сертификат специалиста'
  ]
};