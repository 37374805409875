import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_web_design',
  title: 'Веб-дизайнер',
  description: 'Онлайн-курс «Веб-дизайнер» от Нетологии: Пройдите обучение на веб-дизайнера с нуля! Практические задания, реальные проекты и создание портфолио. Получите диплом, профессиональные навыки и помощь в трудоустройстве — начните карьеру в веб-дизайне уже сегодня!',
  school: 'netology',
  category: 'design',
  price: 144105,
  oldPrice: 240175,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fweb-designer&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4002,
  skills: [
    'Веб-дизайн',
    'UX/UI дизайн',
    'Figma',
    'Adobe Photoshop',
    'Адаптивный дизайн',
    'Прототипирование'
  ],
  program: [
    {
      title: 'Основы веб-дизайна',
      topics: [
        'Принципы дизайна',
        'Типографика',
        'Композиция',
        'Цвет в интерфейсах'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Figma',
        'Adobe Photoshop',
        'Прототипирование',
        'Анимация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание макетов',
        'Адаптивный дизайн',
        'Работа с заказчиками',
        'Портфолио'
      ]
    }
  ],
  features: [
    '29 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};