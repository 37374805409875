import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_3d_max',
  title: 'Офлайн-курс 3ds Max',
  description: 'Полный разбор программы 3ds Max: от интерфейса до работы с библиотеками. Дизайн интерьера и моделирование. Готовый проект в конце обучения',
  school: 'brunoyam',
  category: 'design',
  price: 28900,
  currency: 'RUB',
  duration: '7 недель',
  imageUrl: 'https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2F3d-max&m=5&erid=LdtCK4uLs',
  skills: [
    '3D моделирование',
    'Визуализация',
    'Текстурирование',
    'Освещение',
    'Анимация'
  ],
  program: [
    {
      title: 'Основы 3ds Max',
      topics: [
        'Интерфейс программы',
        'Примитивы и модификаторы',
        'Полигональное моделирование',
        'Сплайны'
      ]
    },
    {
      title: 'Материалы и текстуры',
      topics: [
        'Редактор материалов',
        'UV-развертка',
        'Текстурирование',
        'PBR материалы'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Настройка освещения',
        'Камеры',
        'Рендер',
        'Постобработка'
      ]
    }
  ],
  features: [
    '52 академических часа',
    'Практические проекты',
    'Создание портфолио',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};