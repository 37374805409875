import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_python_dev_plus',
  title: 'Python-разработчик расширенный',
  description: 'В дополнение к базовому курсу пройдёте ещё 4 темы: парсинг, альтернативные фреймворки Flask и FastAPI, Python для бизнеса.',
  school: 'yandex',
  category: 'programming',
  price: 221000,
  currency: 'RUB',
  duration: '14 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fpython-developer-plus&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Python разработка',
    'Flask и FastAPI',
    'Парсинг данных',
    'Python для бизнеса',
    'Продвинутые концепции'
  ],
  program: [
    {
      title: 'Парсинг и скрейпинг',
      topics: [
        'Работа с API',
        'Web скрейпинг',
        'Асинхронный парсинг',
        'Обработка данных'
      ]
    },
    {
      title: 'Альтернативные фреймворки',
      topics: [
        'Flask',
        'FastAPI',
        'REST API',
        'Микросервисы'
      ]
    },
    {
      title: 'Python для бизнеса',
      topics: [
        'Автоматизация',
        'Работа с документами',
        'Интеграции',
        'Оптимизация процессов'
      ]
    }
  ],
  features: [
    'Углубленная программа',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};