import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_cpp',
  title: 'Разработчик на С++',
  description: 'Профессия «Разработчик на C++» от Нетологии: вы освоите C++ с нуля до продвинутого уровня и добавите в портфолио до 15 проектов. Получите диплом и поддержку в трудоустройстве для успешного старта карьеры.',
  school: 'netology',
  category: 'programming',
  price: 140004,
  oldPrice: 233340,
  currency: 'RUB',
  duration: '11 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1629654297299-c8506221ca97?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fcpp-developer&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3889,
  skills: [
    'C++ разработка',
    'STL',
    'Многопоточность',
    'Алгоритмы',
    'ООП',
    'Оптимизация'
  ],
  program: [
    {
      title: 'Основы C++',
      topics: [
        'Синтаксис C++',
        'Типы данных',
        'Указатели и ссылки',
        'ООП'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'STL',
        'Многопоточность',
        'Шаблоны',
        'Исключения'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные проекты',
        'Оптимизация кода',
        'Отладка',
        'Code Review'
      ]
    }
  ],
  features: [
    '18 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};