import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_yandex_direct',
  title: 'Специалист по Яндекс Директу',
  description: 'Пройдите курс по Яндекс Директ в Нетологии. Научитесь настраивать контекстную рекламу, работать с Яндекс Метрикой и собирать семантические ядра. Получите практические навыки и возможность стажировки в Leading Digital-компании.',
  school: 'netology',
  category: 'marketing',
  price: 42000,
  oldPrice: 70000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fyd-course&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2333,
  skills: [
    'Яндекс Директ',
    'Контекстная реклама',
    'Яндекс Метрика',
    'Семантическое ядро',
    'Аналитика рекламы'
  ],
  program: [
    {
      title: 'Основы контекстной рекламы',
      topics: [
        'Принципы работы',
        'Настройка кампаний',
        'Подбор ключевых слов',
        'Создание объявлений'
      ]
    },
    {
      title: 'Работа с Метрикой',
      topics: [
        'Настройка целей',
        'Анализ данных',
        'Отчеты',
        'Оптимизация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с бюджетом',
        'A/B тестирование',
        'Оптимизация кампаний',
        'Масштабирование'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными проектами',
    'Стажировка',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};