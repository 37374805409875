import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_clinical_full',
  title: 'Клиническая психология. Психологическая диагностика и психотерапия в клинической и психолого-педагогической практике',
  description: 'Дистанционное обучение по программе Клиническая психология. Психологическая диагностика и психотерапия в клинической и психолого-педагогической практике с присвоением квалификации Клинический психолог(1250 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 62900,
  currency: 'RUR',
  duration: '1250 часов',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fklinicheskaya_psikhologiya_psikhologicheskaya_diagnostika_i_psikhoterapiya_v_klinicheskoy_i_psikholo%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Клиническая диагностика',
    'Психопатология',
    'Нейропсихология',
    'Психотерапия',
    'Реабилитация'
  ],
  program: [
    {
      title: 'Основы клинической психологии',
      topics: [
        'Общая психопатология',
        'Нейропсихология',
        'Патопсихология',
        'Психосоматика'
      ]
    },
    {
      title: 'Диагностика и обследование',
      topics: [
        'Клиническое интервью',
        'Психодиагностика',
        'Нейропсихологическая диагностика',
        'Оценка состояния'
      ]
    },
    {
      title: 'Методы терапии',
      topics: [
        'Психотерапевтические подходы',
        'Реабилитация',
        'Работа с семьей',
        'Профилактика'
      ]
    }
  ],
  features: [
    'Углубленная подготовка',
    'Практические навыки',
    'Супервизия',
    'Методические материалы',
    'Диплом клинического психолога'
  ]
};