import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_php',
  title: 'Офлайн-курс PHP-разработчик',
  description: 'Курс подойдёт тем, кто хочет работать backend-разработчиком и веб-программистом. Научитесь создавать веб-проекты на языке PHP. Обучение в мини-группах.',
  school: 'brunoyam',
  category: 'programming',
  price: 33900,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1599507593499-a3f7d7d97667?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-programmirovaniya%2Fphp&m=5&erid=LdtCK4uLs',
  skills: [
    'PHP',
    'MySQL',
    'Laravel',
    'REST API',
    'Git',
    'Composer'
  ],
  program: [
    {
      title: 'Основы PHP',
      topics: [
        'Синтаксис PHP',
        'ООП в PHP',
        'Работа с формами',
        'Базы данных'
      ]
    },
    {
      title: 'Laravel Framework',
      topics: [
        'MVC архитектура',
        'Маршрутизация',
        'Миграции',
        'Eloquent ORM'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разработка API',
        'Аутентификация',
        'Тестирование',
        'Деплой'
      ]
    }
  ],
  features: [
    '64 академических часа',
    '12 занятий',
    'Практика на реальных проектах',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};