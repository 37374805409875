import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_illustration_basics',
  title: 'Иллюстрация и рисование с нуля',
  description: 'Онлайн-курс «Иллюстратор» — Нетология: Пройдите обучение иллюстрации и рисованию с нуля. Практика на реальных проектах, освоение Photoshop, Illustrator и Cinema 4D, помощь с трудоустройством и диплом. Начните карьеру коммерческого иллюстратора с созданием портфолио за 9 месяцев обучения!',
  school: 'netology',
  category: 'design',
  price: 93684,
  oldPrice: 156140,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fillustration&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3903,
  skills: [
    'Рисование',
    'Цифровая иллюстрация',
    'Работа с графикой',
    'Создание персонажей',
    'Коммерческая иллюстрация'
  ],
  program: [
    {
      title: 'Основы рисования',
      topics: [
        'Композиция',
        'Перспектива',
        'Цвет и свет',
        'Анатомия'
      ]
    },
    {
      title: 'Цифровые инструменты',
      topics: [
        'Photoshop',
        'Illustrator',
        'Cinema 4D',
        'Графический планшет'
      ]
    },
    {
      title: 'Коммерческая практика',
      topics: [
        'Работа с заказчиками',
        'Создание портфолио',
        'Стоимость работ',
        'Продвижение'
      ]
    }
  ],
  features: [
    '22 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};