import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_unity',
  title: 'Разработка игр на Unity для детей',
  description: 'Узнайте на бесплатном занятии, понравится ли вашему ребенку создавать видеоигры',
  school: 'brunoyam',
  category: 'game-dev',
  price: 43628,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1580234811497-9df7fd2f357e?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fcourses%2Fgeek-school%2Fgamedev&m=4&erid=LdtCK7b7u',
  skills: [
    'Unity Engine',
    'C# для игр',
    'Геймдизайн',
    'Создание уровней',
    'Игровые механики'
  ],
  program: [
    {
      title: 'Основы Unity',
      topics: [
        'Интерфейс Unity',
        'Основы C#',
        'Игровые объекты',
        'Физика в играх'
      ]
    },
    {
      title: 'Разработка игр',
      topics: [
        'Создание механик',
        'Дизайн уровней',
        'Анимация',
        'Звуки и эффекты'
      ]
    },
    {
      title: 'Создание проекта',
      topics: [
        'Работа над игрой',
        'Тестирование',
        'Оптимизация',
        'Публикация'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Создание собственной игры',
    'Работа в команде',
    'Персональный наставник',
    'Сертификат разработчика'
  ]
};