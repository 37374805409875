import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_perinatal',
  title: 'Практические навыки по перинатальной психологии. Ступень 1',
  description: 'Дистанционное обучение по программе Практические навыки по перинатальной психологии. Ступень 1(37 часов) в ЦАППКК. Освоение методов работы с беременными женщинами и молодыми родителями.',
  school: 'appkk',
  category: 'psychology',
  price: 17900,
  currency: 'RUR',
  duration: '37 часов',
  imageUrl: 'https://images.unsplash.com/photo-1584714268709-c3dd9c92b378?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_po_perinatalnoy_psikhologii_stupen_1%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Перинатальное консультирование',
    'Работа с беременными',
    'Психологическая подготовка к родам',
    'Сопровождение семьи',
    'Работа с психотравмой'
  ],
  program: [
    {
      title: 'Основы перинатальной психологии',
      topics: [
        'Психология беременности',
        'Эмоциональные состояния',
        'Семейные отношения',
        'Подготовка к родам'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Консультирование семьи',
        'Коррекция страхов',
        'Работа с травмой',
        'Поддержка диады'
      ]
    },
    {
      title: 'Сложные ситуации',
      topics: [
        'Осложнения беременности',
        'Перинатальные потери',
        'Послеродовая депрессия',
        'Кризисные состояния'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};