import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_blood_formula',
  title: 'Формула крови: практический курс для врачей',
  description: 'Обучение онлайн на платформе Нетология - курсы IT и образовательные программы с трудоустройством. Получи профессию с нашими онлайн курсами!',
  school: 'netology',
  category: 'medicine',
  price: 25000,
  oldPrice: 25000,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1579165466741-7f35e4755660?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fhematology&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2083,
  skills: [
    'Гематология',
    'Анализ крови',
    'Диагностика',
    'Интерпретация результатов',
    'Клиническая практика'
  ],
  program: [
    {
      title: 'Основы гематологии',
      topics: [
        'Состав крови',
        'Показатели анализов',
        'Нормы и отклонения',
        'Методы исследования'
      ]
    },
    {
      title: 'Диагностика',
      topics: [
        'Интерпретация результатов',
        'Клинические случаи',
        'Дифференциальная диагностика',
        'Алгоритмы обследования'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разбор кейсов',
        'Сложные случаи',
        'Современные методики',
        'Рекомендации'
      ]
    }
  ],
  features: [
    'Практические занятия',
    'Разбор клинических случаев',
    'Консультации экспертов',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};