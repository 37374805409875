import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_photo',
  title: 'Курс «Авторская фотография»',
  description: 'Профессиональный курс по фотографии для подготовки к творческим экзаменам. Научитесь создавать уникальные художественные снимки и развить свой авторский стиль.',
  school: '100points',
  category: 'photo-video',
  price: 35802,
  oldPrice: 79560,
  currency: 'RUB',
  duration: '24 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1542038784456-1ea8e935640e?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 2984,
  discountPercent: 55,
  skills: [
    'Работа с фототехникой',
    'Композиция и построение кадра',
    'Работа со светом',
    'Художественная фотография',
    'Обработка фотографий',
    'Создание портфолио'
  ],
  program: [
    {
      title: 'Основы фотографии',
      topics: [
        'Устройство камеры',
        'Экспозиция',
        'Фокусировка',
        'Основы композиции'
      ]
    },
    {
      title: 'Художественная съемка',
      topics: [
        'Работа со светом',
        'Построение кадра',
        'Художественные приемы',
        'Авторский стиль'
      ]
    },
    {
      title: 'Жанры фотографии',
      topics: [
        'Портретная съемка',
        'Пейзажная фотография',
        'Репортажная съемка',
        'Предметная съемка'
      ]
    },
    {
      title: 'Постобработка',
      topics: [
        'Работа с RAW',
        'Цветокоррекция',
        'Художественная обработка',
        'Подготовка к печати'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Индивидуальные консультации',
    'Разбор работ',
    'Помощь в создании портфолио',
    'Подготовка к экзаменам'
  ]
};