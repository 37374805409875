import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_excel_basic',
  title: 'Excel: базовый курс',
  description: 'Обучение Excel в Нетологии с нуля до профессионала. Освойте сводные таблицы, формулы и функции. Практический курс с сертификацией и примерами из реальной жизни. Запишитесь уже сегодня!',
  school: 'netology',
  category: 'analytics',
  price: 15750,
  oldPrice: 22500,
  currency: 'RUB',
  duration: '9 недель',
  imageUrl: 'https://images.unsplash.com/photo-1543286386-713bdd548da4?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fexcel-s-nulya&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 1312,
  skills: [
    'Основы Excel',
    'Работа с формулами',
    'Создание таблиц',
    'Базовая аналитика',
    'Визуализация данных'
  ],
  program: [
    {
      title: 'Основы работы',
      topics: [
        'Интерфейс Excel',
        'Ввод данных',
        'Форматирование',
        'Простые формулы'
      ]
    },
    {
      title: 'Базовые функции',
      topics: [
        'Математические функции',
        'Логические функции',
        'Работа с датами',
        'Фильтрация'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Создание таблиц',
        'Построение диаграмм',
        'Графики',
        'Базовые отчеты'
      ]
    }
  ],
  features: [
    '22 практических занятия',
    'Базовые навыки',
    'Практические задания',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};