import { create } from 'zustand';
import { Course, School, Category } from '../types';

interface CourseStore {
  courses: Course[];
  schools: School[];
  categories: Category[];
  searchQuery: string;
  selectedCategory: string | null;
  selectedSchool: string | null;
  setSearchQuery: (query: string) => void;
  setSelectedCategory: (categoryId: string | null) => void;
  setSelectedSchool: (schoolId: string | null) => void;
  setCourses: (courses: Course[]) => void;
  setSchools: (schools: School[]) => void;
  setCategories: (categories: Category[]) => void;
  getFilteredCourses: () => Course[];
}

// Dictionary for search translations
const professionTranslations: { [key: string]: string[] } = {
  'programming': ['программирование', 'разработка', 'coding', 'development', 'программист', 'разработчик', 'питон', 'пайтон', 'джава', 'ява', 'javascript', 'джаваскрипт', 'питончик'],
  'marketing': ['маркетинг', 'реклама', 'продвижение', 'advertising', 'маркетолог', 'смм', 'таргетолог', 'контекстная реклама'],
  'design': ['дизайн', 'графика', 'визуал', 'creative', 'дизайнер', 'фотошоп', 'иллюстратор', 'веб-дизайн', 'графический дизайн'],
  'analytics': ['аналитика', 'данные', 'метрики', 'data', 'аналитик', 'анализ данных', 'статистика', 'бизнес-аналитика'],
  'management': ['управление', 'менеджмент', 'leadership', 'менеджер', 'руководитель', 'управленец'],
  'languages': ['язык', 'перевод', 'language', 'translation', 'английский', 'немецкий', 'французский', 'китайский', 'испанский'],
  'business': ['бизнес', 'предпринимательство', 'finance', 'финансы', 'предприниматель', 'стартап', 'дело'],
  'school': ['школьный', 'школьник', 'для школы', 'егэ', 'огэ', 'экзамен'],
  'development': ['разработка', 'девелопмент', 'создание', 'программирование'],
  'web': ['веб', 'вэб', 'сайты', 'интернет'],
  'mobile': ['мобильный', 'mobile', 'приложения'],
  'data_science': ['data science', 'наука о данных', 'машинное обучение', 'ml'],
  'artificial_intelligence': ['искусственный интеллект', 'ии', 'ai', 'нейросети', 'нейронные сети'],
  'cloud': ['облачные технологии', 'cloud', 'облака'],
  'security': ['безопасность', 'security', 'защита'],
  'devops': ['девопс', 'devops', 'автоматизация'],
  'qa': ['тестирование', 'quality assurance', 'qa', 'тестировщик'],
  'ux_ui': ['ux/ui', 'user experience', 'user interface', 'юикс', 'юай'],
  'digital': ['digital', 'диджитал', 'цифровой'],
  'education': ['образование', 'обучение', 'преподавание'],
  'psychology': ['психология', 'психолог', 'психологическое консультирование'],
  'medicine': ['медицина', 'здравоохранение', 'врач'],
  'finance': ['финансы', 'экономика', 'бухгалтерия'],
  'law': ['право', 'юриспруденция', 'закон']
};

export const useCourseStore = create<CourseStore>((set, get) => ({
  courses: [],
  schools: [],
  categories: [],
  searchQuery: '',
  selectedCategory: null,
  selectedSchool: null,
  setCourses: (courses) => set({ courses }),
  setSchools: (schools) => set({ schools }),
  setCategories: (categories) => set({ categories }),
  setSearchQuery: (query) => set({ searchQuery: query }),
  setSelectedCategory: (categoryId) => set({ selectedCategory: categoryId }),
  setSelectedSchool: (schoolId) => set({ selectedSchool: schoolId }),
  getFilteredCourses: () => {
    const { courses, searchQuery, selectedCategory, selectedSchool, categories, schools } = get();
    
    return courses.filter(course => {
      // Normalize strings for search
      const normalizedQuery = searchQuery.toLowerCase().trim();
      const normalizedTitle = course.title.toLowerCase();
      const normalizedDescription = course.description?.toLowerCase() || '';
      const category = categories.find(c => c.id === course.category);
      const school = schools.find(s => s.id === course.school);
      const normalizedCategory = category?.name.toLowerCase() || '';
      const normalizedSchool = school?.name.toLowerCase() || '';
      
      // Check all possible fields for search
      let matchesSearch = !normalizedQuery || [
        normalizedTitle,
        normalizedDescription,
        normalizedCategory,
        normalizedSchool,
        ...(course.skills || []).map(skill => skill.toLowerCase()),
        ...(course.program || []).flatMap(module => [
          module.title.toLowerCase(),
          ...module.topics.map(topic => topic.toLowerCase())
        ]),
        ...(course.features || []).map(feature => feature.toLowerCase())
      ].some(text => text.includes(normalizedQuery));

      // Check translations for professions and terms
      if (!matchesSearch && normalizedQuery) {
        const words = normalizedQuery.split(/\s+/);
        matchesSearch = words.every(word => {
          return Object.entries(professionTranslations).some(([key, translations]) => {
            const normalizedKey = key.toLowerCase();
            const normalizedTranslations = translations.map(t => t.toLowerCase());
            
            const wordMatches = normalizedKey.includes(word) || 
              normalizedTranslations.some(t => t.includes(word));
            
            const fieldsToCheck = [
              normalizedTitle,
              normalizedDescription,
              normalizedCategory,
              normalizedSchool,
              ...(course.skills || []).map(skill => skill.toLowerCase()),
              ...(course.program || []).flatMap(module => [
                module.title.toLowerCase(),
                ...module.topics.map(topic => topic.toLowerCase())
              ]),
              ...(course.features || []).map(feature => feature.toLowerCase())
            ];

            return wordMatches && fieldsToCheck.some(field => 
              field.includes(normalizedKey) || 
              normalizedTranslations.some(t => field.includes(t))
            );
          });
        });
      }
      
      // Check filters
      const matchesCategory = !selectedCategory || course.category === selectedCategory;
      const matchesSchool = !selectedSchool || course.school === selectedSchool;
      
      return matchesSearch && matchesCategory && matchesSchool;
    });
  }
}));