import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sql',
  title: 'Основы SQL',
  description: 'Бесплатное обучение работе SQL и базами данных для начинающих от Нетологии. На онлайн курсе, пройдя бесплатные уроки, вы с нуля научитесь работать с базой данных (БД) в SQL.',
  school: 'netology',
  category: 'analytics',
  price: 0,
  oldPrice: 0,
  currency: 'RUB',
  duration: '14 дней',
  imageUrl: 'https://images.unsplash.com/photo-1544383835-bda2bc66a55d?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fvvedenie-v-sql-i-rabotu-s-bazoi-dannih&m=5&erid=LdtCKCxkP',
  skills: [
    'Основы SQL',
    'Работа с базами данных',
    'Запросы SELECT',
    'Фильтрация данных',
    'Базовые операции'
  ],
  program: [
    {
      title: 'Введение в SQL',
      topics: [
        'Основы баз данных',
        'Структура SQL',
        'Типы данных',
        'Таблицы'
      ]
    },
    {
      title: 'Практика SQL',
      topics: [
        'SELECT запросы',
        'WHERE условия',
        'JOIN операции',
        'Группировка'
      ]
    }
  ],
  features: [
    '8 практических занятий',
    'Базовые навыки',
    'Интерактивные уроки',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};