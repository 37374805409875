import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_power_bi_analytics',
  title: 'Power BI: анализ и визуализация данных',
  description: 'Изучите Power BI на онлайн-курсе в Нетологии. Освойте создание дашбордов и анализ данных за 2 месяца. Дистанционное обучение с сертификатом и проектами для портфолио. Получите навыки, востребованные на рынке, и начните карьеру аналитика.',
  school: 'netology',
  category: 'analytics',
  price: 32200,
  oldPrice: 46000,
  currency: 'RUB',
  duration: '9 недель',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fpower-bi&m=5&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2683,
  skills: [
    'Power BI',
    'Визуализация данных',
    'DAX формулы',
    'Построение дашбордов',
    'Анализ данных'
  ],
  program: [
    {
      title: 'Основы Power BI',
      topics: [
        'Интерфейс Power BI',
        'Загрузка данных',
        'Трансформация данных',
        'Создание отчетов'
      ]
    },
    {
      title: 'Продвинутые техники',
      topics: [
        'DAX формулы',
        'Сложные визуализации',
        'Моделирование данных',
        'Публикация отчетов'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание дашбордов',
        'Анализ данных',
        'Оптимизация отчетов',
        'Реальные кейсы'
      ]
    }
  ],
  features: [
    '25 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};