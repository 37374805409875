import { Course } from '../../../../types';

export const course: Course = {
  id: 'anecole_italian_exams',
  title: 'Итальянский для подготовки к экзаменам',
  description: 'Подготовка к языковым экзаменам – это большая работа, которая требует усидчивости, терпения и регулярности. Наши преподаватели организуют весь процесс подготовки, пройдут с вами этот путь и помогут получить заветный балл.',
  school: 'anecole',
  category: 'italian',
  price: 19120,
  currency: 'RUB',
  duration: '8 часов',
  imageUrl: 'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=1200',
  url: 'https://go.redav.online/4f106e4f2bb25c71?dl=https%3A%2F%2Fanecole.com%2Fitalian-exams&m=5&erid=2Vfnxwa5gNL',
  monthlyPayment: 4780,
  skills: [
    'Подготовка к CILS/CELI',
    'Аудирование',
    'Чтение',
    'Письмо',
    'Говорение'
  ],
  program: [
    {
      title: 'Подготовительный этап',
      topics: [
        'Вводный урок',
        'Диагностика уровня',
        'Структура экзамена',
        'Стратегии подготовки'
      ]
    },
    {
      title: 'Практика экзамена',
      topics: [
        'Тренировочные тесты',
        'Разбор типичных ошибок',
        'Пробный экзамен',
        'Финальная подготовка'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивные тренажеры',
    'Пробные тесты',
    'Проверка работ',
    'Сертификат по окончании'
  ]
};