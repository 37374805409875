import React from 'react';

const categoryGroups = [
  {
    id: 'programming',
    name: 'Программирование',
    description: 'Frontend, Backend, Mobile, GameDev',
    color: 'bg-blue-100 text-blue-800'
  },
  {
    id: 'marketing',
    name: 'Маркетинг',
    description: 'SMM, SEO, Контекст, Таргетинг',
    color: 'bg-green-100 text-green-800'
  },
  {
    id: 'design',
    name: 'Дизайн',
    description: 'UX/UI, Графика, 3D, Motion',
    color: 'bg-purple-100 text-purple-800'
  },
  {
    id: 'analytics',
    name: 'Аналитика',
    description: 'Data Science, BI, Метрики',
    color: 'bg-yellow-100 text-yellow-800'
  },
  {
    id: 'management',
    name: 'Управление',
    description: 'Проекты, Продукты, HR',
    color: 'bg-red-100 text-red-800'
  },
  {
    id: 'languages',
    name: 'Языки',
    description: 'Английский, Немецкий, Китайский',
    color: 'bg-indigo-100 text-indigo-800'
  },
  {
    id: 'business',
    name: 'Бизнес',
    description: 'Финансы, Продажи, Стратегия',
    color: 'bg-orange-100 text-orange-800'
  },
  {
    id: 'school',
    name: 'Для школы',
    description: 'Подготовка к экзаменам, Развитие',
    color: 'bg-pink-100 text-pink-800'
  }
];

export const CategoryGroups: React.FC = () => {
  const handleCategoryClick = (categoryId: string) => {
    const element = document.getElementById(`category-${categoryId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-12">
      {categoryGroups.map((group) => (
        <div
          key={group.id}
          onClick={() => handleCategoryClick(group.id)}
          className="block p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow bg-white border border-gray-100 cursor-pointer"
        >
          <div className={`inline-block px-2 py-1 rounded text-sm font-medium mb-2 ${group.color}`}>
            {group.name}
          </div>
          <p className="text-sm text-gray-600">{group.description}</p>
        </div>
      ))}
    </div>
  );
};