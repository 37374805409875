import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_stylist',
  title: 'Стилист-имиджмейкер',
  description: 'Пройдите курс стилиста-имиджмейкера в Нетологии. Обучение онлайн, практика с реальными клиентами, диплом, помощь в трудоустройстве. Начните карьеру стилиста и имиджмейкера уже сегодня!',
  school: 'netology',
  category: 'design',
  price: 85200,
  oldPrice: 142000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1483985988355-763728e1935b?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ffashion_stylist&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3550,
  skills: [
    'Стилистика',
    'Колористика',
    'Работа с клиентами',
    'Создание образов',
    'Шопинг-сопровождение'
  ],
  program: [
    {
      title: 'Основы стилистики',
      topics: [
        'Теория стиля',
        'Цветовой анализ',
        'Типы фигуры',
        'Базовый гардероб'
      ]
    },
    {
      title: 'Практика стилиста',
      topics: [
        'Работа с клиентами',
        'Создание образов',
        'Шопинг-сопровождение',
        'Разбор гардероба'
      ]
    },
    {
      title: 'Бизнес стилиста',
      topics: [
        'Продвижение услуг',
        'Работа с брендами',
        'Ценообразование',
        'Портфолио'
      ]
    }
  ],
  features: [
    '10 практических занятий',
    'Работа с клиентами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};