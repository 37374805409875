import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_team_management_pro',
  title: 'Расширенный курс «Управление командой»',
  description: 'Расширенный курс «Управление командой»',
  school: 'yandex',
  category: 'management',
  price: 112500,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fteam-management%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление командой',
    'Лидерство',
    'Развитие сотрудников',
    'Управление конфликтами',
    'Стратегическое планирование'
  ],
  program: [
    {
      title: 'Лидерство и управление',
      topics: [
        'Стили руководства',
        'Мотивация команды',
        'Делегирование',
        'Принятие решений'
      ]
    },
    {
      title: 'Развитие команды',
      topics: [
        'Оценка персонала',
        'Обучение сотрудников',
        'Карьерное развитие',
        'Управление талантами'
      ]
    },
    {
      title: 'Организационное развитие',
      topics: [
        'Управление изменениями',
        'Организационная культура',
        'Процессы и KPI',
        'Стратегическое планирование'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Практические кейсы',
    'Персональный наставник',
    'Работа с реальной командой',
    'Сертификат руководителя'
  ]
};