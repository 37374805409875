import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_email',
  title: 'Очный курс Email-маркетинг',
  description: 'За месяц научитесь делать рассылки в email, мессенджерах и пушах — от Tone of Voice до вёрстки. Практика приближена к реальным рабочим задачам и тестовым',
  school: 'brunoyam',
  category: 'marketing',
  price: 19900,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1596526131083-e8c633c948d2?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-internet-marketing%2Femail-marketing&m=5&erid=LdtCK4uLs',
  skills: [
    'Email-маркетинг',
    'Создание рассылок',
    'Автоматизация',
    'A/B тестирование',
    'Аналитика рассылок'
  ],
  program: [
    {
      title: 'Основы email-маркетинга',
      topics: [
        'Стратегия рассылок',
        'Сегментация базы',
        'Tone of Voice',
        'Типы рассылок'
      ]
    },
    {
      title: 'Создание писем',
      topics: [
        'Структура письма',
        'Копирайтинг',
        'Верстка писем',
        'Дизайн рассылок'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Метрики эффективности',
        'A/B тестирование',
        'Автоматизация',
        'Антиспам'
      ]
    }
  ],
  features: [
    '24 академических часа',
    'Практические задания',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};