import { Course } from '../../../../types';

export const course: Course = {
  id: 'abakus_reading_8',
  title: 'Чтение Абакус - обучение и курсы для детей | 8 уроков',
  description: 'Наша авторская методика, направленная на развитие навыков осознанного чтения и улучшение техники и скорости чтения',
  school: 'abakus',
  category: 'russian',
  price: 10800,
  currency: 'RUB',
  duration: '1 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?q=80&w=1200',
  url: 'https://go.redav.online/86fdf99551e92c21?dl=https%3A%2F%2Fabakus-center.ru%2Feducation%2Freading&m=5&erid=2Vfnxx8cgzL',
  monthlyPayment: 10800,
  skills: [
    'Техника чтения',
    'Скорость чтения',
    'Понимание текста',
    'Развитие памяти',
    'Расширение словарного запаса'
  ],
  program: [
    {
      title: 'Основы скорочтения',
      topics: [
        'Диагностика навыков',
        'Базовые упражнения',
        'Работа с текстами',
        'Развитие внимания'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Упражнения на скорость',
        'Работа с пониманием',
        'Развивающие игры',
        'Тренировка памяти'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивная платформа',
    'Опытные преподаватели',
    'Домашние задания',
    'Отслеживание прогресса'
  ]
};