import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_data_analytics_mfti',
  title: 'Аналитика данных с МФТИ',
  description: 'Обучение аналитике данных на совместном курсе Нетологии и МФТИ с дипломом о профессиональной переподготовке.',
  school: 'netology',
  category: 'analytics',
  price: 247200,
  oldPrice: 412000,
  currency: 'RUB',
  duration: '11 дней',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fprofessiya-analitik-dannyh-mfti&m=4&erid=LdtCKCxkP',
  monthlyPayment: 6866,
  discountPercent: 40,
  skills: [
    'Анализ данных',
    'Python для анализа',
    'Математическая статистика',
    'Machine Learning',
    'Визуализация данных'
  ],
  program: [
    {
      title: 'Основы анализа данных',
      topics: [
        'Python и библиотеки',
        'Статистика',
        'Визуализация',
        'Предобработка данных'
      ]
    },
    {
      title: 'Продвинутая аналитика',
      topics: [
        'Machine Learning',
        'Глубокое обучение',
        'Big Data',
        'Нейронные сети'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные проекты',
        'Работа с данными',
        'Построение моделей',
        'Защита проектов'
      ]
    }
  ],
  features: [
    'Совместная программа с МФТИ',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом двух учебных заведений'
  ]
};