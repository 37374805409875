import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_stard',
  title: 'Старт в дизайне',
  description: 'Введение в профессию дизайнера',
  school: 'contented',
  category: 'design',
  price: 183007,
  oldPrice: 332739,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fdesign-start-proforientacia&m=5&erid=LdtCKHL5u',
  monthlyPayment: 5084,
  discountPercent: 45,
  skills: [
    'Основы дизайна',
    'Работа с цветом',
    'Типографика',
    'Композиция',
    'Работа с графическими редакторами',
    'Создание портфолио'
  ],
  program: [
    {
      title: 'Введение в дизайн',
      topics: [
        'История дизайна',
        'Принципы дизайна',
        'Теория цвета',
        'Основы композиции'
      ]
    },
    {
      title: 'Инструменты дизайнера',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Figma',
        'Работа с референсами'
      ]
    },
    {
      title: 'Практика дизайна',
      topics: [
        'Создание логотипов',
        'Разработка фирменного стиля',
        'Дизайн интерфейсов',
        'Работа с типографикой'
      ]
    },
    {
      title: 'Профессиональное развитие',
      topics: [
        'Создание портфолио',
        'Работа с заказчиками',
        'Презентация проектов',
        'Развитие в профессии'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Персональный наставник',
    'Доступ к онлайн-библиотеке',
    'Помощь в создании портфолио',
    'Сертификат о прохождении'
  ]
};