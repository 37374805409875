import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_hr',
  title: 'Инновационное управление персоналом организации',
  description: 'Программа повышения квалификации Инновационное управление персоналом организации с присвоением квалификации Директор по персоналу (1030 ч.) в дистанционном формате на базе высшего или среднего профессионального образования, 2024 год.',
  school: 'appkk',
  category: 'management',
  price: 58700,
  currency: 'RUR',
  duration: '1030 часов',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Finnovatsionnoe_upravlenie_personalom_organizatsii_s_prisvoeniem_kvalifikatsii_direktor_po_personalu%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Управление персоналом',
    'HR-стратегия',
    'Подбор персонала',
    'Развитие сотрудников',
    'Организационные изменения'
  ],
  program: [
    {
      title: 'Стратегическое управление',
      topics: [
        'HR-стратегия',
        'Организационное развитие',
        'Управление изменениями',
        'KPI в HR'
      ]
    },
    {
      title: 'Операционное управление',
      topics: [
        'Рекрутмент',
        'Адаптация персонала',
        'Оценка персонала',
        'Развитие талантов'
      ]
    },
    {
      title: 'Инновационные подходы',
      topics: [
        'Digital HR',
        'HR-аналитика',
        'Автоматизация процессов',
        'Управление вовлеченностью'
      ]
    }
  ],
  features: [
    'Практические инструменты',
    'Разбор кейсов',
    'Проектная работа',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};