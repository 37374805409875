import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_cpp',
  title: 'Разработчик C++',
  description: 'Освоите базовые алгоритмы, структуры данных и стандартную библиотеку C++, чтобы разрабатывать сложные IT-продукты: от браузеров до беспилотных автомобилей.',
  school: 'yandex',
  category: 'programming',
  price: 135000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1629654297299-c8506221ca97?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fcpp&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'C++ разработка',
    'Алгоритмы',
    'Структуры данных',
    'Оптимизация кода',
    'Многопоточность'
  ],
  program: [
    {
      title: 'Основы C++',
      topics: [
        'Синтаксис C++',
        'Указатели и ссылки',
        'ООП',
        'STL'
      ]
    },
    {
      title: 'Алгоритмы и структуры данных',
      topics: [
        'Сложность алгоритмов',
        'Базовые структуры',
        'Сортировки и поиск',
        'Графы и деревья'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Многопоточность',
        'Оптимизация',
        'Шаблоны',
        'Профилирование'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Code review',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о профессии'
  ]
};