import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_filmed',
  title: 'Профессия Режиссёр монтажа',
  description: 'Профессиональный курс по видеомонтажу и режиссуре. Научитесь создавать качественный видеоконтент и работать с профессиональными инструментами монтажа.',
  school: 'contented',
  category: 'photo-video',
  price: 123420,
  oldPrice: 224400,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1574717024653-61fd2cf4d44d?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fvideo-editor-producer&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3428,
  discountPercent: 45,
  skills: [
    'Видеомонтаж',
    'Работа со звуком',
    'Цветокоррекция',
    'Визуальные эффекты',
    'Режиссура монтажа',
    'Работа с форматами'
  ],
  program: [
    {
      title: 'Основы монтажа',
      topics: [
        'Теория монтажа',
        'Монтажные программы',
        'Форматы видео',
        'Композиция кадра'
      ]
    },
    {
      title: 'Технические аспекты',
      topics: [
        'Работа со звуком',
        'Цветокоррекция',
        'Визуальные эффекты',
        'Титры и графика'
      ]
    },
    {
      title: 'Режиссура монтажа',
      topics: [
        'Драматургия',
        'Темпоритм',
        'Стилистика',
        'Работа с материалом'
      ]
    },
    {
      title: 'Профессиональная практика',
      topics: [
        'Работа над проектами',
        'Взаимодействие с командой',
        'Сдача проекта',
        'Портфолио'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Работа с профессиональным ПО',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат режиссера монтажа'
  ]
};