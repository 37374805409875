import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_product_marketing_manager',
  title: 'Продуктовый маркетолог',
  description: 'Онлайн-курс «Product‌ ‌marketing‌ ‌manager‌»: обучение на продуктового маркетолога',
  school: 'yandex',
  category: 'marketing',
  price: 79000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fproduct-marketing-manager&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продуктовый маркетинг',
    'Анализ рынка',
    'Стратегия продвижения',
    'Работа с метриками',
    'Управление продуктом'
  ],
  program: [
    {
      title: 'Основы продуктового маркетинга',
      topics: [
        'Анализ рынка',
        'Целевая аудитория',
        'Позиционирование',
        'Ценностное предложение'
      ]
    },
    {
      title: 'Продвижение продукта',
      topics: [
        'Каналы продвижения',
        'Маркетинговые кампании',
        'Работа с метриками',
        'A/B тестирование'
      ]
    },
    {
      title: 'Стратегия и аналитика',
      topics: [
        'Разработка стратегии',
        'Анализ конкурентов',
        'Оценка эффективности',
        'Оптимизация воронки'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными продуктами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};