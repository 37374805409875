import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_marketplace_pro',
  title: 'Менеджер по маркетплейсам',
  description: 'Курс Менеджер по маркетплейсам: научитесь зарабатывать на фрилансе или как селлер. Черная Пятница в Нетологии - двойные скидки на курсы!',
  school: 'netology',
  category: 'marketing',
  price: 42000,
  oldPrice: 70000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmenedger-marketplace&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2333,
  skills: [
    'Работа с маркетплейсами',
    'Управление магазином',
    'Аналитика продаж',
    'Оптимизация карточек',
    'Работа с поставщиками'
  ],
  program: [
    {
      title: 'Основы работы',
      topics: [
        'Регистрация магазина',
        'Настройка кабинета',
        'Работа с каталогом',
        'Управление товарами'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Оптимизация карточек',
        'Работа с отзывами',
        'Реклама',
        'Акции'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'Анализ продаж',
        'Работа с остатками',
        'Ценообразование',
        'Отчетность'
      ]
    }
  ],
  features: [
    '46 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в запуске',
    'Сертификат специалиста'
  ]
};