import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_child_neuro',
  title: 'Нейропсихология детского возраста',
  description: 'Дистанционное обучение по программе Нейропсихология детского возраста(200 часов) в ЦАППКК. Комплексная программа по диагностике и коррекции нейропсихологических особенностей развития детей.',
  school: 'appkk',
  category: 'psychology',
  price: 10500,
  currency: 'RUR',
  duration: '200 часов',
  imageUrl: 'https://images.unsplash.com/photo-1503919545889-aef636e10ad4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2F_neyropsikhologiya_detskogo_vozrasta%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Нейропсихологическая диагностика',
    'Коррекционная работа',
    'Развитие высших психических функций',
    'Работа с детьми с ОВЗ',
    'Консультирование родителей'
  ],
  program: [
    {
      title: 'Основы нейропсихологии',
      topics: [
        'Теория А.Р. Лурии',
        'Мозговая организация ВПФ',
        'Нейропсихологические факторы',
        'Межполушарное взаимодействие'
      ]
    },
    {
      title: 'Диагностика',
      topics: [
        'Методы обследования',
        'Нейропсихологические пробы',
        'Анализ результатов',
        'Составление заключения'
      ]
    },
    {
      title: 'Коррекционная работа',
      topics: [
        'Методы коррекции',
        'Двигательные методы',
        'Когнитивные методы',
        'Работа с родителями'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор случаев',
    'Методические материалы',
    'Супервизия',
    'Сертификат по окончании'
  ]
};