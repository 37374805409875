import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_motion',
  title: 'Офлайн-курс Motion Design',
  description: 'Очный курс-интенсив в маленькой группе. Изучите дизайн, анимацию, режиссуру и видеомонтаж. В конце обучения сможете создавать видео для соцсетей, промо-ролики, короткометражные фильмы и клипы',
  school: 'brunoyam',
  category: 'design',
  price: 24900,
  currency: 'RUB',
  duration: '120 недель',
  imageUrl: 'https://images.unsplash.com/photo-1551269901-5c5e14c25df7?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fafter-effects&m=5&erid=LdtCK4uLs',
  skills: [
    'Motion Design',
    'After Effects',
    'Cinema 4D',
    'Анимация',
    'Композитинг',
    'Режиссура'
  ],
  program: [
    {
      title: 'Основы анимации',
      topics: [
        'Принципы анимации',
        'Таймлайн',
        'Кривые скорости',
        'Композиция'
      ]
    },
    {
      title: 'After Effects',
      topics: [
        'Интерфейс программы',
        'Эффекты и пресеты',
        'Выражения',
        'Рендеринг'
      ]
    },
    {
      title: '3D и композитинг',
      topics: [
        'Cinema 4D',
        'Интеграция с AE',
        'Композитинг',
        'Цветокоррекция'
      ]
    }
  ],
  features: [
    '52 академических часа',
    '3 проекта',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};