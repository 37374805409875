import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_qa_automation_java_extended',
  title: 'Автоматизатор тестирования на Java: расширенная версия',
  description: 'Курс для тех, кто уже умеет тестировать. Выучите Java, чтобы автоматизировать тесты для веб-приложений и API.',
  school: 'yandex',
  category: 'programming',
  price: 136800,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fqa-automation-engineer-java%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Java для тестирования',
    'Автоматизация тестирования',
    'Selenium WebDriver',
    'REST API тестирование',
    'CI/CD'
  ],
  program: [
    {
      title: 'Java для автоматизации',
      topics: [
        'Основы Java',
        'ООП',
        'Коллекции',
        'Исключения'
      ]
    },
    {
      title: 'Автоматизация тестирования',
      topics: [
        'Selenium WebDriver',
        'Page Object',
        'TestNG',
        'REST Assured'
      ]
    },
    {
      title: 'CI/CD и инфраструктура',
      topics: [
        'Git',
        'Jenkins',
        'Docker',
        'Allure'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат тестировщика'
  ]
};