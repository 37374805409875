import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_course_producer',
  title: 'Продюсер онлайн-курсов',
  description: 'Начнёте управлять созданием онлайн-курсов: от идеи до масштабирования. Научитесь координировать команду специалистов и выстраивать процессы, чтобы курс приносил пользу студентам и прибыль бизнесу',
  school: 'brunoyam',
  category: 'management',
  price: 49900,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fproducer-online-courses&m=5&erid=LdtCK4uLs',
  skills: [
    'Создание онлайн-курсов',
    'Управление командой',
    'Маркетинг курсов',
    'Аналитика и метрики',
    'Масштабирование проектов'
  ],
  program: [
    {
      title: 'Разработка курса',
      topics: [
        'Анализ рынка',
        'Создание концепции',
        'Структура курса',
        'Методология обучения'
      ]
    },
    {
      title: 'Производство',
      topics: [
        'Управление командой',
        'Создание контента',
        'Техническая реализация',
        'Контроль качества'
      ]
    },
    {
      title: 'Продвижение и развитие',
      topics: [
        'Маркетинговая стратегия',
        'Монетизация',
        'Аналитика результатов',
        'Масштабирование'
      ]
    }
  ],
  features: [
    '2 проекта в портфолио',
    'Практические задания',
    'Работа с реальными курсами',
    'Персональный наставник',
    'Сертификат продюсера'
  ]
};