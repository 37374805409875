import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_intpro',
  title: 'Профессия «Дизайнер жилых и коммерческих интерьеров»',
  description: 'Углубленное обучение дизайну интерьеров. Научитесь создавать профессиональные проекты для жилых и коммерческих помещений.',
  school: 'contented',
  category: 'design',
  price: 198824,
  oldPrice: 361498,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://static.tildacdn.com/stor3666-6238-4666-b433-616230653934/84194183.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Finterior-designer-pro&m=5&erid=LdtCKHL5u',
  monthlyPayment: 5523,
  discountPercent: 45,
  skills: [
    'Проектирование жилых интерьеров',
    'Дизайн коммерческих помещений',
    'Работа с чертежами и документацией',
    'Подбор материалов и оборудования',
    '3D-визуализация',
    'Управление проектами',
    'Работа с подрядчиками'
  ],
  program: [
    {
      title: 'Проектирование',
      topics: [
        'Обмерный план',
        'Планировочные решения',
        'Функциональное зонирование',
        'Строительные чертежи'
      ]
    },
    {
      title: 'Дизайн интерьера',
      topics: [
        'Стили в интерьере',
        'Цвет и свет',
        'Отделочные материалы',
        'Мебель и декор'
      ]
    },
    {
      title: 'Коммерческие объекты',
      topics: [
        'Особенности проектирования',
        'Нормативы и требования',
        'Зонирование пространства',
        'Брендинг в интерьере'
      ]
    },
    {
      title: 'Ведение проектов',
      topics: [
        'Работа с заказчиками',
        'Авторский надзор',
        'Сметы и спецификации',
        'Работа с подрядчиками'
      ]
    }
  ],
  features: [
    'Персональный наставник',
    'Практика на реальных объектах',
    'Помощь в создании портфолио',
    'Стажировка в дизайн-бюро',
    'Поддержка в запуске бизнеса'
  ]
};