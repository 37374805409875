import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_python_basics',
  title: 'Онлайн-курс Программирование на Python',
  description: 'Курс для тех, кто хочет научиться писать код на Python. Навык подойдёт программистам, маркетологам, аналитикам и всем, кто хочет решать задачи с помощью Python',
  school: 'brunoyam',
  category: 'programming',
  price: 39900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fosnovy-python&m=5&erid=LdtCK4uLs',
  skills: [
    'Python Core',
    'Алгоритмы',
    'Работа с данными',
    'Автоматизация',
    'Базы данных'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис языка',
        'Типы данных',
        'Функции',
        'Модули'
      ]
    },
    {
      title: 'Структуры данных',
      topics: [
        'Списки и кортежи',
        'Словари',
        'Множества',
        'Работа с файлами'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Решение задач',
        'Работа с библиотеками',
        'Автоматизация',
        'Создание скриптов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Поддержка куратора',
    'Работа над проектами',
    'Доступ к материалам',
    'Сертификат разработчика'
  ]
};