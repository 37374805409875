import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_telegram_self',
  title: 'Продвижение в Телеграме. Самостоятельный тариф',
  description: 'Обучение онлайн на платформе Нетология - курсы IT и образовательные программы с трудоустройством. Получи профессию с нашими онлайн курсами!',
  school: 'netology',
  category: 'marketing',
  price: 31980,
  oldPrice: 53300,
  currency: 'RUB',
  duration: '9 недель',
  imageUrl: 'https://images.unsplash.com/photo-1611606063065-ee7946f0787a?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ftg-selfcourse&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2665,
  discountPercent: 40,
  skills: [
    'Telegram маркетинг',
    'Создание контента',
    'Продвижение канала',
    'Монетизация',
    'Аналитика'
  ],
  program: [
    {
      title: 'Основы Telegram',
      topics: [
        'Создание канала',
        'Контент-стратегия',
        'Оформление',
        'Форматы контента'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Органический рост',
        'Платное продвижение',
        'Работа с аудиторией',
        'Кросс-промо'
      ]
    },
    {
      title: 'Монетизация',
      topics: [
        'Модели монетизации',
        'Работа с рекламодателями',
        'Аналитика',
        'Масштабирование'
      ]
    }
  ],
  features: [
    '37 практических занятий',
    'Самостоятельная работа',
    'Доступ к материалам',
    'Практические задания',
    'Сертификат о повышении квалификации'
  ]
};