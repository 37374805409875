import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_finance',
  title: 'Профессия Финансовый менеджер 2.0',
  description: 'Онлайн курс «Финансовый менеджер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на финансового менеджера с нуля онлайн.',
  school: 'geekbrains',
  category: 'management',
  price: 70057,
  oldPrice: 140114,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/ODSwVeaJbM0qxPpLiZBXaDBYNdCzOX9v9vQXgLbu.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmanagement%2Ffinance-manager&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Финансовый анализ',
    'Бюджетирование',
    'Управление инвестициями',
    'Оценка рисков',
    'Финансовое планирование',
    'Управленческий учет'
  ],
  program: [
    {
      title: 'Основы финансов',
      topics: [
        'Финансовый менеджмент',
        'Финансовый анализ',
        'Бухгалтерский учет',
        'Налогообложение'
      ]
    },
    {
      title: 'Управление финансами',
      topics: [
        'Бюджетирование',
        'Управление затратами',
        'Финансовое планирование',
        'Cash Flow'
      ]
    },
    {
      title: 'Инвестиции и риски',
      topics: [
        'Инвестиционный анализ',
        'Оценка рисков',
        'Портфельные инвестиции',
        'Управление рисками'
      ]
    },
    {
      title: 'Практика управления',
      topics: [
        'Финансовая стратегия',
        'Управленческие решения',
        'Отчетность',
        'Автоматизация'
      ]
    }
  ],
  features: [
    'Практические кейсы',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат финансового менеджера'
  ]
};