import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_smm',
  title: 'Профессия SMM-специалист 3.0. Тариф Фрилансер',
  description: 'Онлайн курс «SMM-специалист» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на SMM-специалиста с нуля онлайн.',
  school: 'geekbrains',
  category: 'marketing',
  price: 71920,
  oldPrice: 143839,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/HOJR3FpHfy9QbS9UZlsu8u1eH2izTk7Cbt73Scdw.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmarketing%2Fsmm-management-spec-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Стратегия продвижения в соцсетях',
    'Создание контента',
    'Таргетированная реклама',
    'Аналитика и метрики',
    'Работа с блогерами',
    'Управление репутацией'
  ],
  program: [
    {
      title: 'Основы SMM',
      topics: [
        'Социальные сети',
        'Целевая аудитория',
        'Контент-стратегия',
        'Комьюнити-менеджмент'
      ]
    },
    {
      title: 'Создание контента',
      topics: [
        'Копирайтинг',
        'Визуальный контент',
        'Stories и Reels',
        'Контент-план'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Таргетированная реклама',
        'Работа с блогерами',
        'Конкурсы и активности',
        'Influence-маркетинг'
      ]
    },
    {
      title: 'Аналитика и управление',
      topics: [
        'SMM-метрики',
        'Отчетность',
        'Антикризисный PR',
        'Управление командой'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Персональный наставник',
    'Помощь в поиске клиентов',
    'Стажировка в агентстве',
    'Сертификат SMM-специалиста'
  ]
};