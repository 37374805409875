import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_social_pro',
  title: 'Обществознание [ПРО]',
  description: 'Мастер группа [ПРО] - это углубленный формат подготовки с расширенной программой и дополнительными материалами для достижения максимального результата.',
  school: '99ballov',
  category: 'social',
  price: 4290,
  oldPrice: 4790,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1577036421869-7c8d388d2123?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 10,
  skills: [
    'Углубленный анализ общественных явлений',
    'Работа с научными текстами',
    'Написание сложных эссе',
    'Решение олимпиадных задач',
    'Исследовательская работа'
  ],
  program: [
    {
      title: 'Расширенная программа',
      topics: [
        'Философия',
        'Социология',
        'Политология',
        'Экономика'
      ]
    },
    {
      title: 'Практикум',
      topics: [
        'Разбор сложных заданий',
        'Олимпиадные задачи',
        'Исследовательские проекты',
        'Дебаты'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Углубленные материалы',
    'Олимпиадная подготовка',
    'Личный куратор',
    'Дополнительные консультации'
  ]
};