import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_frontend',
  title: 'Frontend-разработчик',
  description: 'За 9 месяцев научитесь всему, что должен знать Frontend-разработчик. Результат обучения — сможете переводить готовый дизайн-макет в код так, чтобы все работало',
  school: 'brunoyam',
  category: 'programming',
  price: 69900,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1593720213428-28a5b9e94613?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Ffrontend-razrabotchik&m=5&erid=LdtCK4uLs',
  skills: [
    'HTML и CSS',
    'JavaScript',
    'React',
    'TypeScript',
    'Современные инструменты'
  ],
  program: [
    {
      title: 'Основы frontend',
      topics: [
        'HTML5',
        'CSS3',
        'JavaScript',
        'Адаптивная верстка'
      ]
    },
    {
      title: 'JavaScript и React',
      topics: [
        'ES6+',
        'React',
        'Redux',
        'TypeScript'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Webpack',
        'Testing',
        'CI/CD',
        'Performance'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными задачами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат разработчика'
  ]
};