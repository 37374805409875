import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_dev_school',
  title: 'Разработчик для школьников',
  description: 'Станьте востребованным разработчиком. Вы изучите основы программирования и основные концепции компьютерных наук, цифровые технологии, операционные системы, программное обеспечение, базы данных, системы аналитики, языки программирования и многое другое.',
  school: 'geekbrains',
  category: 'programming',
  price: 160216,
  oldPrice: 291301,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/HDPbhxZOV2ipWbzOwEOrLR40g0ygN2ZaI0R9zRax.png',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fobuchenie-it-dlya-shkolnikov&m=4&erid=LdtCK7b7u',
  discountPercent: 45,
  skills: [
    'Основы программирования',
    'Разработка веб-сайтов',
    'Создание приложений',
    'Работа с базами данных',
    'Алгоритмы и структуры данных',
    'Командная работа'
  ],
  program: [
    {
      title: 'Основы программирования',
      topics: [
        'Алгоритмы',
        'Python',
        'JavaScript',
        'Структуры данных'
      ]
    },
    {
      title: 'Веб-разработка',
      topics: [
        'HTML и CSS',
        'JavaScript',
        'Создание сайтов',
        'Адаптивный дизайн'
      ]
    },
    {
      title: 'Базы данных',
      topics: [
        'SQL',
        'Проектирование БД',
        'Работа с данными',
        'CRUD операции'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Командные проекты',
        'Работа с Git',
        'Code Review',
        'Презентация проектов'
      ]
    }
  ],
  features: [
    'Адаптированная программа для школьников',
    'Практические проекты',
    'Опытные преподаватели',
    'Современные технологии',
    'Сертификат об окончании'
  ]
};