import { Course } from '../../../../types';

export const course: Course = {
  id: 'anecole_english_children',
  title: 'Английский для детей',
  description: 'Хотите, чтобы ваш ребёнок с детства был на «ты» с английским? Запишите его на занятия в Anecole.',
  school: 'anecole',
  category: 'english',
  price: 19120,
  currency: 'RUB',
  duration: '8 часов',
  imageUrl: 'https://images.unsplash.com/photo-1503676260728-1c00da094a0b?q=80&w=1200',
  url: 'https://go.redav.online/4f106e4f2bb25c71?dl=https%3A%2F%2Fanecole.com%2Fenglish%2Fkids&m=5&erid=2Vfnxwa5gNL',
  monthlyPayment: 4780,
  skills: [
    'Базовая лексика',
    'Произношение',
    'Игровое обучение',
    'Понимание на слух',
    'Простые диалоги'
  ],
  program: [
    {
      title: 'Начальный этап',
      topics: [
        'Вводный урок',
        'Фонетика',
        'Алфавит',
        'Базовые фразы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Игровые задания',
        'Песни и стихи',
        'Диалоги',
        'Творческие задания'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивные тренажеры',
    'Игровой формат',
    'Развивающие материалы',
    'Сертификат по окончании'
  ]
};