import { Course } from '../../../../types';
import { course as socialStandard } from './social-standard';
import { course as mathStandard } from './math-standard';
import { course as russianStandard } from './russian-standard';
import { course as physicsStandard } from './physics-standard';
import { course as chemistryStandard } from './chemistry-standard';
import { course as informaticsStandard } from './informatics-standard';
import { course as historyStandard } from './history-standard';
import { course as englishStandard } from './english-standard';
import { course as biologyStandard } from './biology-standard';
import { course as literatureStandard } from './literature-standard';
import { course as geographyStandard } from './geography-standard';

import { course as socialPro } from './social-pro';
import { course as mathPro } from './math-pro';
import { course as russianPro } from './russian-pro';
import { course as physicsPro } from './physics-pro';
import { course as chemistryPro } from './chemistry-pro';
import { course as informaticsPro } from './informatics-pro';
import { course as historyPro } from './history-pro';
import { course as englishPro } from './english-pro';
import { course as biologyPro } from './biology-pro';
import { course as literaturePro } from './literature-pro';
import { course as geographyPro } from './geography-pro';

import { course as socialPromax } from './social-promax';
import { course as mathPromax } from './math-promax';
import { course as russianPromax } from './russian-promax';
import { course as physicsPromax } from './physics-promax';
import { course as chemistryPromax } from './chemistry-promax';
import { course as informaticsPromax } from './informatics-promax';
import { course as historyPromax } from './history-promax';
import { course as englishPromax } from './english-promax';
import { course as biologyPromax } from './biology-promax';
import { course as literaturePromax } from './literature-promax';
import { course as geographyPromax } from './geography-promax';

export const ninetyNineBallovCourses: Course[] = [
  socialStandard,
  mathStandard,
  russianStandard,
  physicsStandard,
  chemistryStandard,
  informaticsStandard,
  historyStandard,
  englishStandard,
  biologyStandard,
  literatureStandard,
  geographyStandard,
  
  socialPro,
  mathPro,
  russianPro,
  physicsPro,
  chemistryPro,
  informaticsPro,
  historyPro,
  englishPro,
  biologyPro,
  literaturePro,
  geographyPro,
  
  socialPromax,
  mathPromax,
  russianPromax,
  physicsPromax,
  chemistryPromax,
  informaticsPromax,
  historyPromax,
  englishPromax,
  biologyPromax,
  literaturePromax,
  geographyPromax
];