import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_art_therapy_full',
  title: 'Арт-терапия в практике психолога',
  description: 'Дистанционное обучение по программе Арт-терапия в практике психолога с присвоением квалификации Арт-терапевт(336 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 48200,
  currency: 'RUR',
  duration: '336 часов',
  imageUrl: 'https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fart_terapiya_v_praktike_psikhologa_s_prisvoeniem_kvalifikatsii_art_terapevt%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Арт-терапевтические методы',
    'Диагностика через искусство',
    'Групповая арт-терапия',
    'Работа с разными возрастами',
    'Проективные методики'
  ],
  program: [
    {
      title: 'Основы арт-терапии',
      topics: [
        'История и теория арт-терапии',
        'Направления арт-терапии',
        'Диагностические методы',
        'Терапевтические техники'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Изотерапия',
        'Музыкотерапия',
        'Сказкотерапия',
        'Песочная терапия'
      ]
    },
    {
      title: 'Работа с клиентами',
      topics: [
        'Индивидуальная работа',
        'Групповая арт-терапия',
        'Семейная арт-терапия',
        'Работа с детьми'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом арт-терапевта'
  ]
};