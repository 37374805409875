import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_unreal',
  title: 'Разработчик игр на Unreal Engine с нуля',
  description: 'Онлайн-курс «Разработка игр на Unreal Engine с нуля» — Нетология: Пройдите обучение созданию 3D-игр без программирования. Освойте визуальные скрипты, разработайте игру в жанре Third-Person Adventure, создайте проект для портфолио и получите диплом. Начните карьеру в геймдеве!',
  school: 'netology',
  category: 'game-dev',
  price: 61810,
  oldPrice: 88300,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1580234811497-9df7fd2f357e?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Funreal-engine-s-nulya&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2575,
  discountPercent: 30,
  skills: [
    'Unreal Engine',
    'Разработка игр',
    'Визуальные скрипты',
    '3D моделирование',
    'Геймдизайн'
  ],
  program: [
    {
      title: 'Основы Unreal Engine',
      topics: [
        'Интерфейс движка',
        'Blueprint система',
        'Физика и коллизии',
        'Работа с материалами'
      ]
    },
    {
      title: 'Разработка игры',
      topics: [
        'Создание персонажа',
        'Игровые механики',
        'Искусственный интеллект',
        'Пользовательский интерфейс'
      ]
    },
    {
      title: 'Финализация проекта',
      topics: [
        'Оптимизация',
        'Визуальные эффекты',
        'Звуковое оформление',
        'Публикация игры'
      ]
    }
  ],
  features: [
    '29 практических занятий',
    'Создание игры',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};