import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_data_analyst',
  title: 'Аналитик данных',
  description: 'Научитесь собирать, обрабатывать и изучать данные, чтобы с помощью своих выводов помогать принимать решения в бизнесе и науке.',
  school: 'yandex',
  category: 'analytics',
  price: 103000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fdata-analyst&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Анализ данных',
    'Python для анализа',
    'SQL',
    'Визуализация данных',
    'Статистика'
  ],
  program: [
    {
      title: 'Основы анализа',
      topics: [
        'Базовый Python',
        'Предобработка данных',
        'Исследовательский анализ',
        'Статистический анализ'
      ]
    },
    {
      title: 'Базы данных',
      topics: [
        'SQL запросы',
        'Работа с базами данных',
        'Продвинутый SQL',
        'Оптимизация запросов'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Построение графиков',
        'Дашборды',
        'Презентация результатов',
        'Бизнес-показатели'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о профессии'
  ]
};