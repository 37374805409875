import { useCourseStore } from '../store/courseStore';
import { contentedSchool } from '../data/schools/contented';
import { contentedCourses } from '../data/schools/contented/courses';
import { geekbrainsSchool } from '../data/schools/geekbrains';
import { geekbrainsCourses } from '../data/schools/geekbrains/courses';
import { hundredPointsSchool } from '../data/schools/100points';
import { hundredPointsCourses } from '../data/schools/100points/courses';
import { ninetyNineBallovSchool } from '../data/schools/99ballov';
import { ninetyNineBallovCourses } from '../data/schools/99ballov/courses';
import { abakusSchool } from '../data/schools/abakus';
import { abakusCourses } from '../data/schools/abakus/courses';
import { anecoleSchool } from '../data/schools/anecole';
import { anecoleCourses } from '../data/schools/anecole/courses';
import { appkkSchool } from '../data/schools/appkk';
import { appkkCourses } from '../data/schools/appkk/courses';
import { brunoyamSchool } from '../data/schools/brunoyam';
import { brunoyamCourses } from '../data/schools/brunoyam/courses';
import { netologySchool } from '../data/schools/netology';
import { netologyCourses } from '../data/schools/netology/courses';
import { yandexSchool } from '../data/schools/yandex';
import { yandexCourses } from '../data/schools/yandex/courses';
import { categories } from '../data/categories';

export const initializeCourseData = () => {
  const store = useCourseStore.getState();
  
  // Initialize schools
  store.setSchools([
    contentedSchool,
    geekbrainsSchool,
    hundredPointsSchool,
    ninetyNineBallovSchool,
    abakusSchool,
    anecoleSchool,
    appkkSchool,
    brunoyamSchool,
    netologySchool,
    yandexSchool
  ]);
  
  // Initialize categories
  store.setCategories(categories);
  
  // Initialize courses
  store.setCourses([
    ...contentedCourses,
    ...geekbrainsCourses,
    ...hundredPointsCourses,
    ...ninetyNineBallovCourses,
    ...abakusCourses,
    ...anecoleCourses,
    ...appkkCourses,
    ...brunoyamCourses,
    ...netologyCourses,
    ...yandexCourses
  ]);
};