import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_phsh',
  title: 'Курс Photoshop',
  description: 'Базовый курс по Adobe Photoshop',
  school: 'contented',
  category: 'software',
  price: 21420,
  oldPrice: 30600,
  currency: 'RUR',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1572044162444-ad60f128bdea?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fphotoshop&m=5&erid=LdtCKHL5u',
  discountPercent: 30,
  skills: [
    'Обработка фотографий',
    'Работа со слоями',
    'Ретушь портретов',
    'Создание коллажей',
    'Цветокоррекция',
    'Работа с масками'
  ],
  program: [
    {
      title: 'Основы Photoshop',
      topics: [
        'Интерфейс программы',
        'Инструменты выделения',
        'Работа со слоями',
        'Маски и каналы'
      ]
    },
    {
      title: 'Обработка фотографий',
      topics: [
        'Коррекция экспозиции',
        'Цветокоррекция',
        'Ретушь портретов',
        'Фильтры и эффекты'
      ]
    },
    {
      title: 'Создание графики',
      topics: [
        'Работа с текстом',
        'Создание коллажей',
        'Векторные инструменты',
        'Стили слоев'
      ]
    },
    {
      title: 'Продвинутые техники',
      topics: [
        'Смарт-объекты',
        'Автоматизация',
        'Пакетная обработка',
        'Подготовка к печати'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Обратная связь от преподавателя',
    'Доступ к учебным материалам',
    'Сертификат о прохождении'
  ]
};