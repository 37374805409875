import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_excel',
  title: 'Excel + Google-таблицы с нуля до PRO',
  description: 'Онлайн курс «Excel + Google Таблицы» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 4 месяца. Помощь в трудоустройстве! Обучение Excel и Google Таблицам с нуля онлайн.',
  school: 'geekbrains',
  category: 'management',
  price: 23920,
  oldPrice: 47839,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/1J6FelpdbE409gJC9tLqfxmF2kaLJiO1UP9eqpqG.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmanagement%2Fexcel-and-google-sheets&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Работа с Excel',
    'Google Таблицы',
    'Анализ данных',
    'Формулы и функции',
    'Визуализация данных',
    'Автоматизация'
  ],
  program: [
    {
      title: 'Основы работы с таблицами',
      topics: [
        'Интерфейс Excel и Google Таблиц',
        'Форматирование данных',
        'Базовые формулы',
        'Работа с ячейками'
      ]
    },
    {
      title: 'Продвинутые функции',
      topics: [
        'Сложные формулы',
        'ВПР и VLOOKUP',
        'Сводные таблицы',
        'Макросы'
      ]
    },
    {
      title: 'Анализ данных',
      topics: [
        'Фильтрация данных',
        'Условное форматирование',
        'Статистический анализ',
        'Прогнозирование'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Создание графиков',
        'Диаграммы',
        'Дашборды',
        'Презентация данных'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными данными',
    'Персональный наставник',
    'Сертификат специалиста',
    'Готовые шаблоны'
  ]
};