import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_cognitive_therapy_full',
  title: 'Когнитивно-поведенческая психотерапия в практике психолога',
  description: 'Дистанционное обучение по программе Когнитивно-поведенческая психотерапия в практике психолога(640 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 42000,
  currency: 'RUR',
  duration: '640 часов',
  imageUrl: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fkognitivno_povedencheskaya_psikhoterapiya_v_praktike_psikhologa%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'КПТ-терапия',
    'Диагностика',
    'Работа с когнициями',
    'Поведенческие техники',
    'Схема-терапия'
  ],
  program: [
    {
      title: 'Основы КПТ',
      topics: [
        'Теория КПТ',
        'Когнитивные искажения',
        'Автоматические мысли',
        'Глубинные убеждения'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Когнитивные техники',
        'Поведенческие методы',
        'Экспозиция',
        'Активация'
      ]
    },
    {
      title: 'Работа с расстройствами',
      topics: [
        'Тревожные расстройства',
        'Депрессия',
        'ОКР',
        'Фобии'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};