import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_pm_online',
  title: 'Менеджер проектов',
  description: 'Научитесь управлять проектами в IT: от планирования до реализации. Интенсивный формат — живые онлайн-встречи, видеоматериалы, практические задания',
  school: 'brunoyam',
  category: 'management',
  price: 69900,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fprofession-project-manager&m=5&erid=LdtCK4uLs',
  skills: [
    'Управление проектами',
    'Agile методологии',
    'Работа с командой',
    'Управление рисками',
    'Планирование'
  ],
  program: [
    {
      title: 'Основы управления проектами',
      topics: [
        'Методологии управления',
        'Жизненный цикл проекта',
        'Планирование',
        'Управление рисками'
      ]
    },
    {
      title: 'Работа с командой',
      topics: [
        'Формирование команды',
        'Коммуникации',
        'Мотивация',
        'Разрешение конфликтов'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с документацией',
        'Управление изменениями',
        'Отчетность',
        'Завершение проекта'
      ]
    }
  ],
  features: [
    '2 проекта в портфолио',
    'Практические задания',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат менеджера'
  ]
};