import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_ai_marketing',
  title: 'Онлайн-курс Нейросети для маркетологов',
  description: 'Узнаете, как нейросети помогают маркетологам с аналитикой, стратегиями, исследованиями и идеями по продукту. Попрактикуетесь сразу в нескольких нейросетях',
  school: 'brunoyam',
  category: 'marketing',
  price: 15900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fneural-networks-for-marketing&m=5&erid=LdtCK4uLs',
  skills: [
    'Работа с нейросетями',
    'Маркетинговая аналитика',
    'Генерация контента',
    'Исследование рынка',
    'Автоматизация процессов'
  ],
  program: [
    {
      title: 'Основы AI в маркетинге',
      topics: [
        'Обзор нейросетей',
        'Возможности применения',
        'Базовые принципы',
        'Этика использования'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Анализ данных',
        'Создание контента',
        'Исследование рынка',
        'Генерация идей'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Оптимизация процессов',
        'Интеграция в работу',
        'Масштабирование',
        'Оценка эффективности'
      ]
    }
  ],
  features: [
    '10 практических проектов',
    'Работа с реальными кейсами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат по окончании'
  ]
};