import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_traffic',
  title: 'Офлайн-курс Трафик-менеджер',
  description: 'Научитесь запускать контекстную и таргетированную рекламу. Поработаете с Яндекс Директ, ВКонтакте, myTarget, Дзен и Телеграм. Проанализируете результат в Метрике и Analytics, чтобы идти дальше',
  school: 'brunoyam',
  category: 'marketing',
  price: 34900,
  currency: 'RUB',
  duration: '5 недель',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-internet-marketing%2Ftraffic-manager&m=5&erid=LdtCK4uLs',
  skills: [
    'Управление рекламными кампаниями',
    'Настройка таргетинга',
    'Аналитика трафика',
    'Оптимизация конверсии',
    'Работа с разными площадками'
  ],
  program: [
    {
      title: 'Основы трафик-менеджмента',
      topics: [
        'Источники трафика',
        'Анализ целевой аудитории',
        'Медиапланирование',
        'Бюджетирование'
      ]
    },
    {
      title: 'Рекламные каналы',
      topics: [
        'Контекстная реклама',
        'Таргетированная реклама',
        'Реклама в мессенджерах',
        'Медийная реклама'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Яндекс.Метрика',
        'Google Analytics',
        'Анализ эффективности',
        'Оптимизация кампаний'
      ]
    }
  ],
  features: [
    '42 академических часа',
    'Практические задания',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};