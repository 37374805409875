import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_python',
  title: 'Офлайн-курс Python-разработчик',
  description: 'Очный курс-интенсив в маленькой группе. Получите профессию Python-разработчика за 4 месяца обучения',
  school: 'brunoyam',
  category: 'programming',
  price: 84900,
  currency: 'RUB',
  duration: '53 недели',
  imageUrl: 'https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-programmirovaniya%2Fpython&m=5&erid=LdtCK4uLs',
  skills: [
    'Python Core',
    'Django',
    'Flask',
    'SQL',
    'REST API',
    'Git'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис Python',
        'ООП',
        'Структуры данных',
        'Работа с файлами'
      ]
    },
    {
      title: 'Веб-разработка',
      topics: [
        'Django Framework',
        'Flask',
        'REST API',
        'Базы данных'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Git',
        'Деплой',
        'Тестирование'
      ]
    }
  ],
  features: [
    '72 академических часа',
    '120 занятий',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};