import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_photo',
  title: 'Онлайн-курс Фотография с нуля',
  description: 'Научитесь делать хорошие фотографии, укрепите свой авторский стиль, сформируете портфолио, и найдёте своё место в коммерческой фотографии',
  school: 'brunoyam',
  category: 'photo-video',
  price: 49900,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1542038784456-1ea8e935640e?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fphotography-basics&m=5&erid=LdtCK4uLs',
  skills: [
    'Фотосъемка',
    'Работа со светом',
    'Композиция',
    'Обработка фотографий',
    'Коммерческая съемка'
  ],
  program: [
    {
      title: 'Основы фотографии',
      topics: [
        'Устройство камеры',
        'Экспозиция',
        'Композиция',
        'Работа со светом'
      ]
    },
    {
      title: 'Жанры фотографии',
      topics: [
        'Портретная съемка',
        'Предметная съемка',
        'Пейзажная съемка',
        'Репортажная съемка'
      ]
    },
    {
      title: 'Профессиональная практика',
      topics: [
        'Коммерческая съемка',
        'Работа с клиентами',
        'Создание портфолио',
        'Продвижение услуг'
      ]
    }
  ],
  features: [
    '30 практических проектов',
    'Работа с реальными заказами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат фотографа'
  ]
};