import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_positive_therapy',
  title: 'Позитивная психотерапия',
  description: 'Дистанционное обучение по программе Позитивная психотерапия (732 часа) в ЦАППКК. Освоение методов позитивной психотерапии для профессиональной практики.',
  school: 'appkk',
  category: 'psychology',
  price: 48200,
  currency: 'RUR',
  duration: '732 часа',
  imageUrl: 'https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpozitivnaya_psikhoterapiya_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Позитивная психотерапия',
    'Работа с ресурсами',
    'Конфликтная динамика',
    'Транскультуральный подход',
    'Балансная модель'
  ],
  program: [
    {
      title: 'Основы позитивной психотерапии',
      topics: [
        'История метода',
        'Базовые концепции',
        'Принципы работы',
        'Модель баланса'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Позитивная интерпретация',
        'Работа с историями',
        'Анализ конфликтов',
        'Ресурсный подход'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Консультирование',
        'Работа с семьей',
        'Групповая работа',
        'Самопомощь'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};