import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_social',
  title: 'Годовой курс подготовки к ЕГЭ по обществознанию «Ликбез: общество 2024/2025»',
  description: 'Если хочешь 90+ за ЕГЭ по обществу, этот курс для тебя! Комплексная подготовка к ЕГЭ по обществознанию с опытными преподавателями и персональным куратором.',
  school: '100points',
  category: 'social',
  price: 26590,
  oldPrice: 39490,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1577036421869-7c8d388d2123?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3590,
  discountPercent: 33,
  skills: [
    'Анализ общественных явлений',
    'Работа с документами',
    'Написание эссе',
    'Решение задач',
    'Аргументация позиции',
    'Работа с терминологией'
  ],
  program: [
    {
      title: 'Человек и общество',
      topics: [
        'Природа человека',
        'Общество как система',
        'Культура и духовная жизнь',
        'Познание и истина'
      ]
    },
    {
      title: 'Экономика и право',
      topics: [
        'Экономические системы',
        'Рынок и рыночные отношения',
        'Правовые основы',
        'Отрасли права'
      ]
    },
    {
      title: 'Социальные отношения',
      topics: [
        'Социальная структура',
        'Социальные институты',
        'Семья и брак',
        'Социальные процессы'
      ]
    },
    {
      title: 'Политика',
      topics: [
        'Политическая система',
        'Государство',
        'Демократия',
        'Политическое участие'
      ]
    }
  ],
  features: [
    '3 веба в неделю',
    'Проверка заданий куратором',
    'Разбор сложных тем',
    '9 пробных экзаменов',
    'Психологическая поддержка',
    'Помощь с апелляцией'
  ]
};