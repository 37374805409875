import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_linux_admin',
  title: 'Онлайн-курс Администрирование ОС Linux',
  description: 'Освоите операционную систему Linux с нуля за 2 месяца: от установки и управления серверами до обеспечения безопасности и обслуживания сетей',
  school: 'brunoyam',
  category: 'programming',
  price: 24900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1629654297299-c8506221ca97?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Flinux-os-administration&m=5&erid=LdtCK4uLs',
  skills: [
    'Администрирование Linux',
    'Настройка серверов',
    'Сетевое администрирование',
    'Безопасность систем',
    'Автоматизация задач'
  ],
  program: [
    {
      title: 'Основы Linux',
      topics: [
        'Установка системы',
        'Командная строка',
        'Файловая система',
        'Управление пользователями'
      ]
    },
    {
      title: 'Системное администрирование',
      topics: [
        'Управление процессами',
        'Настройка служб',
        'Резервное копирование',
        'Мониторинг системы'
      ]
    },
    {
      title: 'Сети и безопасность',
      topics: [
        'Настройка сети',
        'Firewall',
        'Безопасность',
        'Troubleshooting'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными серверами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};