import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_design_vision',
  title: 'Насмотренность в дизайне',
  description: 'Развивайте насмотренность в дизайне с помощью курсов Нетологии. Наши программы помогут дизайнерам и веб-дизайнерам улучшить свои навыки, изучая лучшие практики и визуальные решения.',
  school: 'netology',
  category: 'design',
  price: 1490,
  oldPrice: 1490,
  currency: 'RUB',
  duration: '14 дней',
  imageUrl: 'https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fnasmotrennost-v-dizajne&m=4&erid=LdtCKCxkP',
  skills: [
    'Анализ дизайна',
    'Визуальное мышление',
    'Оценка композиции',
    'Работа с референсами',
    'Развитие вкуса'
  ],
  program: [
    {
      title: 'Основы визуального восприятия',
      topics: [
        'Принципы дизайна',
        'Композиция',
        'Цветовые решения',
        'Типографика'
      ]
    },
    {
      title: 'Практика анализа',
      topics: [
        'Разбор кейсов',
        'Анализ трендов',
        'Работа с референсами',
        'Оценка решений'
      ]
    }
  ],
  features: [
    '9 практических занятий',
    'Анализ реальных проектов',
    'Интерактивные материалы',
    'Доступ к базе знаний',
    'Сертификат о прохождении'
  ]
};