import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_product_marketing_finance',
  title: 'Маркетинг продукта с Финансовым университетом',
  description: 'Освойте маркетинг продукта для бизнеса: обучайтесь аналитике, стратегиям и инструментам с Нетологией и Финансовым университетом. Для предпринимателей и руководителей.',
  school: 'netology',
  category: 'marketing',
  price: 243080,
  oldPrice: 412000,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fproduct-marketing&m=4&erid=LdtCKCxkP',
  monthlyPayment: 6752,
  discountPercent: 41,
  skills: [
    'Продуктовый маркетинг',
    'Стратегическое планирование',
    'Анализ рынка',
    'Управление продуктом',
    'Финансовый анализ'
  ],
  program: [
    {
      title: 'Основы продуктового маркетинга',
      topics: [
        'Анализ рынка',
        'Целевая аудитория',
        'Позиционирование',
        'Ценообразование'
      ]
    },
    {
      title: 'Стратегия и планирование',
      topics: [
        'Маркетинговая стратегия',
        'Продуктовая стратегия',
        'Финансовое планирование',
        'KPI и метрики'
      ]
    },
    {
      title: 'Управление продуктом',
      topics: [
        'Развитие продукта',
        'Управление портфелем',
        'Оптимизация процессов',
        'Анализ эффективности'
      ]
    }
  ],
  features: [
    '5 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Диплом двух вузов',
    'Помощь в развитии бизнеса'
  ]
};