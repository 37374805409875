import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_ios',
  title: 'iOS-разработчик',
  description: 'Освоите язык программирования Swift, чтобы создавать приложения для гаджетов Apple.',
  school: 'yandex',
  category: 'programming',
  price: 143000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://mobile-review.com/articles/2021/image/skillfactory/4.jpg?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fios-developer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Swift разработка',
    'iOS SDK',
    'UIKit',
    'SwiftUI',
    'Работа с API'
  ],
  program: [
    {
      title: 'Основы Swift',
      topics: [
        'Синтаксис Swift',
        'ООП',
        'Протоколы',
        'Замыкания'
      ]
    },
    {
      title: 'iOS разработка',
      topics: [
        'UIKit',
        'SwiftUI',
        'Жизненный цикл',
        'Работа с данными'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Архитектурные паттерны',
        'Многопоточность',
        'Core Data',
        'App Store'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными приложениями',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};