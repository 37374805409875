import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_gelotology',
  title: 'Гелотология (смехотерапия) в практике психолога',
  description: 'Дистанционное обучение по программе Гелотология (смехотерапия) в практике психолога(200 часов) в ЦАППКК. Освоение методов смехотерапии для психологической практики.',
  school: 'appkk',
  category: 'psychology',
  price: 17200,
  currency: 'RUR',
  duration: '200 часов',
  imageUrl: 'https://images.unsplash.com/photo-1543157145-f78c636d023d?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fgelotologiya_smekhoterapiya_v_praktike_psikhologa%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Техники смехотерапии',
    'Работа с эмоциями',
    'Групповые практики',
    'Снятие напряжения',
    'Развитие жизнерадостности'
  ],
  program: [
    {
      title: 'Основы гелотологии',
      topics: [
        'История смехотерапии',
        'Физиология смеха',
        'Психологические аспекты',
        'Терапевтические эффекты'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Техники смехотерапии',
        'Групповые упражнения',
        'Индивидуальная работа',
        'Медитативный смех'
      ]
    },
    {
      title: 'Применение в практике',
      topics: [
        'Работа со стрессом',
        'Преодоление депрессии',
        'Командообразование',
        'Личностный рост'
      ]
    }
  ],
  features: [
    'Практические упражнения',
    'Групповые практики',
    'Методические материалы',
    'Супервизия',
    'Сертификат по окончании'
  ]
};