import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_brand_manager',
  title: 'Профессия Бренд-менеджер',
  description: 'Курс «Бренд-менеджер» от Нетологии: обучение созданию и продвижению брендов с нуля. За 8 месяцев освоите профессию, научитесь работать с бренд-стратегией и выберете траекторию перфоманс-маркетолога или продакт-менеджера. Практическое обучение и поддержка экспертов.',
  school: 'netology',
  category: 'marketing',
  price: 104400,
  oldPrice: 174000,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fbrandmanagement&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2900,
  skills: [
    'Управление брендом',
    'Маркетинговые стратегии',
    'Создание айдентики',
    'Продвижение бренда',
    'Аналитика бренда'
  ],
  program: [
    {
      title: 'Основы брендинга',
      topics: [
        'Теория бренда',
        'Позиционирование',
        'Целевая аудитория',
        'Конкурентный анализ'
      ]
    },
    {
      title: 'Создание бренда',
      topics: [
        'Разработка стратегии',
        'Визуальная айдентика',
        'Tone of Voice',
        'Брендбук'
      ]
    },
    {
      title: 'Управление брендом',
      topics: [
        'Продвижение бренда',
        'Коммуникационная стратегия',
        'Метрики эффективности',
        'Развитие бренда'
      ]
    }
  ],
  features: [
    '56 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};