import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_data_analyst_pro',
  title: 'Системный аналитик PRO',
  description: 'Пройдите онлайн-курс системного аналитика с нуля в Нетологии. Освойте SQL, проектирование архитектур и моделирование бизнес-процессов. Дистанционное обучение с дипломом и гарантированным трудоустройством за 10 месяцев.',
  school: 'netology',
  category: 'analytics',
  price: 84000,
  oldPrice: 140000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsystems-analyst-pro&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3500,
  skills: [
    'Системный анализ',
    'SQL',
    'Проектирование архитектур',
    'Моделирование процессов',
    'UML',
    'BPMN'
  ],
  program: [
    {
      title: 'Основы анализа',
      topics: [
        'Методологии анализа',
        'Сбор требований',
        'Моделирование процессов',
        'Проектирование архитектур'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'SQL и базы данных',
        'UML диаграммы',
        'BPMN нотация',
        'Прототипирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с требованиями',
        'Проектная документация',
        'Управление изменениями',
        'Реальные проекты'
      ]
    }
  ],
  features: [
    '79 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};