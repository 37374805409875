import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_drone_engineer',
  title: 'Профессия Инженер-конструктор дрона',
  description: 'Онлайн курс «Инженер-конструктор дрона» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 5 месяцев. Помощь в трудоустройстве! Обучение на инженера-конструктора дронов с нуля онлайн.',
  school: 'geekbrains',
  category: 'engineering',
  price: 61680,
  oldPrice: 123359,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/sHXx06vzVHwN9nASuccEdM0OO4hGNdmdUdXXPFMy.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fengineering%2Fdrone-developer&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Проектирование дронов',
    'Конструирование БПЛА',
    'Расчет характеристик',
    'Подбор компонентов',
    'Сборка и настройка',
    'Тестирование систем'
  ],
  program: [
    {
      title: 'Основы конструирования',
      topics: [
        'Аэродинамика',
        'Механика полета',
        'Материаловедение',
        'САПР системы'
      ]
    },
    {
      title: 'Компоненты дронов',
      topics: [
        'Двигатели и пропеллеры',
        'Полетные контроллеры',
        'Системы питания',
        'Сенсоры и датчики'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Разработка конструкции',
        'Расчеты прочности',
        'Моделирование',
        'Оптимизация'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Сборка прототипов',
        'Настройка систем',
        'Тестирование',
        'Отладка'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными дронами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат инженера'
  ]
};