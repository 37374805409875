import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_psychologist_consultant',
  title: 'Психолог-консультант. Методы и технологии оказания психологических услуг населению и организациям',
  description: 'Дистанционное обучение по программе Психолог-консультант. Методы и технологии оказания психологических услуг населению и организациям с присвоением квалификации Психолог-консультант (1280 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 60600,
  currency: 'RUR',
  duration: '1280 часов',
  imageUrl: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpsikholog_konsultant_metody_i_tekhnologii_okazaniya_psikhologicheskikh_uslug_naseleniyu_i_organizats_pp%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Психологическое консультирование',
    'Психодиагностика',
    'Психокоррекция',
    'Групповая работа',
    'Организационное консультирование'
  ],
  program: [
    {
      title: 'Основы консультирования',
      topics: [
        'Теория консультирования',
        'Этика психолога',
        'Методы диагностики',
        'Построение контакта'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Индивидуальное консультирование',
        'Групповая работа',
        'Кризисное консультирование',
        'Семейное консультирование'
      ]
    },
    {
      title: 'Специализированные направления',
      topics: [
        'Организационное консультирование',
        'Профориентация',
        'Работа с зависимостями',
        'Психосоматика'
      ]
    }
  ],
  features: [
    'Комплексная подготовка',
    'Практика консультирования',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};