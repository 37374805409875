import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_ux_research',
  title: 'UX-исследования',
  description: 'Короткий курс для специалистов с опытом в айти. Для тех, кто хочет прокачать навык исследований и продуктивно использовать его в текущей работе: продакт- и проджект-менеджеров, маркетологов, исследователей уровня джун и всех, кто сталкивается в работе с исследованиями. Студенты научатся проводить разные виды исследований (кабинетные, качественные, количественные, юзабилити-тесты) и использовать исследовательский подход для текущей работы, чтобы проверять гипотезы и принимать решения на основе данных.',
  school: 'yandex',
  category: 'design',
  price: 85000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fuser-experience-research&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'UX исследования',
    'Качественные методы',
    'Количественные методы',
    'Юзабилити-тестирование',
    'Анализ данных'
  ],
  program: [
    {
      title: 'Основы UX-исследований',
      topics: [
        'Методология исследований',
        'Планирование',
        'Гипотезы',
        'Метрики'
      ]
    },
    {
      title: 'Методы исследований',
      topics: [
        'Кабинетные исследования',
        'Глубинные интервью',
        'Количественные исследования',
        'Юзабилити-тестирование'
      ]
    },
    {
      title: 'Анализ и применение',
      topics: [
        'Анализ данных',
        'Формирование выводов',
        'Презентация результатов',
        'Принятие решений'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в развитии',
    'Сертификат специалиста'
  ]
};