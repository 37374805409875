import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_web_pentest',
  title: 'Специалист по информационной безопасности: веб-пентест',
  description: 'Онлайн-обучение на курсе «Специалист по информационной безопасности: веб-пентест» от сервиса Яндекс Практикум. Научитесь пентесту с нуля. Станьте специалистом по компьютерной безопасности.',
  school: 'yandex',
  category: 'programming',
  price: 135000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1614064641938-3bbee52942c7?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fweb-pentest&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Тестирование на проникновение',
    'Анализ уязвимостей',
    'Веб-безопасность',
    'Этичный хакинг',
    'Защита приложений'
  ],
  program: [
    {
      title: 'Основы веб-безопасности',
      topics: [
        'HTTP протокол',
        'Типы уязвимостей',
        'Методология тестирования',
        'Инструменты пентеста'
      ]
    },
    {
      title: 'Практический пентест',
      topics: [
        'SQL инъекции',
        'XSS атаки',
        'CSRF',
        'Обход аутентификации'
      ]
    },
    {
      title: 'Защита и отчетность',
      topics: [
        'Защита приложений',
        'Написание отчетов',
        'Рекомендации по исправлению',
        'Этичный хакинг'
      ]
    }
  ],
  features: [
    'Практические лабораторные работы',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};