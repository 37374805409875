import { Course } from '../../../../types';
import { course as mentalArithmetic8 } from './mental-arithmetic-8';
import { course as mentalArithmetic24 } from './mental-arithmetic-24';
import { course as mentalArithmetic48 } from './mental-arithmetic-48';
import { course as mentalArithmetic72 } from './mental-arithmetic-72';
import { course as speedReading8 } from './speed-reading-8';
import { course as speedReading24 } from './speed-reading-24';
import { course as speedReading48 } from './speed-reading-48';
import { course as speedReading72 } from './speed-reading-72';

export const abakusCourses: Course[] = [
  mentalArithmetic8,
  mentalArithmetic24,
  mentalArithmetic48,
  mentalArithmetic72,
  speedReading8,
  speedReading24,
  speedReading48,
  speedReading72
];