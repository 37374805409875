import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_physics_sigma_curator',
  title: 'Годовой курс по подготовке к ЕГЭ по физике с 10 класса «Сигма 2024/2025» с куратором',
  description: 'Углубленная подготовка к ЕГЭ по физике с персональным куратором. Начни готовиться заранее и получи максимальный результат.',
  school: '100points',
  category: 'physics',
  price: 33890,
  oldPrice: 39490,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1636466497217-26a8cbeaf0aa?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3690,
  discountPercent: 15,
  skills: [
    'Механика',
    'Молекулярная физика',
    'Электродинамика',
    'Оптика',
    'Квантовая физика',
    'Решение задач повышенной сложности'
  ],
  program: [
    {
      title: 'Механика',
      topics: [
        'Кинематика',
        'Динамика',
        'Законы сохранения',
        'Механические колебания'
      ]
    },
    {
      title: 'Молекулярная физика',
      topics: [
        'МКТ',
        'Термодинамика',
        'Агрегатные состояния',
        'Тепловые процессы'
      ]
    },
    {
      title: 'Электродинамика',
      topics: [
        'Электростатика',
        'Постоянный ток',
        'Магнитное поле',
        'Электромагнитная индукция'
      ]
    },
    {
      title: 'Современная физика',
      topics: [
        'Оптика',
        'Квантовые явления',
        'Атомная физика',
        'Ядерная физика'
      ]
    }
  ],
  features: [
    '2 занятия в неделю',
    'Персональный куратор',
    'Индивидуальные консультации',
    'Проверка домашних заданий',
    'Пробные экзамены',
    'Доступ к записям'
  ]
};