import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_go_basic',
  title: 'Go-разработчик с нуля',
  description: 'Курс по разработке на языке Go для начинающих',
  school: 'yandex',
  category: 'programming',
  price: 145000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1600132806370-bf17e65e942f?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fgo-developer-basic&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Golang разработка',
    'Работа с базами данных',
    'Web-разработка',
    'Микросервисы',
    'Тестирование'
  ],
  program: [
    {
      title: 'Основы Go',
      topics: [
        'Синтаксис Go',
        'Типы данных',
        'Горутины',
        'Каналы'
      ]
    },
    {
      title: 'Web-разработка',
      topics: [
        'HTTP сервер',
        'REST API',
        'gRPC',
        'Микросервисы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с БД',
        'Тестирование',
        'Docker',
        'CI/CD'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Code review',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};