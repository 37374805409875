import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_java',
  title: 'Профессия Java-разработчик',
  description: 'Онлайн курс «Java-разработчик» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на Java-разработчика с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 118255,
  oldPrice: 236509,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/mKgM1vWqnCUfNNauMjxaVsmQEcS9d71pvL9FpkgN.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fprogrammer%2Fjava-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Разработка на Java',
    'Spring Framework',
    'Базы данных',
    'Web-разработка',
    'Микросервисы',
    'Тестирование'
  ],
  program: [
    {
      title: 'Основы Java',
      topics: [
        'Синтаксис Java',
        'ООП',
        'Коллекции',
        'Многопоточность'
      ]
    },
    {
      title: 'Spring Framework',
      topics: [
        'Spring Core',
        'Spring MVC',
        'Spring Boot',
        'Spring Security'
      ]
    },
    {
      title: 'Базы данных',
      topics: [
        'SQL',
        'Hibernate',
        'JPA',
        'PostgreSQL'
      ]
    },
    {
      title: 'Web-разработка',
      topics: [
        'REST API',
        'Микросервисы',
        'Docker',
        'CI/CD'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Код-ревью от экспертов',
    'Помощь в трудоустройстве',
    'Персональный наставник',
    'Сертификат разработчика'
  ]
};