import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_3d_max_interior',
  title: 'Создание интерьера в 3ds Max',
  description: 'За 2 месяца освоите 3ds Max и создадите готовый проект интерьера',
  school: 'brunoyam',
  category: 'design',
  price: 24900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1600607687920-4e2a09cf159d?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2F3d-max-visualisation&m=5&erid=LdtCK4uLs',
  skills: [
    '3D моделирование',
    'Визуализация интерьеров',
    'Работа с материалами',
    'Постановка света',
    'Рендеринг'
  ],
  program: [
    {
      title: 'Основы 3ds Max',
      topics: [
        'Интерфейс программы',
        'Моделирование объектов',
        'Работа с материалами',
        'Настройка камер'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Настройка освещения',
        'Текстурирование',
        'Постановка камер',
        'Рендер сцены'
      ]
    },
    {
      title: 'Проектная работа',
      topics: [
        'Создание интерьера',
        'Наполнение мебелью',
        'Финальная визуализация',
        'Подготовка презентации'
      ]
    }
  ],
  features: [
    'Практический проект',
    'Работа с референсами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};