import { Course } from '../../../../types';

export const course: Course = {
  id: 'abakus_math_72',
  title: 'Ментальная арифметика онлайн - обучение и курсы для детей | 72 урока',
  description: 'Индивидуальные онлайн-занятия ментальной арифметикой с опытным педагогом на интерактивной платформе Абакус',
  school: 'abakus',
  category: 'math',
  price: 70560,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1518133910546-b6c2fb7d79e3?q=80&w=1200',
  url: 'https://go.redav.online/86fdf99551e92c21?dl=https%3A%2F%2Fabakus-center.ru%2Feducation%2Fonline&m=5&erid=2Vfnxx8cgzL',
  monthlyPayment: 7840,
  skills: [
    'Экспертный уровень устного счета',
    'Максимальная концентрация',
    'Феноменальная память',
    'Системное мышление',
    'Развитое воображение',
    'Работа с любыми числами'
  ],
  program: [
    {
      title: 'Профессиональный уровень',
      topics: [
        'Экспертные вычисления',
        'Работа с большими числами',
        'Комплексные операции',
        'Ментальные вычисления'
      ]
    },
    {
      title: 'Мастерство',
      topics: [
        'Скоростные вычисления',
        'Олимпиадные задачи',
        'Сложные примеры',
        'Развитие интеллекта'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Полная программа',
    'Лучшие преподаватели',
    'Сложные задания',
    'Детальный мониторинг прогресса'
  ]
};