import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_neuropsychology',
  title: 'Нейропсихологическая диагностика и коррекция в детском возрасте',
  description: 'Дистанционное обучение по программе Нейропсихологическая диагностика и коррекция в детском возрасте(400 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 27300,
  currency: 'RUR',
  duration: '400 часов',
  imageUrl: 'https://images.unsplash.com/photo-1503919545889-aef636e10ad4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fneyropsikhologicheskaya_diagnostika_i_korrektsiya_v_detskom_vozraste%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Нейропсихологическая диагностика',
    'Коррекционная работа',
    'Работа с ВПФ',
    'Развитие когнитивных функций',
    'Работа с нарушениями развития'
  ],
  program: [
    {
      title: 'Основы нейропсихологии',
      topics: [
        'Теория А.Р. Лурии',
        'Мозговая организация ВПФ',
        'Нейропсихологические факторы',
        'Развитие мозга'
      ]
    },
    {
      title: 'Диагностика',
      topics: [
        'Методы обследования',
        'Нейропсихологические пробы',
        'Анализ результатов',
        'Составление заключения'
      ]
    },
    {
      title: 'Коррекционная работа',
      topics: [
        'Методы коррекции',
        'Развивающие программы',
        'Работа с родителями',
        'Мониторинг результатов'
      ]
    }
  ],
  features: [
    'Практические методики',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};