import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_uxui',
  title: 'Профессия UX/UI-дизайнер',
  description: 'Онлайн курс «UX/UI-дизайнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на UX/UI-дизайнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 139375,
  oldPrice: 278749,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/qXiz5jro7yJn8pV1wAGwujBugPapmzjnUsL2qO4M.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Finterface-spec-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Проектирование интерфейсов',
    'UX-исследования',
    'Прототипирование',
    'UI-дизайн',
    'Работа с Figma',
    'Анимация интерфейсов'
  ],
  program: [
    {
      title: 'Основы UX/UI',
      topics: [
        'Принципы UX дизайна',
        'Исследование пользователей',
        'Информационная архитектура',
        'Прототипирование'
      ]
    },
    {
      title: 'UI дизайн',
      topics: [
        'Работа в Figma',
        'Визуальный дизайн',
        'Компонентный подход',
        'Дизайн-системы'
      ]
    },
    {
      title: 'UX исследования',
      topics: [
        'Методы исследований',
        'Юзабилити-тестирование',
        'Аналитика',
        'Customer Journey Map'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Анимация интерфейсов',
        'Адаптивный дизайн',
        'Подготовка портфолио'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Стажировка в компании',
    'Помощь в трудоустройстве',
    'Сертификат UX/UI дизайнера'
  ]
};