import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_social_promax',
  title: 'Обществознание [ПРОМАКС]',
  description: 'Мастер группа [ПРОМАКС] - это максимально углубленный формат подготовки с расширенной программой, индивидуальным подходом и дополнительными материалами для достижения наивысшего результата.',
  school: '99ballov',
  category: 'social',
  price: 7290,
  oldPrice: 7990,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1577036421869-7c8d388d2123?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 9,
  skills: [
    'Углубленный анализ общественных явлений',
    'Работа с научными текстами',
    'Написание сложных эссе',
    'Решение олимпиадных задач',
    'Исследовательская работа'
  ],
  program: [
    {
      title: 'Максимальная программа',
      topics: [
        '14 вебинаров по 1.5-2 часа',
        'Видео-звонки с куратором',
        'Разбор сложных тем',
        'Все материалы от эксперта ЕГЭ'
      ]
    },
    {
      title: 'Практикум',
      topics: [
        'Двухуровневые домашние задания',
        '1-2 пробника в месяц',
        'Автоматическая проверка части 1',
        'Работа с платформой'
      ]
    }
  ],
  features: [
    'Личный куратор и онлайн-куратор',
    'Видео-звонки для разбора сложных тем',
    'Все материалы от эксперта ЕГЭ',
    'Гифт-бокс лучшим ученикам',
    'Расширенная программа подготовки'
  ]
};