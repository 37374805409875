import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sales_manager_pro',
  title: 'Менеджер отдела продаж PRO',
  description: 'Курс «Менеджер продаж PRO»: развивайте навыки ведения сложных переговоров, управления сделками и построения долгосрочных отношений с клиентами. Пройдите обучение за 7 недель, добавив в портфолио 5 реальных кейсов для карьерного роста.',
  school: 'netology',
  category: 'management',
  price: 124500,
  oldPrice: 124500,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1552581234-26160f608093?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsale-manager-pro&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3458,
  skills: [
    'Управление продажами',
    'Сложные переговоры',
    'Работа с клиентами',
    'Управление сделками',
    'Развитие отношений'
  ],
  program: [
    {
      title: 'Продвинутые продажи',
      topics: [
        'Сложные переговоры',
        'Работа с возражениями',
        'Ценовые переговоры',
        'Долгосрочные отношения'
      ]
    },
    {
      title: 'Управление продажами',
      topics: [
        'Воронка продаж',
        'Управление сделками',
        'Прогнозирование',
        'Аналитика'
      ]
    },
    {
      title: 'Развитие бизнеса',
      topics: [
        'Стратегия продаж',
        'Развитие клиентов',
        'Управление командой',
        'Оптимизация процессов'
      ]
    }
  ],
  features: [
    'Практические кейсы',
    'Работа с реальными клиентами',
    'Персональный наставник',
    'Развитие навыков',
    'Сертификат о повышении квалификации'
  ]
};