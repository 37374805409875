import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_screenwriter',
  title: 'Профессия Сценарист',
  description: 'Изучите сценарное мастерство с нуля на курсах сценаристов в Нетологии. Онлайн-программа и обучение для тех, кто хочет стать профессиональным сценаристом и освоить основы сценарного мастерства.',
  school: 'netology',
  category: 'multimedia',
  price: 192900,
  oldPrice: 321500,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fscenarnoye-masterstvo&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 5358,
  skills: [
    'Написание сценариев',
    'Драматургия',
    'Создание персонажей',
    'Сюжетные структуры',
    'Диалоги'
  ],
  program: [
    {
      title: 'Основы сценарного мастерства',
      topics: [
        'Структура сценария',
        'Создание персонажей',
        'Конфликт и действие',
        'Диалоги'
      ]
    },
    {
      title: 'Драматургия',
      topics: [
        'Сюжетные арки',
        'Жанры',
        'Темы и идеи',
        'Подтекст'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Написание сценариев',
        'Редактура',
        'Питчинг проектов',
        'Работа с продюсерами'
      ]
    }
  ],
  features: [
    '75 практических занятий',
    'Написание сценариев',
    'Персональный наставник',
    'Помощь в продвижении',
    'Диплом о профессиональной переподготовке'
  ]
};