import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_history_oge_curator',
  title: 'Годовой курс по истории ОГЭ 2024/2025 с куратором',
  description: 'На годовом курсе ты сможешь подготовиться к ОГЭ по истории с нуля на отлично. Персональный куратор поможет достичь максимального результата.',
  school: '100points',
  category: 'history',
  price: 31390,
  oldPrice: 35890,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1461360370896-922624d12aa1?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3490,
  discountPercent: 15,
  skills: [
    'Работа с историческими источниками',
    'Анализ исторических событий',
    'Работа с картами',
    'Знание дат и фактов',
    'Причинно-следственные связи',
    'Исторические личности'
  ],
  program: [
    {
      title: 'Древняя Русь и Средневековье',
      topics: [
        'Образование государства',
        'Раздробленность',
        'Монгольское нашествие',
        'Объединение русских земель'
      ]
    },
    {
      title: 'Россия в XVI-XVIII веках',
      topics: [
        'Иван Грозный',
        'Смутное время',
        'Первые Романовы',
        'Петровские реформы'
      ]
    },
    {
      title: 'Россия в XIX веке',
      topics: [
        'Отечественная война 1812 года',
        'Великие реформы',
        'Общественные движения',
        'Внешняя политика'
      ]
    },
    {
      title: 'Россия в XX веке',
      topics: [
        'Революции',
        'СССР',
        'Великая Отечественная война',
        'Послевоенный период'
      ]
    }
  ],
  features: [
    '2 вебинара в неделю',
    'Персональный куратор',
    'Индивидуальные консультации',
    'Проверка работ',
    'Разбор исторических источников',
    'Работа с картами',
    'Система мотивации'
  ]
};