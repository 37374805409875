import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_critical_thinking',
  title: 'Критическое мышление',
  description: 'Разовьёте навыки, которые помогают анализировать информацию, принимать взвешенные решения и аргументировать их.',
  school: 'yandex',
  category: 'management',
  price: 40000,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1606326608606-aa0b62935f2b?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fthinking&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Анализ информации',
    'Логическое мышление',
    'Принятие решений',
    'Аргументация',
    'Работа с когнитивными искажениями'
  ],
  program: [
    {
      title: 'Основы критического мышления',
      topics: [
        'Логические ошибки',
        'Когнитивные искажения',
        'Анализ аргументов',
        'Проверка гипотез'
      ]
    },
    {
      title: 'Принятие решений',
      topics: [
        'Методы анализа',
        'Оценка рисков',
        'Работа с информацией',
        'Выбор альтернатив'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Решение кейсов',
        'Групповые дискуссии',
        'Анализ ситуаций',
        'Презентация идей'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа в группах',
    'Разбор реальных кейсов',
    'Индивидуальная обратная связь',
    'Сертификат о прохождении'
  ]
};