import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_crisis_full',
  title: 'Кризисная психология. Оказание психологической помощи в экстремальных ситуациях и при психологических травмах',
  description: 'Дистанционное обучение по программе Кризисная психология. Оказание психологической помощи в экстремальных ситуациях и при психологических травмах с присвоением квалификации Кризисный психолог(458 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 35000,
  currency: 'RUR',
  duration: '458 часов',
  imageUrl: 'https://images.unsplash.com/photo-1590650046871-92c887180603?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fkrizisnaya_psikhologiya_okazanie_psikhologicheskoy_pomoshchi_v_ekstremalnykh_situatsiyakh_i_pri_psikh%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Кризисное консультирование',
    'Работа с травмой',
    'Экстренная помощь',
    'Профилактика суицида',
    'Работа с ПТСР'
  ],
  program: [
    {
      title: 'Основы кризисной психологии',
      topics: [
        'Теория кризисов',
        'Виды психотравм',
        'Диагностика состояний',
        'Методы помощи'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Экстренная помощь',
        'Работа с ПТСР',
        'Суицидальное поведение',
        'Семейные кризисы'
      ]
    },
    {
      title: 'Реабилитация',
      topics: [
        'Долгосрочная работа',
        'Профилактика рецидивов',
        'Групповая терапия',
        'Работа с семьей'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом кризисного психолога'
  ]
};