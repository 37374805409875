import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_ai',
  title: 'Искусственный интеллект. Специалист',
  description: 'Вы изучите основы программирования и основные концепции компьютерных наук, цифровые технологии, операционные системы, программное обеспечение, базы данных, системы аналитики, языки программирования и многое другое.',
  school: 'geekbrains',
  category: 'programming',
  price: 156162,
  oldPrice: 312324,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/kuVPiQQtJlI0CFLskYP6odtCHYekdbdZTopBRolL.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fprogrammer%2Fai-spec&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Разработка AI-систем',
    'Машинное обучение',
    'Нейронные сети',
    'Обработка данных',
    'Python для AI',
    'Компьютерное зрение'
  ],
  program: [
    {
      title: 'Основы AI',
      topics: [
        'Введение в AI',
        'Python для AI',
        'Математика для AI',
        'Работа с данными'
      ]
    },
    {
      title: 'Машинное обучение',
      topics: [
        'Классические алгоритмы ML',
        'Deep Learning',
        'Нейронные сети',
        'Transfer Learning'
      ]
    },
    {
      title: 'Специализация',
      topics: [
        'Computer Vision',
        'NLP',
        'Генеративные модели',
        'Reinforcement Learning'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разработка AI-проектов',
        'Оптимизация моделей',
        'Развертывание систем',
        'MLOps'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат AI-специалиста'
  ]
};