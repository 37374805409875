import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_motionpro',
  title: 'Профессия Моушн-дизайнер c нуля до PRO',
  description: 'Углубленное обучение моушн-дизайну. Освойте создание сложной анимации, спецэффектов и работу с современными инструментами моушн-дизайна.',
  school: 'contented',
  category: '3d',
  price: 196350,
  oldPrice: 357000,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://static.tildacdn.com/stor3761-6332-4031-b033-636332376466/60882313.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fmotion-designer-pro&m=5&erid=LdtCKHL5u',
  monthlyPayment: 5454,
  discountPercent: 45,
  skills: [
    'Создание сложной анимации',
    'Работа с 3D-графикой',
    'Создание спецэффектов',
    'Композитинг',
    'Цветокоррекция',
    'Работа со звуком',
    'Управление проектами'
  ],
  program: [
    {
      title: 'Основы анимации',
      topics: [
        'Принципы анимации',
        'Работа с кривыми',
        'Тайминг и спейсинг',
        'Анимация персонажей'
      ]
    },
    {
      title: '3D и спецэффекты',
      topics: [
        'Cinema 4D',
        'Element 3D',
        'Частицы и симуляции',
        'Интеграция 3D в видео'
      ]
    },
    {
      title: 'Композитинг',
      topics: [
        'After Effects продвинутый уровень',
        'Nuke основы',
        'Трекинг и стабилизация',
        'Ротоскопинг'
      ]
    },
    {
      title: 'Управление проектами',
      topics: [
        'Организация рабочего процесса',
        'Работа с заказчиками',
        'Управление командой',
        'Оценка проектов'
      ]
    }
  ],
  features: [
    'Персональный наставник',
    'Работа над реальными проектами',
    'Стажировка в студии',
    'Помощь в трудоустройстве',
    'Доступ к профессиональному сообществу'
  ]
};