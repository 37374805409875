import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_marketing',
  title: 'Профессия Интернет-маркетолог',
  description: 'Онлайн курс «Интернет-маркетолог» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на интернет-маркетолога с нуля онлайн.',
  school: 'geekbrains',
  category: 'marketing',
  price: 98955,
  oldPrice: 197910,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/KqsDGBLVVVqDnuq2edusqkfD6GIT0qXJtMy8Q2cC.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmarketing%2Finternet-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Маркетинговая стратегия',
    'SEO-продвижение',
    'Контекстная реклама',
    'SMM',
    'Email-маркетинг',
    'Веб-аналитика'
  ],
  program: [
    {
      title: 'Основы интернет-маркетинга',
      topics: [
        'Маркетинговая стратегия',
        'Целевая аудитория',
        'Воронка продаж',
        'Каналы продвижения'
      ]
    },
    {
      title: 'Инструменты продвижения',
      topics: [
        'SEO-оптимизация',
        'Контекстная реклама',
        'Таргетированная реклама',
        'Контент-маркетинг'
      ]
    },
    {
      title: 'Работа с каналами',
      topics: [
        'Social Media Marketing',
        'Email-маркетинг',
        'Мессенджер-маркетинг',
        'Influence-маркетинг'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Веб-аналитика',
        'A/B-тестирование',
        'Метрики эффективности',
        'Оптимизация конверсии'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат маркетолога'
  ]
};