import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_react',
  title: 'Frontend-разработка на React',
  description: 'Научитесь создавать мощные веб-приложения с React.js. Курс включает практические задания, проекты и поддержку наставников. Идеально для начинающих разработчиков. Присоединяйтесь сейчас!',
  school: 'netology',
  category: 'programming',
  price: 28000,
  oldPrice: 40000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Freact&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2333,
  discountPercent: 30,
  skills: [
    'React разработка',
    'JavaScript ES6+',
    'Redux',
    'React Router',
    'Тестирование React'
  ],
  program: [
    {
      title: 'Основы React',
      topics: [
        'Компоненты',
        'Props и State',
        'Хуки',
        'Жизненный цикл'
      ]
    },
    {
      title: 'Продвинутый React',
      topics: [
        'Redux',
        'React Router',
        'Context API',
        'Оптимизация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с API',
        'Тестирование',
        'Деплой',
        'Реальные проекты'
      ]
    }
  ],
  features: [
    '20 практических занятий',
    'Создание приложений',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};