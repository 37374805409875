import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_sysadmin',
  title: 'Системный администратор',
  description: 'Изучите Linux и другие инструменты администрирования за 6 месяцев обучения. Интенсивный формат — вебинары, практика на реальных проектах, дополнительные видеоматериалы',
  school: 'brunoyam',
  category: 'programming',
  price: 74900,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1597852074816-d933c7d2b988?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fsistemnyj-administrator&m=5&erid=LdtCK4uLs',
  skills: [
    'Администрирование Linux',
    'Сетевые технологии',
    'Безопасность систем',
    'Виртуализация',
    'Мониторинг и backup'
  ],
  program: [
    {
      title: 'Основы администрирования',
      topics: [
        'Linux системы',
        'Командная строка',
        'Сетевые протоколы',
        'Управление пользователями'
      ]
    },
    {
      title: 'Сети и безопасность',
      topics: [
        'Настройка сетей',
        'Firewall',
        'VPN',
        'Защита от атак'
      ]
    },
    {
      title: 'Инфраструктура',
      topics: [
        'Виртуализация',
        'Контейнеры',
        'Резервное копирование',
        'Мониторинг систем'
      ]
    }
  ],
  features: [
    '120 практических проектов',
    'Работа с реальными системами',
    'Поддержка 2 года',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};