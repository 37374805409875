import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_internet_marketing_pro',
  title: 'Интернет-маркетолог расширенный',
  description: 'Интернет-маркетолог: расширенный курс',
  school: 'yandex',
  category: 'marketing',
  price: 169000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Finternet-marketing-start%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Digital-маркетинг',
    'Стратегический маркетинг',
    'Аналитика и метрики',
    'Управление каналами',
    'Оптимизация конверсии'
  ],
  program: [
    {
      title: 'Стратегический маркетинг',
      topics: [
        'Анализ рынка',
        'Целевая аудитория',
        'Позиционирование',
        'Маркетинговая стратегия'
      ]
    },
    {
      title: 'Digital-инструменты',
      topics: [
        'SEO-оптимизация',
        'Контекстная реклама',
        'Таргетированная реклама',
        'Email-маркетинг'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Веб-аналитика',
        'A/B тестирование',
        'Оптимизация конверсии',
        'ROI маркетинга'
      ]
    }
  ],
  features: [
    'Углубленная программа',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат маркетолога'
  ]
};