import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_relationships',
  title: 'Психология отношений: здоровое партнерство из любви к себе',
  description: 'На курсе по психологии отношений вы научитесь правильно выстраивать отношения в паре и жить в удовольствии от себя, партнёра и вашего союза.',
  school: 'netology',
  category: 'psychology',
  price: 5460,
  oldPrice: 9100,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1516585427167-9f4af9627e6c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fotnosheniya-v-pare&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 5460,
  skills: [
    'Построение отношений',
    'Личные границы',
    'Эмоциональная близость',
    'Коммуникация в паре',
    'Разрешение конфликтов'
  ],
  program: [
    {
      title: 'Основы отношений',
      topics: [
        'Самопознание',
        'Привязанность',
        'Границы',
        'Ценности'
      ]
    },
    {
      title: 'Практика отношений',
      topics: [
        'Коммуникация',
        'Конфликты',
        'Близость',
        'Развитие отношений'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Работа с психологом',
    'Практические упражнения',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};