import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_mini_mba',
  title: 'Комплексное развитие руководителей: аналог мини-MBA.Pro',
  description: 'Развивайте лидерские навыки и повышайте квалификацию на курсе в Нетологии. Получите инструменты для управления командами, проектами и бизнес-процессами. Учитесь у экспертов и применяйте полученные знания на практике. Программа доступна онлайн и включает в себя все необходимые материалы для эффективного обучения.',
  school: 'netology',
  category: 'management',
  price: 130000,
  oldPrice: 216667,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Flider-360-b2c-pro&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3611,
  discountPercent: 40,
  skills: [
    'Стратегическое управление',
    'Управление командой',
    'Финансовый менеджмент',
    'Операционное управление',
    'Лидерство'
  ],
  program: [
    {
      title: 'Стратегический менеджмент',
      topics: [
        'Стратегическое планирование',
        'Анализ рынка',
        'Управление изменениями',
        'Принятие решений'
      ]
    },
    {
      title: 'Управление персоналом',
      topics: [
        'Лидерство',
        'Мотивация команды',
        'Развитие сотрудников',
        'Организационная культура'
      ]
    },
    {
      title: 'Финансы и операции',
      topics: [
        'Финансовый анализ',
        'Управление проектами',
        'Оптимизация процессов',
        'Управление рисками'
      ]
    }
  ],
  features: [
    'Практические кейсы',
    'Работа с экспертами',
    'Персональный наставник',
    'Networking',
    'Диплом о профессиональной переподготовке'
  ]
};