import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_illust',
  title: 'Курс Adobe Illustrator с нуля',
  description: 'Базовый курс по Adobe Illustrator',
  school: 'contented',
  category: 'software',
  price: 21420,
  oldPrice: 30600,
  currency: 'RUR',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1626785774625-ddcddc3445e9?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fillustrator&m=5&erid=LdtCKHL5u',
  discountPercent: 30,
  skills: [
    'Векторная графика',
    'Создание логотипов',
    'Работа с кривыми',
    'Создание паттернов',
    'Типографика',
    'Подготовка к печати'
  ],
  program: [
    {
      title: 'Основы Illustrator',
      topics: [
        'Интерфейс программы',
        'Инструменты рисования',
        'Работа с объектами',
        'Трансформация'
      ]
    },
    {
      title: 'Работа с кривыми',
      topics: [
        'Кривые Безье',
        'Перья и узлы',
        'Создание форм',
        'Работа с контурами'
      ]
    },
    {
      title: 'Цвет и эффекты',
      topics: [
        'Цветовые палитры',
        'Градиенты',
        'Паттерны',
        'Эффекты и стили'
      ]
    },
    {
      title: 'Подготовка макетов',
      topics: [
        'Работа с текстом',
        'Создание логотипов',
        'Подготовка к печати',
        'Экспорт файлов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Обратная связь от преподавателя',
    'Доступ к учебным материалам',
    'Сертификат о прохождении'
  ]
};