import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_illustrator_commercial',
  title: 'Коммерческий иллюстратор',
  description: 'Онлайн-курс «Коммерческий иллюстратор» от Нетологии: Станьте профессиональным иллюстратором всего за 6 месяцев! Онлайн-обучение с реальными проектами, практика в разных стилях и диплом. Овладейте востребованными инструментами и получите помощь в трудоустройстве.',
  school: 'netology',
  category: 'design',
  price: 127263,
  oldPrice: 212105,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fillustration-ultimate&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3535,
  skills: [
    'Коммерческая иллюстрация',
    'Цифровая графика',
    'Работа с заказчиками',
    'Создание портфолио',
    'Продвижение услуг'
  ],
  program: [
    {
      title: 'Основы иллюстрации',
      topics: [
        'Композиция',
        'Цвет и свет',
        'Анатомия',
        'Перспектива'
      ]
    },
    {
      title: 'Цифровые инструменты',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Procreate',
        'Digital painting'
      ]
    },
    {
      title: 'Коммерческая практика',
      topics: [
        'Работа с клиентами',
        'Создание портфолио',
        'Ценообразование',
        'Продвижение'
      ]
    }
  ],
  features: [
    '22 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};