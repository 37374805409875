import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_game_design_basics',
  title: 'Геймдизайн для начинающих',
  description: 'Онлайн-курс «Гейм-дизайн для начинающих» — Нетология: Пройдите обучение с нуля и научитесь разрабатывать игры. Создайте дизайн-документацию, проектируйте уровни, балансируйте игровые системы и получите диплом. Начните карьеру в игровой индустрии!',
  school: 'netology',
  category: 'game-dev',
  price: 66360,
  oldPrice: 110600,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1511512578047-dfb367046420?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fgamedesign&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2765,
  discountPercent: 40,
  skills: [
    'Геймдизайн',
    'Разработка игровых механик',
    'Левел-дизайн',
    'Балансировка игр',
    'Создание GDD'
  ],
  program: [
    {
      title: 'Основы геймдизайна',
      topics: [
        'Теория игр',
        'Игровые механики',
        'Проектирование уровней',
        'Баланс игры'
      ]
    },
    {
      title: 'Документация',
      topics: [
        'Game Design Document',
        'Техническое задание',
        'Описание механик',
        'Спецификации'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Прототипирование',
        'Тестирование',
        'Итерации',
        'Презентация проекта'
      ]
    }
  ],
  features: [
    '41 практическое занятие',
    'Создание прототипа игры',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};