import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_avito_manager',
  title: 'Менеджер по работе с Авито',
  description: 'Курс «Менеджер по работе с Авито» от Нетологии: научитесь продвигать бизнесы и зарабатывать на самой посещаемой платформе для онлайн-покупок в России. Получите бонусы от Авито и станьте востребованным специалистом.',
  school: 'netology',
  category: 'marketing',
  price: 40200,
  oldPrice: 67000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmenedzher-avito&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2233,
  skills: [
    'Работа с Авито',
    'Продвижение объявлений',
    'Аналитика продаж',
    'Управление магазином',
    'Оптимизация конверсии'
  ],
  program: [
    {
      title: 'Основы работы с Авито',
      topics: [
        'Создание магазина',
        'Настройка профиля',
        'Работа с объявлениями',
        'Управление товарами'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Оптимизация объявлений',
        'Работа с отзывами',
        'Реклама на площадке',
        'Акции и скидки'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'Анализ продаж',
        'Статистика магазина',
        'Оптимизация конверсии',
        'Отчетность'
      ]
    }
  ],
  features: [
    '27 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Бонусы от Авито',
    'Сертификат специалиста'
  ]
};