import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_illustrator',
  title: 'Офлайн-курс Adobe Illustrator',
  description: 'За 1 месяц научитесь создавать логотипы, иллюстрации, инфографику и айдентику. Занятия в мини-группе, поддержка преподавателя и доступ к онлайн-курсу',
  school: 'brunoyam',
  category: 'design',
  price: 11400,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fadobe-illustrator&m=5&erid=LdtCK4uLs',
  skills: [
    'Adobe Illustrator',
    'Векторная графика',
    'Создание логотипов',
    'Работа с кривыми',
    'Типографика'
  ],
  program: [
    {
      title: 'Основы Illustrator',
      topics: [
        'Интерфейс программы',
        'Инструменты',
        'Работа с объектами',
        'Кривые Безье'
      ]
    },
    {
      title: 'Создание графики',
      topics: [
        'Логотипы',
        'Иллюстрации',
        'Инфографика',
        'Паттерны'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с клиентами',
        'Подготовка к печати',
        'Создание портфолио',
        'Реальные проекты'
      ]
    }
  ],
  features: [
    '32 академических часа',
    'Практические задания',
    'Работа над проектами',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};