import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_body_therapy_full',
  title: 'Телесно-ориентированная терапия в психологическом консультировании',
  description: 'Дистанционное обучение по программе Телесно-ориентированная терапия в психологическом консультировании (650 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 36900,
  currency: 'RUR',
  duration: '650 часов',
  imageUrl: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Ftelesno_orientirovannaya_terapiya_v_psikhologicheskom_konsultirovanii_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Телесно-ориентированная терапия',
    'Работа с телом',
    'Диагностика телесных проявлений',
    'Релаксационные техники',
    'Работа с напряжением'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'История метода',
        'Теория характера',
        'Телесная диагностика',
        'Механизмы защиты'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Работа с дыханием',
        'Телесные практики',
        'Релаксация',
        'Биоэнергетика'
      ]
    },
    {
      title: 'Терапевтическая работа',
      topics: [
        'Ведение сессии',
        'Работа с травмой',
        'Групповая работа',
        'Интеграция опыта'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};