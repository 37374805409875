import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_math_oge_curator',
  title: 'Годовой курс по подготовке к ОГЭ по математике «База 2024/2025» с куратором',
  description: 'Годовой курс «База» с персональным куратором - это подготовка к ОГЭ по математике с нуля на отлично. Индивидуальный подход и постоянная поддержка.',
  school: '100points',
  category: 'math',
  price: 31390,
  oldPrice: 35890,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1560785496-3c9d27877182?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3490,
  discountPercent: 15,
  skills: [
    'Алгебра',
    'Геометрия',
    'Решение задач',
    'Работа с графиками',
    'Теория вероятностей',
    'Индивидуальные консультации'
  ],
  program: [
    {
      title: 'Алгебра',
      topics: [
        'Числа и вычисления',
        'Алгебраические выражения',
        'Уравнения и неравенства',
        'Функции и графики'
      ]
    },
    {
      title: 'Геометрия',
      topics: [
        'Треугольники',
        'Четырехугольники',
        'Окружность',
        'Площади фигур'
      ]
    },
    {
      title: 'Статистика и теория вероятностей',
      topics: [
        'Описательная статистика',
        'Вероятности',
        'Комбинаторика',
        'Анализ данных'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Решение типовых задач',
        'Пробные работы',
        'Работа над ошибками',
        'Итоговое повторение'
      ]
    }
  ],
  features: [
    '2 вебинара в неделю',
    'Персональный куратор',
    'Индивидуальные консультации',
    'Проверка домашних заданий',
    'Система мотивации',
    'Доступ к записям уроков'
  ]
};