import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_autocad',
  title: 'AutoCAD c нуля до PRO',
  description: 'Онлайн курс «AutoCAD с нуля до PRO» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 2 месяца. Помощь в трудоустройстве! Обучение AutoCAD с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 16917,
  oldPrice: 33833,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/ZFEWVMmppJ3holKdVDC4CGr6eokQJODrzZS4PeWG.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Fautocad%2F&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Работа в AutoCAD',
    'Создание чертежей',
    '2D проектирование',
    '3D моделирование',
    'Оформление документации',
    'Печать проектов'
  ],
  program: [
    {
      title: 'Основы AutoCAD',
      topics: [
        'Интерфейс программы',
        'Базовые инструменты',
        'Настройка рабочей среды',
        'Координаты и привязки'
      ]
    },
    {
      title: '2D проектирование',
      topics: [
        'Создание примитивов',
        'Редактирование объектов',
        'Слои и свойства',
        'Размеры и аннотации'
      ]
    },
    {
      title: '3D моделирование',
      topics: [
        'Основы 3D',
        'Твердотельное моделирование',
        'Поверхности',
        'Визуализация'
      ]
    },
    {
      title: 'Документация',
      topics: [
        'Оформление чертежей',
        'Стандарты',
        'Печать',
        'Экспорт'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Сертификат специалиста',
    'Готовые шаблоны'
  ]
};