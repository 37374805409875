import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_autocad',
  title: 'Офлайн-курс AutoCAD',
  description: 'Полностью практический курс для конструкторов, архитекторов, дизайнеров и студентов профильных специальностей. AutoCAD - лидер среди программ для создания чертежей в строительстве, архитектуре, машиностроении',
  school: 'brunoyam',
  category: 'design',
  price: 19900,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1581094288338-2314dddb7ece?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fautocad&m=5&erid=LdtCK4uLs',
  skills: [
    'AutoCAD',
    '2D черчение',
    '3D моделирование',
    'Оформление чертежей',
    'Печать проектов'
  ],
  program: [
    {
      title: 'Основы AutoCAD',
      topics: [
        'Интерфейс программы',
        'Инструменты черчения',
        'Слои и блоки',
        'Размеры и аннотации'
      ]
    },
    {
      title: '2D проектирование',
      topics: [
        'Создание чертежей',
        'Редактирование',
        'Штриховка',
        'Печать'
      ]
    },
    {
      title: '3D моделирование',
      topics: [
        'Твердотельное моделирование',
        'Поверхности',
        'Визуализация',
        'Рендеринг'
      ]
    }
  ],
  features: [
    '48 академических часов',
    '12 занятий',
    'Практические проекты',
    'Работа с реальными чертежами',
    'Сертификат специалиста'
  ]
};