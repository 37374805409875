import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_excel_advanced',
  title: 'Excel: от основ до анализа данных',
  description: 'Освойте продвинутые навыки Excel на онлайн-курсе. Автоматизируйте задачи с помощью Excel. Черная Пятница в Нетологии - двойные скидки на курсы!',
  school: 'netology',
  category: 'analytics',
  price: 25130,
  oldPrice: 35900,
  currency: 'RUB',
  duration: '9 недель',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fexcel&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2094,
  discountPercent: 30,
  skills: [
    'Excel продвинутый уровень',
    'Анализ данных',
    'Сводные таблицы',
    'Макросы',
    'Визуализация данных'
  ],
  program: [
    {
      title: 'Продвинутые функции',
      topics: [
        'ВПР и ГПР',
        'Сложные формулы',
        'Условное форматирование',
        'Работа с данными'
      ]
    },
    {
      title: 'Анализ данных',
      topics: [
        'Сводные таблицы',
        'Анализ данных',
        'Визуализация',
        'Отчетность'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Макросы',
        'VBA',
        'Автоматизация задач',
        'Оптимизация работы'
      ]
    }
  ],
  features: [
    '27 практических занятий',
    'Реальные задачи',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};