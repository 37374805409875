import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_hr_analytics',
  title: 'HR-аналитика',
  description: 'Курс для HR-специалистов, которые хотят научиться работать с данными и принимать решения на их основе.',
  school: 'yandex',
  category: 'management',
  price: 72000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fhr-analytics&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'HR аналитика',
    'Работа с данными',
    'Визуализация',
    'Принятие решений',
    'Метрики HR'
  ],
  program: [
    {
      title: 'Основы HR-аналитики',
      topics: [
        'Метрики и KPI',
        'Сбор данных',
        'Анализ показателей',
        'Отчетность'
      ]
    },
    {
      title: 'Инструменты анализа',
      topics: [
        'Excel для HR',
        'Дашборды',
        'Визуализация данных',
        'Статистика'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Рекрутинг',
        'Текучесть кадров',
        'Эффективность обучения',
        'Прогнозирование'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в развитии',
    'Сертификат HR-аналитика'
  ]
};