import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_journalism',
  title: 'Практическая журналистика',
  description: 'Программа проф. переподготовки 2024 года по журналистике от ЦАППКК поможет вам получить практические знания по сторителлингу, блогингу и PR и ряду других направлений.',
  school: 'appkk',
  category: 'journalism',
  price: 28900,
  currency: 'RUR',
  duration: '600 часов',
  imageUrl: 'https://images.unsplash.com/photo-1504711434969-e33886168f5c?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskaya_zhurnalistika%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Журналистское мастерство',
    'Создание контента',
    'Работа с источниками',
    'Медиапроизводство',
    'Цифровая журналистика'
  ],
  program: [
    {
      title: 'Основы журналистики',
      topics: [
        'Теория журналистики',
        'Жанры и форматы',
        'Работа с информацией',
        'Этика журналиста'
      ]
    },
    {
      title: 'Практические навыки',
      topics: [
        'Написание текстов',
        'Интервьюирование',
        'Фактчекинг',
        'Редактирование'
      ]
    },
    {
      title: 'Современные медиа',
      topics: [
        'Цифровая журналистика',
        'Социальные медиа',
        'Мультимедийный контент',
        'Мобильная журналистика'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Создание портфолио',
    'Работа с редакторами',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};