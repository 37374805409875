import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_product',
  title: 'Профессия Продакт-менеджер',
  description: 'Онлайн курс «Продакт-менеджер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на продакт-менеджера с нуля онлайн.',
  school: 'geekbrains',
  category: 'management',
  price: 121530,
  oldPrice: 243059,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/bqDtRTN2ZaVAq6SF1gLpqxqgXmU2zn0IbapGgOM1.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fproduct-manager-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Управление продуктом',
    'Анализ рынка',
    'Проведение исследований',
    'Разработка стратегии',
    'Работа с метриками',
    'Развитие продукта'
  ],
  program: [
    {
      title: 'Основы продакт-менеджмента',
      topics: [
        'Роль продакт-менеджера',
        'Жизненный цикл продукта',
        'Анализ рынка',
        'Customer Development'
      ]
    },
    {
      title: 'Исследования и аналитика',
      topics: [
        'UX-исследования',
        'Анализ конкурентов',
        'Работа с данными',
        'Метрики продукта'
      ]
    },
    {
      title: 'Развитие продукта',
      topics: [
        'Формирование гипотез',
        'Приоритизация задач',
        'A/B-тестирование',
        'Roadmap продукта'
      ]
    },
    {
      title: 'Управление командой',
      topics: [
        'Работа с разработкой',
        'Взаимодействие с дизайном',
        'Управление стейкхолдерами',
        'Agile-процессы'
      ]
    }
  ],
  features: [
    'Практика на реальных продуктах',
    'Персональный наставник',
    'Работа над портфолио',
    'Помощь в трудоустройстве',
    'Сертификат продакт-менеджера'
  ]
};