import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_sales',
  title: 'Руководитель отдела продаж',
  description: 'За 3 месяца узнаете все про эффективные продажи: от стратегии и инструментов до конкретных шагов, как повлиять на рост выручки компании',
  school: 'brunoyam',
  category: 'management',
  price: 48000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1552581234-26160f608093?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fhead-of-sales&m=5&erid=LdtCK4uLs',
  skills: [
    'Управление продажами',
    'Построение отдела',
    'Мотивация команды',
    'Аналитика продаж',
    'Стратегическое планирование'
  ],
  program: [
    {
      title: 'Управление отделом',
      topics: [
        'Структура отдела',
        'Найм персонала',
        'Обучение команды',
        'Мотивация'
      ]
    },
    {
      title: 'Процессы продаж',
      topics: [
        'Воронка продаж',
        'CRM-системы',
        'Скрипты продаж',
        'Работа с возражениями'
      ]
    },
    {
      title: 'Аналитика и развитие',
      topics: [
        'KPI и метрики',
        'Прогнозирование',
        'Оптимизация процессов',
        'Стратегия роста'
      ]
    }
  ],
  features: [
    '2 проекта',
    'Практические задания',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Сертификат руководителя'
  ]
};