import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_hr_manager',
  title: 'HR-менеджер',
  description: 'Научитесь организовывать работу сотрудников компании так, чтобы людям было комфортно, а бизнесу выгодно.',
  school: 'yandex',
  category: 'management',
  price: 92000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fhr-manager&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление персоналом',
    'Рекрутинг',
    'Адаптация сотрудников',
    'HR-аналитика',
    'Развитие персонала'
  ],
  program: [
    {
      title: 'Основы HR',
      topics: [
        'HR-стратегия',
        'Подбор персонала',
        'Адаптация',
        'Оценка персонала'
      ]
    },
    {
      title: 'Развитие персонала',
      topics: [
        'Обучение',
        'Мотивация',
        'Карьерное развитие',
        'Корпоративная культура'
      ]
    },
    {
      title: 'HR-процессы',
      topics: [
        'Кадровое делопроизводство',
        'HR-аналитика',
        'Автоматизация процессов',
        'Управление эффективностью'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат HR-менеджера'
  ]
};