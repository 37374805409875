import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_business_tracker',
  title: 'Бизнес-трекер',
  description: 'Освойте профессию бизнес-трекера с курсом в Нетологии. Научитесь диагностировать и улучшать бизнес-процессы, разрабатывать стратегии и поддерживать рост компаний. Обучение включает практические кейсы, вебинары и экспертную поддержку.',
  school: 'netology',
  category: 'management',
  price: 64380,
  oldPrice: 107300,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fbusiness_tracker&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2682,
  skills: [
    'Бизнес-анализ',
    'Стратегическое планирование',
    'Управление изменениями',
    'Развитие бизнеса',
    'Работа с собственниками'
  ],
  program: [
    {
      title: 'Основы трекинга',
      topics: [
        'Диагностика бизнеса',
        'Анализ процессов',
        'Метрики и KPI',
        'Стратегическое планирование'
      ]
    },
    {
      title: 'Практика трекинга',
      topics: [
        'Работа с собственниками',
        'Внедрение изменений',
        'Управление проектами',
        'Развитие бизнеса'
      ]
    },
    {
      title: 'Инструменты трекера',
      topics: [
        'Аналитические инструменты',
        'Управление командой',
        'Фасилитация',
        'Коучинг'
      ]
    }
  ],
  features: [
    '60 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в развитии карьеры',
    'Сертификат о повышении квалификации'
  ]
};