import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_copywriter',
  title: 'Онлайн-курс Копирайтер с нуля',
  description: 'Научитесь писать 20+ самых нужных для бизнеса типов текстов, выстраивать процесс от получения задания до публикации, ускорять работу с помощью нейросетей и сервисов',
  school: 'brunoyam',
  category: 'marketing',
  price: 24900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fcopywriter&m=5&erid=LdtCK4uLs',
  skills: [
    'Копирайтинг',
    'Создание контента',
    'Работа с текстом',
    'SEO-оптимизация',
    'Работа с нейросетями'
  ],
  program: [
    {
      title: 'Основы копирайтинга',
      topics: [
        'Типы текстов',
        'Структура текста',
        'Стилистика',
        'Редактура'
      ]
    },
    {
      title: 'Практика написания',
      topics: [
        'Продающие тексты',
        'Информационные статьи',
        'SEO-тексты',
        'Рекламные материалы'
      ]
    },
    {
      title: 'Работа с инструментами',
      topics: [
        'Нейросети в работе',
        'Сервисы для проверки',
        'Оптимизация процессов',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными проектами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат по окончании'
  ]
};