import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_qa_auto',
  title: 'Профессия Инженер по автоматизации тестирования',
  description: 'Онлайн курс «Инженер по автоматизации тестирования» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 9 месяцев. Помощь в трудоустройстве! Обучение на инженера по автоматизации тестирования с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 104645,
  oldPrice: 209290,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/KYyJDCJG1QReE3yG6wzUtg3TPwbw9xQGmRo9fuqY.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fqa-engineer%2Fauto-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Автоматизация тестирования',
    'Программирование на Python',
    'Selenium WebDriver',
    'API тестирование',
    'CI/CD для тестирования',
    'Performance Testing'
  ],
  program: [
    {
      title: 'Основы тестирования',
      topics: [
        'Теория тестирования',
        'Виды тестирования',
        'Тест-дизайн',
        'Тест-кейсы'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Python для тестирования',
        'Selenium WebDriver',
        'Page Object Pattern',
        'Pytest'
      ]
    },
    {
      title: 'API тестирование',
      topics: [
        'REST API',
        'Postman',
        'API автотесты',
        'Mock-сервисы'
      ]
    },
    {
      title: 'DevOps практики',
      topics: [
        'Git',
        'Jenkins',
        'Docker',
        'Непрерывное тестирование'
      ]
    }
  ],
  features: [
    'Практика автоматизации',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат QA инженера'
  ]
};