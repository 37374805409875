import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_animator',
  title: 'Аниматор-мультипликатор',
  description: 'Онлайн-курс «Профессия Аниматор-мультипликатор» — Нетология: Пройдите обучение 2D и 3D анимации с нуля. Освойте Adobe Animate, TVPaint и After Effects, создайте свой первый мультфильм и добавьте его в портфолио. Получите диплом и начните карьеру в анимации!',
  school: 'netology',
  category: 'design',
  price: 120498,
  oldPrice: 200830,
  currency: 'RUB',
  duration: '11 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1616499452581-cc7f8e3dd3c9?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fanimator-multiplikator&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3347,
  skills: [
    '2D анимация',
    '3D анимация',
    'Работа со звуком',
    'Сторителлинг',
    'Композиция',
    'Режиссура анимации'
  ],
  program: [
    {
      title: 'Основы анимации',
      topics: [
        'Принципы анимации',
        'Тайминг и спейсинг',
        'Композиция',
        'Сторителлинг'
      ]
    },
    {
      title: '2D и 3D анимация',
      topics: [
        'Adobe Animate',
        'TVPaint',
        'After Effects',
        'Основы 3D'
      ]
    },
    {
      title: 'Создание мультфильма',
      topics: [
        'Разработка концепции',
        'Анимация персонажей',
        'Монтаж',
        'Озвучка'
      ]
    }
  ],
  features: [
    '20 практических занятий',
    'Создание мультфильма',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};