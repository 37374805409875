import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_java_dev_plus',
  title: 'Java-разработчик расширенный',
  description: 'Углублённый курс Java-разработчик',
  school: 'yandex',
  category: 'programming',
  price: 215000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fjava-developer-plus&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Java Core продвинутый',
    'Spring Framework',
    'Микросервисы',
    'Высоконагруженные системы',
    'DevOps практики'
  ],
  program: [
    {
      title: 'Продвинутая Java',
      topics: [
        'Многопоточность',
        'Коллекции',
        'Рефлексия',
        'Оптимизация'
      ]
    },
    {
      title: 'Spring и микросервисы',
      topics: [
        'Spring Cloud',
        'Микросервисная архитектура',
        'Распределенные системы',
        'Масштабирование'
      ]
    },
    {
      title: 'DevOps и инфраструктура',
      topics: [
        'Docker',
        'Kubernetes',
        'CI/CD',
        'Мониторинг'
      ]
    }
  ],
  features: [
    'Углубленная программа',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};