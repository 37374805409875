import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_targeting',
  title: 'Офлайн-курс таргетолог с нуля',
  description: 'Научитесь настраивать таргетированную рекламу: от подбора креативов до аналитики по всем каналам. Обучение в центре Петербурга',
  school: 'brunoyam',
  category: 'marketing',
  price: 34900,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1533750349088-cd871a92f312?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-internet-marketing%2Fobuchenie-targeting&m=5&erid=LdtCK4uLs',
  skills: [
    'Таргетированная реклама',
    'Работа с аудиториями',
    'Создание креативов',
    'Аналитика рекламы',
    'Оптимизация кампаний'
  ],
  program: [
    {
      title: 'Основы таргетинга',
      topics: [
        'Принципы таргетинга',
        'Рекламные кабинеты',
        'Аудитории и сегменты',
        'Форматы рекламы'
      ]
    },
    {
      title: 'Создание кампаний',
      topics: [
        'Настройка рекламы',
        'Работа с креативами',
        'Таргетинг и ретаргетинг',
        'Управление ставками'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Анализ результатов',
        'Оптимизация кампаний',
        'Масштабирование',
        'Отчетность'
      ]
    }
  ],
  features: [
    '32 академических часа',
    '8 занятий',
    'Практические задания',
    'Работа с реальными проектами',
    'Сертификат специалиста'
  ]
};