import { Course } from '../../../../types';

export const course: Course = {
  id: 'abakus_reading_24',
  title: 'Чтение Абакус - обучение и курсы для детей | 24 урока',
  description: 'Наша авторская методика, направленная на развитие навыков осознанного чтения и улучшение техники и скорости чтения',
  school: 'abakus',
  category: 'russian',
  price: 29400,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?q=80&w=1200',
  url: 'https://go.redav.online/86fdf99551e92c21?dl=https%3A%2F%2Fabakus-center.ru%2Feducation%2Freading&m=5&erid=2Vfnxx8cgzL',
  monthlyPayment: 9800,
  skills: [
    'Продвинутая техника чтения',
    'Высокая скорость чтения',
    'Глубокое понимание текста',
    'Развитая память',
    'Богатый словарный запас'
  ],
  program: [
    {
      title: 'Базовый уровень',
      topics: [
        'Диагностика навыков',
        'Базовые упражнения',
        'Работа с текстами',
        'Развитие внимания'
      ]
    },
    {
      title: 'Продвинутый уровень',
      topics: [
        'Сложные упражнения',
        'Скорочтение',
        'Анализ текстов',
        'Мнемотехники'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивная платформа',
    'Опытные преподаватели',
    'Регулярные домашние задания',
    'Отслеживание прогресса'
  ]
};