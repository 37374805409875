import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_mak_practice',
  title: 'Практические навыки применения метафорических ассоциативных карт в психологическом консультировании',
  description: 'Дистанционное обучение по программе Практические навыки применения метафорических ассоциативных карт в психологическом консультировании(52 часа) в ЦАППКК. Освоение практических навыков работы с МАК.',
  school: 'appkk',
  category: 'psychology',
  price: 24900,
  currency: 'RUR',
  duration: '52 часа',
  imageUrl: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_primeneniya_metaforicheskikh_assotsiativnykh_kart_v_psikhologicheskom_konsultir%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Работа с МАК',
    'Проведение консультаций',
    'Диагностика с помощью МАК',
    'Групповая работа',
    'Индивидуальное консультирование'
  ],
  program: [
    {
      title: 'Основы работы с МАК',
      topics: [
        'Ведение консультаций',
        'Универсальные техники',
        'Работа с запросами',
        'Диагностика'
      ]
    },
    {
      title: 'Специальные техники',
      topics: [
        'Работа с самооценкой',
        'Семейное консультирование',
        'Работа с ресурсами',
        'Принятие решений'
      ]
    },
    {
      title: 'Групповая работа',
      topics: [
        'Организация группы',
        'Групповые техники',
        'Работа с сопротивлением',
        'Завершение работы'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};