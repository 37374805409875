import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_web_designer_mentor',
  title: 'Веб-дизайнер расширенный',
  description: 'Изучите основы визуального дизайна, освоите Figma, Adobe Photoshop, Tilda, создадите 6 проектов в портфолио',
  school: 'yandex',
  category: 'design',
  price: 154000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fweb-designer%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Веб-дизайн',
    'Figma',
    'Adobe Photoshop',
    'Tilda',
    'UI/UX дизайн'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Типографика',
        'Цветоведение'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Figma',
        'Adobe Photoshop',
        'Tilda',
        'Прототипирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание макетов',
        'Адаптивный дизайн',
        'Анимация интерфейсов',
        'Портфолио'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат дизайнера'
  ]
};