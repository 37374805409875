import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_devops',
  title: 'Профессия DevOps-инженер 2.0',
  description: 'Онлайн курс «Devops-инженер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на Devops-инженера с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 103720,
  oldPrice: 207440,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/ubNOPSsUAlQ7Ep6Y2LudXVMLmU3ml5woQMawPGRX.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Farchitecture%2Fdevops-spec-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Администрирование Linux',
    'Работа с контейнерами',
    'Continuous Integration',
    'Continuous Delivery',
    'Мониторинг систем',
    'Автоматизация процессов'
  ],
  program: [
    {
      title: 'Основы DevOps',
      topics: [
        'Linux администрирование',
        'Сети и безопасность',
        'Скриптинг',
        'Git'
      ]
    },
    {
      title: 'Контейнеризация',
      topics: [
        'Docker',
        'Kubernetes',
        'Оркестрация',
        'Микросервисы'
      ]
    },
    {
      title: 'CI/CD',
      topics: [
        'Jenkins',
        'GitLab CI',
        'Автоматизация сборки',
        'Деплой'
      ]
    },
    {
      title: 'Мониторинг',
      topics: [
        'Prometheus',
        'Grafana',
        'ELK Stack',
        'Логирование'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат DevOps-инженера'
  ]
};