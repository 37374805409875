import React from 'react';
import { Link } from 'react-router-dom';

export const Header: React.FC = () => {
  return (
    <header className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-blue-600 hover:text-blue-700 transition-colors">
            Обучение.online
          </Link>
          <nav className="flex items-center gap-8">
            <Link to="/search" className="text-gray-600 hover:text-gray-900">Все курсы</Link>
            <Link to="/blog" className="text-gray-600 hover:text-gray-900">Блог</Link>
          </nav>
          <div className="flex items-center gap-4">
            <button className="text-gray-700 hover:text-gray-900">
              Войти
            </button>
            <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors">
              Регистрация
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};