import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCourseStore } from '../store/courseStore';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { SearchSuggestions } from './SearchSuggestions';

export const Hero: React.FC = () => {
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery } = useCourseStore();
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      setShowSuggestions(false);
      navigate('/search');
    }
  };

  const handleSuggestionSelect = (suggestion: string) => {
    setSearchQuery(suggestion);
    setShowSuggestions(false);
    navigate('/search');
  };

  return (
    <div className="py-12 md:py-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold text-white text-center mb-6">
            Выбирайте лучшие онлайн‑курсы
          </h1>
          <p className="text-xl text-blue-100 text-center mb-8">
            Покупайте дешевле с Обучение.online
          </p>
          
          <form onSubmit={handleSubmit} className="relative">
            <div className="bg-white p-2 rounded-xl shadow-lg">
              <div className="flex">
                <div className="flex-1">
                  <div className="relative">
                    <MagnifyingGlassIcon className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Какой курс вы ищете?"
                      value={searchQuery}
                      onChange={handleSearch}
                      onFocus={() => setShowSuggestions(true)}
                      className="w-full pl-10 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
                <button 
                  type="submit"
                  className="bg-blue-600 text-white px-6 py-3 rounded-lg ml-2 hover:bg-blue-700 transition-colors"
                >
                  Найти
                </button>
              </div>
            </div>
            
            {showSuggestions && (
              <SearchSuggestions 
                query={searchQuery} 
                onSelect={handleSuggestionSelect} 
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};