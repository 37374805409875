import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_data_engineer_start',
  title: 'Инженер данных с нуля',
  description: 'Обучение на инженера данных для начинающих специалистов.',
  school: 'yandex',
  category: 'analytics',
  price: 165000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1509228627152-72ae9ae6848d?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fdata-engineer-start&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Проектирование хранилищ',
    'ETL процессы',
    'Big Data',
    'Python для данных',
    'SQL и базы данных'
  ],
  program: [
    {
      title: 'Основы инженерии данных',
      topics: [
        'Python для данных',
        'SQL и базы данных',
        'ETL процессы',
        'Хранилища данных'
      ]
    },
    {
      title: 'Big Data технологии',
      topics: [
        'Hadoop экосистема',
        'Apache Spark',
        'Kafka',
        'Airflow'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Проектирование хранилищ',
        'Построение пайплайнов',
        'Оптимизация процессов',
        'Мониторинг'
      ]
    }
  ],
  features: [
    'Обучение с нуля',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат инженера данных'
  ]
};