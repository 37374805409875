import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_radio',
  title: 'Радиоведущий',
  description: 'Дистанционная программа проф. переподготовки 2024 года поможет вам освоить профессию радиоведущего и даст первый опыт в новой профессии.',
  school: 'appkk',
  category: 'journalism',
  price: 21200,
  currency: 'RUR',
  duration: '500 часов',
  imageUrl: 'https://images.unsplash.com/photo-1593697821252-0c9137d9fc45?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fradiovedushchiy%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Техника речи',
    'Работа с микрофоном',
    'Ведение эфира',
    'Интервьюирование',
    'Создание программ'
  ],
  program: [
    {
      title: 'Основы радиовещания',
      topics: [
        'История радио',
        'Форматы радиостанций',
        'Структура эфира',
        'Целевая аудитория'
      ]
    },
    {
      title: 'Практические навыки',
      topics: [
        'Техника речи',
        'Работа с голосом',
        'Импровизация',
        'Работа с оборудованием'
      ]
    },
    {
      title: 'Создание контента',
      topics: [
        'Форматы программ',
        'Подготовка материалов',
        'Проведение интервью',
        'Музыкальное оформление'
      ]
    }
  ],
  features: [
    'Практические занятия',
    'Работа в студии',
    'Создание демо',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};