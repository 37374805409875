import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_social_psychology',
  title: 'Предоставление психологических услуг в социальной сфере',
  description: 'Дистанционное обучение по программе Предоставление психологических услуг в социальной сфере с присвоением квалификации Психолог в социальной сфере(560 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 35000,
  currency: 'RUR',
  duration: '560 часов',
  imageUrl: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpredostavlenie_psikhologicheskikh_uslug_v_sotsialnoy_sfere_s_prisvoeniem_kvalifikatsii_psikholog_v_s%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Социальная психология',
    'Психологическая помощь',
    'Работа с группами риска',
    'Социальная адаптация',
    'Профилактическая работа'
  ],
  program: [
    {
      title: 'Основы социальной работы',
      topics: [
        'Нормативно-правовая база',
        'Профессиональные стандарты',
        'Этика работы',
        'Документация'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Консультирование',
        'Групповая работа',
        'Кризисная помощь',
        'Реабилитация'
      ]
    },
    {
      title: 'Специальные направления',
      topics: [
        'Работа с семьей',
        'Помощь детям',
        'Поддержка пожилых',
        'Работа с инвалидами'
      ]
    }
  ],
  features: [
    'Практическая подготовка',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};