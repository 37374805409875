import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_unit_economics',
  title: 'Юнит-экономика и продуктовые метрики',
  description: 'Изучите юнит-экономику с курсом в Нетологии и научитесь прогнозировать прибыльность продуктов. Освойте ключевые метрики и инструменты для оценки рентабельности и инвестиционной привлекательности. Курс включает практические задания и поддержку экспертов, что поможет вам принимать обоснованные бизнес-решения.',
  school: 'netology',
  category: 'management',
  price: 22800,
  oldPrice: 38000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Funit-eco&m=4&erid=LdtCKCxkP',
  monthlyPayment: 1900,
  discountPercent: 40,
  skills: [
    'Юнит-экономика',
    'Продуктовые метрики',
    'Анализ рентабельности',
    'Прогнозирование',
    'Принятие решений'
  ],
  program: [
    {
      title: 'Основы юнит-экономики',
      topics: [
        'Базовые метрики',
        'Экономические модели',
        'Анализ данных',
        'Расчет показателей'
      ]
    },
    {
      title: 'Продуктовая аналитика',
      topics: [
        'Ключевые метрики',
        'Когортный анализ',
        'LTV и CAC',
        'Retention'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Построение моделей',
        'Оптимизация метрик',
        'Прогнозирование',
        'Принятие решений'
      ]
    }
  ],
  features: [
    '10 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};