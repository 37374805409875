import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_django',
  title: 'Backend-разработка на Django',
  description: 'Пройдите курс по Django онлайн от Нетологии. Освойте разработку веб-приложений с нуля, научитесь работать с базами данных и становитесь востребованным Django разработчиком. Запишитесь сейчас!',
  school: 'netology',
  category: 'programming',
  price: 28000,
  oldPrice: 40000,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdjango&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2333,
  discountPercent: 30,
  skills: [
    'Django Framework',
    'Python для веб',
    'REST API',
    'Базы данных',
    'Тестирование'
  ],
  program: [
    {
      title: 'Основы Django',
      topics: [
        'MVT архитектура',
        'Маршрутизация',
        'Шаблоны',
        'ORM'
      ]
    },
    {
      title: 'Продвинутый Django',
      topics: [
        'Аутентификация',
        'REST Framework',
        'Celery',
        'Кеширование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разработка API',
        'Тестирование',
        'Деплой',
        'Оптимизация'
      ]
    }
  ],
  features: [
    '15 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};