import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_interface_designer',
  title: 'Дизайнер интерфейсов',
  description: 'Освоите Figma и будете проектировать интерфейсы для сайтов и приложений, чтобы ими было удобно пользоваться.',
  school: 'yandex',
  category: 'design',
  price: 139000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Finterface-designer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'UI/UX дизайн',
    'Figma',
    'Прототипирование',
    'Анимация интерфейсов',
    'Дизайн-системы'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Типографика',
        'Цвет'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'UX исследования',
        'Прототипирование',
        'Тестирование',
        'Дизайн-системы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Анимация',
        'Портфолио',
        'Презентация работ'
      ]
    }
  ],
  features: [
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о профессии',
    'Стажировка'
  ]
};