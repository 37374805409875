import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_komil',
  title: 'Профессия коммерческий иллюстратор',
  description: 'Научитесь создавать коммерчески успешные иллюстрации и построите карьеру в иллюстрации. От основ рисунка до работы с заказчиками.',
  school: 'contented',
  category: 'design',
  price: 105019,
  oldPrice: 190944,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://static.tildacdn.com/tild3538-3865-4163-a233-636665376435/_.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fcom-illustrator&m=5&erid=LdtCKHL5u',
  monthlyPayment: 2917,
  discountPercent: 45,
  skills: [
    'Создание цифровых иллюстраций',
    'Работа с графическими редакторами',
    'Разработка персонажей',
    'Композиция и цвет',
    'Стилизация',
    'Работа с заказчиками',
    'Продвижение и монетизация'
  ],
  program: [
    {
      title: 'Основы иллюстрации',
      topics: [
        'Рисунок и композиция',
        'Работа с цветом',
        'Стилизация',
        'Техники иллюстрации'
      ]
    },
    {
      title: 'Цифровые инструменты',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Procreate',
        'Графический планшет'
      ]
    },
    {
      title: 'Коммерческая иллюстрация',
      topics: [
        'Работа с брифом',
        'Поиск стиля',
        'Создание портфолио',
        'Работа с заказчиками'
      ]
    },
    {
      title: 'Продвижение и бизнес',
      topics: [
        'Личный бренд',
        'Поиск клиентов',
        'Ценообразование',
        'Авторское право'
      ]
    }
  ],
  features: [
    'Индивидуальные консультации',
    'Практика на реальных проектах',
    'Помощь в создании портфолио',
    'Доступ к базе заказчиков',
    'Поддержка в запуске фриланс-карьеры'
  ]
};