import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_product_manager_start',
  title: 'Продакт-менеджер с нуля',
  description: 'Курс для тех, кто хочет работать в IT, но не готов писать код. Освоите новую профессию и поймёте, как разрабатывать, запускать и развивать цифровые продукты.',
  school: 'yandex',
  category: 'management',
  price: 159000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fproduct-manager-start&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление продуктом',
    'Анализ рынка',
    'Проведение исследований',
    'Работа с метриками',
    'Развитие продукта'
  ],
  program: [
    {
      title: 'Основы продакт-менеджмента',
      topics: [
        'Продуктовое мышление',
        'Анализ рынка',
        'Customer Development',
        'Метрики продукта'
      ]
    },
    {
      title: 'Разработка продукта',
      topics: [
        'Формирование гипотез',
        'Проектирование решений',
        'Прототипирование',
        'Тестирование'
      ]
    },
    {
      title: 'Развитие продукта',
      topics: [
        'Запуск продукта',
        'Анализ результатов',
        'Оптимизация метрик',
        'Масштабирование'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными продуктами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат продакт-менеджера'
  ]
};