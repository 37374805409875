import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_business_simulation',
  title: 'Симулятор развития руководителей',
  description: 'Онлайн-курс «Бизнес-симулятор для руководителей» от Нетологии — обучение лидерству, управлению проектами, предотвращению выгорания и принятию решений через симуляции и бизнес-кейсы.',
  school: 'netology',
  category: 'management',
  price: 69900,
  oldPrice: 116500,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Flider-upskill&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2912,
  skills: [
    'Управление командой',
    'Принятие решений',
    'Лидерство',
    'Предотвращение выгорания',
    'Управление проектами'
  ],
  program: [
    {
      title: 'Лидерство и управление',
      topics: [
        'Стили руководства',
        'Мотивация команды',
        'Делегирование',
        'Обратная связь'
      ]
    },
    {
      title: 'Принятие решений',
      topics: [
        'Анализ ситуации',
        'Оценка рисков',
        'Стратегическое мышление',
        'Кризисное управление'
      ]
    },
    {
      title: 'Развитие компетенций',
      topics: [
        'Эмоциональный интеллект',
        'Управление стрессом',
        'Коммуникация',
        'Тайм-менеджмент'
      ]
    }
  ],
  features: [
    'Бизнес-симуляции',
    'Практические кейсы',
    'Работа с экспертами',
    'Индивидуальный подход',
    'Сертификат о повышении квалификации'
  ]
};