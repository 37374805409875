import React from 'react';
import { CourseFilters } from '../components/CourseFilters';
import { CourseGrid } from '../components/CourseGrid';
import { useCourseStore } from '../store/courseStore';

export const SearchPage: React.FC = () => {
  const { getFilteredCourses, searchQuery } = useCourseStore();
  const courses = getFilteredCourses();

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          {searchQuery ? (
            `Результаты поиска по запросу "${searchQuery}"`
          ) : (
            'Все курсы'
          )}
        </h1>
        <p className="text-gray-600 mt-2">
          {courses.length > 0 
            ? `Найдено ${courses.length} курсов`
            : 'Курсы не найдены'}
        </p>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <aside className="lg:w-1/4">
          <CourseFilters />
        </aside>
        
        <div className="lg:w-3/4">
          <div className="flex justify-end mb-6">
            <select className="bg-white border border-gray-300 rounded-lg px-3 py-2 text-sm">
              <option>По популярности</option>
              <option>По цене ↑</option>
              <option>По цене ↓</option>
              <option>По рейтингу</option>
            </select>
          </div>
          
          {courses.length > 0 ? (
            <CourseGrid courses={courses} />
          ) : (
            <div className="text-center py-12 bg-gray-50 rounded-lg">
              <p className="text-gray-600 mb-4">
                По вашему запросу ничего не найдено
              </p>
              <p className="text-gray-500 text-sm">
                Попробуйте изменить параметры поиска или сбросить фильтры
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};