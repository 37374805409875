import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_data_scientist_plus',
  title: 'Специалист по Data Science расширенный',
  description: 'Всё то же, что в базовом курсе, а ещё продвинутые запросы SQL и инструменты PyShark, работа с СУБД PostgreSQL и библиотека PyTorch.',
  school: 'yandex',
  category: 'analytics',
  price: 230000,
  currency: 'RUB',
  duration: '14 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1509228627152-72ae9ae6848d?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fdata-scientist-plus&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продвинутый Python',
    'Machine Learning',
    'Deep Learning',
    'SQL и PostgreSQL',
    'PyTorch'
  ],
  program: [
    {
      title: 'Продвинутый SQL',
      topics: [
        'Сложные запросы',
        'Оптимизация',
        'PostgreSQL',
        'Хранимые процедуры'
      ]
    },
    {
      title: 'Deep Learning',
      topics: [
        'PyTorch',
        'Нейронные сети',
        'Computer Vision',
        'NLP'
      ]
    },
    {
      title: 'MLOps',
      topics: [
        'Развертывание моделей',
        'Мониторинг',
        'Оптимизация',
        'CI/CD для ML'
      ]
    }
  ],
  features: [
    'Углубленные проекты',
    'Расширенная программа',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};