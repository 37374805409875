import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_coaching',
  title: 'Основы коучинга: курс обучения базовым инструментам',
  description: 'Дистанционное обучение по программе Основы коучинга: курс обучения базовым инструментам (72 часа) в ЦАППКК. Освоение базовых инструментов и техник коучинга для профессиональной практики.',
  school: 'appkk',
  category: 'psychology',
  price: 10000,
  currency: 'RUR',
  duration: '72 часа',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fosnovy_kouchinga_kurs_obucheniya_bazovym_instrumentam_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Базовые инструменты коучинга',
    'Проведение коуч-сессий',
    'Постановка целей',
    'Работа с ценностями',
    'Преодоление препятствий'
  ],
  program: [
    {
      title: 'Основы коучинга',
      topics: [
        'Философия коучинга',
        'Принципы коучинга',
        'Этика коуча',
        'Компетенции коуча'
      ]
    },
    {
      title: 'Инструменты коучинга',
      topics: [
        'Колесо баланса',
        'GROW модель',
        'Шкалирование',
        'Сильные вопросы'
      ]
    },
    {
      title: 'Практика коучинга',
      topics: [
        'Структура сессии',
        'Работа с целями',
        'Преодоление препятствий',
        'Планирование действий'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор кейсов',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};