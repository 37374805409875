import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_math_legion',
  title: 'Годовой курс подготовки к ЕГЭ по профильной математике на 90+ «Легион с Ильичом 2024/2025»',
  description: 'Годовой курс «Легион с Ильичом 2024/2025» для подготовки к ЕГЭ по профильной математике. Курс включает 3-4 занятия в неделю, систему мотивации, помощь экспертов и психологическую поддержку.',
  school: '100points',
  category: 'math',
  price: 33890,
  oldPrice: 39490,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3690,
  discountPercent: 15,
  skills: [
    'Решение профильных задач',
    'Подготовка к экзамену',
    'Работа с типовыми заданиями',
    'Разбор сложных тем',
    'Практика решения задач',
    'Работа над ошибками'
  ],
  program: [
    {
      title: 'Базовые темы',
      topics: [
        'Числа и вычисления',
        'Алгебраические выражения',
        'Уравнения и неравенства',
        'Функции и графики'
      ]
    },
    {
      title: 'Геометрия',
      topics: [
        'Планиметрия',
        'Стереометрия',
        'Координатный метод',
        'Векторы'
      ]
    },
    {
      title: 'Профильные темы',
      topics: [
        'Производная и интеграл',
        'Комбинаторика и теория вероятностей',
        'Задачи с параметрами',
        'Экономические задачи'
      ]
    },
    {
      title: 'Подготовка к экзамену',
      topics: [
        'Тренировочные варианты',
        'Пробные экзамены',
        'Работа над ошибками',
        'Стратегия выполнения заданий'
      ]
    }
  ],
  features: [
    '3-4 занятия в неделю',
    'Система мотивации с жизнями',
    'Помощь экспертов ЕГЭ',
    'Психологическая поддержка',
    'Разбор домашних заданий'
  ]
};