import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_psychology_basics',
  title: 'Психология: узнать себя и понять других',
  description: 'Дистанционное обучение психологии для начинающих с сертификатом. Практический курс для тех, кто хочет разобраться в себе и других людях.',
  school: 'netology',
  category: 'psychology',
  price: 9900,
  oldPrice: 16500,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fpsihologiya-dlya-zhizni&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 9900,
  skills: [
    'Самопознание',
    'Психология отношений',
    'Эмоциональный интеллект',
    'Коммуникация',
    'Личностный рост'
  ],
  program: [
    {
      title: 'Основы психологии',
      topics: [
        'Психология личности',
        'Эмоции и чувства',
        'Мотивация',
        'Самопознание'
      ]
    },
    {
      title: 'Практическая психология',
      topics: [
        'Отношения с собой',
        'Отношения с другими',
        'Конфликты',
        'Стресс-менеджмент'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Интерактивные упражнения',
    'Работа с психологом',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};