import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_data_engineer',
  title: 'Дата-инженер',
  description: 'Профессия «Дата-инженер» от Нетологии: научитесь автоматизировать работу с данными и разрабатывать архитектуру данных. Практика на реальных проектах поможет вам развить ключевые навыки, необходимые для успешной карьеры в сфере обработки данных.',
  school: 'netology',
  category: 'analytics',
  price: 147000,
  oldPrice: 245000,
  currency: 'RUB',
  duration: '18 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdata-engineer&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4083,
  skills: [
    'ETL процессы',
    'Big Data',
    'SQL и NoSQL',
    'Python для данных',
    'Apache Spark',
    'Data Pipeline'
  ],
  program: [
    {
      title: 'Основы инженерии данных',
      topics: [
        'Архитектура данных',
        'ETL процессы',
        'Хранилища данных',
        'Data Lake'
      ]
    },
    {
      title: 'Инструменты и технологии',
      topics: [
        'SQL и NoSQL базы',
        'Apache Spark',
        'Hadoop экосистема',
        'Airflow'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Построение пайплайнов',
        'Оптимизация процессов',
        'Мониторинг',
        'Real-time обработка'
      ]
    }
  ],
  features: [
    '51 практическое занятие',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};