import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_advanced_methodologist',
  title: 'Методист онлайн-курсов: расширенная программа',
  description: 'На курсе вы узнаете о навыках и приёмах, которые используют в работе методисты Нетологии и других крупных образовательных платформ; а также получите продвинутые навыки проектирования сложных образовательных продуктов',
  school: 'netology',
  category: 'education',
  price: 77100,
  oldPrice: 128500,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmetodist-online-obucheniya-pro&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3212,
  discountPercent: 40,
  skills: [
    'Методический дизайн',
    'Проектирование курсов',
    'Разработка контента',
    'Оценка эффективности',
    'Управление обучением'
  ],
  program: [
    {
      title: 'Продвинутая методология',
      topics: [
        'Образовательный дизайн',
        'Проектирование программ',
        'Создание контента',
        'Оценочные материалы'
      ]
    },
    {
      title: 'Управление разработкой',
      topics: [
        'Управление проектами',
        'Работа с экспертами',
        'Контроль качества',
        'Внедрение курсов'
      ]
    },
    {
      title: 'Оценка и развитие',
      topics: [
        'Анализ эффективности',
        'Обратная связь',
        'Актуализация программ',
        'Масштабирование'
      ]
    }
  ],
  features: [
    '13 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};