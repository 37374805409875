import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_seo',
  title: 'Основы SEO-продвижения',
  description: 'Бесплатное обучение SEO-продвижению для новичков от Нетологии. На онлайн курсе вы с нуля разберетесь, кто такой специалист по поисковому продвижению сайтов, и научитесь основам профессии.',
  school: 'netology',
  category: 'marketing',
  price: 0,
  oldPrice: 0,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgUHH_2eTSGl8XYh3KuzO8Zf585mtsQQJxwmnveaTRaX-iynu3y01lL7nBq-w49-byVF2dfwIUEc4lPPN5X735_6KMvMpABx2HL2rvCQo8N8BJDhNAKGfd0m4apjFQs7VnRKWozF7U0hH6rdZGGLeVM6iwtTKvBu328Y4bpNlMXtIlaqgY7fRwPHnAPnGv7/s1100/seo-canberra.jpg?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fseo-free&m=5&erid=LdtCKCxkP',
  skills: [
    'Основы SEO',
    'Поисковая оптимизация',
    'Работа с контентом',
    'Анализ конкурентов',
    'Базовая аналитика'
  ],
  program: [
    {
      title: 'Введение в SEO',
      topics: [
        'Принципы работы',
        'Факторы ранжирования',
        'Семантическое ядро',
        'Мета-теги'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Оптимизация контента',
        'Работа с ключевыми словами',
        'Внутренняя оптимизация',
        'Базовая аналитика'
      ]
    }
  ],
  features: [
    '12 практических занятий',
    'Базовые навыки',
    'Интерактивные уроки',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};