import React from 'react';
import { Article } from '../../../types';

export const howToChooseCourse: Article = {
  id: 'how-to-choose-course',
  title: 'Как выбрать онлайн-курс: 7 главных критериев',
  description: 'При выборе онлайн-курса важно учитывать несколько ключевых факторов. Разбираем основные критерии, которые помогут сделать правильный выбор.',
  date: '12 ноября 2023',
  category: 'Советы',
  imageUrl: 'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=1200',
  content: React.createElement('div', {}, [
    React.createElement('p', { className: 'mb-4', key: 'intro' },
      'Выбор онлайн-курса может оказаться непростой задачей, особенно учитывая огромное количество предложений на рынке. Мы подготовили список из 7 ключевых критериев, которые помогут вам сделать правильный выбор.'
    ),

    React.createElement('h2', { className: 'text-xl font-bold mt-8 mb-4', key: 'h2-1' }, '1. Репутация школы'),
    React.createElement('p', { className: 'mb-4', key: 'p-1' },
      'Изучите отзывы выпускников, проверьте, как давно школа на рынке, какие у нее показатели трудоустройства. Надежные образовательные платформы открыто делятся этой информацией.'
    ),

    React.createElement('h2', { className: 'text-xl font-bold mt-8 mb-4', key: 'h2-2' }, '2. Программа обучения'),
    React.createElement('p', { className: 'mb-4', key: 'p-2' },
      'Внимательно изучите учебный план. Он должен быть структурированным, логичным и соответствовать вашим целям обучения. Обратите внимание на соотношение теории и практики.'
    ),

    React.createElement('h2', { className: 'text-xl font-bold mt-8 mb-4', key: 'h2-3' }, '3. Преподаватели'),
    React.createElement('p', { className: 'mb-4', key: 'p-3' },
      'Узнайте, кто будет вести занятия. Важно, чтобы преподаватели были практикующими специалистами с реальным опытом в индустрии.'
    ),

    React.createElement('h2', { className: 'text-xl font-bold mt-8 mb-4', key: 'h2-4' }, '4. Формат обучения'),
    React.createElement('p', { className: 'mb-4', key: 'p-4' },
      'Оцените, насколько формат обучения подходит вашему графику и стилю восприятия информации. Некоторым удобнее заниматься по вечерам, другим — в выходные дни.'
    ),

    React.createElement('h2', { className: 'text-xl font-bold mt-8 mb-4', key: 'h2-5' }, '5. Практические задания'),
    React.createElement('p', { className: 'mb-4', key: 'p-5' },
      'Хороший курс всегда включает достаточное количество практики. Убедитесь, что вы сможете применить полученные знания на реальных проектах.'
    ),

    React.createElement('h2', { className: 'text-xl font-bold mt-8 mb-4', key: 'h2-6' }, '6. Поддержка студентов'),
    React.createElement('p', { className: 'mb-4', key: 'p-6' },
      'Узнайте, какая поддержка предоставляется во время обучения. Наличие кураторов, обратной связи от преподавателей и технической поддержки очень важно.'
    ),

    React.createElement('h2', { className: 'text-xl font-bold mt-8 mb-4', key: 'h2-7' }, '7. Цена и условия оплаты'),
    React.createElement('p', { className: 'mb-4', key: 'p-7' },
      'Сравните стоимость похожих курсов на рынке. Слишком низкая цена должна насторожить, а слишком высокая — иметь четкое обоснование. Многие школы предлагают рассрочку или помесячную оплату.'
    )
  ])
};