import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_webdesign',
  title: 'Профессия Веб-дизайнер 3.0',
  description: 'Онлайн курс «Веб-дизайнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на веб-дизайнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 129366,
  oldPrice: 258732,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/SwmrogJjktDMVVvueM30ORV25lCGXQepj925OcYb.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Fweb-spec-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Создание современных веб-интерфейсов',
    'Работа с Figma',
    'Адаптивный дизайн',
    'Анимация и интерактив',
    'Типографика для веба',
    'Работа с заказчиками'
  ],
  program: [
    {
      title: 'Основы веб-дизайна',
      topics: [
        'Принципы веб-дизайна',
        'Композиция',
        'Цветовые решения',
        'Типографика'
      ]
    },
    {
      title: 'Инструменты дизайнера',
      topics: [
        'Figma',
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Прототипирование'
      ]
    },
    {
      title: 'Практика дизайна',
      topics: [
        'Дизайн лендингов',
        'Интернет-магазины',
        'Корпоративные сайты',
        'Порталы'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Анимация интерфейсов',
        'Микровзаимодействия',
        'Дизайн-системы',
        'Подготовка макетов'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в создании портфолио',
    'Стажировка в компании',
    'Сертификат веб-дизайнера'
  ]
};