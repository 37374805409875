import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_devops_basic',
  title: 'DevOps-инженер',
  description: 'Курс «DevOps-инженер» от Нетологии: освоите ключевые инструменты DevOps — Kubernetes, Ansible, Gitlab, Terraform. Практические задания и поддержка экспертов помогут вам успешно освоить профессию.',
  school: 'netology',
  category: 'programming',
  price: 105000,
  oldPrice: 175000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1667372393119-3d4c48d07fc9?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdevops&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2916,
  skills: [
    'DevOps практики',
    'Контейнеризация',
    'CI/CD',
    'Мониторинг',
    'Автоматизация'
  ],
  program: [
    {
      title: 'Основы DevOps',
      topics: [
        'Linux администрирование',
        'Сети и безопасность',
        'Git',
        'Docker'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Kubernetes',
        'Ansible',
        'Terraform',
        'Gitlab CI'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Построение пайплайнов',
        'Мониторинг систем',
        'Управление конфигурациями',
        'Отказоустойчивость'
      ]
    }
  ],
  features: [
    '19 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};