import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_product_pro',
  title: 'Продакт-менеджер: расширенный курс',
  description: 'Продакт-менеджер: расширенный курс от Нетологии — пройдите все этапы работы над продуктом и укрепите навыки с помощью кейсов, интенсивов, групповой работы и общения с экспертами. Освойте основы SQL и customer development.',
  school: 'netology',
  category: 'management',
  price: 181200,
  oldPrice: 302000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fproduct-ultimate&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 5033,
  skills: [
    'Управление продуктом',
    'Customer Development',
    'SQL для продактов',
    'Метрики продукта',
    'Развитие продукта'
  ],
  program: [
    {
      title: 'Стратегия продукта',
      topics: [
        'Продуктовое мышление',
        'Анализ рынка',
        'Целевая аудитория',
        'Продуктовые метрики'
      ]
    },
    {
      title: 'Разработка продукта',
      topics: [
        'Customer Development',
        'Проектирование решений',
        'Прототипирование',
        'Тестирование гипотез'
      ]
    },
    {
      title: 'Управление и развитие',
      topics: [
        'Работа с командой',
        'Приоритизация задач',
        'Анализ данных',
        'Развитие продукта'
      ]
    }
  ],
  features: [
    '18 практических занятий',
    'Работа над продуктом',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};