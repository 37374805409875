import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_django',
  title: 'Python-фреймворк Django',
  description: 'За 3 месяца освоите фреймворк Django для разработки веб-сайтов и приложений на Python',
  school: 'brunoyam',
  category: 'programming',
  price: 29900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fdjango-framework&m=5&erid=LdtCK4uLs',
  skills: [
    'Django Framework',
    'Python для веб',
    'Работа с базами данных',
    'REST API',
    'Развертывание приложений'
  ],
  program: [
    {
      title: 'Основы Django',
      topics: [
        'Архитектура Django',
        'MVT паттерн',
        'Маршрутизация',
        'Шаблоны'
      ]
    },
    {
      title: 'Работа с данными',
      topics: [
        'Models и ORM',
        'Формы',
        'Аутентификация',
        'API Views'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'REST Framework',
        'Тестирование',
        'Кеширование',
        'Деплой'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа над проектами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат разработчика'
  ]
};