import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_project_manager',
  title: 'Менеджер проектов',
  description: 'Научитесь запускать проекты, управлять командой и применять в работе разные инструменты менеджера, чтобы начать карьеру в IT.',
  school: 'yandex',
  category: 'management',
  price: 107000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fproject-manager&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление проектами',
    'Agile методологии',
    'Работа с командой',
    'Управление рисками',
    'Планирование'
  ],
  program: [
    {
      title: 'Основы управления проектами',
      topics: [
        'Жизненный цикл проекта',
        'Методологии управления',
        'Планирование',
        'Оценка рисков'
      ]
    },
    {
      title: 'Работа с командой',
      topics: [
        'Формирование команды',
        'Коммуникации',
        'Мотивация',
        'Конфликты'
      ]
    },
    {
      title: 'Инструменты и процессы',
      topics: [
        'Agile и Scrum',
        'Kanban',
        'Документация',
        'Отчетность'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат менеджера'
  ]
};