import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_marketing_analyst',
  title: 'Маркетинговый аналитик',
  description: 'Освойте маркетинговую аналитику на онлайн-курсе в Нетологии. Научитесь анализировать маркетинговые данные с помощью SQL и Python, проводить A/B-тестирование и создавать отчеты в Power BI. Получите диплом после 14 месяцев интенсивного обучения.',
  school: 'netology',
  category: 'analytics',
  price: 120840,
  oldPrice: 201400,
  currency: 'RUB',
  duration: '14 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdatamarketolog&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3356,
  skills: [
    'Маркетинговая аналитика',
    'SQL и Python',
    'A/B-тестирование',
    'Power BI',
    'Google Analytics',
    'Яндекс.Метрика'
  ],
  program: [
    {
      title: 'Основы аналитики',
      topics: [
        'Метрики маркетинга',
        'Работа с данными',
        'Статистика',
        'Визуализация'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'SQL и Python',
        'Power BI',
        'Google Analytics',
        'Яндекс.Метрика'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'A/B-тестирование',
        'Анализ воронок',
        'Когортный анализ',
        'Отчетность'
      ]
    }
  ],
  features: [
    '26 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};