import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_design_basics',
  title: 'Основы графического дизайна',
  description: 'Онлайн-курс «Основы графического дизайна» — Нетология: Пройдите обучение с нуля и научитесь создавать рекламную графику, макеты сайтов и анимацию. Освойте Figma, Photoshop и Adobe Animate, создайте 6 проектов для портфолио и получите диплом. Начните карьеру в дизайне уже через 4 месяца!',
  school: 'netology',
  category: 'design',
  price: 52290,
  oldPrice: 74700,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdesign-digital&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2905,
  skills: [
    'Графический дизайн',
    'Работа в Figma',
    'Adobe Photoshop',
    'Adobe Animate',
    'Создание макетов'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Цветоведение',
        'Типографика'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Figma',
        'Adobe Photoshop',
        'Adobe Animate',
        'Работа с макетами'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание графики',
        'Веб-дизайн',
        'Анимация',
        'Подготовка портфолио'
      ]
    }
  ],
  features: [
    '35 практических занятий',
    '6 проектов в портфолио',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};