import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_interior',
  title: 'Онлайн-курс Дизайнер интерьера',
  description: 'Научим создавать интерьеры с нуля, через 9 месяцев сможете найти работу в студии или уйти на фриланс. Интенсивный формат — живые онлайн-встречи, видеоматериалы, практические задания',
  school: 'brunoyam',
  category: 'design',
  price: 94900,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fdizajner-intererov&m=5&erid=LdtCK4uLs',
  skills: [
    'Проектирование интерьеров',
    'Работа с планировками',
    'Подбор материалов',
    '3D-визуализация',
    'Работа с заказчиками'
  ],
  program: [
    {
      title: 'Основы дизайна интерьера',
      topics: [
        'Стили в интерьере',
        'Композиция',
        'Цветоведение',
        'Эргономика'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Обмерный план',
        'Планировочные решения',
        'Визуализация',
        'Рабочая документация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Подбор материалов',
        'Авторский надзор',
        'Ведение проекта'
      ]
    }
  ],
  features: [
    '3 проекта в портфолио',
    'Практические задания',
    'Работа с реальными объектами',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};