import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_python_bootcamp',
  title: 'Python-разработчик буткемп',
  description: 'Интенсивный темп учёбы — по 8 часов 5 дней в неделю. Зато получите новую профессию в 2 раза быстрее, чем на обычном курсе.',
  school: 'yandex',
  category: 'programming',
  price: 177000,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fpython-developer-bootcamp&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Python разработка',
    'Django',
    'REST API',
    'Базы данных',
    'Git'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис Python',
        'ООП',
        'Структуры данных',
        'Алгоритмы'
      ]
    },
    {
      title: 'Веб-разработка',
      topics: [
        'Django',
        'REST API',
        'Базы данных',
        'Тестирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Git',
        'CI/CD',
        'Деплой'
      ]
    }
  ],
  features: [
    'Интенсивное обучение',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};