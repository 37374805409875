import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_blend',
  title: 'Курс Blender',
  description: 'Базовый курс по 3D-моделированию в Blender',
  school: 'contented',
  category: 'software',
  price: 36108,
  oldPrice: 60180,
  currency: 'RUR',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fblender-3d&m=5&erid=LdtCKHL5u',
  discountPercent: 40,
  skills: [
    '3D моделирование',
    'Текстурирование',
    'Анимация',
    'Рендеринг',
    'Скульптинг',
    'Создание материалов'
  ],
  program: [
    {
      title: 'Основы Blender',
      topics: [
        'Интерфейс программы',
        'Базовые инструменты',
        'Навигация в 3D пространстве',
        'Горячие клавиши'
      ]
    },
    {
      title: 'Моделирование',
      topics: [
        'Полигональное моделирование',
        'Модификаторы',
        'Скульптинг',
        'Ретопология'
      ]
    },
    {
      title: 'Материалы и текстуры',
      topics: [
        'Создание материалов',
        'UV-развертка',
        'Текстурирование',
        'Nodes Editor'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Настройка освещения',
        'Настройка камеры',
        'Рендер в Cycles',
        'Композитинг'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Обратная связь от преподавателя',
    'Доступ к учебным материалам',
    'Сертификат о прохождении'
  ]
};