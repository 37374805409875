import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_bpmn',
  title: 'Моделирование бизнес-процессов по BPMN 2.0',
  description: 'Курс по BPMN 2.0 от Нетологии: изучите моделирование и оптимизацию бизнес-процессов с использованием нотации BPMN 2.0. Получите практические навыки, работая с реальными кейсами, и сертификат.',
  school: 'netology',
  category: 'management',
  price: 32580,
  oldPrice: 54300,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fbpmn-not&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2715,
  discountPercent: 40,
  skills: [
    'Моделирование процессов',
    'Нотация BPMN 2.0',
    'Оптимизация процессов',
    'Анализ процессов',
    'Документирование'
  ],
  program: [
    {
      title: 'Основы BPMN',
      topics: [
        'Введение в BPMN',
        'Элементы нотации',
        'Правила моделирования',
        'Базовые паттерны'
      ]
    },
    {
      title: 'Продвинутое моделирование',
      topics: [
        'Сложные процессы',
        'Подпроцессы',
        'Обработка ошибок',
        'События и шлюзы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Моделирование процессов',
        'Анализ и оптимизация',
        'Документирование',
        'Работа с инструментами'
      ]
    }
  ],
  features: [
    '8 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};