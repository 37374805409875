import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_excel_accountant',
  title: 'Excel и Google-таблицы для бухгалтеров',
  description: 'Курс для бухгалтеров по Excel и Google-таблицам: от базовых возможностей до макросов, включая ВПР, ГПР, сводные и умные таблицы',
  school: 'brunoyam',
  category: 'analytics',
  price: 14900,
  currency: 'RUB',
  duration: '1 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fexcel-buhgalter&m=5&erid=LdtCK4uLs',
  skills: [
    'Excel для бухгалтера',
    'Google Таблицы',
    'Автоматизация расчетов',
    'Работа с формулами',
    'Анализ данных'
  ],
  program: [
    {
      title: 'Основы работы',
      topics: [
        'Интерфейс программ',
        'Базовые формулы',
        'Форматирование',
        'Работа с данными'
      ]
    },
    {
      title: 'Продвинутые функции',
      topics: [
        'ВПР и ГПР',
        'Сводные таблицы',
        'Макросы',
        'Автоматизация'
      ]
    },
    {
      title: 'Специальные возможности',
      topics: [
        'Финансовые функции',
        'Анализ данных',
        'Защита данных',
        'Совместная работа'
      ]
    }
  ],
  features: [
    '40 практических заданий',
    '120 часов поддержки',
    'Работа с реальными данными',
    'Персональный наставник',
    'Сертификат по окончании'
  ]
};