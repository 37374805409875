import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_practical_neuropsychology',
  title: 'Практическая нейропсихология',
  description: 'Дистанционное обучение по программе Практическая нейропсихология(850 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 56000,
  currency: 'RUR',
  duration: '850 часов',
  imageUrl: 'https://images.unsplash.com/photo-1559757175-5700dde675bc?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskaya_neyropsikhologiya%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Нейропсихологическая диагностика',
    'Коррекционная работа',
    'Реабилитация',
    'Работа с нарушениями',
    'Консультирование'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'Клиническая психология',
        'Нейропсихология',
        'Патопсихология',
        'Психофизиология'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Диагностические методики',
        'Коррекционные программы',
        'Реабилитационные техники',
        'Профилактика нарушений'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Работа с детьми',
        'Работа со взрослыми',
        'Семейное консультирование',
        'Ведение документации'
      ]
    }
  ],
  features: [
    'Углубленная подготовка',
    'Практические навыки',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};