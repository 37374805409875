import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_crisis',
  title: 'Практические навыки работы с кризисными состояниями',
  description: 'Дистанционное обучение по программе Практические навыки работы с кризисными состояниями(37 часов) в ЦАППКК. Освоение методов работы с различными кризисными ситуациями.',
  school: 'appkk',
  category: 'psychology',
  price: 20200,
  currency: 'RUR',
  duration: '37 часов',
  imageUrl: 'https://images.unsplash.com/photo-1590650046871-92c887180603?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_raboty_s_krizisnymi_sostoyaniyami%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Кризисное консультирование',
    'Работа с травмой',
    'Помощь при ПТСР',
    'Работа с утратой',
    'Поддержка при разводе'
  ],
  program: [
    {
      title: 'Основы кризисной помощи',
      topics: [
        'Типы кризисов',
        'Диагностика состояний',
        'Экстренная помощь',
        'Стабилизация'
      ]
    },
    {
      title: 'Работа с травмой',
      topics: [
        'Травматические события',
        'ПТСР',
        'Техники стабилизации',
        'Ресурсные состояния'
      ]
    },
    {
      title: 'Особые ситуации',
      topics: [
        'Работа с утратой',
        'Кризис развода',
        'Суицидальные намерения',
        'Профилактика'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};