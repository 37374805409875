import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_bachelor_media',
  title: 'Бакалавриат Медиакоммуникации',
  description: 'Программа Медиакоммуникации в Нетологии - это совместный проект с РГГУ, предлагающий высшее образование в области медиакоммуникаций. Изучайте теории и практики медиакоммуникаций, развивайте навыки создания и управления медиаконтентом.',
  school: 'netology',
  category: 'marketing',
  price: 1280000,
  oldPrice: 1280000,
  currency: 'RUB',
  duration: '48 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmediakommunikatsii&m=4&erid=LdtCKCxkP',
  monthlyPayment: 1280000,
  skills: [
    'Медиакоммуникации',
    'Создание контента',
    'Управление медиа',
    'Маркетинг',
    'PR и реклама'
  ],
  program: [
    {
      title: 'Основы медиакоммуникаций',
      topics: [
        'Теория коммуникации',
        'Медиапланирование',
        'Контент-стратегии',
        'Digital-маркетинг'
      ]
    },
    {
      title: 'Создание контента',
      topics: [
        'Копирайтинг',
        'Визуальный контент',
        'Видеопроизводство',
        'Мультимедиа'
      ]
    },
    {
      title: 'Управление медиа',
      topics: [
        'Медиаменеджмент',
        'Аналитика',
        'Монетизация',
        'Продвижение'
      ]
    }
  ],
  features: [
    'Государственный диплом',
    'Дистанционное обучение',
    'Практические проекты',
    'Стажировки',
    'Помощь в трудоустройстве'
  ]
};