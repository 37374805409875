import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_agile_pro',
  title: 'Управление по Agile: Scrum, Kanban, Lean',
  description: 'Познакомитесь с методологиями управления проектами и опытом практикующих экспертов Agile. Обучение на курсе онлайн с нуля, с сертификатом.',
  school: 'netology',
  category: 'management',
  price: 42540,
  oldPrice: 70900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fagile-for-all&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2363,
  skills: [
    'Agile методологии',
    'Scrum',
    'Kanban',
    'Lean',
    'Управление проектами'
  ],
  program: [
    {
      title: 'Основы Agile',
      topics: [
        'Agile-манифест',
        'Ценности и принципы',
        'Фреймворки',
        'Роли и церемонии'
      ]
    },
    {
      title: 'Методологии',
      topics: [
        'Scrum',
        'Kanban',
        'Lean',
        'Гибридные подходы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Внедрение Agile',
        'Работа с командой',
        'Метрики',
        'Масштабирование'
      ]
    }
  ],
  features: [
    '37 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Работа в команде',
    'Сертификат о повышении квалификации'
  ]
};