import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_neurocd',
  title: 'Нейросети для решения творческих задач',
  description: 'Практический курс по применению нейросетей в дизайне и творчестве. Научитесь использовать AI-инструменты для оптимизации рабочих процессов и создания уникального контента.',
  school: 'contented',
  category: 'software',
  price: 52785,
  oldPrice: 70380,
  currency: 'RUR',
  duration: '12 месяцев',
  imageUrl: 'https://static.tildacdn.com/tild3930-3462-4665-b061-613135663665/_____1.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fneural-networks-for-designers&m=5&erid=LdtCKHL5u',
  monthlyPayment: 4399,
  discountPercent: 25,
  skills: [
    'Работа с Midjourney',
    'Использование Stable Diffusion',
    'Применение ChatGPT',
    'Генерация изображений',
    'Редактирование AI-контента',
    'Интеграция AI в рабочий процесс',
    'Промпт-инжиниринг'
  ],
  program: [
    {
      title: 'Введение в AI',
      topics: [
        'Основы работы нейросетей',
        'Обзор AI-инструментов',
        'Этика использования AI',
        'Возможности и ограничения'
      ]
    },
    {
      title: 'Генерация изображений',
      topics: [
        'Работа с Midjourney',
        'Настройка Stable Diffusion',
        'Написание промптов',
        'Стилизация изображений'
      ]
    },
    {
      title: 'AI в дизайне',
      topics: [
        'Генерация идей',
        'Создание мудбордов',
        'Редактирование контента',
        'Оптимизация процессов'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Интеграция в проекты',
        'Автоматизация задач',
        'Работа с заказчиками',
        'Презентация результатов'
      ]
    }
  ],
  features: [
    'Доступ к AI-инструментам',
    'Практические задания',
    'Обратная связь от экспертов',
    'Готовые кейсы для портфолио',
    'Сертификат о прохождении курса'
  ]
};