import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_team_management_dev',
  title: 'Управление командой разработки',
  description: 'Курс для тех, кто хочет руководить или уже руководит командой разработки. Научитесь лучшим практикам менеджмента у опытных руководителей крупных IT-компаний.',
  school: 'yandex',
  category: 'management',
  price: 135000,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fteam-management-development&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление разработкой',
    'Agile методологии',
    'Управление командой',
    'Процессы разработки',
    'Работа с заказчиками'
  ],
  program: [
    {
      title: 'Управление командой',
      topics: [
        'Формирование команды',
        'Мотивация разработчиков',
        'Развитие сотрудников',
        'Конфликт-менеджмент'
      ]
    },
    {
      title: 'Процессы разработки',
      topics: [
        'Agile и Scrum',
        'Kanban',
        'Оценка задач',
        'Планирование спринтов'
      ]
    },
    {
      title: 'Стратегическое управление',
      topics: [
        'Продуктовая стратегия',
        'Роадмап разработки',
        'Управление рисками',
        'Метрики эффективности'
      ]
    }
  ],
  features: [
    'Практические кейсы',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Networking',
    'Сертификат руководителя'
  ]
};