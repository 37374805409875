import React from 'react';
import { useCourseStore } from '../store/courseStore';

interface SearchSuggestionsProps {
  query: string;
  onSelect: (suggestion: string) => void;
}

export const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({ query, onSelect }) => {
  const { courses, categories } = useCourseStore();
  
  if (!query.trim()) return null;
  
  const suggestions = new Set<string>();
  const normalizedQuery = query.toLowerCase().trim();
  
  // Добавляем подсказки из названий курсов
  courses.forEach(course => {
    if (course.title.toLowerCase().includes(normalizedQuery)) {
      suggestions.add(course.title);
    }
  });
  
  // Добавляем подсказки из категорий
  categories.forEach(category => {
    if (category.name.toLowerCase().includes(normalizedQuery)) {
      suggestions.add(category.name);
    }
  });
  
  // Добавляем подсказки из навыков
  courses.forEach(course => {
    course.skills?.forEach(skill => {
      if (skill.toLowerCase().includes(normalizedQuery)) {
        suggestions.add(skill);
      }
    });
  });
  
  const suggestionsList = Array.from(suggestions).slice(0, 5);
  
  if (suggestionsList.length === 0) return null;
  
  return (
    <div className="absolute left-0 right-0 top-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden z-50">
      {suggestionsList.map((suggestion, index) => (
        <button
          key={index}
          onClick={() => onSelect(suggestion)}
          className="w-full px-4 py-3 text-left hover:bg-gray-50 transition-colors border-b border-gray-100 last:border-0"
        >
          <span className="text-gray-900">{suggestion}</span>
        </button>
      ))}
    </div>
  );
};