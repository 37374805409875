import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_desgame',
  title: 'Профессия «Геймдизайнер»',
  description: 'Комплексный курс по разработке игр и геймдизайну. Научитесь создавать игровые механики, проектировать уровни и работать с игровыми движками.',
  school: 'contented',
  category: 'game-dev',
  price: 130817,
  oldPrice: 237849,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1511512578047-dfb367046420?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fgame-designer&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3634,
  discountPercent: 45,
  skills: [
    'Геймдизайн',
    'Разработка игровых механик',
    'Проектирование уровней',
    'Работа с Unity',
    'Прототипирование',
    'Балансировка игры'
  ],
  program: [
    {
      title: 'Основы геймдизайна',
      topics: [
        'Теория игр',
        'Игровые механики',
        'Психология игроков',
        'Жанры игр'
      ]
    },
    {
      title: 'Разработка игр',
      topics: [
        'Unity для геймдизайнера',
        'Прототипирование',
        'Левел-дизайн',
        'Баланс игры'
      ]
    },
    {
      title: 'Игровой контент',
      topics: [
        'Нарративный дизайн',
        'Создание персонажей',
        'Квесты и миссии',
        'Игровая экономика'
      ]
    },
    {
      title: 'Управление проектом',
      topics: [
        'Документация',
        'Работа с командой',
        'Тестирование',
        'Релиз игры'
      ]
    }
  ],
  features: [
    'Создание собственной игры',
    'Работа с Unity',
    'Персональный наставник',
    'Стажировка в игровой студии',
    'Сертификат геймдизайнера'
  ]
};