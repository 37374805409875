import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_business_analyst',
  title: 'Бизнес-аналитик',
  description: 'Профессия «Бизнес-аналитик» от Нетологии: научитесь выявлять проблемы бизнеса и находить решения для роста. В программе вы изучите бизнес-анализ, аналитиз данных и системный анализ, что поможет вам развить ключевые навыки для успешной карьеры в области аналитики.',
  school: 'netology',
  category: 'analytics',
  price: 127380,
  oldPrice: 212300,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fbusiness-analytics-online&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3538,
  skills: [
    'Бизнес-анализ',
    'Системный анализ',
    'Управление требованиями',
    'Моделирование процессов',
    'Проектная документация'
  ],
  program: [
    {
      title: 'Основы анализа',
      topics: [
        'Методологии анализа',
        'Сбор требований',
        'Моделирование процессов',
        'Документация'
      ]
    },
    {
      title: 'Инструменты аналитика',
      topics: [
        'BPMN',
        'UML',
        'SQL',
        'Прототипирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Управление изменениями',
        'Оптимизация процессов',
        'Внедрение решений'
      ]
    }
  ],
  features: [
    '22 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};