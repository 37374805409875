import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_psychoanalysis',
  title: 'Аналитическая психология',
  description: 'Дистанционное обучение по программе Аналитическая психология (470 часов) в ЦАППКК. Углубленное изучение психоаналитического подхода и его применения в практике.',
  school: 'appkk',
  category: 'psychology',
  price: 26800,
  currency: 'RUR',
  duration: '470 часов',
  imageUrl: 'https://images.unsplash.com/photo-1590650153855-d9e808231d41?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fanaliticheskaya_psikhologiya_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Психоаналитическая теория',
    'Работа с бессознательным',
    'Анализ сновидений',
    'Интерпретация символов',
    'Глубинная психология'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'История психоанализа',
        'Теория личности',
        'Структура психики',
        'Защитные механизмы'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Анализ сновидений',
        'Работа с символами',
        'Активное воображение',
        'Анализ переноса'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Ведение случая',
        'Работа с сопротивлением',
        'Терапевтические отношения',
        'Завершение терапии'
      ]
    }
  ],
  features: [
    'Углубленное изучение теории',
    'Практические навыки',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};