import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_figma',
  title: 'Обучение основам работы в Figma с нуля',
  description: 'Бесплатное обучение по работе в Figma для начинающих от Нетологии. Черная Пятница в Нетологии - двойные скидки на курсы!',
  school: 'netology',
  category: 'design',
  price: 0,
  oldPrice: 0,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fosnovy-figma&m=5&erid=LdtCKCxkP',
  skills: [
    'Работа в Figma',
    'Прототипирование',
    'Компоненты',
    'Auto-layout',
    'Интерактивный дизайн'
  ],
  program: [
    {
      title: 'Основы Figma',
      topics: [
        'Интерфейс программы',
        'Инструменты',
        'Работа с объектами',
        'Компоненты'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание макетов',
        'Прототипирование',
        'Auto-layout',
        'Экспорт'
      ]
    }
  ],
  features: [
    '8 практических занятий',
    'Базовые навыки',
    'Интерактивные уроки',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};