import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_public_speaking_individual',
  title: 'Индивидуальные занятия по ораторскому мастерству',
  description: 'Бесплатное обучение ораторскому мастерству для начинающих от Нетологии. На онлайн консультации вы узнаете, как круто выступить перед аудиторией. Персональная подготовка с экспертом под вашу задачу.',
  school: 'netology',
  category: 'lifestyle',
  price: 1000,
  oldPrice: 1000,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1475721027785-f74eccf877e2?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fpodgotovka-k-vystupleniyu-prezentacii&m=4&erid=LdtCKCxkP',
  monthlyPayment: 1000,
  skills: [
    'Публичные выступления',
    'Работа с голосом',
    'Управление волнением',
    'Структура выступления',
    'Работа с аудиторией'
  ],
  program: [
    {
      title: 'Подготовка выступления',
      topics: [
        'Анализ аудитории',
        'Структура речи',
        'Работа с контентом',
        'Визуальное сопровождение'
      ]
    },
    {
      title: 'Техника выступления',
      topics: [
        'Работа с голосом',
        'Язык тела',
        'Управление волнением',
        'Ответы на вопросы'
      ]
    }
  ],
  features: [
    'Индивидуальный подход',
    'Работа с экспертом',
    'Практические упражнения',
    'Видеозапись выступления',
    'Обратная связь'
  ]
};