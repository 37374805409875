import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_gestalt_full',
  title: 'Гештальт-терапия в практике психолога',
  description: 'Дистанционное обучение по программе Гештальт-терапия в практике психолога (510 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 31000,
  currency: 'RUR',
  duration: '510 часов',
  imageUrl: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fgeshtalt_terapiya_v_praktike_psikhologa_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Гештальт-подход',
    'Работа с осознаванием',
    'Феноменологический метод',
    'Терапевтические отношения',
    'Работа с сопротивлением'
  ],
  program: [
    {
      title: 'Основы гештальт-терапии',
      topics: [
        'История гештальта',
        'Теория поля',
        'Цикл контакта',
        'Механизмы прерывания'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Осознавание',
        'Эксперимент',
        'Диалог',
        'Работа со сновидениями'
      ]
    },
    {
      title: 'Практика терапии',
      topics: [
        'Ведение сессии',
        'Работа с группой',
        'Супервизия',
        'Личная терапия'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};