import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_aba_full',
  title: 'Прикладной анализ поведения (АВА-терапия)',
  description: 'Дистанционное обучение по программе Прикладной анализ поведения (АВА-терапия): коррекция поведенческих расстройств и развитие адаптивных форм поведения(560 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 25000,
  currency: 'RUR',
  duration: '560 часов',
  imageUrl: 'https://images.unsplash.com/photo-1602052793312-c6c2b8bca2c3?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprikladnoy_analiz_povedeniya_ava_terapiya_korrektsiya_povedencheskikh_rasstroystv_i_razvitie_adaptiv%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Поведенческий анализ',
    'АВА-терапия',
    'Коррекция поведения',
    'Развитие навыков',
    'Работа с РАС'
  ],
  program: [
    {
      title: 'Основы АВА',
      topics: [
        'Теория поведения',
        'Принципы АВА',
        'Методы оценки',
        'Сбор данных'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Разработка программ',
        'Коррекция поведения',
        'Формирование навыков',
        'Генерализация'
      ]
    },
    {
      title: 'Работа с особыми детьми',
      topics: [
        'РАС',
        'СДВГ',
        'Нарушения развития',
        'Работа с родителями'
      ]
    }
  ],
  features: [
    'Практические методики',
    'Разбор случаев',
    'Супервизия',
    ' Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};