import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_coaching_business',
  title: 'Коучинг в обучении и профессиональном развитии персонала',
  description: 'Дистанционное обучение по программе Коучинг в обучении и профессиональном развитии персонала(260 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 17200,
  currency: 'RUR',
  duration: '260 часов',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fkouching_v_obuchenii_i_professionalnom_razvitii_personala%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Бизнес-коучинг',
    'Развитие персонала',
    'Карьерное консультирование',
    'Командный коучинг',
    'Организационное развитие'
  ],
  program: [
    {
      title: 'Основы бизнес-коучинга',
      topics: [
        'Принципы коучинга',
        'Модели коучинга',
        'Инструменты коуча',
        'Этика коучинга'
      ]
    },
    {
      title: 'Работа с персоналом',
      topics: [
        'Развитие сотрудников',
        'Карьерное планирование',
        'Мотивация персонала',
        'Оценка эффективности'
      ]
    },
    {
      title: 'Организационный коучинг',
      topics: [
        'Командный коучинг',
        'Стратегический коучинг',
        'Управление изменениями',
        'Развитие лидерства'
      ]
    }
  ],
  features: [
    'Практические инструменты',
    'Разбор кейсов',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};