import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_hr',
  title: 'Онлайн-курс HR-менеджер',
  description: 'Освоите все HR-процессы: от стратегии и найма сотрудников до HR-аналитики и развития корпоративной культуры. Интенсивный формат — вебинары, видеоматериалы, практические задания',
  school: 'brunoyam',
  category: 'management',
  price: 44900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fhr-manager&m=5&erid=LdtCK4uLs',
  skills: [
    'HR-стратегия',
    'Рекрутинг',
    'Адаптация персонала',
    'HR-аналитика',
    'Развитие персонала'
  ],
  program: [
    {
      title: 'HR-стратегия',
      topics: [
        'HR-бизнес-партнерство',
        'Планирование персонала',
        'KPI в HR',
        'Бюджетирование'
      ]
    },
    {
      title: 'Рекрутмент',
      topics: [
        'Поиск и подбор',
        'Оценка кандидатов',
        'Адаптация',
        'Онбординг'
      ]
    },
    {
      title: 'Развитие персонала',
      topics: [
        'Обучение',
        'Оценка эффективности',
        'Карьерное развитие',
        'Корпоративная культура'
      ]
    }
  ],
  features: [
    'Поддержка куратора 2 года',
    'Практические задания',
    'Работа с реальными кейсами',
    'HR-инструменты',
    'Сертификат специалиста'
  ]
};