import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_qa_automation_python',
  title: 'Автоматизатор тестирования на Python',
  description: 'Освоите pytest, Selenium WebDriver и другие нужные инструменты. Поймёте, как строить процесс автоматизации внутри проекта.',
  school: 'yandex',
  category: 'programming',
  price: 98000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fqa-automation-engineer-python&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Python для тестирования',
    'Автоматизация тестирования',
    'Selenium WebDriver',
    'pytest',
    'CI/CD для тестов'
  ],
  program: [
    {
      title: 'Основы автоматизации',
      topics: [
        'Python основы',
        'pytest фреймворк',
        'Паттерны тестирования',
        'Отчетность'
      ]
    },
    {
      title: 'Web-тестирование',
      topics: [
        'Selenium WebDriver',
        'Page Object',
        'API тестирование',
        'Работа с данными'
      ]
    },
    {
      title: 'CI/CD и инфраструктура',
      topics: [
        'Jenkins',
        'Docker',
        'Отчеты Allure',
        'Мониторинг тестов'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};