import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_ux_research',
  title: 'UX-исследования',
  description: 'Пройдите курс UX-аналитики в Нетологии. Наше онлайн обучение UX-исследованиям включает ключевые методы и техники, которые помогут вам стать профессионалом в области UX-анализа и улучшить пользовательский опыт. Изучите все аспекты проведения эффективных исследований и анализа интерфейсов.',
  school: 'netology',
  category: 'design',
  price: 61985,
  oldPrice: 88550,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fux-analytics&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2582,
  skills: [
    'UX исследования',
    'Качественные методы',
    'Количественные методы',
    'Аналитика данных',
    'Тестирование интерфейсов'
  ],
  program: [
    {
      title: 'Основы UX-исследований',
      topics: [
        'Методология исследований',
        'Планирование',
        'Рекрутинг',
        'Анализ данных'
      ]
    },
    {
      title: 'Методы исследований',
      topics: [
        'Интервью',
        'Юзабилити-тестирование',
        'A/B тестирование',
        'Аналитика'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Проведение исследований',
        'Анализ результатов',
        'Презентация выводов',
        'Рекомендации'
      ]
    }
  ],
  features: [
    '33 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в карьере',
    'Сертификат о повышении квалификации'
  ]
};