import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_graphic_pro',
  title: 'Графический дизайнер: расширенный курс',
  description: 'Расширенный онлайн-курс «Графический дизайнер» от Нетологии: Пройдите обучение графическому дизайну с нуля! Овладейте Adobe Photoshop, Illustrator и InDesign, работайте с реальными клиентами и получите диплом. Удобный формат, стажировка и карьера дизайнера — начните свой путь к успеху!',
  school: 'netology',
  category: 'design',
  price: 184211,
  oldPrice: 307018,
  currency: 'RUB',
  duration: '18 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fgraphic-design-ultimate&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 5116,
  skills: [
    'Adobe Creative Suite',
    'Брендинг',
    'Типографика',
    'Веб-дизайн',
    'Упаковка',
    'Иллюстрация'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Цветоведение',
        'Типографика'
      ]
    },
    {
      title: 'Профессиональные инструменты',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe InDesign',
        'Figma'
      ]
    },
    {
      title: 'Специализация',
      topics: [
        'Брендинг',
        'Веб-дизайн',
        'Упаковка',
        'Иллюстрация'
      ]
    }
  ],
  features: [
    '51 практическое занятие',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};