import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_illustration',
  title: 'Офлайн-курс коммерческой иллюстрации',
  description: 'Интенсивный курс по коммерческой иллюстрации. Получите основы продающего дизайна, научитесь работать в Adobe Illustrator, генерировать идеи и рисовать под заказ',
  school: 'brunoyam',
  category: 'design',
  price: 29900,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fkommercheskaya-illyustraciya&m=5&erid=LdtCK4uLs',
  skills: [
    'Коммерческая иллюстрация',
    'Adobe Illustrator',
    'Композиция',
    'Работа с цветом',
    'Векторная графика'
  ],
  program: [
    {
      title: 'Основы иллюстрации',
      topics: [
        'Композиция',
        'Цвет и свет',
        'Перспектива',
        'Анатомия'
      ]
    },
    {
      title: 'Adobe Illustrator',
      topics: [
        'Инструменты программы',
        'Работа с векторами',
        'Текст и типографика',
        'Эффекты'
      ]
    },
    {
      title: 'Коммерческая работа',
      topics: [
        'Работа с заказчиком',
        'Создание портфолио',
        'Поиск клиентов',
        'Ценообразование'
      ]
    }
  ],
  features: [
    '48 академических часов',
    '12 занятий',
    'Практические проекты',
    'Создание портфолио',
    'Сертификат по окончании'
  ]
};