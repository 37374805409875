import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_media',
  title: 'Профессия Дизайнер интерактивных медиа',
  description: 'Научитесь создавать современные цифровые продукты, интерактивные инсталляции и мультимедийные проекты. Освойте работу с различными медиа-форматами.',
  school: 'contented',
  category: 'design',
  price: 144542,
  oldPrice: 262805,
  currency: 'RUR',
  duration: '24 месяца',
  imageUrl: 'https://static.tildacdn.com/stor3639-3230-4564-b931-333034656464/84900202.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Finteractive-media-design%2F&m=5&erid=LdtCKHL5u',
  monthlyPayment: 6023,
  discountPercent: 45,
  skills: [
    'Создание интерактивных инсталляций',
    'Разработка мультимедийных проектов',
    'Работа с AR/VR технологиями',
    'Проектирование пользовательского опыта',
    'Программирование интерактивных элементов',
    'Работа со звуком и видео'
  ],
  program: [
    {
      title: 'Основы интерактивного дизайна',
      topics: [
        'Теория интерактивности',
        'Принципы взаимодействия',
        'UX/UI для медиа проектов',
        'Сторителлинг'
      ]
    },
    {
      title: 'Технологии и инструменты',
      topics: [
        'Программирование на JavaScript',
        'Работа с WebGL',
        'Создание AR эффектов',
        'Основы Unity'
      ]
    },
    {
      title: 'Медиа-арт',
      topics: [
        'Генеративное искусство',
        'Интерактивные инсталляции',
        'Видеомэппинг',
        'Саунд-дизайн'
      ]
    },
    {
      title: 'Проектная работа',
      topics: [
        'Концепция проекта',
        'Прототипирование',
        'Тестирование',
        'Презентация работ'
      ]
    }
  ],
  features: [
    'Работа с современными технологиями',
    'Создание портфолио проектов',
    'Практика в медиа-лаборатории',
    'Участие в выставках',
    'Сертификат о профессиональной переподготовке'
  ]
};