import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_hotel_management',
  title: 'Управление гостиничным предприятием',
  description: 'Дистанционное обучение по программе 2024 года Управление гостиничным предприятием с присвоением квалификации Управляющий гостиницы.',
  school: 'appkk',
  category: 'management',
  price: 32900,
  currency: 'RUR',
  duration: '520 часов',
  imageUrl: 'https://images.unsplash.com/photo-1566073771259-6a8506099945?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fupravlenie_gostinichnym_predpriyatiem_s_prisvoeniem_kvalifikatsii_upravlyayushchiy_gostinitsy%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Управление отелем',
    'Операционный менеджмент',
    'Маркетинг гостиниц',
    'Управление персоналом',
    'Финансовое планирование'
  ],
  program: [
    {
      title: 'Основы гостиничного бизнеса',
      topics: [
        'Индустрия гостеприимства',
        'Типы гостиниц',
        'Стандарты обслуживания',
        'Правовые аспекты'
      ]
    },
    {
      title: 'Управление операциями',
      topics: [
        'Служба приема',
        'Хозяйственная служба',
        'Служба питания',
        'Безопасность'
      ]
    },
    {
      title: 'Бизнес-процессы',
      topics: [
        'Финансовый менеджмент',
        'Управление персоналом',
        'Маркетинг и продажи',
        'Качество сервиса'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор кейсов',
    'Бизнес-симуляции',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};