import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_project_management_basics',
  title: 'Основы управления проектами: обучение с сопровождением',
  description: 'За 6 недель научитесь запускать проекты, даже если никогда раньше этого не делали.',
  school: 'yandex',
  category: 'management',
  price: 70000,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fproject-management-basics&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление проектами',
    'Планирование',
    'Работа с командой',
    'Управление рисками',
    'Agile методологии'
  ],
  program: [
    {
      title: 'Основы управления',
      topics: [
        'Жизненный цикл проекта',
        'Планирование',
        'Формирование команды',
        'Оценка рисков'
      ]
    },
    {
      title: 'Методологии',
      topics: [
        'Waterfall',
        'Agile',
        'Scrum',
        'Kanban'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Запуск проекта',
        'Контроль исполнения',
        'Работа с командой',
        'Завершение проекта'
      ]
    }
  ],
  features: [
    'Интенсивное обучение',
    'Практические задания',
    'Персональный наставник',
    'Работа над проектом',
    'Сертификат менеджера'
  ]
};