import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_psychosomatic',
  title: 'Психосоматика и телесная психотерапия: теория, практика',
  description: 'Дистанционное обучение по программе Психосоматика и телесная психотерапия: теория, практика(144 часа) в ЦАППКК. Комплексный подход к работе с психосоматическими расстройствами.',
  school: 'appkk',
  category: 'psychology',
  price: 12000,
  currency: 'RUR',
  duration: '144 часа',
  imageUrl: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpsikhosomatika_i_telesnaya_psikhoterapiya_teoriya_praktika%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Диагностика психосоматики',
    'Телесно-ориентированная терапия',
    'Работа с телесными проявлениями',
    'Релаксационные техники',
    'Дыхательные практики'
  ],
  program: [
    {
      title: 'Основы психосоматики',
      topics: [
        'Теории психосоматики',
        'Механизмы развития расстройств',
        'Диагностические подходы',
        'Модели терапии'
      ]
    },
    {
      title: 'Телесная психотерапия',
      topics: [
        'Методы телесной терапии',
        'Работа с напряжением',
        'Телесные метафоры',
        'Техники саморегуляции'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Дыхательные техники',
        'Релаксация',
        'Работа с образами',
        'Телесный диалог'
      ]
    }
  ],
  features: [
    'Практические упражнения',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};