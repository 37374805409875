import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_business_analyst',
  title: 'Бизнес-аналитик',
  description: 'За 6 месяцев научитесь исследовать боли бизнеса с помощью данных и находить решения для роста. Интенсивный формат — видеоматериалы, практические задания и проекты',
  school: 'brunoyam',
  category: 'analytics',
  price: 72900,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fbusiness-analyst&m=5&erid=LdtCK4uLs',
  skills: [
    'Бизнес-анализ',
    'Управление требованиями',
    'Моделирование процессов',
    'Работа с данными',
    'Проектное управление'
  ],
  program: [
    {
      title: 'Основы бизнес-анализа',
      topics: [
        'Роль бизнес-аналитика',
        'Методологии анализа',
        'Сбор требований',
        'Документация'
      ]
    },
    {
      title: 'Инструменты аналитика',
      topics: [
        'BPMN',
        'UML',
        'SQL',
        'Power BI'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Анализ бизнес-процессов',
        'Оптимизация',
        'Управление изменениями',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    '2 проекта в портфолио',
    'Поддержка куратора 1 год',
    'Практические задания',
    'Работа с реальными кейсами',
    'Помощь в трудоустройстве'
  ]
};