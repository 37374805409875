import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_after_effects',
  title: 'Онлайн-курс After Effects',
  description: 'За 2 месяца научитесь всему, что нужно для создания анимированной графики и визуальных эффектов',
  school: 'brunoyam',
  category: 'design',
  price: 19900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1535016120720-40c646be5580?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fafter-effects-basics&m=5&erid=LdtCK4uLs',
  skills: [
    'Анимация',
    'Визуальные эффекты',
    'Композитинг',
    'Motion Design',
    'Работа со звуком'
  ],
  program: [
    {
      title: 'Основы After Effects',
      topics: [
        'Интерфейс программы',
        'Базовая анимация',
        'Работа со слоями',
        'Эффекты'
      ]
    },
    {
      title: 'Анимация и эффекты',
      topics: [
        'Продвинутая анимация',
        'Визуальные эффекты',
        'Маски и маскирование',
        'Трекинг'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание интро',
        'Анимация логотипа',
        'Титры',
        'Экспорт проектов'
      ]
    }
  ],
  features: [
    '30 практических проектов',
    'Работа с реальными заказами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};