import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_accountant',
  title: 'Профессия Бухгалтер',
  description: 'За 3 месяца научитесь работать с денежными потоками, вести отчетность и документацию компании, разберетесь с налогами и сможете систематизировать это с помощью Excel.',
  school: 'brunoyam',
  category: 'management',
  price: 39900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1554224154-26032ffc0d07?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fbuhgaltersky-uchet&m=5&erid=LdtCK4uLs',
  skills: [
    'Бухгалтерский учет',
    'Налоговый учет',
    'Работа с документацией',
    'Финансовая отчетность',
    'Excel для бухгалтера'
  ],
  program: [
    {
      title: 'Основы бухучета',
      topics: [
        'Теория бухучета',
        'План счетов',
        'Первичная документация',
        'Учетная политика'
      ]
    },
    {
      title: 'Налоги и отчетность',
      topics: [
        'Налоговый учет',
        'Виды налогов',
        'Отчетность',
        'Оптимизация налогов'
      ]
    },
    {
      title: 'Практика учета',
      topics: [
        'Учет активов',
        'Расчеты с контрагентами',
        'Зарплата и кадры',
        'Финансовый анализ'
      ]
    }
  ],
  features: [
    '3 проекта',
    'Практические задания',
    'Работа с документами',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};