import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_nodejs',
  title: 'Node.js для backend-разработки',
  description: 'Пройдите курс по Node.js онлайн от Нетологии. Освойте backend-разработку на Node.js, создавайте приложения и становитесь востребованным специалистом. Запишитесь сейчас и начните обучение!',
  school: 'netology',
  category: 'programming',
  price: 30000,
  oldPrice: 50000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1627398242454-45a1465c2479?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fnodejs&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2500,
  discountPercent: 40,
  skills: [
    'Node.js разработка',
    'Express.js',
    'REST API',
    'Базы данных',
    'Тестирование'
  ],
  program: [
    {
      title: 'Основы Node.js',
      topics: [
        'JavaScript для Node.js',
        'Асинхронное программирование',
        'Работа с файлами',
        'Модули'
      ]
    },
    {
      title: 'Web-разработка',
      topics: [
        'Express.js',
        'REST API',
        'Middleware',
        'Аутентификация'
      ]
    },
    {
      title: 'Базы данных',
      topics: [
        'MongoDB',
        'PostgreSQL',
        'ORM',
        'Миграции'
      ]
    }
  ],
  features: [
    '42 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};