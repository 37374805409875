import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_personal_finance',
  title: 'Как устроены финансы компании',
  description: 'Изучите финансовую грамотность с нуля на курсе Нетологии. Обучение онлайн, финансовый анализ, управление финансами компании. Пройдите курс и получите сертификат.',
  school: 'netology',
  category: 'finance',
  price: 1575,
  oldPrice: 2250,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-6726b3ff858f?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ffinancial_basic&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 1575,
  skills: [
    'Финансовый анализ',
    'Управление финансами',
    'Бюджетирование',
    'Финансовая отчетность',
    'Принятие решений'
  ],
  program: [
    {
      title: 'Основы финансов',
      topics: [
        'Финансовые показатели',
        'Отчетность',
        'Анализ данных',
        'Планирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с отчетами',
        'Расчет показателей',
        'Принятие решений',
        'Оптимизация'
      ]
    }
  ],
  features: [
    '17 практических занятий',
    'Реальные кейсы',
    'Практические задания',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};