import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_data_analyst',
  title: 'Очный курс Аналитик данных',
  description: 'Научитесь работать с данными: собирать, обрабатывать и визуализировать. 2 месяца офлайн-занятий в мини-группе и месяц проектной работы вместе с личным наставником',
  school: 'brunoyam',
  category: 'analytics',
  price: 59900,
  currency: 'RUB',
  duration: '150 недель',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-analitika-dannyh%2Fdata-analyst&m=5&erid=LdtCK4uLs',
  skills: [
    'Анализ данных',
    'SQL',
    'Python',
    'Power BI',
    'Excel',
    'Статистика'
  ],
  program: [
    {
      title: 'Основы анализа',
      topics: [
        'Excel для аналитика',
        'Статистика',
        'Визуализация',
        'Работа с данными'
      ]
    },
    {
      title: 'Инструменты аналитика',
      topics: [
        'SQL',
        'Python для анализа',
        'Power BI',
        'Tableau'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Анализ данных',
        'Построение дашбордов',
        'A/B тесты',
        'Проектная работа'
      ]
    }
  ],
  features: [
    '64 академических часа',
    '3 проекта',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};