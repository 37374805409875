import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_neurofitness',
  title: 'Нейрофитнес',
  description: 'Дистанционное обучение по программе Нейрофитнес(108 часов) в ЦАППКК. Современные методики развития когнитивных функций мозга через специальные упражнения.',
  school: 'appkk',
  category: 'psychology',
  price: 11400,
  currency: 'RUR',
  duration: '108 часов',
  imageUrl: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fneyrofitnes%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Развитие когнитивных функций',
    'Нейрогимнастика',
    'Мозговые тренировки',
    'Работа с памятью',
    'Развитие внимания'
  ],
  program: [
    {
      title: 'История и теория нейрофитнеса',
      topics: [
        'Основные понятия',
        'Научные исследования',
        'Принципы работы мозга',
        'Нейропластичность'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Упражнения для мозга',
        'Когнитивные тренировки',
        'Дыхательные практики',
        'Координационные упражнения'
      ]
    },
    {
      title: 'Организация занятий',
      topics: [
        'Структура тренировки',
        'Подбор упражнений',
        'Мониторинг результатов',
        'Составление программ'
      ]
    }
  ],
  features: [
    'Практические упражнения',
    'Методические материалы',
    'Индивидуальный подход',
    'Мониторинг прогресса',
    'Сертификат по окончании'
  ]
};