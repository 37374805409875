import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_informatics_pro',
  title: 'Информатика [ПРО]',
  description: 'Мастер группа [ПРО] - это углубленный формат подготовки с расширенной программой и дополнительными материалами для достижения максимального результата.',
  school: '99ballov',
  category: 'programming',
  price: 4290,
  oldPrice: 4790,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 10,
  skills: [
    'Олимпиадное программирование',
    'Сложные алгоритмы',
    'Структуры данных',
    'Проектная работа',
    'Решение нестандартных задач'
  ],
  program: [
    {
      title: 'Расширенная программа',
      topics: [
        'Олимпиадные задачи',
        'Алгоритмы и структуры данных',
        'Теория графов',
        'Динамическое программирование'
      ]
    },
    {
      title: 'Практикум',
      topics: [
        'Сложные задачи',
        'Проектная работа',
        'Соревновательное программирование',
        'Исследовательские проекты'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Углубленные материалы',
    'Олимпиадная подготовка',
    'Личный куратор',
    'Дополнительные консультации'
  ]
};