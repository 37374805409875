import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_unity_dev',
  title: 'Онлайн-курс Разработка игр на Unity',
  description: 'Освоите игровой движок Unity за 3 месяца: научитесь создавать игры, программировать на C# и создадите первые игры для портфолио',
  school: 'brunoyam',
  category: 'game-dev',
  price: 49900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1580234811497-9df7fd2f357e?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Funity&m=5&erid=LdtCK4uLs',
  skills: [
    'Unity Engine',
    'C# для игр',
    'Геймдизайн',
    'Разработка механик',
    'Оптимизация игр'
  ],
  program: [
    {
      title: 'Основы Unity',
      topics: [
        'Интерфейс Unity',
        'Основы C#',
        'GameObject и компоненты',
        'Физика в играх'
      ]
    },
    {
      title: 'Разработка игр',
      topics: [
        'Игровые механики',
        'Управление персонажем',
        'Искусственный интеллект',
        'Пользовательский интерфейс'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Оптимизация',
        'Работа со звуком',
        'Эффекты частиц',
        'Публикация игры'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Создание портфолио',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат разработчика'
  ]
};