import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_data_analyst',
  title: 'Аналитик данных',
  description: 'Курс «Аналитик данных» от Нетологии: научитесь анализировать данные с помощью SQL, Python и Power BI, а также разберётесь в основах статистики. Программа включает практическую работу и экспертов, которые помогут применить знания для реальных задач.',
  school: 'netology',
  category: 'analytics',
  price: 96600,
  oldPrice: 161000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms <boltAction type="file" filePath="src/data/schools/netology/courses/data-analyst.ts">%2Fdata-analyst&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4025,
  skills: [
    'SQL и базы данных',
    'Python для анализа',
    'Power BI',
    'Статистика',
    'Визуализация данных',
    'Аналитика'
  ],
  program: [
    {
      title: 'Основы анализа',
      topics: [
        'Базы данных и SQL',
        'Python для анализа',
        'Статистика',
        'Визуализация'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Power BI',
        'Pandas и NumPy',
        'Jupyter Notebook',
        'Git'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Анализ данных',
        'Построение дашбордов',
        'A/B тестирование',
        'Реальные проекты'
      ]
    }
  ],
  features: [
    '23 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};