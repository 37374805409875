import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_python_pro',
  title: 'Профессия Python-разработчик PRO',
  description: 'Углубленный курс по Python-разработке. Изучите все аспекты разработки на Python и станьте востребованным специалистом.',
  school: 'geekbrains',
  category: 'programming',
  price: 129374,
  oldPrice: 258748,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/WheFXU2ro0jy7Kg49cQVGTFVb6HFmGpBWuAdfSP0.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fanalyst-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Python продвинутый уровень',
    'Django и FastAPI',
    'Микросервисы',
    'Базы данных',
    'DevOps практики',
    'Тестирование',
    'Асинхронное программирование'
  ],
  program: [
    {
      title: 'Python углубленно',
      topics: [
        'Метапрограммирование',
        'Многопоточность',
        'Асинхронность',
        'Оптимизация'
      ]
    },
    {
      title: 'Web-разработка',
      topics: [
        'Django',
        'FastAPI',
        'GraphQL',
        'WebSockets'
      ]
    },
    {
      title: 'Базы данных',
      topics: [
        'PostgreSQL',
        'MongoDB',
        'Redis',
        'ORM'
      ]
    },
    {
      title: 'DevOps практики',
      topics: [
        'Docker',
        'Kubernetes',
        'CI/CD',
        'Мониторинг'
      ]
    }
  ],
  features: [
    'Углубленное изучение Python',
    'Работа над сложными проектами',
    'Код-ревью от экспертов',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};