import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_python_basic',
  title: 'Python-разработчик с нуля',
  description: 'Профессия «Python-разработчик с нуля» от Нетологии: за 6 месяцев вы освоите Python, научитесь работать с GIT, базами данных и SQL. В ходе курса также изучите фреймворк Django, чтобы создавать полноценные веб-приложения и уверенно начать карьеру в программировании.',
  school: 'netology',
  category: 'programming',
  price: 87000,
  oldPrice: 145000,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fpython-basic&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3625,
  skills: [
    'Python',
    'Django',
    'Git',
    'SQL',
    'Базы данных',
    'Веб-разработка'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис Python',
        'Типы данных',
        'Функции',
        'ООП'
      ]
    },
    {
      title: 'Веб-разработка',
      topics: [
        'Django',
        'HTML и CSS',
        'Базы данных',
        'REST API'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Git',
        'Тестирование',
        'Деплой',
        'Реальные проекты'
      ]
    }
  ],
  features: [
    '15 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};