import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_systems_analyst_bootcamp',
  title: 'Системный аналитик буткемп',
  description: 'Интенсивный курс для тех, кто хочет стать системным аналитиком в 2 раза быстрее, чем на базовом курсе, и готов заниматься по 30 часов в неделю.',
  school: 'yandex',
  category: 'analytics',
  price: 141000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fsystems-analyst-bootcamp&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Системный анализ',
    'Проектирование систем',
    'Работа с требованиями',
    'UML моделирование',
    'Бизнес-анализ'
  ],
  program: [
    {
      title: 'Основы системного анализа',
      topics: [
        'Методологии анализа',
        'Сбор требований',
        'Моделирование процессов',
        'Документация'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'UML диаграммы',
        'Архитектура систем',
        'Прототипирование',
        'Валидация требований'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Взаимодействие с разработкой',
        'Управление изменениями',
        'Внедрение систем'
      ]
    }
  ],
  features: [
    'Интенсивное обучение',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат аналитика'
  ]
};