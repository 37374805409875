import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_physics_oge_curator',
  title: 'Годовой курс по физике ОГЭ «Азазат 2024/2025» с куратором',
  description: 'Годовой курс «Азазат» с персональным куратором - это подготовка к ОГЭ по физике с нуля на отлично. Индивидуальный подход и постоянная поддержка.',
  school: '100points',
  category: 'physics',
  price: 31390,
  oldPrice: 35890,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1636466497217-26a8cbeaf0aa?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3490,
  discountPercent: 15,
  skills: [
    'Механика',
    'Электричество',
    'Оптика',
    'Тепловые явления',
    'Решение задач',
    'Лабораторные работы'
  ],
  program: [
    {
      title: 'Механические явления',
      topics: [
        'Кинематика',
        'Динамика',
        'Законы сохранения',
        'Механические колебания'
      ]
    },
    {
      title: 'Тепловые явления',
      topics: [
        'Строение вещества',
        'Тепловые процессы',
        'Агрегатные состояния',
        'Тепловые двигатели'
      ]
    },
    {
      title: 'Электромагнитные явления',
      topics: [
        'Электрический ток',
        'Магнитное поле',
        'Электромагнитная индукция',
        'Оптика'
      ]
    },
    {
      title: 'Квантовые явления',
      topics: [
        'Строение атома',
        'Ядерные реакции',
        'Радиоактивность',
        'Элементарные частицы'
      ]
    }
  ],
  features: [
    '2 вебинара в неделю',
    'Персональный куратор',
    'Индивидуальные консультации',
    'Проверка работ',
    'Разбор задач',
    'Система мотивации',
    'Доступ к записям уроков'
  ]
};