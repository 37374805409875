import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_time_management',
  title: 'Тайм-менеджмент',
  description: 'За 1 месяц научитесь управлять своим временем, выполнять рабочие задачи вовремя и успевать жить. Подойдет для личных целей и работы',
  school: 'brunoyam',
  category: 'management',
  price: 12900,
  currency: 'RUB',
  duration: '1 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Ftime-management&m=5&erid=LdtCK4uLs',
  skills: [
    'Управление временем',
    'Планирование',
    'Целеполагание',
    'Продуктивность',
    'Делегирование'
  ],
  program: [
    {
      title: 'Основы тайм-менеджмента',
      topics: [
        'Анализ времени',
        'Постановка целей',
        'Приоритизация',
        'Планирование'
      ]
    },
    {
      title: 'Инструменты и методы',
      topics: [
        'Матрица Эйзенхауэра',
        'Метод Помодоро',
        'Канбан',
        'GTD'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Борьба с прокрастинацией',
        'Делегирование',
        'Автоматизация',
        'Личная эффективность'
      ]
    }
  ],
  features: [
    '15 практических заданий',
    'Поддержка куратора 1 год',
    'Доступ к материалам',
    'Практические инструменты',
    'Сертификат по окончании'
  ]
};