import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_sexology',
  title: 'Сексология в психологическом консультировании',
  description: 'Дистанционное обучение по программе Сексология в психологическом консультировании(144 часа) в ЦАППКК. Комплексный подход к работе с сексологическими проблемами в психологическом консультировании.',
  school: 'appkk',
  category: 'psychology',
  price: 10200,
  currency: 'RUR',
  duration: '144 часа',
  imageUrl: 'https://images.unsplash.com/photo-1516534775068-ba3e7458af70?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fseksologiya_v_psikhologicheskom_konsultirovanii_pk%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Сексологическое консультирование',
    'Диагностика проблем',
    'Работа с парами',
    'Сексуальное образование',
    'Терапевтические техники'
  ],
  program: [
    {
      title: 'Основы сексологии',
      topics: [
        'Анатомия и физиология',
        'Психосексуальное развитие',
        'Сексуальная норма и патология',
        'Гендерная идентичность'
      ]
    },
    {
      title: 'Консультирование',
      topics: [
        'Диагностика проблем',
        'Работа с дисфункциями',
        'Семейная терапия',
        'Сексуальное образование'
      ]
    },
    {
      title: 'Практические аспекты',
      topics: [
        'Терапевтические техники',
        'Работа с травмой',
        'Профилактика проблем',
        'Этические вопросы'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};