import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_devops_extended',
  title: 'DevOps для эксплуатации и разработки: расширенный',
  description: 'Расширенная версия программы «DevOps для эксплуатации и разработки»',
  school: 'yandex',
  category: 'programming',
  price: 170000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1667372393119-3d4c48d07fc9?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fdevops%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'DevOps практики',
    'Контейнеризация',
    'CI/CD',
    'Мониторинг',
    'Автоматизация'
  ],
  program: [
    {
      title: 'Основы DevOps',
      topics: [
        'Linux администрирование',
        'Git',
        'Docker',
        'Kubernetes'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'CI/CD пайплайны',
        'Jenkins',
        'Ansible',
        'Terraform'
      ]
    },
    {
      title: 'Мониторинг и безопасность',
      topics: [
        'Prometheus',
        'Grafana',
        'Логирование',
        'Безопасность'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат DevOps инженера'
  ]
};