import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_it_project_mba',
  title: 'Mini-MBA: Управление проектами и командами в IT',
  description: 'Обучение профессии проджект-менеджера на совместном курсе Нетологии и ИБДА РАНХиГС с дипломом, дистанционно.',
  school: 'netology',
  category: 'management',
  price: 247200,
  oldPrice: 412000,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fprofessiya-menedzher-proektov-v-it-ranhigs&m=4&erid=LdtCKCxkP',
  monthlyPayment: 6866,
  discountPercent: 40,
  skills: [
    'Управление IT-проектами',
    'Agile методологии',
    'Управление командой',
    'Стратегическое планирование',
    'Управление рисками'
  ],
  program: [
    {
      title: 'Управление проектами',
      topics: [
        'Методологии управления',
        'Планирование проектов',
        'Управление рисками',
        'Agile-трансформация'
      ]
    },
    {
      title: 'Управление командой',
      topics: [
        'Формирование команды',
        'Мотивация',
        'Развитие сотрудников',
        'Конфликт-менеджмент'
      ]
    },
    {
      title: 'Стратегическое управление',
      topics: [
        'Стратегия развития',
        'Управление изменениями',
        'Финансовое планирование',
        'Оценка эффективности'
      ]
    }
  ],
  features: [
    'Диплом РАНХиГС',
    'Практические проекты',
    'Персональный наставник',
    'Networking',
    'Помощь в карьерном росте'
  ]
};