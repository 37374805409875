import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_clinic_admin',
  title: 'Администратор клиники: навыки для генерации выручки',
  description: 'Пройдите курсы для администраторов клиник и стоматологий от Нетологии. Освойте управление, работу с клиентами и предотвратите выгорание. Онлайн формат, сертификат, повышение выручки!',
  school: 'netology',
  category: 'medicine',
  price: 29900,
  oldPrice: 29900,
  currency: 'RUB',
  duration: '7 недель',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Feffektivnyi-administrstor&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2491,
  skills: [
    'Управление клиникой',
    'Работа с клиентами',
    'Администрирование',
    'Предотвращение выгорания',
    'Повышение выручки'
  ],
  program: [
    {
      title: 'Управление клиникой',
      topics: [
        'Организация работы',
        'Документооборот',
        'Стандарты сервиса',
        'Управление записью'
      ]
    },
    {
      title: 'Работа с клиентами',
      topics: [
        'Коммуникация',
        'Работа с жалобами',
        'Продажи услуг',
        'Сервис'
      ]
    },
    {
      title: 'Развитие навыков',
      topics: [
        'Стрессоустойчивость',
        'Конфликтология',
        'Тайм-менеджмент',
        'Профилактика выгорания'
      ]
    }
  ],
  features: [
    'Практические занятия',
    'Работа с реальными кейсами',
    'Консультации экспертов',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};