import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_medical_communication',
  title: 'Эффективная коммуникация в медицине: как заслужить доверие пациентов',
  description: 'Курс по эффективной медицинской коммуникации поможет врачам вырасти в востребованного специалиста, которого будут ценить и пациенты, и работодатели. После обучения студенты смогут общаться даже со сложными пациентами без репутационных рисков, а также монетизировать свои навыки коммуникации',
  school: 'netology',
  category: 'medicine',
  price: 23000,
  oldPrice: 23000,
  currency: 'RUB',
  duration: '7 недель',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmedicine-communication&m=4&erid=LdtCKCxkP',
  monthlyPayment: 1916,
  skills: [
    'Медицинская коммуникация',
    'Работа с пациентами',
    'Управление конфликтами',
    'Профессиональная этика',
    'Эмпатия'
  ],
  program: [
    {
      title: 'Основы коммуникации',
      topics: [
        'Модели общения',
        'Эмпатия',
        'Активное слушание',
        'Работа с возражениями'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Сложные пациенты',
        'Конфликтные ситуации',
        'Профилактика выгорания',
        'Репутационный менеджмент'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Разбор кейсов',
    'Работа с экспертами',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};