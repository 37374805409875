import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_ai_integration',
  title: 'Специалист по внедрению ИИ',
  description: 'Создавай AI-продукты и зарабатывай на их внедрении. Научим с нуля использовать AI-инструменты для повышения эффективности компаний.',
  school: 'geekbrains',
  category: 'programming',
  price: 87730,
  oldPrice: 175460,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/wdkpgFp8ANE4Rlhd28oejaJDNv21lf7jq65ieDcC.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fs%2Fai-integration-specialist&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Внедрение AI-решений',
    'Оценка эффективности AI',
    'Управление AI-проектами',
    'Работа с AI-инструментами',
    'Оптимизация бизнес-процессов',
    'Интеграция AI-систем'
  ],
  program: [
    {
      title: 'Основы AI',
      topics: [
        'Введение в AI',
        'Типы AI-систем',
        'Возможности и ограничения',
        'Оценка применимости'
      ]
    },
    {
      title: 'AI-инструменты',
      topics: [
        'Обзор решений',
        'Выбор инструментов',
        'Настройка систем',
        'Интеграция'
      ]
    },
    {
      title: 'Внедрение',
      topics: [
        'Анализ процессов',
        'Планирование внедрения',
        'Управление изменениями',
        'Обучение персонала'
      ]
    },
    {
      title: 'Оценка и развитие',
      topics: [
        'Метрики эффективности',
        'Оптимизация решений',
        'Масштабирование',
        'Поддержка систем'
      ]
    }
  ],
  features: [
    'Практические кейсы',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};