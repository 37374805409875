import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_java',
  title: 'Java-разработчик с нуля',
  description: 'Профессия «Java-разработчик с нуля» от Нетологии: изучите Spring, SQL, NoSQL и микросервисы. Наполните портфолио реальными проектами и начните карьеру уже через 12 месяцев.',
  school: 'netology',
  category: 'programming',
  price: 138000,
  oldPrice: 230000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fjava-developer&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3833,
  skills: [
    'Java Core',
    'Spring Framework',
    'SQL и NoSQL',
    'Микросервисы',
    'Git',
    'Maven/Gradle'
  ],
  program: [
    {
      title: 'Основы Java',
      topics: [
        'Java Core',
        'ООП',
        'Коллекции',
        'Многопоточность'
      ]
    },
    {
      title: 'Spring Framework',
      topics: [
        'Spring Core',
        'Spring MVC',
        'Spring Boot',
        'Spring Security'
      ]
    },
    {
      title: 'Базы данных и микросервисы',
      topics: [
        'SQL и NoSQL',
        'Микросервисы',
        'REST API',
        'Docker'
      ]
    }
  ],
  features: [
    '14 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};