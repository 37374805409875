import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_indes',
  title: 'Курс InDesign',
  description: 'Базовый курс по Adobe InDesign',
  school: 'contented',
  category: 'software',
  price: 21420,
  oldPrice: 30600,
  currency: 'RUR',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Findesign&m=5&erid=LdtCKHL5u',
  discountPercent: 30,
  skills: [
    'Верстка публикаций',
    'Работа с текстом',
    'Работа с изображениями',
    'Создание макетов',
    'Подготовка к печати',
    'Интерактивные публикации'
  ],
  program: [
    {
      title: 'Основы InDesign',
      topics: [
        'Интерфейс программы',
        'Настройка документа',
        'Работа с инструментами',
        'Мастер-страницы'
      ]
    },
    {
      title: 'Работа с текстом',
      topics: [
        'Форматирование текста',
        'Стили абзацев и символов',
        'Таблицы',
        'Буквицы и спецсимволы'
      ]
    },
    {
      title: 'Работа с графикой',
      topics: [
        'Импорт изображений',
        'Обтекание текстом',
        'Эффекты',
        'Прозрачность'
      ]
    },
    {
      title: 'Подготовка к публикации',
      topics: [
        'Проверка документа',
        'Сборка пакета',
        'Экспорт в PDF',
        'Печать'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Обратная связь от преподавателя',
    'Доступ к учебным материалам',
    'Сертификат о прохождении'
  ]
};