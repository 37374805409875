import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_landscape',
  title: 'Ландшафтный дизайнер',
  description: 'Онлайн-курс «Ландшафтный дизайнер» — Нетология: Пройдите обучение с нуля и научитесь проектировать ландшафтные пространства. Освойте Photoshop, SketchUp, InDesign, ArchiCAD и создайте проекты для портфолио. Получите диплом и начните карьеру в ландшафтном дизайне!',
  school: 'netology',
  category: 'design',
  price: 90422,
  oldPrice: 150702,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1558904541-efa843a96f01?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Flandshaftnyj-dizajner&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3767,
  skills: [
    'Ландшафтное проектирование',
    'Работа с растениями',
    '3D-визуализация',
    'Генеральный план',
    'Дендрология'
  ],
  program: [
    {
      title: 'Основы ландшафтного дизайна',
      topics: [
        'Теория дизайна',
        'Стили в ландшафте',
        'Композиция',
        'Проектирование'
      ]
    },
    {
      title: 'Работа с растениями',
      topics: [
        'Дендрология',
        'Подбор растений',
        'Озеленение',
        'Уход за садом'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        '3D-моделирование',
        'Чертежи',
        'Визуализация проектов',
        'Документация'
      ]
    }
  ],
  features: [
    '23 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};