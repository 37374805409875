import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_screenwriter',
  title: 'Профессия Сценарист',
  description: 'Онлайн курс «Сценарист» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на сценариста с нуля онлайн.',
  school: 'geekbrains',
  category: 'multimedia',
  price: 139361,
  oldPrice: 278722,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/vrHJFS5xFUkaG9rMk5DXn4isNS7XV7wdkaKL7GqJ.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmultimedia%2Fscreenwriter-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Написание сценариев',
    'Драматургия',
    'Создание персонажей',
    'Структура сюжета',
    'Диалоги',
    'Работа с форматами'
  ],
  program: [
    {
      title: 'Основы сценарного мастерства',
      topics: [
        'Теория драматургии',
        'Структура сценария',
        'Конфликт и действие',
        'Работа с идеями'
      ]
    },
    {
      title: 'Персонажи и диалоги',
      topics: [
        'Создание персонажей',
        'Написание диалогов',
        'Характеры и мотивация',
        'Подтекст'
      ]
    },
    {
      title: 'Форматы и жанры',
      topics: [
        'Короткий метр',
        'Полный метр',
        'Сериалы',
        'Веб-сериалы'
      ]
    },
    {
      title: 'Продвижение и работа',
      topics: [
        'Питчинг проектов',
        'Работа с продюсерами',
        'Авторское право',
        'Сценарный портфель'
      ]
    }
  ],
  features: [
    'Написание собственных сценариев',
    'Разбор работ с экспертами',
    'Персональный наставник',
    'Помощь в продвижении',
    'Сертификат сценариста'
  ]
};