import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_infosec',
  title: 'Специалист по информационной безопасности: расширенный курс',
  description: 'Курс Специалист по информационной безопасности: DevSecOps, Pentest и Forensics. Черная Пятница в Нетологии - двойные скидки на курсы!',
  school: 'netology',
  category: 'programming',
  price: 135000,
  oldPrice: 225000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1614064641938-3bbee52942c7?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Finformationsecurity&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3750,
  skills: [
    'DevSecOps',
    'Pentest',
    'Forensics',
    'Защита информации',
    'Сетевая безопасность',
    'Криптография'
  ],
  program: [
    {
      title: 'Основы безопасности',
      topics: [
        'Базовые принципы',
        'Криптография',
        'Сетевая безопасность',
        'Защита данных'
      ]
    },
    {
      title: 'DevSecOps',
      topics: [
        'CI/CD безопасность',
        'Контейнеризация',
        'Мониторинг',
        'Автоматизация'
      ]
    },
    {
      title: 'Pentest и Forensics',
      topics: [
        'Тестирование на проникновение',
        'Анализ уязвимостей',
        'Расследование инцидентов',
        'Сбор доказательств'
      ]
    }
  ],
  features: [
    'Практические лабораторные работы',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};