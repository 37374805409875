import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_business_process',
  title: 'Менеджер бизнес-процессов',
  description: 'Курс для специалистов по бизнес-процессам в Нетологии. Освойте моделирование, оптимизацию и автоматизацию бизнес-процессов для повышения эффективности компании. Изучите методики анализа, улучшения и внедрения изменений. Обучение длится 5,5 месяцев с дипломом о профессиональной переподготовке.',
  school: 'netology',
  category: 'management',
  price: 126060,
  oldPrice: 210100,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fbusiness_process_manager&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3501,
  skills: [
    'Анализ бизнес-процессов',
    'Моделирование процессов',
    'Оптимизация',
    'Автоматизация',
    'Управление изменениями'
  ],
  program: [
    {
      title: 'Основы управления процессами',
      topics: [
        'Методологии анализа',
        'Нотации моделирования',
        'Документирование',
        'Метрики процессов'
      ]
    },
    {
      title: 'Оптимизация и автоматизация',
      topics: [
        'Методы оптимизации',
        'Инструменты автоматизации',
        'Внедрение изменений',
        'Контроль эффективности'
      ]
    },
    {
      title: 'Управление проектами',
      topics: [
        'Планирование изменений',
        'Управление командой',
        'Работа с сопротивлением',
        'Оценка результатов'
      ]
    }
  ],
  features: [
    '65 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в карьере',
    'Диплом о профессиональной переподготовке'
  ]
};