import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_video_editing',
  title: 'Режиссёр монтажа',
  description: 'Онлайн-курс «Режиссёр монтажа» от Нетологии: освоите профессиональный видеомонтаж сегодня! Черная Пятница в Нетологии - двойные скидки на курсы!',
  school: 'netology',
  category: 'multimedia',
  price: 116421,
  oldPrice: 194035,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1536240478700-b869070f9d7c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fvideo-editing&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3233,
  skills: [
    'Видеомонтаж',
    'Работа со звуком',
    'Цветокоррекция',
    'Визуальные эффекты',
    'Режиссура монтажа',
    'Работа с форматами'
  ],
  program: [
    {
      title: 'Основы монтажа',
      topics: [
        'Теория монтажа',
        'Монтажные программы',
        'Форматы видео',
        'Композиция кадра'
      ]
    },
    {
      title: 'Технические аспекты',
      topics: [
        'Работа со звуком',
        'Цветокоррекция',
        'Визуальные эффекты',
        'Титры и графика'
      ]
    },
    {
      title: 'Режиссура монтажа',
      topics: [
        'Драматургия',
        'Темпоритм',
        'Стилистика',
        'Работа с материалом'
      ]
    }
  ],
  features: [
    '32 практических занятия',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};