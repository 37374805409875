import { Course } from '../../../../types';

// Import courses
import { course as dataAnalyst } from './data-analyst';
import { course as backendDeveloper } from './backend-developer';
import { course as interfaceDesigner } from './interface-designer';
import { course as dataScientist } from './data-scientist';
import { course as qaEngineer } from './qa-engineer';
import { course as cppDeveloper } from './cpp-developer';
import { course as qaAutomationJava } from './qa-automation-java';
import { course as algorithms } from './algorithms';
import { course as middleFrontend } from './middle-frontend';
import { course as middlePython } from './middle-python';
import { course as criticalThinking } from './critical-thinking';
import { course as projectManager } from './project-manager';
import { course as reactDeveloper } from './react-developer';
import { course as dataScientistPlus } from './data-scientist-plus';
import { course as dataAnalystPlus } from './data-analyst-plus';
import { course as hrManager } from './hr-manager';
import { course as itDesigner } from './it-designer';
import { course as webDesigner } from './web-designer';
import { course as qaEngineerPlus } from './qa-engineer-plus';
import { course as webPentest } from './web-pentest';
import { course as goAdvanced } from './go-advanced';
import { course as hrAnalytics } from './hr-analytics';
import { course as itRecruiter } from './it-recruiter';
import { course as smmTelegram } from './smm-telegram';
import { course as trafficManager } from './traffic-manager';
import { course as unitEconomics } from './unit-economics';
import { course as softwareArchitect } from './software-architect';
import { course as sqlForDevelopers } from './sql-for-developers';
import { course as systemsAnalyst } from './systems-analyst';
import { course as teamManagement } from './team-management';
import { course as frontendDeveloper } from './frontend-developer';
import { course as mathForDataAnalysis } from './math-for-data-analysis';
import { course as motionDesigner } from './motion-designer';
import { course as operationalManagement } from './operational-management';
import { course as productManager } from './product-manager';
import { course as qaAutomationPython } from './qa-automation-python';
import { course as qaEngineerFullStack } from './qa-engineer-full-stack';
import { course as softSkillsProjectManager } from './soft-skills-project-manager';
import { course as teamManagementDevelopment } from './team-management-development';
import { course as pythonDeveloperPlus } from './python-developer-plus';
import { course as productMarketingManager } from './product-marketing-manager';
import { course as projectManagementBasics } from './project-management-basics';
import { course as pythonDeveloperBootcamp } from './python-developer-bootcamp';
import { course as uxResearchForDesigners } from './ux-research-for-designers';
import { course as mathForDaDataSciencePlus } from './math-for-da-ds-plus';
import { course as projectManagementBasicsSelfpaced } from './project-management-basics-selfpaced';
import { course as sysAdmin } from './sys-admin';
import { course as threeDArtist } from './3d-artist';
import { course as mobileAppDesignSelfPaced } from './mobile-app-design-self-paced';
import { course as mobileAppDesignPro } from './mobile-app-design-pro';
import { course as webDesignerMentor } from './web-designer-mentor';
import { course as aiToolsForDesigners } from './ai-tools-for-designers';
import { course as userExperienceResearch } from './user-experience-research';
import { course as smmSpecialistPro } from './smm-specialist-pro';
import { course as graphicDesignerPlus } from './graphic-designer-plus';
import { course as internetMarketingPro } from './internet-marketing-pro';
import { course as interfaceDesignerPlus } from './interface-designer-plus';
import { course as marketplaceManagerPro } from './marketplace-manager-pro';
import { course as motionDesignerMentor } from './motion-designer-mentor';
import { course as productManagerStart } from './product-manager-start';
import { course as qaAutomationJavaExtended } from './qa-automation-java-extended';
import { course as systemsAnalystBootcamp } from './systems-analyst-bootcamp';
import { course as teamManagementPro } from './team-management-pro';
import { course as businessAnalystPro } from './business-analyst-pro';
import { course as dataEngineerStart } from './data-engineer-start';
import { course as dataVisualization } from './data-visualization';
import { course as devopsExtended } from './devops-extended';
import { course as financialManagement } from './financial-management';
import { course as fullstackDeveloper } from './fullstack-developer';
import { course as fullstackPersonal } from './fullstack-personal';
import { course as goDeveloperBasic } from './go-developer-basic';
import { course as headOfSales } from './head-of-sales';
import { course as iosDeveloper } from './ios-developer';
import { course as iosDeveloperPlus } from './ios-developer-plus';
import { course as javaDeveloper } from './java-developer';
import { course as javaDeveloperPlus } from './java-developer-plus';
import { course as marketplaceManager } from './marketplace-manager';
import { course as productApproach } from './product-approach';
import { course as productApproachForDesigners } from './product-approach-for-designers';

// Export all courses
export const yandexCourses: Course[] = [
  dataAnalyst,
  backendDeveloper,
  interfaceDesigner,
  dataScientist,
  qaEngineer,
  cppDeveloper,
  qaAutomationJava,
  algorithms,
  middleFrontend,
  middlePython,
  criticalThinking,
  projectManager,
  reactDeveloper,
  dataScientistPlus,
  dataAnalystPlus,
  hrManager,
  itDesigner,
  webDesigner,
  qaEngineerPlus,
  webPentest,
  goAdvanced,
  hrAnalytics,
  itRecruiter,
  smmTelegram,
  trafficManager,
  unitEconomics,
  softwareArchitect,
  sqlForDevelopers,
  systemsAnalyst,
  teamManagement,
  frontendDeveloper,
  mathForDataAnalysis,
  motionDesigner,
  operationalManagement,
  productManager,
  qaAutomationPython,
  qaEngineerFullStack,
  softSkillsProjectManager,
  teamManagementDevelopment,
  pythonDeveloperPlus,
  productMarketingManager,
  projectManagementBasics,
  pythonDeveloperBootcamp,
  uxResearchForDesigners,
  mathForDaDataSciencePlus,
  projectManagementBasicsSelfpaced,
  sysAdmin,
  threeDArtist,
  mobileAppDesignSelfPaced,
  mobileAppDesignPro,
  webDesignerMentor,
  aiToolsForDesigners,
  userExperienceResearch,
  smmSpecialistPro,
  graphicDesignerPlus,
  internetMarketingPro,
  interfaceDesignerPlus,
  marketplaceManagerPro,
  motionDesignerMentor,
  productManagerStart,
  qaAutomationJavaExtended,
  systemsAnalystBootcamp,
  teamManagementPro,
  businessAnalystPro,
  dataEngineerStart,
  dataVisualization,
  devopsExtended,
  financialManagement,
  fullstackDeveloper,
  fullstackPersonal,
  goDeveloperBasic,
  headOfSales,
  iosDeveloper,
  iosDeveloperPlus,
  javaDeveloper,
  javaDeveloperPlus,
  marketplaceManager,
  productApproach,
  productApproachForDesigners
];