import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_security',
  title: 'Профессия Специалист по кибербезопасности 2.0',
  description: 'Онлайн курс «Специалист по кибербезопасности» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение информационной безопасности с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 122565,
  oldPrice: 245130,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/GtLjP1kRIsOlzydXfmQQEkEjpAyfVHkMsFDxUiqa.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fqa-engineer%2Fsecurity-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Анализ безопасности систем',
    'Тестирование на проникновение',
    'Защита от кибератак',
    'Криптография',
    'Сетевая безопасность',
    'Безопасность веб-приложений'
  ],
  program: [
    {
      title: 'Основы кибербезопасности',
      topics: [
        'Введение в информационную безопасность',
        'Типы угроз',
        'Методы защиты',
        'Правовые аспекты'
      ]
    },
    {
      title: 'Практическая безопасность',
      topics: [
        'Тестирование на проникновение',
        'Анализ уязвимостей',
        'Защита сетей',
        'Безопасность приложений'
      ]
    },
    {
      title: 'Инструменты безопасности',
      topics: [
        'Системы обнаружения вторжений',
        'Файрволы',
        'Антивирусы',
        'Мониторинг безопасности'
      ]
    },
    {
      title: 'Реагирование на инциденты',
      topics: [
        'Обработка инцидентов',
        'Форензика',
        'Восстановление систем',
        'Документирование'
      ]
    }
  ],
  features: [
    'Практические лабораторные работы',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};