import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_pm',
  title: 'Профессия Менеджер проектов',
  description: 'Онлайн курс «Менеджер проектов» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 7 месяцев. Помощь в трудоустройстве! Обучение на менеджера проектов с нуля онлайн.',
  school: 'geekbrains',
  category: 'management',
  price: 122784,
  oldPrice: 245567,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/I7a7TkcU80q1wMPEWxOuqpkOLPiIFdKxdMRqclkK.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fproject-manager-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Управление проектами',
    'Agile методологии',
    'Работа с командой',
    'Оценка рисков',
    'Планирование проектов',
    'Управление бюджетом'
  ],
  program: [
    {
      title: 'Основы управления проектами',
      topics: [
        'Жизненный цикл проекта',
        'Методологии управления',
        'Инструменты планирования',
        'Управление рисками'
      ]
    },
    {
      title: 'Agile и Scrum',
      topics: [
        'Agile-манифест',
        'Scrum фреймворк',
        'Kanban',
        'Гибридные подходы'
      ]
    },
    {
      title: 'Работа с командой',
      topics: [
        'Формирование команды',
        'Мотивация',
        'Коммуникации',
        'Конфликт-менеджмент'
      ]
    },
    {
      title: 'Управление процессами',
      topics: [
        'Бюджетирование',
        'Контроль качества',
        'Отчетность',
        'Оптимизация процессов'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Работа с реальными кейсами',
    'Сертификат менеджера проектов'
  ]
};