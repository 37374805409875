import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_data_scientist',
  title: 'Специалист по Data Science',
  description: 'Научитесь писать код на Python и SQL, строить модели для анализа данных, работать с компьютерным зрением и машинным обучением.',
  school: 'yandex',
  category: 'analytics',
  price: 115000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1509228627152-72ae9ae6848d?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fdata-scientist&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Python для DS',
    'Машинное обучение',
    'Нейронные сети',
    'Computer Vision',
    'NLP'
  ],
  program: [
    {
      title: 'Основы Data Science',
      topics: [
        'Python и библиотеки',
        'Математическая статистика',
        'Исследовательский анализ',
        'Визуализация'
      ]
    },
    {
      title: 'Машинное обучение',
      topics: [
        'Классификация',
        'Регрессия',
        'Кластеризация',
        'Ансамбли'
      ]
    },
    {
      title: 'Нейронные сети',
      topics: [
        'Deep Learning',
        'Computer Vision',
        'NLP',
        'Time Series'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о профессии'
  ]
};