import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_argumentation',
  title: 'Аргументация: как решать конфликты и убеждать людей',
  description: 'Обучение онлайн на платформе Нетология - курсы IT и образовательные программы с трудоустройством. Получи профессию с нашими онлайн курсами!',
  school: 'netology',
  category: 'lifestyle',
  price: 8320,
  oldPrice: 8320,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1552581234-26160f608093?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fargumenty-dlya-ubezhdeniy&m=4&erid=LdtCKCxkP',
  monthlyPayment: 8320,
  skills: [
    'Техники аргументации',
    'Разрешение конфликтов',
    'Убеждение и влияние',
    'Ведение переговоров',
    'Эффективная коммуникация'
  ],
  program: [
    {
      title: 'Основы аргументации',
      topics: [
        'Логика аргументации',
        'Структура доказательств',
        'Работа с возражениями',
        'Техники убеждения'
      ]
    },
    {
      title: 'Конфликты и решения',
      topics: [
        'Анализ конфликтов',
        'Стратегии разрешения',
        'Эмоциональный интеллект',
        'Медиация'
      ]
    },
    {
      title: 'Практика влияния',
      topics: [
        'Техники влияния',
        'Работа с сопротивлением',
        'Переговорные тактики',
        'Достижение согласия'
      ]
    }
  ],
  features: [
    'Практические упражнения',
    'Разбор реальных ситуаций',
    'Работа в группах',
    'Обратная связь',
    'Сертификат о прохождении'
  ]
};