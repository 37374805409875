import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_team_management',
  title: 'Базовый курс «Управление командой»',
  description: 'За 1 месяц освоите важные менеджерские навыки и эффективные фреймворки управления командой, чтобы почувствовать себя увереннее как руководитель.',
  school: 'yandex',
  category: 'management',
  price: 72000,
  currency: 'RUB',
  duration: '1 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fteam-management&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление командой',
    'Делегирование',
    'Обратная связь',
    'Планирование',
    'Принятие решений'
  ],
  program: [
    {
      title: 'Основы управления',
      topics: [
        'Роль руководителя',
        'Стили управления',
        'Постановка целей',
        'Организация работы'
      ]
    },
    {
      title: 'Работа с командой',
      topics: [
        'Делегирование задач',
        'Мотивация сотрудников',
        'Обратная связь',
        'Развитие команды'
      ]
    },
    {
      title: 'Инструменты руководителя',
      topics: [
        'Планирование',
        'Контроль исполнения',
        'Принятие решений',
        'Управление конфликтами'
      ]
    }
  ],
  features: [
    'Практические инструменты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Обратная связь',
    'Сертификат руководителя'
  ]
};