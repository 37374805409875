import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sql_advanced',
  title: 'Продвинутый SQL',
  description: 'Изучите продвинутый уровень SQL на курсе от Нетологии. Научитесь проектировать, администрировать и масштабировать базы данных, работать с триггерами и функциями в PostgreSQL.',
  school: 'netology',
  category: 'analytics',
  price: 46200,
  oldPrice: 66000,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsql-for-data-analysis&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2566,
  skills: [
    'Продвинутый SQL',
    'PostgreSQL',
    'Оптимизация запросов',
    'Администрирование БД',
    'Проектирование БД'
  ],
  program: [
    {
      title: 'Продвинутые запросы',
      topics: [
        'Сложные JOIN',
        'Оконные функции',
        'Рекурсивные запросы',
        'Оптимизация'
      ]
    },
    {
      title: 'Администрирование',
      topics: [
        'Индексы',
        'Транзакции',
        'Триггеры',
        'Функции'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Нормализация',
        'Денормализация',
        'Масштабирование',
        'Репликация'
      ]
    }
  ],
  features: [
    '13 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};