import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_effective_manager',
  title: 'Как стать результативным руководителем — эффективное управление',
  description: 'Узнайте, как стать эффективным руководителем на курсе в Нетологии. Освойте навыки управления командами, стратегического планирования и финансового анализа. Программа включает онлайн-вебинары, видеолекции и практические задания для углубленного обучения.',
  school: 'netology',
  category: 'management',
  price: 31220,
  oldPrice: 44600,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ftop-manager&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2601,
  skills: [
    'Управление командой',
    'Стратегическое планирование',
    'Финансовый анализ',
    'Принятие решений',
    'Эффективные коммуникации'
  ],
  program: [
    {
      title: 'Основы управления',
      topics: [
        'Роль руководителя',
        'Стили управления',
        'Делегирование',
        'Мотивация'
      ]
    },
    {
      title: 'Практические навыки',
      topics: [
        'Планирование',
        'Контроль результатов',
        'Обратная связь',
        'Развитие команды'
      ]
    },
    {
      title: 'Стратегическое управление',
      topics: [
        'Постановка целей',
        'Анализ ситуации',
        'Принятие решений',
        'Управление изменениями'
      ]
    }
  ],
  features: [
    '24 практических занятия',
    'Реальные кейсы',
    'Персональный наставник',
    'Работа над навыками',
    'Сертификат о повышении квалификации'
  ]
};