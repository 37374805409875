import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_frontend_pro',
  title: 'Профессия Frontend-разработчик PRO',
  description: 'Углубленный курс по frontend-разработке. Освойте современные технологии и станьте востребованным специалистом в области веб-разработки.',
  school: 'geekbrains',
  category: 'programming',
  price: 142993,
  oldPrice: 285985,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/RxjvzIRIrM5MRqxASUSM7Jhcd3FwAf3u5ZSF5nqc.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Современный JavaScript',
    'React и Redux',
    'TypeScript',
    'Node.js',
    'Тестирование',
    'CI/CD',
    'Оптимизация производительности'
  ],
  program: [
    {
      title: 'JavaScript и TypeScript',
      topics: [
        'ES6+ возможности',
        'TypeScript',
        'Паттерны проектирования',
        'Асинхронное программирование'
      ]
    },
    {
      title: 'React экосистема',
      topics: [
        'React Hooks',
        'Redux Toolkit',
        'React Query',
        'Next.js'
      ]
    },
    {
      title: 'Тестирование',
      topics: [
        'Jest',
        'React Testing Library',
        'Cypress',
        'TDD'
      ]
    },
    {
      title: 'DevOps для frontend',
      topics: [
        'Webpack',
        'Docker',
        'CI/CD',
        'Мониторинг'
      ]
    }
  ],
  features: [
    'Углубленное изучение технологий',
    'Работа над реальными проектами',
    'Код-ревью от экспертов',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};