import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_uxspro',
  title: 'Профессия UX/UI-дизайнер с нуля до PRO',
  description: 'Углубленное обучение UX/UI дизайну. Станьте экспертом в создании цифровых продуктов и освойте все аспекты проектирования пользовательского опыта.',
  school: 'contented',
  category: 'design',
  price: 179199,
  oldPrice: 325817,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://static.tildacdn.com/stor6434-3232-4532-a632-303231316466/11063918.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fux-ui-designer-pro%2F&m=5&erid=LdtCKHL5u',
  monthlyPayment: 4978,
  discountPercent: 45,
  skills: [
    'Проектирование пользовательских интерфейсов',
    'UX-исследования и аналитика',
    'Создание прототипов и wireframes',
    'Дизайн-системы и компоненты',
    'Анимация интерфейсов',
    'Управление проектами',
    'Работа с командой разработки'
  ],
  program: [
    {
      title: 'UX исследования',
      topics: [
        'Методы исследований',
        'Customer Journey Map',
        'User Flow',
        'Аналитика и метрики'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Информационная архитектура',
        'Wireframing',
        'Прототипирование',
        'Юзабилити-тестирование'
      ]
    },
    {
      title: 'UI дизайн',
      topics: [
        'Визуальный дизайн',
        'Компонентный подход',
        'Дизайн-системы',
        'Адаптивный дизайн'
      ]
    },
    {
      title: 'Управление проектами',
      topics: [
        'Работа с заказчиком',
        'Ведение проектов',
        'Работа с командой',
        'Оценка и планирование'
      ]
    }
  ],
  features: [
    'Персональный наставник',
    'Работа над реальными проектами',
    'Стажировка в IT-компании',
    'Помощь в трудоустройстве',
    'Доступ к профессиональному сообществу'
  ]
};