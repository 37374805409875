import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_marketing_director',
  title: 'Директор по интернет-маркетингу',
  description: 'Курс «Директор по интернет-маркетингу» от Нетологии: научитесь комплексно разрабатывать стратегии продвижения продуктов, составите личный план развития с ментором, выполните более 12 практических заданий и станете частью сообщества единомышленников.',
  school: 'netology',
  category: 'marketing',
  price: 186340,
  oldPrice: 338800,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmarketing-director&m=4&erid=LdtCKCxkP',
  discountPercent: 45,
  monthlyPayment: 5176,
  skills: [
    'Маркетинговая стратегия',
    'Управление командой',
    'Digital-маркетинг',
    'Аналитика и метрики',
    'Бюджетирование'
  ],
  program: [
    {
      title: 'Стратегический маркетинг',
      topics: [
        'Анализ рынка',
        'Разработка стратегии',
        'Позиционирование',
        'Управление брендом'
      ]
    },
    {
      title: 'Digital-инструменты',
      topics: [
        'SEO и контент',
        'Контекстная реклама',
        'Таргетированная реклама',
        'Email-маркетинг'
      ]
    },
    {
      title: 'Управление и аналитика',
      topics: [
        'Управление командой',
        'Бюджетирование',
        'Аналитика и KPI',
        'Оптимизация процессов'
      ]
    }
  ],
  features: [
    '82 практических занятия',
    'Реальные проекты',
    'Персональный ментор',
    'Помощь в карьерном росте',
    'Диплом о профессиональной переподготовке'
  ]
};