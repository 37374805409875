import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_math_pro',
  title: 'Математика [ПРО]',
  description: 'Мастер группа [ПРО] - это углубленный формат подготовки с расширенной программой и дополнительными материалами для достижения максимального результата.',
  school: '99ballov',
  category: 'math',
  price: 4290,
  oldPrice: 4790,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 10,
  skills: [
    'Решение олимпиадных задач',
    'Углубленная геометрия',
    'Задачи с параметрами',
    'Теория чисел',
    'Нестандартные методы решения'
  ],
  program: [
    {
      title: 'Расширенная программа',
      topics: [
        'Олимпиадная математика',
        'Высшая математика',
        'Теория вероятностей',
        'Комбинаторика'
      ]
    },
    {
      title: 'Практикум',
      topics: [
        'Сложные задачи',
        'Исследовательские задания',
        'Математические бои',
        'Проектная работа'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Углубленные материалы',
    'Олимпиадная подготовка',
    'Личный куратор',
    'Дополнительные консультации'
  ]
};