import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_devops',
  title: 'DevOps-инженер с нуля',
  description: 'Курс «DevOps-инженер с нуля» от Нетологии: с нуля освоите одну из самых высокооплачиваемых профессий в IT и на практике выстроите процессы DevOps с использованием облачных сервисов. Поддержка в обучении и помощь с трудоустройством.',
  school: 'netology',
  category: 'programming',
  price: 172800,
  oldPrice: 288000,
  currency: 'RUB',
  duration: '19 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1667372393119-3d4c48d07fc9?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ffullstack-devops&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4800,
  skills: [
    'DevOps практики',
    'CI/CD',
    'Контейнеризация',
    'Мониторинг',
    'Облачные сервисы',
    'Автоматизация'
  ],
  program: [
    {
      title: 'Основы DevOps',
      topics: [
        'Linux администрирование',
        'Git',
        'Сети',
        'Безопасность'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Docker',
        'Kubernetes',
        'Jenkins',
        'Ansible'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'CI/CD пайплайны',
        'Мониторинг систем',
        'Облачные платформы',
        'Автоматизация'
      ]
    }
  ],
  features: [
    '19 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};