import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sound_designer',
  title: 'Cаунд-дизайнер',
  description: 'Профессия саунд-дизайнера: обучение для начинающих. Освойте навыки саунд-дизайна и станьте востребованным специалистом в этой творческой сфере.',
  school: 'netology',
  category: 'multimedia',
  price: 85263,
  oldPrice: 142105,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1598488035139-bdbb2231ce04?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsound-design&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3552,
  skills: [
    'Звуковой дизайн',
    'Работа со звуком',
    'Аудио постпродакшн',
    'Создание звуковых эффектов',
    'Музыкальное оформление'
  ],
  program: [
    {
      title: 'Основы саунд-дизайна',
      topics: [
        'Теория звука',
        'Звуковое оборудование',
        'DAW системы',
        'Запись звука'
      ]
    },
    {
      title: 'Создание звука',
      topics: [
        'Синтез звука',
        'Звуковые эффекты',
        'Обработка звука',
        'Сведение'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Звук для видео',
        'Звук для игр',
        'Создание библиотек'
      ]
    }
  ],
  features: [
    '57 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};