import { Category } from '../../types';

export const categories: Category[] = [
  {
    id: 'design',
    name: 'Дизайн',
    description: 'Курсы по графическому, веб и UX/UI дизайну'
  },
  {
    id: '3d',
    name: '3D и анимация',
    description: 'Курсы по 3D моделированию и анимации'
  },
  {
    id: 'photo-video',
    name: 'Фото и видео',
    description: 'Курсы по фотографии и видеопроизводству'
  },
  {
    id: 'game-dev',
    name: 'Разработка игр',
    description: 'Курсы по геймдизайну и разработке игр'
  },
  {
    id: 'software',
    name: 'Программы',
    description: 'Курсы по работе с профессиональным ПО'
  },
  {
    id: 'programming',
    name: 'Программирование',
    description: 'Курсы по разработке программного обеспечения'
  },
  {
    id: 'management',
    name: 'Управление',
    description: 'Курсы по управлению проектами и бизнесом'
  },
  {
    id: 'marketing',
    name: 'Маркетинг',
    description: 'Курсы по маркетингу и продвижению'
  },
  {
    id: 'multimedia',
    name: 'Мультимедиа',
    description: 'Курсы по созданию мультимедийного контента'
  },
  {
    id: 'engineering',
    name: 'Инженерия',
    description: 'Курсы по инженерным специальностям'
  },
  {
    id: 'french',
    name: 'Французский язык',
    description: 'Курсы французского языка для разных целей и уровней'
  },
  {
    id: 'spanish',
    name: 'Испанский язык',
    description: 'Курсы испанского языка для разных целей и уровней'
  },
  {
    id: 'english',
    name: 'Английский язык',
    description: 'Курсы английского языка для разных целей и уровней'
  },
  {
    id: 'german',
    name: 'Немецкий язык',
    description: 'Курсы немецкого языка для разных целей и уровней'
  },
  {
    id: 'chinese',
    name: 'Китайский язык',
    description: 'Курсы китайского языка для разных целей и уровней'
  },
  {
    id: 'italian',
    name: 'Итальянский язык',
    description: 'Курсы итальянского языка для разных целей и уровней'
  },
  {
    id: 'portuguese',
    name: 'Португальский язык',
    description: 'Курсы португальского языка для разных целей и уровней'
  },
  {
    id: 'math',
    name: 'Математика',
    description: 'Курсы по математике и подготовке к экзаменам'
  },
  {
    id: 'physics',
    name: 'Физика',
    description: 'Курсы по физике и подготовке к экзаменам'
  },
  {
    id: 'chemistry',
    name: 'Химия',
    description: 'Курсы по химии и подготовке к экзаменам'
  },
  {
    id: 'biology',
    name: 'Биология',
    description: 'Курсы по биологии и подготовке к экзаменам'
  },
  {
    id: 'history',
    name: 'История',
    description: 'Курсы по истории и подготовке к экзаменам'
  },
  {
    id: 'social',
    name: 'Обществознание',
    description: 'Курсы по обществознанию и подготовке к экзаменам'
  },
  {
    id: 'russian',
    name: 'Русский язык',
    description: 'Курсы по русскому языку и подготовке к экзаменам'
  },
  {
    id: 'literature',
    name: 'Литература',
    description: 'Курсы по литературе и подготовке к экзаменам'
  },
  {
    id: 'geography',
    name: 'География',
    description: 'Курсы по географии и подготовке к экзаменам'
  }
];