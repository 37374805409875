import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_web_design',
  title: 'Офлайн-курс Веб-дизайн UX/UI с нуля',
  description: 'Очный курс-интенсив в маленькой группе. Освоите основы дизайна, Figma, Photoshop, Tilda. Создадите портфолио и защитите проект перед реальным заказчиком',
  school: 'brunoyam',
  category: 'design',
  price: 49900,
  currency: 'RUB',
  duration: '50 недель',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fveb-dizajna&m=5&erid=LdtCK4uLs',
  skills: [
    'UX/UI дизайн',
    'Figma',
    'Прототипирование',
    'Веб-дизайн',
    'Работа с заказчиками'
  ],
  program: [
    {
      title: 'Основы веб-дизайна',
      topics: [
        'Теория дизайна',
        'UX исследования',
        'Прототипирование',
        'Работа с сеткой'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Figma',
        'Adobe Photoshop',
        'Tilda',
        'Protopie'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание макетов',
        'Работа с клиентами',
        'Защита проектов',
        'Портфолио'
      ]
    }
  ],
  features: [
    '48 академических часов',
    '80 занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};