import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_math_data',
  title: 'Математика для анализа данных',
  description: 'Курс для тех, кто хочет развиваться в аналитике и Data Science. Подтянете статистику, линейную алгебру, матанализ и разберётесь в теории вероятностей.',
  school: 'yandex',
  category: 'analytics',
  price: 44000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmath-for-da-ds&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Математическая статистика',
    'Линейная алгебра',
    'Математический анализ',
    'Теория вероятностей',
    'Python для вычислений'
  ],
  program: [
    {
      title: 'Линейная алгебра',
      topics: [
        'Матрицы и векторы',
        'Системы уравнений',
        'Собственные значения',
        'Метрические пространства'
      ]
    },
    {
      title: 'Математический анализ',
      topics: [
        'Функции и пределы',
        'Производные',
        'Интегралы',
        'Ряды'
      ]
    },
    {
      title: 'Теория вероятностей',
      topics: [
        'Случайные события',
        'Распределения',
        'Статистические оценки',
        'Проверка гипотез'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Python для вычислений',
    'Персональный наставник',
    'Помощь в обучении',
    'Сертификат о прохождении'
  ]
};