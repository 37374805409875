import { Course } from '../../../../types';

export const course: Course = {
  id: 'anecole_chinese_travel',
  title: 'Китайский для путешествий',
  description: 'Любите путешествовать и хотите овладеть китайским, чтобы чувствовать себя свободнее и увереннее во время поездок?',
  school: 'anecole',
  category: 'chinese',
  price: 19120,
  currency: 'RUB',
  duration: '8 часов',
  imageUrl: 'https://images.unsplash.com/photo-1547981609-4b6bfe67ca0b?q=80&w=1200',
  url: 'https://go.redav.online/4f106e4f2bb25c71?dl=https%3A%2F%2Fanecole.com%2Fchinese-travel&m=5&erid=2Vfnxwa5gNL',
  monthlyPayment: 4780,
  skills: [
    'Базовая коммуникация',
    'Ситуативные диалоги',
    'Заказ в ресторане',
    'Бронирование отеля',
    'Общение в магазине'
  ],
  program: [
    {
      title: 'Вводный блок',
      topics: [
        'Вводный урок',
        'Фонетика',
        'Базовая грамматика',
        'Числительные и даты'
      ]
    },
    {
      title: 'Практические ситуации',
      topics: [
        'В аэропорту',
        'В отеле',
        'В ресторане',
        'На экскурсии'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивные тренажеры',
    'Разговорная практика',
    'Ситуативные диалоги',
    'Сертификат по окончании'
  ]
};