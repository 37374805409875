import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_eating_disorders',
  title: 'Психология нарушений пищевого поведения. Стратегии психологической помощи',
  description: 'Дистанционное обучение по программе Психология нарушений пищевого поведения. Стратегии психологической помощи(230 часов) в ЦАППКК. Комплексный подход к работе с нарушениями пищевого поведения.',
  school: 'appkk',
  category: 'psychology',
  price: 15000,
  currency: 'RUR',
  duration: '230 часов',
  imageUrl: 'https://images.unsplash.com/photo-1493836512294-502baa1986e2?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpsikhologiya_narusheniy_pishchevogo_povedeniya_strategii_psikhologicheskoy_pomoshchi%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Диагностика РПП',
    'Психотерапевтические техники',
    'Работа с пищевым поведением',
    'Семейная терапия',
    'Профилактика рецидивов'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'Виды нарушений пищевого поведения',
        'Причины возникновения РПП',
        'Диагностические критерии',
        'Коморбидные расстройства'
      ]
    },
    {
      title: 'Диагностика и оценка',
      topics: [
        'Методы диагностики',
        'Оценка рисков',
        'Планирование терапии',
        'Мониторинг прогресса'
      ]
    },
    {
      title: 'Терапевтические подходы',
      topics: [
        'КПТ при РПП',
        'Семейная терапия',
        'Работа с образом тела',
        'Профилактика рецидивов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор клинических случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};