import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_ml',
  title: 'Профессия Machine Learning Engineer',
  description: 'Онлайн курс «Machine Learning Engineer» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на Machine Learning Engineer с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 111154,
  oldPrice: 222307,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/K8uX4ZUbse3nTkANAojgXqFo0APJTdeGC9NTGraI.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fprogrammer%2Fmachine-learning&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Машинное обучение',
    'Deep Learning',
    'Python для ML',
    'Нейронные сети',
    'Обработка данных',
    'MLOps'
  ],
  program: [
    {
      title: 'Основы ML',
      topics: [
        'Введение в ML',
        'Математика для ML',
        'Python для ML',
        'Pandas и NumPy'
      ]
    },
    {
      title: 'Алгоритмы ML',
      topics: [
        'Классификация',
        'Регрессия',
        'Кластеризация',
        'Ансамбли моделей'
      ]
    },
    {
      title: 'Deep Learning',
      topics: [
        'Нейронные сети',
        'Computer Vision',
        'NLP',
        'TensorFlow и PyTorch'
      ]
    },
    {
      title: 'MLOps',
      topics: [
        'Развертывание моделей',
        'Мониторинг',
        'CI/CD для ML',
        'Оптимизация'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат ML Engineer'
  ]
};