import { Course } from '../../../../types';

export const course: Course = {
  id: 'abakus_reading_48',
  title: 'Чтение Абакус - обучение и курсы для детей | 48 уроков',
  description: 'Наша авторская методика, направленная на развитие навыков осознанного чтения и улучшение техники и скорости чтения',
  school: 'abakus',
  category: 'russian',
  price: 52530,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?q=80&w=1200',
  url: 'https://go.redav.online/86fdf99551e92c21?dl=https%3A%2F%2Fabakus-center.ru%2Feducation%2Freading&m=5&erid=2Vfnxx8cgzL',
  monthlyPayment: 8755,
  skills: [
    'Экспертная техника чтения',
    'Максимальная скорость',
    'Полное понимание текста',
    'Феноменальная память',
    'Расширенный словарный запас',
    'Анализ сложных текстов'
  ],
  program: [
    {
      title: 'Углубленный курс',
      topics: [
        'Продвинутые техники',
        'Скорочтение',
        'Работа со сложными текстами',
        'Развитие памяти'
      ]
    },
    {
      title: 'Практический блок',
      topics: [
        'Скоростное чтение',
        'Анализ литературы',
        'Развивающие упражнения',
        'Работа с информацией'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Расширенная программа',
    'Опытные преподаватели',
    'Сложные задания',
    'Мониторинг успеваемости'
  ]
};