import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_power_bi',
  title: 'Power BI и Excel для продвинутых',
  description: 'Углубленное изучение Power BI и Excel на онлайн-курсе от Нетологии. Научитесь создавать комплексные интерактивные дашборды и автоматизировать отчётность за 2 месяца. Получите удостоверение о повышении квалификации.',
  school: 'netology',
  category: 'analytics',
  price: 38500,
  oldPrice: 55000,
  currency: 'RUB',
  duration: '9 недель',
  imageUrl: 'https://images.unsplash.com/photo-1543286386-713bdd548da4?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fexcelpbi&m=5&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 3208,
  skills: [
    'Power BI',
    'Excel продвинутый',
    'Визуализация данных',
    'Построение дашбордов',
    'Анализ данных',
    'Автоматизация отчетности'
  ],
  program: [
    {
      title: 'Excel продвинутый',
      topics: [
        'Сложные формулы',
        'Сводные таблицы',
        'VBA и макросы',
        'Power Query'
      ]
    },
    {
      title: 'Power BI',
      topics: [
        'Импорт данных',
        'DAX',
        'Визуализации',
        'Публикация отчетов'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание дашбордов',
        'Автоматизация',
        'Оптимизация отчетов',
        'Реальные кейсы'
      ]
    }
  ],
  features: [
    '21 практическое занятие',
    'Работа с реальными данными',
    'Персональный наставник',
    'Доступ к материалам',
    'Удостоверение о повышении квалификации'
  ]
};