import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_russian',
  title: 'Годовой курс по подготовке к ЕГЭ по русскому языку с Машей Птипцей',
  description: 'Годовой курс по русскому языку ЕГЭ с Машей Птипцей — это курс для тех, кто хорошенько хочет подготовиться к ЕГЭ по русскому и написать его на 90+ баллов.',
  school: '100points',
  category: 'russian',
  price: 33890,
  oldPrice: 39490,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3690,
  discountPercent: 15,
  skills: [
    'Написание сочинений',
    'Работа с текстом',
    'Орфография и пунктуация',
    'Языковые нормы',
    'Анализ текста',
    'Аргументация'
  ],
  program: [
    {
      title: 'Орфография',
      topics: [
        'Правописание корней',
        'Правописание приставок',
        'Правописание суффиксов',
        'Слитное и раздельное написание'
      ]
    },
    {
      title: 'Пунктуация',
      topics: [
        'Знаки препинания в простом предложении',
        'Знаки препинания в сложном предложении',
        'Обособленные члены предложения',
        'Вводные конструкции'
      ]
    },
    {
      title: 'Работа с текстом',
      topics: [
        'Анализ текста',
        'Средства выразительности',
        'Типы речи',
        'Стили речи'
      ]
    },
    {
      title: 'Сочинение',
      topics: [
        'Структура сочинения',
        'Комментарий к проблеме',
        'Аргументация',
        'Композиция текста'
      ]
    }
  ],
  features: [
    '2 веба в неделю',
    'Бонусный вебинар по сочинению',
    'Проверка работ куратором',
    'Разбор типичных ошибок',
    'Психологическая поддержка'
  ]
};