import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_drawing',
  title: 'Как научиться рисовать',
  description: 'В основе курса методика интуитивного рисования. Поможем освоить простые инструменты и техники, работать с разными материалами и выражать свои чувства в творчестве.',
  school: 'netology',
  category: 'design',
  price: 3060,
  oldPrice: 5100,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1513364776144-60967b0f800f?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fintuitivnoe_risovanie&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3060,
  skills: [
    'Основы рисунка',
    'Интуитивное рисование',
    'Работа с материалами',
    'Композиция',
    'Развитие креативности'
  ],
  program: [
    {
      title: 'Основы рисования',
      topics: [
        'Базовые техники',
        'Материалы',
        'Композиция',
        'Цвет'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Интуитивные техники',
        'Работа с эмоциями',
        'Создание образов',
        'Развитие стиля'
      ]
    }
  ],
  features: [
    '13 практических занятий',
    'Индивидуальный подход',
    'Обратная связь',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};