import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_3d',
  title: 'Профессия «3D Artist»',
  description: 'Комплексное обучение 3D графике',
  school: 'contented',
  category: '3d',
  price: 175388,
  oldPrice: 318888,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2F3d-artist&m=5&erid=LdtCKHL5u',
  monthlyPayment: 4872,
  discountPercent: 45,
  skills: [
    '3D моделирование',
    'Текстурирование',
    'Анимация',
    'Визуализация',
    'Работа со светом',
    'Рендеринг'
  ],
  program: [
    {
      title: 'Основы 3D',
      topics: [
        'Введение в 3D графику',
        'Интерфейс программ',
        'Базовое моделирование',
        'Работа с материалами'
      ]
    },
    {
      title: 'Продвинутое моделирование',
      topics: [
        'Hard Surface моделирование',
        'Органическое моделирование',
        'Скульптинг',
        'Ретопология'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Текстурирование',
        'Настройка материалов',
        'Постановка света',
        'Рендеринг'
      ]
    },
    {
      title: 'Анимация',
      topics: [
        'Основы анимации',
        'Риггинг',
        'Анимация персонажей',
        'Спецэффекты'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Доступ к онлайн-библиотеке',
    'Сертификат специалиста'
  ]
};