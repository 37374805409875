import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_medical_marketing',
  title: 'Системный медицинский маркетинг: от стратегии до повторных продаж',
  description: 'Курс «Системный медицинский маркетинг» от Нетологии: создание комплексной маркетинговой стратегии для клиник за 4 месяца. Освойте методы привлечения и удержания пациентов, управление репутацией и тренды медмаркетинга. Практические задания и готовые инструменты помогут внедрить стратегию для роста клиники сразу после обучения.',
  school: 'netology',
  category: 'medicine',
  price: 80000,
  oldPrice: 80000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmedicine-marketing&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3333,
  skills: [
    'Медицинский маркетинг',
    'Привлечение пациентов',
    'Управление репутацией',
    'Стратегическое планирование',
    'Аналитика эффективности'
  ],
  program: [
    {
      title: 'Стратегия и анализ',
      topics: [
        'Анализ рынка',
        'Целевая аудитория',
        'Позиционирование',
        'Конкурентный анализ'
      ]
    },
    {
      title: 'Маркетинговые инструменты',
      topics: [
        'Digital-маркетинг',
        'Работа с репутацией',
        'Контент-маркетинг',
        'Реклама'
      ]
    },
    {
      title: 'Управление клиникой',
      topics: [
        'Сервис и стандарты',
        'Работа с персоналом',
        'Повторные продажи',
        'Аналитика и KPI'
      ]
    }
  ],
  features: [
    'Практические инструменты',
    'Готовые шаблоны',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};