import { Course } from '../../../../types';
import { course as mathLegion } from './math-legion';
import { course as physicsEbonit } from './physics-ebonit';
import { course as authorPhoto } from './author-photography';
import { course as socialStudies } from './social-studies';
import { course as russian } from './russian';
import { course as chemistry } from './chemistry';
import { course as literature } from './literature';
import { course as history } from './history';
import { course as mathBase } from './math-base';
import { course as mathBaseCurator } from './math-base-curator';
import { course as mathKrug } from './math-krug';
import { course as physicsSigma } from './physics-sigma';
import { course as physicsSigmaCurator } from './physics-sigma-curator';
import { course as mathOge } from './math-oge';
import { course as mathOgeCurator } from './math-oge-curator';
import { course as historyOge } from './history-oge';
import { course as historyOgeCurator } from './history-oge-curator';
import { course as socialOge } from './social-oge';
import { course as socialOgeCurator } from './social-oge-curator';
import { course as physicsOge } from './physics-oge';
import { course as physicsOgeCurator } from './physics-oge-curator';

export const hundredPointsCourses: Course[] = [
  mathLegion,
  physicsEbonit,
  authorPhoto,
  socialStudies,
  russian,
  chemistry,
  literature,
  history,
  mathBase,
  mathBaseCurator,
  mathKrug,
  physicsSigma,
  physicsSigmaCurator,
  mathOge,
  mathOgeCurator,
  historyOge,
  historyOgeCurator,
  socialOge,
  socialOgeCurator,
  physicsOge,
  physicsOgeCurator
];