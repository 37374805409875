import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_uxscamp',
  title: 'Профессия UX/UI-дизайнер с нуля за 9 недель',
  description: 'Интенсивное обучение UX/UI дизайну в формате буткемпа. Освойте профессию дизайнера интерфейсов в ускоренном формате с полным погружением.',
  school: 'contented',
  category: 'design',
  price: 132190,
  oldPrice: 240345,
  currency: 'RUR',
  duration: '9 недель',
  imageUrl: 'https://thumb.tildacdn.com/tild3638-3430-4539-b561-333261303935/-/resize/900x/-/format/webp/UI-_____9_.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fux-ui-designer-bootcamp&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3672,
  discountPercent: 45,
  skills: [
    'Проектирование интерфейсов',
    'Создание прототипов',
    'Работа в Figma',
    'UX-исследования',
    'Дизайн-системы',
    'Анимация интерфейсов',
    'Презентация проектов'
  ],
  program: [
    {
      title: 'Основы UX/UI',
      topics: [
        'Введение в UX/UI дизайн',
        'Исследование пользователей',
        'Информационная архитектура',
        'Прототипирование'
      ]
    },
    {
      title: 'Инструменты и практика',
      topics: [
        'Работа в Figma',
        'Создание компонентов',
        'Адаптивный дизайн',
        'Дизайн-системы'
      ]
    },
    {
      title: 'Проектная работа',
      topics: [
        'Работа над проектом',
        'Анимация интерфейсов',
        'Подготовка презентации',
        'Защита проекта'
      ]
    },
    {
      title: 'Карьера',
      topics: [
        'Создание портфолио',
        'Подготовка к собеседованиям',
        'Поиск работы',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    'Интенсивный формат обучения',
    'Ежедневная практика',
    'Персональный наставник',
    'Работа над реальными проектами',
    'Помощь в трудоустройстве'
  ]
};