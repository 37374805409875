import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_ios',
  title: 'iOS-разработчик с нуля',
  description: 'Профессия «iOS-разработчик с нуля» от Нетологии: научитесь разрабатывать приложения для мобильных устройств Apple на языке Swift 5. Изучите новые фреймворки UIKit и SwiftUI. Практика на реальных проектах позволит вам развить ключевые навыки для успешной карьеры в мобильной разработке.',
  school: 'netology',
  category: 'programming',
  price: 125001,
  oldPrice: 208334,
  currency: 'RUB',
  duration: '11 месяцев',
  imageUrl: 'https://i.pinimg.com/originals/55/89/1a/55891a685032550a1ce45ec25d211021.jpg?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fios-developer&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3472,
  skills: [
    'Swift 5',
    'UIKit',
    'SwiftUI',
    'iOS разработка',
    'Xcode',
    'Core Data'
  ],
  program: [
    {
      title: 'Основы iOS разработки',
      topics: [
        'Swift 5',
        'Xcode',
        'UIKit основы',
        'Жизненный цикл'
      ]
    },
    {
      title: 'Продвинутая разработка',
      topics: [
        'SwiftUI',
        'Core Data',
        'Networking',
        'Архитектура'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные проекты',
        'App Store',
        'Оптимизация',
        'Тестирование'
      ]
    }
  ],
  features: [
    '18 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};