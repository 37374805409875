import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_math_data_plus',
  title: 'Математика для анализа данных плюс',
  description: 'Математика для анализа данных плюс',
  school: 'yandex',
  category: 'analytics',
  price: 40000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmath-for-da-ds-plus&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продвинутая математика',
    'Статистический анализ',
    'Линейная алгебра',
    'Теория вероятностей',
    'Python для вычислений'
  ],
  program: [
    {
      title: 'Углубленная математика',
      topics: [
        'Многомерный анализ',
        'Оптимизация',
        'Дифференциальные уравнения',
        'Численные методы'
      ]
    },
    {
      title: 'Статистика и вероятности',
      topics: [
        'Байесовская статистика',
        'Временные ряды',
        'Стохастические процессы',
        'Статистические тесты'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Python для вычислений',
        'Математическое моделирование',
        'Анализ данных',
        'Машинное обучение'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Практические задания',
    'Персональный наставник',
    'Работа с реальными данными',
    'Сертификат о прохождении'
  ]
};