import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_chatgpt',
  title: 'Онлайн-курс Chat GPT',
  description: 'За неделю освоите ChatGPT, научитесь с его помощью быстрее делать задачи в работе и жизни, поймёте, как генерировать идеи для контента, рекламы, стратегий',
  school: 'brunoyam',
  category: 'analytics',
  price: 5900,
  currency: 'RUB',
  duration: '1 неделя',
  imageUrl: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fchat-gpt&m=5&erid=LdtCK4uLs',
  skills: [
    'Работа с ChatGPT',
    'Генерация контента',
    'Prompt Engineering',
    'Анализ текста',
    'Автоматизация задач'
  ],
  program: [
    {
      title: 'Основы работы с ChatGPT',
      topics: [
        'Принципы работы',
        'Написание промптов',
        'Типы задач',
        'Ограничения системы'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Генерация контента',
        'Анализ текстов',
        'Исследования',
        'Креативные задачи'
      ]
    },
    {
      title: 'Продвинутые техники',
      topics: [
        'Сложные промпты',
        'Цепочки команд',
        'Интеграция в работу',
        'Автоматизация'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными кейсами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат по окончании'
  ]
};