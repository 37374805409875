import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_uxui_basic',
  title: 'UX/UI-дизайнер',
  description: 'Онлайн-курс «Профессия UX/UI-дизайнер» — Нетология: изучите проектирование интерфейсов, UX-исследования и дизайн мобильных приложений. Практическое обучение с реальными кейсами, работа в Figma и Sketch, поддержка наставников и диплом. Станьте профессионалом в продуктовом дизайне и начните карьеру с портфолио.',
  school: 'netology',
  category: 'design',
  price: 143264,
  oldPrice: 238772,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fproduct-design&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3979,
  skills: [
    'UX исследования',
    'UI дизайн',
    'Прототипирование',
    'Работа с Figma',
    'Дизайн-системы'
  ],
  program: [
    {
      title: 'Основы UX/UI',
      topics: [
        'Исследования пользователей',
        'Информационная архитектура',
        'Прототипирование',
        'Тестирование'
      ]
    },
    {
      title: 'UI дизайн',
      topics: [
        'Визуальный дизайн',
        'Компонентный подход',
        'Дизайн-системы',
        'Анимация интерфейсов'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Создание портфолио',
        'Презентация работ',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    '30 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};