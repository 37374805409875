import React from 'react';
import { useCourseStore } from '../store/courseStore';
import { 
  AcademicCapIcon, 
  BuildingOfficeIcon,
  BookOpenIcon,
  UsersIcon 
} from '@heroicons/react/24/outline';

export const Statistics: React.FC = () => {
  const { courses, schools, categories } = useCourseStore();

  const stats = [
    { 
      name: 'Курсов',
      value: courses.length,
      icon: BookOpenIcon,
      color: 'bg-white/10 text-white'
    },
    { 
      name: 'Школ',
      value: schools.length,
      icon: BuildingOfficeIcon,
      color: 'bg-white/10 text-white'
    },
    { 
      name: 'Направлений',
      value: categories.length,
      icon: AcademicCapIcon,
      color: 'bg-white/10 text-white'
    },
    { 
      name: 'Студентов',
      value: '10000+',
      icon: UsersIcon,
      color: 'bg-white/10 text-white'
    }
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
      {stats.map((stat) => (
        <div key={stat.name} className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
          <div className="flex items-center space-x-4">
            <div className={`p-3 rounded-lg ${stat.color}`}>
              <stat.icon className="h-6 w-6" />
            </div>
            <div>
              <div className="text-2xl font-bold text-white">{stat.value}</div>
              <div className="text-sm text-white/80">{stat.name}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};