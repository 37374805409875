import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_deep_learning',
  title: 'Deep Learning',
  description: 'Пройдите курс по глубокому обучению в Нетологии и научитесь создавать нейросети для анализа изображений и текста. Получите практические навыки в AI и машинном обучении за 2,5 месяца.',
  school: 'netology',
  category: 'analytics',
  price: 52500,
  oldPrice: 75000,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdeep-learning&m=5&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2916,
  skills: [
    'Deep Learning',
    'Нейронные сети',
    'Computer Vision',
    'NLP',
    'TensorFlow',
    'PyTorch'
  ],
  program: [
    {
      title: 'Основы Deep Learning',
      topics: [
        'Нейронные сети',
        'Функции активации',
        'Оптимизация',
        'Архитектуры сетей'
      ]
    },
    {
      title: 'Computer Vision',
      topics: [
        'CNN',
        'Обработка изображений',
        'Детекция объектов',
        'Сегментация'
      ]
    },
    {
      title: 'NLP',
      topics: [
        'Обработка текста',
        'RNN и LSTM',
        'Трансформеры',
        'BERT'
      ]
    }
  ],
  features: [
    '24 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};