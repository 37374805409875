import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_cognitive_therapy',
  title: 'Когнитивно-поведенческая терапия в практике психолога',
  description: 'Дистанционное обучение по программе Когнитивно-поведенческая терапия в практике психолога(115 часов) в ЦАППКК. Освоение современных методов КПТ для эффективной работы с клиентами.',
  school: 'appkk',
  category: 'psychology',
  price: 16000,
  currency: 'RUR',
  duration: '115 часов',
  imageUrl: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fkognitivno_povedencheskaya_terapiya_v_praktike_psikhologa%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Основы КПТ',
    'Диагностика в КПТ',
    'Работа с когнициями',
    'Поведенческие техники',
    'Эмоциональная регуляция'
  ],
  program: [
    {
      title: 'Теоретические основы КПТ',
      topics: [
        'История развития направления',
        'Базовые принципы',
        'Модели психических расстройств',
        'Когнитивные искажения'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Когнитивное реструктурирование',
        'Поведенческие эксперименты',
        'Экспозиция',
        'Релаксационные техники'
      ]
    },
    {
      title: 'Работа с клиентами',
      topics: [
        'Структура сессии',
        'Концептуализация случая',
        'Домашние задания',
        'Профилактика рецидивов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Супервизия',
    'Разбор случаев',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};