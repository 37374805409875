import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_russian_pro',
  title: 'Русский язык [ПРО]',
  description: 'Мастер группа [ПРО] - это углубленный формат подготовки с расширенной программой и дополнительными материалами для достижения максимального результата.',
  school: '99ballov',
  category: 'russian',
  price: 4290,
  oldPrice: 4790,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 10,
  skills: [
    'Углубленная работа с текстом',
    'Стилистический анализ',
    'Написание сложных сочинений',
    'Лингвистический анализ',
    'Редактирование текстов'
  ],
  program: [
    {
      title: 'Расширенная программа',
      topics: [
        'Лингвистический анализ',
        'Стилистика текста',
        'Теория языка',
        'Практика редактирования'
      ]
    },
    {
      title: 'Практикум',
      topics: [
        'Сложные случаи орфографии',
        'Трудные пунктограммы',
        'Комплексный анализ текста',
        'Написание сочинений'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Углубленные материалы',
    'Олимпиадная подготовка',
    'Личный куратор',
    'Дополнительные консультации'
  ]
};