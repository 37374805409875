import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_java',
  title: 'Офлайн-курс Java-разработчик',
  description: 'Практический курс по Java с нуля до Junior Java Developer. 2 месяца офлайн-занятий в мини-группе и 2 месяца проектной работы с личным наставником. Всё, чтобы вы освоили профессию Java-разработчика.',
  school: 'brunoyam',
  category: 'programming',
  price: 69900,
  currency: 'RUB',
  duration: '1 неделя',
  imageUrl: 'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-programmirovaniya%2Fjava&m=5&erid=LdtCK4uLs',
  skills: [
    'Java Core',
    'Spring Framework',
    'Hibernate',
    'REST API',
    'SQL',
    'Git'
  ],
  program: [
    {
      title: 'Основы Java',
      topics: [
        'Синтаксис Java',
        'ООП',
        'Коллекции',
        'Многопоточность'
      ]
    },
    {
      title: 'Spring Framework',
      topics: [
        'Spring Core',
        'Spring MVC',
        'Spring Boot',
        'Spring Security'
      ]
    },
    {
      title: 'Базы данных и веб',
      topics: [
        'SQL и JDBC',
        'Hibernate',
        'REST API',
        'Микросервисы'
      ]
    }
  ],
  features: [
    '72 академических часа',
    '108 занятий',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};