import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_public_speaking',
  title: 'Ораторское мастерство: практикум',
  description: 'Обучение навыкам публичного выступления на онлайн-курсе Нетологии с сертификатом. Станьте уверенным спикером — на сцене у микрофона и перед камерой, во время переговоров и дискуссий, офлайн и онлайн.',
  school: 'netology',
  category: 'lifestyle',
  price: 18000,
  oldPrice: 30000,
  currency: 'RUB',
  duration: '7 недель',
  imageUrl: 'https://images.unsplash.com/photo-1475721027785-f74eccf877e2?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fvystupleniya-praktikum&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 1500,
  skills: [
    'Публичные выступления',
    'Работа с голосом',
    'Управление волнением',
    'Структура выступления',
    'Работа с аудиторией'
  ],
  program: [
    {
      title: 'Основы публичных выступлений',
      topics: [
        'Подготовка выступления',
        'Работа с волнением',
        'Структура речи',
        'Взаимодействие с аудиторией'
      ]
    },
    {
      title: 'Практика выступлений',
      topics: [
        'Техника речи',
        'Язык тела',
        'Импровизация',
        'Работа с вопросами'
      ]
    }
  ],
  features: [
    'Практические выступления',
    'Обратная связь от экспертов',
    'Работа с видеозаписью',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};