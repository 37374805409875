import { Course } from '../../../../types';

export const course: Course = {
  id: 'anecole_english_move',
  title: 'Английский для переезда и жизни',
  description: 'Для тех, кто планирует переезд в новую страну и хочет уверенно чувствовать себя в любой жизненной ситуации: от похода в магазин до подачи документов в госучреждения.',
  school: 'anecole',
  category: 'english',
  price: 19120,
  currency: 'RUB',
  duration: '8 часов',
  imageUrl: 'https://images.unsplash.com/photo-1523240795612-9a054b0db644?q=80&w=1200',
  url: 'https://go.redav.online/4f106e4f2bb25c71?dl=https%3A%2F%2Fanecole.com%2Fenglish-move&m=5&erid=2Vfnxwa5gNL',
  monthlyPayment: 4780,
  skills: [
    'Бытовое общение',
    'Работа с документами',
    'Медицинская лексика',
    'Поиск жилья',
    'Общение с госорганами'
  ],
  program: [
    {
      title: 'Базовые навыки',
      topics: [
        'Вводный урок',
        'Бытовая лексика',
        'Грамматика',
        'Повседневные ситуации'
      ]
    },
    {
      title: 'Практические ситуации',
      topics: [
        'Аренда жилья',
        'Медицинское обслуживание',
        'Банковские услуги',
        'Государственные учреждения'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивные тренажеры',
    'Практика общения',
    'Работа с документами',
    'Сертификат по окончании'
  ]
};