import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_it_recruiter',
  title: 'IT-рекрутер',
  description: 'Курс для тех, кто хочет работать рекрутером в IT. За 3 месяца разберётесь в специфике отрасли, освоите полный цикл поиска и подбора специалистов.',
  school: 'yandex',
  category: 'management',
  price: 62000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fit-recruiter&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'IT рекрутинг',
    'Поиск кандидатов',
    'Проведение интервью',
    'Оценка специалистов',
    'Работа с заказчиками'
  ],
  program: [
    {
      title: 'Основы IT рекрутинга',
      topics: [
        'Специфика IT отрасли',
        'Типы IT специалистов',
        'Технический стек',
        'Рынок IT'
      ]
    },
    {
      title: 'Поиск и оценка',
      topics: [
        'Методы поиска',
        'Оценка кандидатов',
        'Проведение интервью',
        'Работа с откликами'
      ]
    },
    {
      title: 'Работа с заказчиками',
      topics: [
        'Формирование требований',
        'Воронка подбора',
        'Ведение кандидатов',
        'Закрытие вакансий'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными вакансиями',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат IT-рекрутера'
  ]
};