import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_interior_landscape',
  title: 'Профессия Дизайнер интерьеров и ландшафтной среды 2.0',
  description: 'Онлайн курс «Дизайнер интерьеров и ландшафтной среды» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на дизайнера интерьеров и ландшафтной среды с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 152484,
  oldPrice: 304967,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/qJ7uUlYarmh6nKZNNE5YSBcG8QKcmS6cg8o36Kvt.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Finterior-and-landscape&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Проектирование интерьеров',
    'Ландшафтный дизайн',
    'Работа с материалами',
    '3D-визуализация',
    'Организация пространства',
    'Работа с заказчиками'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Цветоведение',
        'Стили в дизайне'
      ]
    },
    {
      title: 'Интерьерный дизайн',
      topics: [
        'Планировочные решения',
        'Отделочные материалы',
        'Освещение',
        'Мебель и декор'
      ]
    },
    {
      title: 'Ландшафтный дизайн',
      topics: [
        'Планирование участка',
        'Озеленение',
        'Малые архитектурные формы',
        'Системы полива'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        '3D-визуализация',
        'Чертежи и планы',
        'Сметы',
        'Ведение проекта'
      ]
    }
  ],
  features: [
    'Комплексное обучение',
    'Практика на реальных проектах',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат дизайнера'
  ]
};