import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_graphic_plus',
  title: 'Онлайн-курс Графический дизайнер Plus',
  description: 'Расширенный курс по графическому дизайну. За 10 месяцев освоите профессию графического дизайнера',
  school: 'brunoyam',
  category: 'design',
  price: 124900,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fgraphic-designer-plus&m=5&erid=LdtCK4uLs',
  skills: [
    'Графический дизайн',
    'Брендинг',
    'Типографика',
    'Веб-дизайн',
    'Моушн-дизайн'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Цветоведение',
        'Типографика'
      ]
    },
    {
      title: 'Профессиональные инструменты',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe InDesign',
        'After Effects'
      ]
    },
    {
      title: 'Специализация',
      topics: [
        'Брендинг',
        'Веб-дизайн',
        'Упаковка',
        'Моушн-дизайн'
      ]
    }
  ],
  features: [
    '15 проектов в портфолио',
    'Годовая поддержка',
    'Практические задания',
    'Работа с реальными проектами',
    'Сертификат дизайнера'
  ]
};