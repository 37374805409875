import React from 'react';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-bold mb-4">О проекте</h3>
            <p className="text-gray-400">
              Обучение.online - агрегатор онлайн-курсов для развития ваших навыков и карьеры
            </p>
          </div>
          
          <div>
            <h3 className="text-white text-lg font-bold mb-4">Разделы</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/search" className="hover:text-white transition-colors">Все курсы</Link>
              </li>
              <li>
                <Link to="/blog" className="hover:text-white transition-colors">Блог</Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white text-lg font-bold mb-4">Категории</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/search?category=programming" className="hover:text-white transition-colors">Программирование</Link>
              </li>
              <li>
                <Link to="/search?category=design" className="hover:text-white transition-colors">Дизайн</Link>
              </li>
              <li>
                <Link to="/search?category=marketing" className="hover:text-white transition-colors">Маркетинг</Link>
              </li>
              <li>
                <Link to="/search?category=management" className="hover:text-white transition-colors">Управление</Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white text-lg font-bold mb-4">Контакты</h3>
            <ul className="space-y-2">
              <li>
                <a href="mailto:info@learning.online" className="hover:text-white transition-colors">
                  info@learning.online
                </a>
              </li>
              <li>
                <a href="https://t.me/learning_online" className="hover:text-white transition-colors">
                  Telegram
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
          <p>© {new Date().getFullYear()} Обучение.online. Все права защищены.</p>
        </div>
      </div>
    </footer>
  );
};