import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_identity',
  title: 'Онлайн-курс Работа с айдентикой',
  description: 'За 1,5 месяца научитесь доносить смыслы и ценности компаний через визуал. Освоите инструменты, коммуникацию с заказчиками и создадите свои первые проекты',
  school: 'brunoyam',
  category: 'design',
  price: 14900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fidentity&m=5&erid=LdtCK4uLs',
  skills: [
    'Создание айдентики',
    'Брендинг',
    'Работа с заказчиками',
    'Визуальные коммуникации',
    'Презентация проектов'
  ],
  program: [
    {
      title: 'Основы айдентики',
      topics: [
        'Теория брендинга',
        'Анализ бренда',
        'Визуальный язык',
        'Создание концепции'
      ]
    },
    {
      title: 'Разработка элементов',
      topics: [
        'Логотип',
        'Фирменные цвета',
        'Типографика',
        'Паттерны и текстуры'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с брифом',
        'Создание гайдлайнов',
        'Презентация проекта',
        'Подготовка файлов'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными брендами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};