import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_unity',
  title: 'Разработчик игр на Unity с нуля до Middle',
  description: 'Онлайн курс «Разработчик игр на Unity» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 10 месяцев. Помощь в трудоустройстве! Обучение разработке игр на Unity с нуля онлайн.',
  school: 'geekbrains',
  category: 'game-dev',
  price: 134822,
  oldPrice: 269643,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/3SBZlvnhFdrqmTZYVUQYcSSwdBB7bEijKVpgFx4V.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fprogrammer%2Funity-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Разработка на Unity',
    'Программирование на C#',
    'Создание игровых механик',
    'Работа с графикой и анимацией',
    'Оптимизация производительности',
    'Монетизация игр'
  ],
  program: [
    {
      title: 'Основы Unity',
      topics: [
        'Интерфейс Unity',
        'Основы C#',
        'GameObject и компоненты',
        'Физика в Unity'
      ]
    },
    {
      title: 'Игровые механики',
      topics: [
        'Управление персонажем',
        'Системы частиц',
        'Искусственный интеллект',
        'Взаимодействие объектов'
      ]
    },
    {
      title: 'Графика и анимация',
      topics: [
        'Работа с графикой',
        'Анимация в Unity',
        'Шейдеры',
        'Визуальные эффекты'
      ]
    },
    {
      title: 'Продвинутая разработка',
      topics: [
        'Оптимизация',
        'Монетизация',
        'Мультиплеер',
        'Публикация игр'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Портфолио из 5 игр',
    'Сертификат разработчика'
  ]
};