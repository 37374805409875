import { Course } from '../../../../types';

// Import all course modules
import { course as motionDesign } from './motion-design';
import { course as webDesign } from './web-design';
import { course as threeDMax } from './3d-max';
import { course as photoshop } from './photoshop';
import { course as corelDraw } from './corel-draw';
import { course as autocad } from './autocad';
import { course as illustrator } from './illustrator';
import { course as indesign } from './indesign';
import { course as vba } from './vba';
import { course as phpDeveloper } from './php-developer';
import { course as javaDeveloper } from './java-developer';
import { course as pythonDeveloper } from './python-developer';
import { course as excelWord } from './excel-word';
import { course as access } from './access';
import { course as visualPresentations } from './visual-presentations';
import { course as powerBi } from './power-bi';
import { course as dataScience } from './data-science';
import { course as dataAnalyst } from './data-analyst';
import { course as goDeveloper } from './go-developer';
import { course as neuralNetworks } from './neural-networks';
import { course as marketplaceManager } from './marketplace-manager';
import { course as figmaBasics } from './figma-basics';
import { course as commercialIllustration } from './commercial-illustration';
import { course as graphicDesign } from './graphic-design';
import { course as hrManager } from './hr-manager';
import { course as projectManager } from './project-manager';
import { course as reactDeveloper } from './react-developer';
import { course as qaAutomation } from './qa-automation';
import { course as timeManagement } from './time-management';
import { course as unityDeveloper } from './unity-developer';
import { course as businessAnalyst } from './business-analyst';
import { course as dataAnalystOffline } from './data-analyst-offline';
import { course as financialManagement } from './financial-management';
import { course as contextAds } from './context-ads';
import { course as emailMarketing } from './email-marketing';
import { course as trafficManager } from './traffic-manager';
import { course as targetingSpecialist } from './targeting-specialist';
import { course as excelAccountant } from './excel-accountant';
import { course as accountant } from './accountant';
import { course as chatGpt } from './chat-gpt';
import { course as aiMarketing } from './ai-marketing';
import { course as aiDesign } from './ai-design';
import { course as interiorDesigner } from './interior-designer';
import { course as copywriter } from './copywriter';
import { course as oneCAanalyst } from './1c-analyst';
import { course as linuxAdmin } from './linux-admin';
import { course as django } from './django';
import { course as telegramMarketing } from './telegram-marketing';
import { course as salesDirector } from './sales-director';
import { course as onlineCourseProducer } from './online-course-producer';
import { course as threeDMaxInterior } from './3d-max-interior';
import { course as gitBasics } from './git-basics';
import { course as learnStudy } from './learn-study';
import { course as excelBase } from './excel-base';
import { course as systemAdministrator } from './system-administrator';
import { course as blenderBasics } from './blender-basics';
import { course as afterEffectsBasics } from './after-effects-basics';
import { course as photographyBasics } from './photography-basics';
import { course as identity } from './identity';
import { course as graphicDesignerPlus } from './graphic-designer-plus';
import { course as linuxOsAdministration } from './linux-os-administration';
import { course as unityGameDevelopment } from './unity-game-development';
import { course as oneCDeveloperOnline } from './1c-developer-online';
import { course as projectManagerOnline } from './project-manager-online';
import { course as frontendDeveloper } from './frontend-developer';
import { course as pythonBasics } from './python-basics';
import { course as cppDeveloper } from './cpp-developer';
import { course as javaBasics } from './java-basics';

// Export all courses
export const brunoyamCourses: Course[] = [
  motionDesign,
  webDesign,
  threeDMax,
  photoshop,
  corelDraw,
  autocad,
  illustrator,
  indesign,
  vba,
  phpDeveloper,
  javaDeveloper,
  pythonDeveloper,
  excelWord,
  access,
  visualPresentations,
  powerBi,
  dataScience,
  dataAnalyst,
  goDeveloper,
  neuralNetworks,
  marketplaceManager,
  figmaBasics,
  commercialIllustration,
  graphicDesign,
  hrManager,
  projectManager,
  reactDeveloper,
  qaAutomation,
  timeManagement,
  unityDeveloper,
  businessAnalyst,
  dataAnalystOffline,
  financialManagement,
  contextAds,
  emailMarketing,
  trafficManager,
  targetingSpecialist,
  excelAccountant,
  accountant,
  chatGpt,
  aiMarketing,
  aiDesign,
  interiorDesigner,
  copywriter,
  oneCAanalyst,
  linuxAdmin,
  django,
  telegramMarketing,
  salesDirector,
  onlineCourseProducer,
  threeDMaxInterior,
  gitBasics,
  learnStudy,
  excelBase,
  systemAdministrator,
  blenderBasics,
  afterEffectsBasics,
  photographyBasics,
  identity,
  graphicDesignerPlus,
  linuxOsAdministration,
  unityGameDevelopment,
  oneCDeveloperOnline,
  projectManagerOnline,
  frontendDeveloper,
  pythonBasics,
  cppDeveloper,
  javaBasics
];