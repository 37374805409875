import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_chemistry',
  title: 'Годовой курс по подготовке к ЕГЭ по химии «Химфак 2024/2025»',
  description: 'Годовой курс по подготовке к ЕГЭ по химии для тех, кто хочет получить высокие баллы. Комплексная подготовка с опытными преподавателями и персональным куратором.',
  school: '100points',
  category: 'chemistry',
  price: 33890,
  oldPrice: 39490,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1603126857599-f6e157fa2fe6?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3690,
  discountPercent: 15,
  skills: [
    'Решение задач по химии',
    'Написание уравнений реакций',
    'Расчеты по формулам',
    'Органическая химия',
    'Неорганическая химия',
    'Окислительно-восстановительные реакции'
  ],
  program: [
    {
      title: 'Общая химия',
      topics: [
        'Строение атома',
        'Химическая связь',
        'Классификация веществ',
        'Основные законы химии'
      ]
    },
    {
      title: 'Неорганическая химия',
      topics: [
        'Основные классы соединений',
        'Химические реакции',
        'Растворы',
        'Электролиз'
      ]
    },
    {
      title: 'Органическая химия',
      topics: [
        'Углеводороды',
        'Кислородсодержащие соединения',
        'Азотсодержащие соединения',
        'Полимеры'
      ]
    },
    {
      title: 'Решение задач',
      topics: [
        'Расчеты по формулам',
        'Расчеты по уравнениям',
        'Окислительно-восстановительные реакции',
        'Генетическая связь'
      ]
    }
  ],
  features: [
    '3 веба в неделю',
    'Проверка домашних заданий',
    'Разбор сложных тем',
    'Пробные экзамены',
    'Психологическая поддержка'
  ]
};