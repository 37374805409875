import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_go',
  title: 'Go-разработчик',
  description: 'За 3 месяца изучите язык программирования Go. Интенсивный формат — вебинары, воркшопы, видеоматериалы, практические задания.',
  school: 'brunoyam',
  category: 'programming',
  price: 49900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1600132806370-bf17e65e942f?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fgo&m=5&erid=LdtCK4uLs',
  skills: [
    'Golang',
    'Микросервисы',
    'REST API',
    'gRPC',
    'Docker',
    'Тестирование'
  ],
  program: [
    {
      title: 'Основы Go',
      topics: [
        'Синтаксис Go',
        'Типы данных',
        'Горутины',
        'Каналы'
      ]
    },
    {
      title: 'Веб-разработка',
      topics: [
        'HTTP сервер',
        'REST API',
        'gRPC',
        'Микросервисы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с БД',
        'Тестирование',
        'Docker',
        'CI/CD'
      ]
    }
  ],
  features: [
    '72 академических часа',
    '72 занятия',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};