import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_work_life_balance',
  title: 'Work-life balance',
  description: 'Практический курс для тех, кто стремится к балансу между работой и личной жизнью. Поможем справиться с выгоранием и восстановить личностные ресурсы.',
  school: 'netology',
  category: 'psychology',
  price: 4440,
  oldPrice: 7400,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1506126613408-eca07ce68773?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fwork-life-balance&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4440,
  skills: [
    'Управление стрессом',
    'Баланс работы и жизни',
    'Профилактика выгорания',
    'Самоорганизация',
    'Восстановление ресурсов'
  ],
  program: [
    {
      title: 'Основы баланса',
      topics: [
        'Диагностика состояния',
        'Работа со стрессом',
        'Границы',
        'Ресурсные состояния'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Техники релаксации',
        'Планирование времени',
        'Работа с энергией',
        'Профилактика выгорания'
      ]
    }
  ],
  features: [
    '10 практических занятий',
    'Техники релаксации',
    'Индивидуальные консультации',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};