import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_blender',
  title: 'Blender с нуля',
  description: 'За 2 месяца научитесь работать с 3D в Blender. Поработаете с графикой для кино, игр и рекламы',
  school: 'brunoyam',
  category: 'design',
  price: 24900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fblender&m=5&erid=LdtCK4uLs',
  skills: [
    '3D моделирование',
    'Анимация',
    'Текстурирование',
    'Рендеринг',
    'Скульптинг'
  ],
  program: [
    {
      title: 'Основы Blender',
      topics: [
        'Интерфейс программы',
        'Базовое моделирование',
        'Работа с объектами',
        'Модификаторы'
      ]
    },
    {
      title: 'Материалы и текстуры',
      topics: [
        'PBR материалы',
        'UV-развертка',
        'Текстурирование',
        'Nodes Editor'
      ]
    },
    {
      title: 'Анимация и рендер',
      topics: [
        'Основы анимации',
        'Освещение',
        'Настройка рендера',
        'Композитинг'
      ]
    }
  ],
  features: [
    '3 проекта в портфолио',
    'Практические задания',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};