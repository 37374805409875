import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_fgm',
  title: 'Курс Figma',
  description: 'Базовый курс по Figma. Освойте современный инструмент для создания интерфейсов, прототипов и совместной работы над дизайн-проектами.',
  school: 'contented',
  category: 'software',
  price: 21420,
  oldPrice: 30600,
  currency: 'RUR',
  duration: '2 месяца',
  imageUrl: 'https://thumb.tildacdn.com/tild6330-6438-4565-b432-386337623666/-/resize/900x/-/format/webp/Figma.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Ffigma%2F&m=5&erid=LdtCKHL5u',
  discountPercent: 30,
  skills: [
    'Создание интерфейсов',
    'Работа с компонентами',
    'Построение дизайн-систем',
    'Прототипирование',
    'Совместная работа',
    'Auto-layout и констрейнты'
  ],
  program: [
    {
      title: 'Основы Figma',
      topics: [
        'Интерфейс программы',
        'Инструменты рисования',
        'Работа с фреймами',
        'Стили и цвета'
      ]
    },
    {
      title: 'Компоненты и библиотеки',
      topics: [
        'Создание компонентов',
        'Варианты компонентов',
        'Библиотеки компонентов',
        'Auto-layout'
      ]
    },
    {
      title: 'Прототипирование',
      topics: [
        'Создание связей',
        'Анимация переходов',
        'Интерактивные компоненты',
        'Презентация прототипа'
      ]
    },
    {
      title: 'Командная работа',
      topics: [
        'Организация файлов',
        'Совместное редактирование',
        'Комментарии и фидбек',
        'Версионирование'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа над реальными проектами',
    'Доступ к учебным материалам',
    'Сертификат о прохождении курса'
  ]
};