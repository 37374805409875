import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_motion',
  title: 'Профессия Motion-дизайнер 2.0',
  description: 'Онлайн курс «Моушн-дизайнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на моушн-дизайнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 140007,
  oldPrice: 280013,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/xgk9MVxAtAXT5yhSUAptcHKWHT8UiCnZjJX9ck2n.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Fmotion-spec-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Создание анимации',
    'Работа с After Effects',
    'Cinema 4D',
    'Композитинг',
    'Создание визуальных эффектов',
    'Работа со звуком'
  ],
  program: [
    {
      title: 'Основы анимации',
      topics: [
        'Принципы анимации',
        'Таймлайн и кейфреймы',
        'Графики движения',
        'Основы композиции'
      ]
    },
    {
      title: 'After Effects',
      topics: [
        'Интерфейс программы',
        'Работа со слоями',
        'Эффекты и пресеты',
        'Экспрессии'
      ]
    },
    {
      title: 'Cinema 4D',
      topics: [
        '3D моделирование',
        'Текстурирование',
        'Анимация в 3D',
        'Рендеринг'
      ]
    },
    {
      title: 'Продвинутые техники',
      topics: [
        'Композитинг',
        'Цветокоррекция',
        'Трекинг',
        'Постпродакшн'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Доступ к онлайн-платформе',
    'Сертификат о профессиональной переподготовке'
  ]
};