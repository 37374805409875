import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_power_bi',
  title: 'Офлайн-курс Power BI',
  description: 'За 3 недели научитесь собирать, анализировать и визуализировать информацию для решения бизнес-задач. Офлайн-занятия в мини-группе, проектная работа с личным наставником и доступ к материалам онлайн-курса.',
  school: 'brunoyam',
  category: 'analytics',
  price: 19900,
  currency: 'RUB',
  duration: '28 недель',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-analitika-dannyh%2Fpower-bi&m=5&erid=LdtCK4uLs',
  skills: [
    'Power BI',
    'Визуализация данных',
    'Анализ данных',
    'DAX',
    'Power Query'
  ],
  program: [
    {
      title: 'Основы Power BI',
      topics: [
        'Интерфейс Power BI',
        'Загрузка данных',
        'Power Query',
        'Моделирование данных'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Типы визуализаций',
        'Настройка графиков',
        'Форматирование',
        'Интерактивность'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'DAX формулы',
        'Меры и вычисления',
        'Анализ данных',
        'Публикация отчетов'
      ]
    }
  ],
  features: [
    '24 академических часа',
    '56 занятий',
    'Практические задания',
    'Работа с реальными данными',
    'Сертификат по окончании'
  ]
};