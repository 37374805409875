import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_cbt_2',
  title: 'Практические навыки когнитивно-поведенческой терапии (ступень 2)',
  description: 'Дистанционное обучение по программе Практические навыки когнитивно-поведенческой терапии (ступень 2)(38 часов) в ЦАППКК. Углубленное изучение техник КПТ для работы со сложными случаями.',
  school: 'appkk',
  category: 'psychology',
  price: 15100,
  currency: 'RUR',
  duration: '38 часов',
  imageUrl: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_kognitivno_povedencheskoy_terapii_stupen_2_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Работа с депрессией',
    'Терапия зависимостей',
    'Управление стрессом',
    'Социальные навыки',
    'Работа с ПТСР'
  ],
  program: [
    {
      title: 'Депрессия и зависимости',
      topics: [
        'Модель депрессии',
        'Техники активации',
        'Работа с зависимостями',
        'Профилактика рецидивов'
      ]
    },
    {
      title: 'Стресс и тревога',
      topics: [
        'Управление стрессом',
        'Социальная тревога',
        'Ассертивность',
        'Релаксация'
      ]
    },
    {
      title: 'ПТСР',
      topics: [
        'Диагностика ПТСР',
        'Техники стабилизации',
        'Работа с травмой',
        'EMDR'
      ]
    }
  ],
  features: [
    'Углубленные техники',
    'Разбор сложных случаев',
    'Групповая супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};