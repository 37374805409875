import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_middle_frontend',
  title: 'Мидл фронтенд-разработчик',
  description: 'Продвинутый курс для тех, кто уверенно чувствует себя в HTML, CSS и JavaScript и хочет вырасти до мидла за 5 месяцев.',
  school: 'yandex',
  category: 'programming',
  price: 103000,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1593720213428-28a5b9e94613?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmiddle-frontend&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продвинутый JavaScript',
    'TypeScript',
    'React',
    'Архитектура приложений',
    'Оптимизация производительности'
  ],
  program: [
    {
      title: 'Продвинутый JavaScript',
      topics: [
        'Event Loop',
        'Асинхронность',
        'Паттерны',
        'Тестирование'
      ]
    },
    {
      title: 'Современные технологии',
      topics: [
        'TypeScript',
        'React Hooks',
        'Redux Toolkit',
        'GraphQL'
      ]
    },
    {
      title: 'Архитектура и оптимизация',
      topics: [
        'Архитектурные паттерны',
        'Производительность',
        'SSR',
        'PWA'
      ]
    }
  ],
  features: [
    'Сложные проекты',
    'Code review',
    'Персональный наставник',
    'Помощь в карьерном росте',
    'Сертификат Middle разработчика'
  ]
};