import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_qa_automation_java',
  title: 'Автоматизатор тестирования на Java',
  description: 'Изучите юнит-тестирование, тестирование API, инфраструктуру и архитектуру, Selenide, базы данных, JUnit 5',
  school: 'yandex',
  category: 'programming',
  price: 98000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fqa-automation-engineer-java&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Java для тестирования',
    'Автоматизация тестирования',
    'Selenide',
    'JUnit 5',
    'REST API тестирование'
  ],
  program: [
    {
      title: 'Основы Java',
      topics: [
        'Синтаксис Java',
        'ООП',
        'Коллекции',
        'Исключения'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'JUnit 5',
        'Selenide',
        'Page Object',
        'REST Assured'
      ]
    },
    {
      title: 'Инфраструктура',
      topics: [
        'Git',
        'CI/CD',
        'Docker',
        'Allure'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о профессии'
  ]
};