import React from 'react';
import { Hero } from '../components/Hero';
import { Statistics } from '../components/Statistics';
import { CategoryGroups } from '../components/CategoryGroups';
import { CategoryCarousel } from '../components/CategoryCarousel';
import { useCourseStore } from '../store/courseStore';

// Function to shuffle array
const shuffleArray = <T extends unknown>(array: T[]): T[] => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export const HomePage: React.FC = () => {
  const { courses } = useCourseStore();

  const programmingCourses = shuffleArray(courses.filter(course => course.category === 'programming'));
  const marketingCourses = shuffleArray(courses.filter(course => course.category === 'marketing'));
  const designCourses = shuffleArray(courses.filter(course => course.category === 'design'));
  const analyticsCourses = shuffleArray(courses.filter(course => course.category === 'analytics'));
  const managementCourses = shuffleArray(courses.filter(course => course.category.includes('management')));
  const languageCourses = shuffleArray(courses.filter(course => ['english', 'german', 'french', 'chinese', 'spanish', 'italian', 'portuguese'].includes(course.category)));
  const businessCourses = shuffleArray(courses.filter(course => course.category === 'business' || course.category === 'finance'));
  const schoolCourses = shuffleArray(courses.filter(course => ['math', 'physics', 'chemistry', 'biology', 'history', 'literature', 'russian', 'social', 'geography'].includes(course.category)));

  return (
    <>
      <div className="bg-gradient-to-br from-blue-600 via-blue-700 to-indigo-800">
        <Hero />
        <div className="max-w-7xl mx-auto px-4 pb-12">
          <Statistics />
        </div>
      </div>
      <main className="max-w-7xl mx-auto px-4 py-8">
        <CategoryGroups />
        <div className="space-y-12">
          {programmingCourses.length > 0 && (
            <div id="category-programming">
              <CategoryCarousel
                title="Программирование"
                courses={programmingCourses}
                courseCount={programmingCourses.length}
              />
            </div>
          )}
          {marketingCourses.length > 0 && (
            <div id="category-marketing">
              <CategoryCarousel
                title="Маркетинг"
                courses={marketingCourses}
                courseCount={marketingCourses.length}
              />
            </div>
          )}
          {designCourses.length > 0 && (
            <div id="category-design">
              <CategoryCarousel
                title="Дизайн"
                courses={designCourses}
                courseCount={designCourses.length}
              />
            </div>
          )}
          {analyticsCourses.length > 0 && (
            <div id="category-analytics">
              <CategoryCarousel
                title="Аналитика"
                courses={analyticsCourses}
                courseCount={analyticsCourses.length}
              />
            </div>
          )}
          {managementCourses.length > 0 && (
            <div id="category-management">
              <CategoryCarousel
                title="Управление"
                courses={managementCourses}
                courseCount={managementCourses.length}
              />
            </div>
          )}
          {languageCourses.length > 0 && (
            <div id="category-languages">
              <CategoryCarousel
                title="Языки"
                courses={languageCourses}
                courseCount={languageCourses.length}
              />
            </div>
          )}
          {businessCourses.length > 0 && (
            <div id="category-business">
              <CategoryCarousel
                title="Бизнес"
                courses={businessCourses}
                courseCount={businessCourses.length}
              />
            </div>
          )}
          {schoolCourses.length > 0 && (
            <div id="category-school">
              <CategoryCarousel
                title="Для школы"
                courses={schoolCourses}
                courseCount={schoolCourses.length}
              />
            </div>
          )}
        </div>
      </main>
    </>
  );
};