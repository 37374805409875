import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_graphic',
  title: 'Профессия Графический дизайнер 2.0',
  description: 'Онлайн курс «Графический дизайнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 10 месяцев. Помощь в трудоустройстве! Обучение на графического дизайнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 139375,
  oldPrice: 278749,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/DUmF1V8cFrNQg5yvmQdv3XTPcPGeM5NyE29CuYfU.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Fgraphic-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Работа в Adobe Creative Suite',
    'Создание фирменного стиля',
    'Разработка логотипов',
    'Дизайн полиграфии',
    'Типографика',
    'Веб-графика',
    'Подготовка к печати'
  ],
  program: [
    {
      title: 'Основы графического дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Цветоведение',
        'Типографика'
      ]
    },
    {
      title: 'Инструменты дизайнера',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe InDesign',
        'Figma'
      ]
    },
    {
      title: 'Брендинг и айдентика',
      topics: [
        'Разработка логотипа',
        'Фирменный стиль',
        'Брендбук',
        'Упаковка'
      ]
    },
    {
      title: 'Практика и портфолио',
      topics: [
        'Работа над проектами',
        'Подготовка к печати',
        'Создание портфолио',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в создании портфолио',
    'Стажировка в дизайн-студии',
    'Сертификат графического дизайнера'
  ]
};