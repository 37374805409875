import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_communication',
  title: 'Практические навыки по психологии общения с клиентами в работе психолога-консультанта',
  description: 'Дистанционное обучение в формате онлайн-практикума по программе Психология общения с клиентами в работе психолога-консультанта (52 ч.), программа 2024 года.',
  school: 'appkk',
  category: 'psychology',
  price: 24900,
  currency: 'RUR',
  duration: '52 часа',
  imageUrl: 'https://images.unsplash.com/photo-1573497620053-ea5300f94f21?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_po_psikhologii_obshcheniya_s_klientami-pk%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Эффективная коммуникация',
    'Работа с сопротивлением',
    'Техники активного слушания',
    'Управление беседой',
    'Работа с эмоциями'
  ],
  program: [
    {
      title: 'Основы коммуникации',
      topics: [
        'Преодоление ограничений',
        'Техники присоединения',
        'Активное слушание',
        'Работа с запросом'
      ]
    },
    {
      title: 'Практические навыки',
      topics: [
        'Техники вопросов',
        'Работа с эмоциями',
        'Управление диалогом',
        'Обратная связь'
      ]
    },
    {
      title: 'Сложные ситуации',
      topics: [
        'Работа с сопротивлением',
        'Конфликтные ситуации',
        'Трудные клиенты',
        'Профессиональные границы'
      ]
    }
  ],
  features: [
    'Практические упражнения',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};