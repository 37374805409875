import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_photographer',
  title: 'Профессия Фотограф',
  description: 'Онлайн курс «Фотограф» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 14 месяцев. Помощь в трудоустройстве! Обучение на фотографа с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 96147,
  oldPrice: 192294,
  currency: 'RUB',
  duration: '14 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/7jzSxC9nZUvaCOjosvfyLBW1SSxjWLGyPfLxVQOV.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Fphotographer-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Фотосъемка',
    'Работа со светом',
    'Обработка фотографий',
    'Композиция',
    'Портретная съемка',
    'Коммерческая фотография'
  ],
  program: [
    {
      title: 'Основы фотографии',
      topics: [
        'Устройство камеры',
        'Экспозиция',
        'Композиция',
        'Работа со светом'
      ]
    },
    {
      title: 'Жанры фотографии',
      topics: [
        'Портретная съемка',
        'Предметная съемка',
        'Репортажная съемка',
        'Пейзажная съемка'
      ]
    },
    {
      title: 'Обработка фотографий',
      topics: [
        'Adobe Lightroom',
        'Adobe Photoshop',
        'Цветокоррекция',
        'Ретушь'
      ]
    },
    {
      title: 'Коммерческая съемка',
      topics: [
        'Работа с клиентами',
        'Ценообразование',
        'Продвижение услуг',
        'Организация съемок'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат фотографа'
  ]
};