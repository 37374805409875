import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_git',
  title: 'Основы работы в Git',
  description: 'Бесплатный курс. За 2 недели поможем освоить популярную систему контроля версий файлов',
  school: 'brunoyam',
  category: 'programming',
  price: 0,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1618401471353-b98afee0b2eb?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fgit-basics&m=5&erid=LdtCK4uLs',
  skills: [
    'Основы Git',
    'Работа с репозиториями',
    'Ветвление',
    'Командная работа',
    'Разрешение конфликтов'
  ],
  program: [
    {
      title: 'Введение в Git',
      topics: [
        'Установка и настройка',
        'Базовые команды',
        'Создание репозитория',
        'Коммиты'
      ]
    },
    {
      title: 'Работа с ветками',
      topics: [
        'Создание веток',
        'Слияние веток',
        'Разрешение конфликтов',
        'Работа с удаленным репозиторием'
      ]
    },
    {
      title: 'Командная работа',
      topics: [
        'Git Flow',
        'Code Review',
        'Pull Requests',
        'Совместная разработка'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с GitHub',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};