import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_mobile_designer',
  title: 'Дизайнер мобильных приложений',
  description: 'Онлайн-курс «Дизайнер мобильных приложений» — Нетология: Пройдите обучение созданию UX/UI-дизайна для мобильных приложений с нуля. Освойте Figma, Photoshop и анимацию интерфейсов, создайте проекты для портфолио и получите диплом. Начните карьеру в мобильном дизайне!',
  school: 'netology',
  category: 'design',
  price: 80619,
  oldPrice: 115170,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdizajner-mobilnyh-prilozhenij&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 3359,
  skills: [
    'UX/UI дизайн',
    'Мобильные интерфейсы',
    'Прототипирование',
    'Анимация интерфейсов',
    'Работа с Figma'
  ],
  program: [
    {
      title: 'Основы мобильного дизайна',
      topics: [
        'Принципы UI/UX',
        'Мобильные паттерны',
        'Архитектура приложений',
        'Прототипирование'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Figma',
        'Adobe Photoshop',
        'Protopie',
        'Principle'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание интерфейсов',
        'Анимация и микроанимация',
        'Тестирование',
        'Подготовка макетов'
      ]
    }
  ],
  features: [
    '44 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};