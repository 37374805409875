import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_mobile_design_self',
  title: 'Дизайн мобильных и кросс-платформенных приложений. Самостоятельный',
  description: 'На курсе вы погрузитесь в проектирование мобильных приложений, изучите гайдлайны iOS и Android и научитесь адаптировать интерфейс для смартфонов и планшетов, десктопа, ТВ, умных часов и других устройств. Вы пройдёте весь цикл разработки дизайна: от прототипирования до презентации проекта. В конце сможете оформить кейс в портфолио.',
  school: 'yandex',
  category: 'design',
  price: 48000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmobile-app-design%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Мобильный дизайн',
    'Кросс-платформенный дизайн',
    'Прототипирование',
    'UI/UX дизайн',
    'Работа с гайдлайнами'
  ],
  program: [
    {
      title: 'Основы мобильного дизайна',
      topics: [
        'Особенности платформ',
        'Гайдлайны iOS и Android',
        'Паттерны взаимодействия',
        'Адаптивный дизайн'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Прототипирование',
        'Компонентный подход',
        'Дизайн-система',
        'Тестирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание приложения',
        'Кросс-платформенность',
        'Анимация интерфейсов',
        'Подготовка макетов'
      ]
    }
  ],
  features: [
    'Самостоятельное обучение',
    'Практические проекты',
    'Доступ к материалам',
    'Работа над портфолио',
    'Сертификат дизайнера'
  ]
};