import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_sexology_full',
  title: 'Сексология в психологическом консультировании',
  description: 'Дистанционное обучение по программе Сексология в психологическом консультировании(600 часов) в ЦАППКК. Комплексная подготовка специалистов в области сексологического консультирования.',
  school: 'appkk',
  category: 'psychology',
  price: 39000,
  currency: 'RUR',
  duration: '600 часов',
  imageUrl: 'https://images.unsplash.com/photo-1516534775068-ba3e7458af70?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fseksologiya_v_psikhologicheskom_konsultirovanii_pp%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Сексологическое консультирование',
    'Семейная терапия',
    'Работа с дисфункциями',
    'Сексуальное образование',
    'Психотерапевтические техники'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'Основы сексологии',
        'Психосексуальное развитие',
        'Сексуальная норма и патология',
        'Гендерная идентичность'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Диагностика проблем',
        'Консультирование пар',
        'Работа с дисфункциями',
        'Сексуальное образование'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Работа с травмой',
        'Семейная терапия',
        'Профилактика проблем',
        'Этические вопросы'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};