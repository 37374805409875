import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_educational_psychology',
  title: 'Педагог-психолог в сфере образования. Преподаватель психологии',
  description: 'Дистанционное обучение по программе Педагог-психолог в сфере образования. Преподаватель психологии с присвоением квалификации Педагог-психолог. Преподаватель психологии(1090 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 40300,
  currency: 'RUR',
  duration: '1090 часов',
  imageUrl: 'https://images.unsplash.com/photo-1509062522246-3755977927d7?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpedagog_psikholog_v_sfere_obrazovaniya_prepodavatel_psikhologii_s_prisvoeniem_kvalifikatsii_pedagog_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Педагогическая психология',
    'Преподавание психологии',
    'Психодиагностика',
    'Консультирование',
    'Развивающая работа'
  ],
  program: [
    {
      title: 'Основы педагогической психологии',
      topics: [
        'Психология обучения',
        'Возрастная психология',
        'Педагогическая психология',
        'Методика преподавания'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Психодиагностика',
        'Консультирование',
        'Коррекционная работа',
        'Профилактика'
      ]
    },
    {
      title: 'Преподавание психологии',
      topics: [
        'Методика преподавания',
        'Разработка программ',
        'Оценка знаний',
        'Работа с группой'
      ]
    }
  ],
  features: [
    'Практические навыки',
    'Разработка программ',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};