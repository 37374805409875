import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_counseling_skills',
  title: 'Практические навыки по психологическому консультированию. Ступень 1',
  description: 'Дистанционное обучение по программе Практические навыки по психологическому консультированию. Ступень 1(44 часа) в ЦАППКК. Освоение базовых навыков психологического консультирования.',
  school: 'appkk',
  category: 'psychology',
  price: 24300,
  currency: 'RUR',
  duration: '44 часа',
  imageUrl: 'https://images.unsplash.com/photo-1553272725-086100aecf5e?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_po_psikhologicheskomu_konsultirovaniyu_stupen_1%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Ведение консультации',
    'Работа с запросом',
    'Психодиагностика',
    'Построение контакта',
    'Работа с сопротивлением'
  ],
  program: [
    {
      title: 'Основы консультирования',
      topics: [
        'Структура консультации',
        'Этапы работы',
        'Методы консультирования',
        'Работа с запросом'
      ]
    },
    {
      title: 'Практические навыки',
      topics: [
        'Техники слушания',
        'Работа с эмоциями',
        'Постановка целей',
        'Обратная связь'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Работа с кризисами',
        'Травматические состояния',
        'Семейные проблемы',
        'Профессиональное выгорание'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};