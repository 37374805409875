import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_logistics',
  title: 'Логистика и управление цепями поставок',
  description: 'Дистанционное обучение по программе 2024 года Логистика и управление цепями поставок с присвоением квалификации Менеджер по логистике.',
  school: 'appkk',
  category: 'management',
  price: 32200,
  currency: 'RUR',
  duration: '650 часов',
  imageUrl: 'https://images.unsplash.com/photo-1586528116311-ad8dd3c8310d?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Flogistika_i_upravlenie_tsepyami_postavok_s_prisvoeniem_kvalifikatsii_menedzher_po_logistike_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Управление поставками',
    'Складская логистика',
    'Транспортная логистика',
    'Управление запасами',
    'Оптимизация процессов'
  ],
  program: [
    {
      title: 'Основы логистики',
      topics: [
        'Теория логистики',
        'Виды логистики',
        'Цепи поставок',
        'Логистические системы'
      ]
    },
    {
      title: 'Практические аспекты',
      topics: [
        'Управление складом',
        'Транспортировка',
        'Таможенное оформление',
        'Документооборот'
      ]
    },
    {
      title: 'Управление процессами',
      topics: [
        'Планирование поставок',
        'Оптимизация маршрутов',
        'Контроль качества',
        'Управление рисками'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор кейсов',
    'Работа с документами',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};