import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_marketplace',
  title: 'Онлайн-курс Менеджер маркетплейсов',
  description: 'Научитесь работать на популярных площадках —Wildberries, Озон, AliExpress, Мегамаркет, Яндекс Маркет, Lamoda.',
  school: 'brunoyam',
  category: 'marketing',
  price: 34900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fmarketplace-manager&m=5&erid=LdtCK4uLs',
  skills: [
    'Работа с маркетплейсами',
    'Аналитика продаж',
    'Управление товарами',
    'Оптимизация карточек',
    'Реклама на площадках'
  ],
  program: [
    {
      title: 'Основы работы',
      topics: [
        'Регистрация на площадках',
        'Настройка магазина',
        'Работа с каталогом',
        'Управление остатками'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'SEO-оптимизация',
        'Реклама',
        'Акции и скидки',
        'Работа с отзывами'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'Анализ продаж',
        'Работа с отчетами',
        'Оптимизация процессов',
        'Прогнозирование'
      ]
    }
  ],
  features: [
    '12 проектов',
    'Практические задания',
    'Работа с площадками',
    'Персональный наставник',
    'Сертификат по окончании'
  ]
};