import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_data_science',
  title: 'Офлайн-курс Data Science',
  description: 'Практический курс по Data Science. 2 месяца офлайн-занятий в мини-группе. 2 месяца работы по двум большим проектам: анализ данных и машинное обучение. Все это вместе с личным наставником',
  school: 'brunoyam',
  category: 'analytics',
  price: 59900,
  currency: 'RUB',
  duration: '84 недели',
  imageUrl: 'https://images.unsplash.com/photo-1509228627152-72ae9ae6848d?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-analitika-dannyh%2Fdata-science&m=5&erid=LdtCK4uLs',
  skills: [
    'Python для DS',
    'Машинное обучение',
    'Анализ данных',
    'Визуализация',
    'Статистика',
    'Deep Learning'
  ],
  program: [
    {
      title: 'Основы Data Science',
      topics: [
        'Python для DS',
        'Pandas и NumPy',
        'Визуализация данных',
        'Статистика'
      ]
    },
    {
      title: 'Машинное обучение',
      topics: [
        'Классификация',
        'Регрессия',
        'Кластеризация',
        'Оценка моделей'
      ]
    },
    {
      title: 'Deep Learning',
      topics: [
        'Нейронные сети',
        'Computer Vision',
        'NLP',
        'Практические проекты'
      ]
    }
  ],
  features: [
    '76 академических часов',
    '116 занятий',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};