import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useCourseStore } from '../store/courseStore';
import { CategoryCarousel } from '../components/CategoryCarousel';
import { Course } from '../types';

export const SchoolPage: React.FC = () => {
  const { schoolId } = useParams<{ schoolId: string }>();
  const { schools, courses, categories } = useCourseStore();
  
  const school = schools.find(s => s.id === schoolId);
  const schoolCourses = courses.filter(course => course.school === schoolId);

  if (!school) {
    return <Navigate to="/" replace />;
  }

  // Group courses by category
  const coursesByCategory = schoolCourses.reduce((acc, course) => {
    const category = categories.find(c => c.id === course.category);
    if (category) {
      if (!acc[category.id]) {
        acc[category.id] = {
          category,
          courses: []
        };
      }
      acc[category.id].courses.push(course);
    }
    return acc;
  }, {} as Record<string, { category: typeof categories[0], courses: Course[] }>);

  // Sort categories by number of courses
  const sortedCategories = Object.values(coursesByCategory).sort((a, b) => 
    b.courses.length - a.courses.length
  );
  
  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
          <div className="mb-8">
            <h1 className="text-2xl font-bold mb-2">{school.name}</h1>
            <p className="text-gray-600">{school.description}</p>
          </div>
          
          <div className="space-y-12">
            {sortedCategories.map(({ category, courses }) => (
              <CategoryCarousel
                key={category.id}
                title={category.name}
                courses={courses}
                courseCount={courses.length}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};