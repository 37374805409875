import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_algorithms',
  title: 'Алгоритмы и структуры данных',
  description: 'Курс для тех, кто уже знает C++, Python, Java, Go или C#. Научитесь быстрее писать код, видеть варианты решения задач и проходить алгоритмическое интервью.',
  school: 'yandex',
  category: 'programming',
  price: 86000,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Falgorithms%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Алгоритмы',
    'Структуры данных',
    'Оптимизация кода',
    'Решение задач',
    'Алгоритмические интервью'
  ],
  program: [
    {
      title: 'Базовые алгоритмы',
      topics: [
        'Сложность алгоритмов',
        'Сортировки',
        'Поиск',
        'Рекурсия'
      ]
    },
    {
      title: 'Структуры данных',
      topics: [
        'Массивы и списки',
        'Деревья',
        'Хеш-таблицы',
        'Графы'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Динамическое программирование',
        'Жадные алгоритмы',
        'Строковые алгоритмы',
        'Геометрические алгоритмы'
      ]
    }
  ],
  features: [
    'Практические задачи',
    'Разбор решений',
    'Персональный наставник',
    'Подготовка к собеседованиям',
    'Сертификат о прохождении'
  ]
};