import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_python_dev',
  title: 'Python-разработчик: расширенный курс',
  description: 'Расширенный курс «Python-разработчик» от Нетологии: освоите Python на продвинутом уровне с упором на практику. Вас ждут 22 проекта, хакатоны и реальные задачи от партнёра ГК Самолет. Получите ценные навыки для успешной карьеры в IT с поддержкой экспертов.',
  school: 'netology',
  category: 'programming',
  price: 137040,
  oldPrice: 228400,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fpython&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3806,
  skills: [
    'Python Core',
    'Django Framework',
    'REST API',
    'Базы данных',
    'Git',
    'Тестирование'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис Python',
        'ООП',
        'Работа с файлами',
        'Структуры данных'
      ]
    },
    {
      title: 'Веб-разработка',
      topics: [
        'Django',
        'REST API',
        'Базы данных',
        'Тестирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Хакатоны',
        'Реальные задачи',
        'Code Review'
      ]
    }
  ],
  features: [
    '22 проекта в портфолио',
    'Хакатоны',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};