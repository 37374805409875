import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_math_base',
  title: 'Курс по подготовке База отдыха 2024/2025 | Базовая математика',
  description: 'Курс по базовой математике "База отдыха" подготовит тебя на 5 без стресса и паники. Присоединиться к курсу можно в любой день.',
  school: '100points',
  category: 'math',
  price: 2190,
  oldPrice: 2190,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1596495577886-d920f1fb7238?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  skills: [
    'Базовые математические навыки',
    'Решение типовых задач',
    'Работа с формулами',
    'Геометрические задачи',
    'Практические задачи',
    'Подготовка к экзамену'
  ],
  program: [
    {
      title: 'Числа и вычисления',
      topics: [
        'Действия с числами',
        'Проценты и дроби',
        'Степени и корни',
        'Округление чисел'
      ]
    },
    {
      title: 'Алгебраические выражения',
      topics: [
        'Формулы сокращенного умножения',
        'Линейные уравнения',
        'Квадратные уравнения',
        'Системы уравнений'
      ]
    },
    {
      title: 'Функции и графики',
      topics: [
        'Линейная функция',
        'Квадратичная функция',
        'Чтение графиков',
        'Построение графиков'
      ]
    },
    {
      title: 'Геометрия',
      topics: [
        'Треугольники',
        'Четырехугольники',
        'Окружность',
        'Площади фигур'
      ]
    }
  ],
  features: [
    '22 записанных урока',
    'Гибкий график обучения',
    'Дежурный куратор',
    'Три пробных экзамена',
    'Доступ к материалам до экзамена'
  ]
};