import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_traffic_manager',
  title: 'Трафик-менеджер',
  description: 'Изучите весь цикл запуска интернет-рекламы в Яндекс Директе, VK Рекламе и Google Ads: от анализа бизнеса до ретаргетинга.',
  school: 'yandex',
  category: 'marketing',
  price: 91000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Ftraffic-manager&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление рекламой',
    'Настройка кампаний',
    'Аналитика трафика',
    'Оптимизация бюджета',
    'Ретаргетинг'
  ],
  program: [
    {
      title: 'Основы трафик-менеджмента',
      topics: [
        'Анализ бизнеса',
        'Настройка рекламных кабинетов',
        'Создание кампаний',
        'Таргетинг'
      ]
    },
    {
      title: 'Работа с площадками',
      topics: [
        'Яндекс Директ',
        'VK Реклама',
        'Google Ads',
        'Ретаргетинг'
      ]
    },
    {
      title: 'Оптимизация и аналитика',
      topics: [
        'Анализ эффективности',
        'A/B тестирование',
        'Оптимизация кампаний',
        'Отчетность'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными кампаниями',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};