import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_unreal',
  title: 'Профессия Разработчик игр на Unreal Engine 5 с нуля',
  description: 'Онлайн курс «Разработчик игр на Unreal Engine» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение разработке игр на Unreal Engine с нуля онлайн.',
  school: 'geekbrains',
  category: 'game-dev',
  price: 121891,
  oldPrice: 243781,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/8jrgkFOGwBcdJXwPXDzFLRZyXXGwBPeDDkvXH185.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fprogrammer%2Funrealengine-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Разработка на Unreal Engine 5',
    'Программирование на C++',
    'Создание игровых механик',
    'Работа с Blueprint',
    'Оптимизация производительности',
    'Создание мультиплеера'
  ],
  program: [
    {
      title: 'Основы Unreal Engine',
      topics: [
        'Интерфейс движка',
        'Blueprint система',
        'Физика и коллизии',
        'Материалы и текстуры'
      ]
    },
    {
      title: 'Программирование',
      topics: [
        'C++ для UE5',
        'Игровые механики',
        'AI и поведение',
        'Оптимизация кода'
      ]
    },
    {
      title: 'Геймплей',
      topics: [
        'Системы сохранения',
        'Пользовательский интерфейс',
        'Анимация персонажей',
        'Звук в играх'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Мультиплеер',
        'Сетевое взаимодействие',
        'Оптимизация игры',
        'Публикация проекта'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Портфолио из 3-4 игр',
    'Сертификат разработчика'
  ]
};