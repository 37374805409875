import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_interior',
  title: 'Профессия Дизайнер интерьеров',
  description: 'Онлайн курс «Дизайнер интерьеров» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 12 месяцев. Помощь в трудоустройстве! Обучение на дизайнера интерьеров с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 139375,
  oldPrice: 278749,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/sO4QDnEkuEk4ywAWdA8yo6nGuIQogIZ4kbbAlJzL.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Finterior-spec-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Проектирование интерьеров',
    'Работа с планировками',
    'Подбор материалов',
    '3D-визуализация',
    'Работа со светом',
    'Ведение проектов'
  ],
  program: [
    {
      title: 'Основы дизайна интерьера',
      topics: [
        'История стилей',
        'Композиция',
        'Цветоведение',
        'Эргономика'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Обмерный план',
        'Планировочные решения',
        'Строительные чертежи',
        'Визуализация'
      ]
    },
    {
      title: 'Материалы и технологии',
      topics: [
        'Отделочные материалы',
        'Мебель и декор',
        'Освещение',
        'Инженерные системы'
      ]
    },
    {
      title: 'Ведение проектов',
      topics: [
        'Работа с заказчиками',
        'Смета и документация',
        'Авторский надзор',
        'Реализация проекта'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Индивидуальные консультации',
    'Помощь в создании портфолио',
    'Стажировка в дизайн-студии',
    'Сертификат дизайнера интерьеров'
  ]
};