import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sysadmin',
  title: 'Системный администратор',
  description: 'Курс «Системный администратор» от Нетологии: изучите углубленные основы системного администрирования с нуля, овладеете управлением серверами и сетями. Уже через 6 месяцев сможете начать карьеру с поддержкой в трудоустройстве.',
  school: 'netology',
  category: 'programming',
  price: 104400,
  oldPrice: 180000,
  currency: 'RUB',
  duration: '11 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1597852074816-d933c7d2b988?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsysadmin&m=5&erid=LdtCKCxkP',
  discountPercent: 42,
  monthlyPayment: 2900,
  skills: [
    'Администрирование Linux',
    'Сетевые технологии',
    'Виртуализация',
    'Безопасность систем',
    'Мониторинг',
    'Автоматизация'
  ],
  program: [
    {
      title: 'Основы администрирования',
      topics: [
        'Linux системы',
        'Сетевые протоколы',
        'Виртуализация',
        'Безопасность'
      ]
    },
    {
      title: 'Сетевые технологии',
      topics: [
        'TCP/IP',
        'Маршрутизация',
        'Firewall',
        'VPN'
      ]
    },
    {
      title: 'Управление системами',
      topics: [
        'Мониторинг',
        'Резервное копирование',
        'Автоматизация',
        'Документирование'
      ]
    }
  ],
  features: [
    '17 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};