import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sports_management',
  title: 'Спортивный менеджмент и маркетинг',
  description: 'Курс «Спортивный менеджмент и маркетинг» от Нетологии: разберитесь в работе спортивных клубов, узнайте, как работает футбольный клуб изнутри, и пройдите стажировку в ПФК ЦСКА.',
  school: 'netology',
  category: 'marketing',
  price: 174000,
  oldPrice: 290000,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1461896836934-ffe607ba8211?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsportivnyy-menedzhent-i-marketing&m=4&erid=LdtCKCxkP',
  monthlyPayment: 4833,
  discountPercent: 40,
  skills: [
    'Спортивный менеджмент',
    'Спортивный маркетинг',
    'Управление клубом',
    'Организация мероприятий',
    'Работа со спонсорами'
  ],
  program: [
    {
      title: 'Основы спортивного менеджмента',
      topics: [
        'Структура спортивной индустрии',
        'Управление клубом',
        'Финансы в спорте',
        'Правовые аспекты'
      ]
    },
    {
      title: 'Маркетинг в спорте',
      topics: [
        'Спортивный маркетинг',
        'Работа со спонсорами',
        'Медиаправа',
        'PR в спорте'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Организация мероприятий',
        'Работа с болельщиками',
        'Управление персоналом',
        'Стажировка в клубе'
      ]
    }
  ],
  features: [
    '34 практических занятия',
    'Стажировка в ПФК ЦСКА',
    'Персональный наставник',
    'Работа над проектами',
    'Диплом о профессиональной переподготовке'
  ]
};