import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_business_analyst',
  title: 'Профессия Бизнес-аналитик. Тариф Оптимальный',
  description: 'Онлайн курс «Бизнес-аналитик» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на бизнес-аналитика с нуля онлайн.',
  school: 'geekbrains',
  category: 'management',
  price: 112624,
  oldPrice: 225247,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/Rg5iP5B2pEkCZ6G7cGDvw06YlPzEmjoE7D1NHRow.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fanalyst%2Fbusiness-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Анализ бизнес-процессов',
    'Работа с требованиями',
    'Проектирование решений',
    'Управление изменениями',
    'Документирование',
    'Презентация результатов'
  ],
  program: [
    {
      title: 'Основы бизнес-анализа',
      topics: [
        'Роль бизнес-аналитика',
        'Методологии анализа',
        'Сбор требований',
        'Моделирование процессов'
      ]
    },
    {
      title: 'Инструменты аналитика',
      topics: [
        'BPMN',
        'UML',
        'User Story Mapping',
        'Прототипирование'
      ]
    },
    {
      title: 'Работа с данными',
      topics: [
        'SQL для аналитика',
        'Анализ данных',
        'Визуализация',
        'Отчетность'
      ]
    },
    {
      title: 'Управление проектами',
      topics: [
        'Agile методологии',
        'Оценка и планирование',
        'Управление рисками',
        'Документация'
      ]
    }
  ],
  features: [
    'Реальные кейсы',
    'Персональный наставник',
    'Практика в компаниях',
    'Помощь в трудоустройстве',
    'Сертификат бизнес-аналитика'
  ]
};