import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_gamedesign',
  title: 'Профессия Геймдизайнер с нуля до PRO',
  description: 'Онлайн курс «Геймдизайнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 7 месяцев. Помощь в трудоустройстве! Обучение на геймдизайнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'game-dev',
  price: 133069,
  oldPrice: 266138,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/biKu8joGmaoIi2yzdDznLbqBppNJPdTkMemBf5Kt.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fgames%2Fgame-designer-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Разработка игровых механик',
    'Создание игровых концепций',
    'Проектирование уровней',
    'Балансировка геймплея',
    'Написание сценариев',
    'Работа с игровыми движками'
  ],
  program: [
    {
      title: 'Основы геймдизайна',
      topics: [
        'Теория игр',
        'Игровые механики',
        'Психология игроков',
        'Жанры игр'
      ]
    },
    {
      title: 'Разработка игр',
      topics: [
        'Концепт-документ',
        'Прототипирование',
        'Левел-дизайн',
        'Баланс игры'
      ]
    },
    {
      title: 'Нарративный дизайн',
      topics: [
        'Сюжет и сценарий',
        'Персонажи',
        'Диалоги',
        'Квесты'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Монетизация',
        'Аналитика',
        'Управление командой',
        'Продвижение игры'
      ]
    }
  ],
  features: [
    'Создание собственной игры',
    'Работа с Unity и Unreal Engine',
    'Персональный наставник',
    'Стажировка в игровой студии',
    'Сертификат геймдизайнера'
  ]
};