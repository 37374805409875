import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_burnout',
  title: 'Эмоциональное выгорание',
  description: 'На дистанционном курсе расскажем, как справиться с эмоциональным выгоранием и вернуть себе энергию, силы и интерес к жизни при помощи методов самопомощи.',
  school: 'netology',
  category: 'psychology',
  price: 7896,
  oldPrice: 13160,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1590650046871-92c887180603?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Femotional-burnout&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 7896,
  skills: [
    'Работа со стрессом',
    'Профилактика выгорания',
    'Восстановление энергии',
    'Самопомощь',
    'Управление эмоциями'
  ],
  program: [
    {
      title: 'Диагностика выгорания',
      topics: [
        'Признаки выгорания',
        'Причины',
        'Стадии',
        'Самодиагностика'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Техники самопомощи',
        'Управление стрессом',
        'Восстановление ресурсов',
        'Профилактика'
      ]
    }
  ],
  features: [
    '8 практических занятий',
    'Техники самопомощи',
    'Индивидуальные консультации',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};