import React from 'react';
import { Link } from 'react-router-dom';

export const BlogPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Блог об образовании</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Link 
            to="/blog/how-to-choose-course" 
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow"
          >
            <img 
              src="https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=1200" 
              alt="Как выбрать онлайн-курс"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <div className="flex items-center gap-2 mb-3">
                <span className="text-sm text-gray-500">12 ноября 2023</span>
                <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                  Советы
                </span>
              </div>
              <h2 className="text-xl font-bold text-gray-900 mb-2">
                Как выбрать онлайн-курс: 7 главных критериев
              </h2>
              <p className="text-gray-600 line-clamp-2">
                При выборе онлайн-курса важно учитывать несколько ключевых факторов. Разбираем основные критерии, которые помогут сделать правильный выбор.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};