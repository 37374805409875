import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_math_base_curator',
  title: 'Курс по подготовке База отдыха 2024/2025 | Базовая математика с куратором',
  description: 'Курс по базовой математике "База отдыха" с персональным куратором. Индивидуальный подход и постоянная поддержка в процессе обучения.',
  school: '100points',
  category: 'math',
  price: 3190,
  oldPrice: 3990,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1560785496-3c9d27877182?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3190,
  discountPercent: 20,
  skills: [
    'Базовые математические навыки',
    'Решение типовых задач',
    'Работа с формулами',
    'Геометрические задачи',
    'Практические задачи',
    'Индивидуальные консультации'
  ],
  program: [
    {
      title: 'Числа и вычисления',
      topics: [
        'Действия с числами',
        'Проценты и дроби',
        'Степени и корни',
        'Округление чисел'
      ]
    },
    {
      title: 'Алгебраические выражения',
      topics: [
        'Формулы сокращенного умножения',
        'Линейные уравнения',
        'Квадратные уравнения',
        'Системы уравнений'
      ]
    },
    {
      title: 'Функции и графики',
      topics: [
        'Линейная функция',
        'Квадратичная функция',
        'Чтение графиков',
        'Построение графиков'
      ]
    },
    {
      title: 'Геометрия',
      topics: [
        'Треугольники',
        'Четырехугольники',
        'Окружность',
        'Площади фигур'
      ]
    }
  ],
  features: [
    '22 записанных урока',
    'Персональный куратор',
    'Индивидуальные консультации',
    'Проверка домашних заданий',
    'Три пробных экзамена',
    'Доступ к материалам до экзамена'
  ]
};