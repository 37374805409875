import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_bitrix',
  title: 'Bitrix-разработчик',
  description: 'Профессия «Bitrix-разработчик с нуля» от Нетологии: научитесь разрабатывать сайты на CMS Bitrix Framework. Выполните комплексные проекты для портфолио и получите сертификат от «1С-Битрикс», что поможет вам уверенно начать карьеру в веб-разработке.',
  school: 'netology',
  category: 'programming',
  price: 86040,
  oldPrice: 143400,
  currency: 'RUB',
  duration: '11 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1547658719-da2b51169166?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fbitrix&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3585,
  skills: [
    'Bitrix Framework',
    'PHP разработка',
    'Интеграция компонентов',
    'Работа с БД',
    'Разработка модулей'
  ],
  program: [
    {
      title: 'Основы Bitrix',
      topics: [
        'Архитектура Bitrix',
        'Компоненты',
        'Модули',
        'Шаблоны'
      ]
    },
    {
      title: 'Разработка',
      topics: [
        'PHP для Bitrix',
        'ORM',
        'API Bitrix',
        'Интеграции'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разработка модулей',
        'Создание компонентов',
        'Оптимизация',
        'Безопасность'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Сертификация 1С-Битрикс',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};