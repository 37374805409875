import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_fullstack',
  title: 'Фулстек-разработчик',
  description: 'Освоите полный цикл создания сайтов и веб-приложений: будете писать код для бэкенда на JavaScript и создавать интерфейсы на HTML и CSS.',
  school: 'yandex',
  category: 'programming',
  price: 225000,
  currency: 'RUB',
  duration: '14 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1587620962725-abab7fe55159?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Ffullstack-developer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Frontend разработка',
    'Backend разработка',
    'JavaScript',
    'Node.js',
    'Базы данных'
  ],
  program: [
    {
      title: 'Frontend',
      topics: [
        'HTML и CSS',
        'JavaScript',
        'React',
        'Адаптивная верстка'
      ]
    },
    {
      title: 'Backend',
      topics: [
        'Node.js',
        'Express',
        'MongoDB',
        'REST API'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Полноценные приложения',
        'Работа с API',
        'Деплой',
        'Оптимизация'
      ]
    }
  ],
  features: [
    'Комплексные проекты',
    'Работа с реальными задачами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};