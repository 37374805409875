import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_drones',
  title: 'Профессия Программист дронов и роботов',
  description: 'Онлайн курс «Программист дронов и роботов» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 13 месяцев. Помощь в трудоустройстве! Обучение на программиста дронов и роботов с нуля онлайн.',
  school: 'geekbrains',
  category: 'engineering',
  price: 65151,
  oldPrice: 130301,
  currency: 'RUB',
  duration: '13 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/KFLs3zEX9FRtJlluUfBUPfaGm83aRGsAkqKxsI9Z.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fengineering%2Fdrone-and-robots-developer&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Программирование дронов',
    'Разработка для роботов',
    'Работа с сенсорами',
    'Системы управления',
    'Компьютерное зрение',
    'Автономная навигация'
  ],
  program: [
    {
      title: 'Основы робототехники',
      topics: [
        'Введение в робототехнику',
        'Электроника',
        'Механика',
        'Системы управления'
      ]
    },
    {
      title: 'Программирование',
      topics: [
        'Python для роботов',
        'ROS',
        'Алгоритмы управления',
        'Обработка сенсорных данных'
      ]
    },
    {
      title: 'Дроны и БПЛА',
      topics: [
        'Устройство дронов',
        'Полетные контроллеры',
        'Автономные полеты',
        'Планирование миссий'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разработка проектов',
        'Тестирование систем',
        'Отладка программ',
        'Внедрение решений'
      ]
    }
  ],
  features: [
    'Практика на реальном оборудовании',
    'Работа с современными технологиями',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};