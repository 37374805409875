import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_telegram',
  title: 'Онлайн-курс Продвижение в Telegram',
  description: 'Научитесь системно продвигать каналы в Telegram: от создания контента до управления бюджетом на рекламу',
  school: 'brunoyam',
  category: 'marketing',
  price: 19900,
  currency: 'RUB',
  duration: '1 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1611606063065-ee7946f0787a?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fsmm-telegram&m=5&erid=LdtCK4uLs',
  skills: [
    'Ведение Telegram-канала',
    'Создание контента',
    'Продвижение канала',
    'Работа с аудиторией',
    'Монетизация'
  ],
  program: [
    {
      title: 'Основы Telegram',
      topics: [
        'Создание канала',
        'Оформление',
        'Контент-стратегия',
        'Форматы постов'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Реклама',
        'Взаимопиар',
        'Работа с блогерами',
        'Органический рост'
      ]
    },
    {
      title: 'Монетизация',
      topics: [
        'Модели монетизации',
        'Работа с рекламодателями',
        'Создание продуктов',
        'Аналитика'
      ]
    }
  ],
  features: [
    '15 практических проектов',
    'Работа с реальным каналом',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};