import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_crisis_psychology',
  title: 'Клиническая психология со специализацией в психологии экстремальных ситуаций',
  description: 'Дистанционное обучение по программе Клиническая психология со специализацией в психологии экстремальных ситуаций с присвоением квалификации Клинический психолог. Кризисный психолог (2064 часа) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 76200,
  currency: 'RUR',
  duration: '2064 часа',
  imageUrl: 'https://images.unsplash.com/photo-1590650046871-92c887180603?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fklinicheskaya_psikhologiya_psikhologicheskaya_diagnostika_i_psikhoterapiya_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Кризисное консультирование',
    'Работа с ПТСР',
    'Экстренная психологическая помощь',
    'Работа с утратой',
    'Профилактика суицида'
  ],
  program: [
    {
      title: 'Основы кризисной психологии',
      topics: [
        'Психология экстремальных ситуаций',
        'Психическая травма',
        'Острые стрессовые реакции',
        'Посттравматический стресс'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Экстренная помощь',
        'Кризисное консультирование',
        'Техники стабилизации',
        'Работа с утратой'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Суицидология',
        'Работа с насилием',
        'Семейные кризисы',
        'Профессиональное выгорание'
      ]
    }
  ],
  features: [
    'Углубленная специализация',
    'Практическая подготовка',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};