import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_smm_pro',
  title: 'SMM-специалист расширенный',
  description: 'Онлайн-курс «SMM-специалист расширенный» от сервиса Яндекс Практикум. Обучение для начинающих SMM-менеджеров.',
  school: 'yandex',
  category: 'marketing',
  price: 104000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1611926653458-09294b3142bf?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fsmm-specialist%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'SMM стратегия',
    'Контент-маркетинг',
    'Таргетированная реклама',
    'Аналитика соцсетей',
    'Управление репутацией'
  ],
  program: [
    {
      title: 'Стратегия и планирование',
      topics: [
        'Разработка стратегии',
        'Анализ целевой аудитории',
        'Контент-планирование',
        'Работа с метриками'
      ]
    },
    {
      title: 'Продвижение и реклама',
      topics: [
        'Таргетированная реклама',
        'Работа с блогерами',
        'Органическое продвижение',
        'Управление бюджетом'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Анализ эффективности',
        'Работа с метриками',
        'A/B тестирование',
        'Оптимизация стратегии'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};