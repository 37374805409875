import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_text_editing',
  title: 'Редактирование и коррекция художественного текста',
  description: 'Программа проф. переподготовки 2024 года по редактированию и коррекции художественных текстов от ЦАППКК поможет вам получить практические знания по выбранному направлению.',
  school: 'appkk',
  category: 'journalism',
  price: 34200,
  currency: 'RUR',
  duration: '670 часов',
  imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fredaktirovanie_i_korrektsiya_khudozhestvennogo_teksta_s_prisvoeniem_kvalifikatsii_redaktor_sredstv_m%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Редактирование текстов',
    'Литературная правка',
    'Корректура',
    'Стилистический анализ',
    'Работа с авторами'
  ],
  program: [
    {
      title: 'Основы редактирования',
      topics: [
        'Теория редактирования',
        'Виды правки',
        'Работа с текстом',
        'Нормы языка'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Редакторский анализ',
        'Стилистическая правка',
        'Корректура',
        'Работа с рукописью'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Художественное редактирование',
        'Работа с автором',
        'Подготовка к публикации',
        'Издательский процесс'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с текстами',
    'Редакторское портфолио',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};