import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_fullstack_personal',
  title: 'Фулстек-разработчик. Персональный',
  description: 'Фулстек-разработчик: персональный формат',
  school: 'yandex',
  category: 'programming',
  price: 275000,
  currency: 'RUB',
  duration: '14 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1587620962725-abab7fe55159?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Ffullstack-developer%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Frontend разработка',
    'Backend разработка',
    'Базы данных',
    'API разработка',
    'DevOps'
  ],
  program: [
    {
      title: 'Frontend',
      topics: [
        'HTML и CSS',
        'JavaScript',
        'React',
        'TypeScript'
      ]
    },
    {
      title: 'Backend',
      topics: [
        'Node.js',
        'Express',
        'MongoDB',
        'REST API'
      ]
    },
    {
      title: 'DevOps и инфраструктура',
      topics: [
        'Git',
        'Docker',
        'CI/CD',
        'Деплой'
      ]
    }
  ],
  features: [
    'Индивидуальное обучение',
    'Персональный наставник',
    'Работа над проектами',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};