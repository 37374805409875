import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_product_approach',
  title: 'Основы продакт-менеджмента',
  description: 'Это курс для специалистов с опытом, которые хотят развить продуктовое мышление. За 6 недель научитесь запускать продукт: от идеи до анализа результатов.',
  school: 'yandex',
  category: 'management',
  price: 60000,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fproduct-approach&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продуктовое мышление',
    'Управление продуктом',
    'Анализ метрик',
    'Проведение исследований',
    'Запуск продукта'
  ],
  program: [
    {
      title: 'Основы продакт-менеджмента',
      topics: [
        'Продуктовое мышление',
        'Анализ рынка',
        'Customer Development',
        'Метрики продукта'
      ]
    },
    {
      title: 'Разработка продукта',
      topics: [
        'Формирование гипотез',
        'Проектирование решений',
        'Прототипирование',
        'Тестирование'
      ]
    },
    {
      title: 'Запуск и анализ',
      topics: [
        'Запуск продукта',
        'Анализ результатов',
        'Оптимизация метрик',
        'Развитие продукта'
      ]
    }
  ],
  features: [
    'Интенсивное обучение',
    'Практические кейсы',
    'Персональный наставник',
    'Работа над проектом',
    'Сертификат менеджера'
  ]
};