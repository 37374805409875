import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_sysadmin',
  title: 'Системный администратор',
  description: 'Научитесь администрировать Linux-системы, работать с сетями и обеспечивать безопасность инфраструктуры',
  school: 'yandex',
  category: 'programming',
  price: 103000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1597852074816-d933c7d2b988?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fsys-admin&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Linux администрирование',
    'Сетевые технологии',
    'Безопасность систем',
    'Мониторинг',
    'Автоматизация'
  ],
  program: [
    {
      title: 'Основы администрирования',
      topics: [
        'Linux системы',
        'Командная строка',
        'Управление пользователями',
        'Файловые системы'
      ]
    },
    {
      title: 'Сети и безопасность',
      topics: [
        'TCP/IP',
        'Маршрутизация',
        'Firewall',
        'VPN'
      ]
    },
    {
      title: 'Инфраструктура',
      topics: [
        'Мониторинг систем',
        'Резервное копирование',
        'Автоматизация',
        'Виртуализация'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными системами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};