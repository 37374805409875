import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_1c_dev_online',
  title: 'Онлайн-курс 1С-разработчик',
  description: 'За 4 месяца получите навыки написания кода и администрирования решений на основе архитектуры 1С. Интенсивный формат — вебинары, видеоматериалы, практические задания',
  school: 'brunoyam',
  category: 'programming',
  price: 59900,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2F1c-developer&m=5&erid=LdtCK4uLs',
  skills: [
    'Разработка на 1С',
    'Конфигурирование',
    'Программирование',
    'Интеграция',
    'Оптимизация'
  ],
  program: [
    {
      title: 'Основы 1С',
      topics: [
        'Платформа 1С:Предприятие',
        'Конфигурации',
        'Объекты конфигурации',
        'Встроенный язык'
      ]
    },
    {
      title: 'Разработка',
      topics: [
        'Программирование на 1С',
        'Запросы и отчеты',
        'Формы и интерфейс',
        'Обмен данными'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разработка решений',
        'Тестирование',
        'Оптимизация',
        'Внедрение'
      ]
    }
  ],
  features: [
    '2 проекта в портфолио',
    'Практические задания',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат разработчика'
  ]
};