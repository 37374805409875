import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_fashion',
  title: 'Профессия Fashion-дизайнер 2.0',
  description: 'Онлайн курс «Fashion-дизайнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на Fashion-дизайнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 120394,
  oldPrice: 240787,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/2NtgEQA2Z5Z1f6o9mrmX55zaakXJ6gk8DGzjvPkx.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Ffashion-design&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Создание коллекций одежды',
    'Работа с тканями',
    'Конструирование',
    'Модный иллюстратор',
    'Работа с трендами',
    'Fashion-маркетинг'
  ],
  program: [
    {
      title: 'Основы fashion-дизайна',
      topics: [
        'История моды',
        'Анализ трендов',
        'Работа с источниками',
        'Создание мудбордов'
      ]
    },
    {
      title: 'Дизайн одежды',
      topics: [
        'Эскизирование',
        'Работа с цветом',
        'Конструирование',
        'Материаловедение'
      ]
    },
    {
      title: 'Создание коллекций',
      topics: [
        'Концепция коллекции',
        'Капсульный гардероб',
        'Технические рисунки',
        'Презентация коллекции'
      ]
    },
    {
      title: 'Бизнес в моде',
      topics: [
        'Fashion-бизнес',
        'Маркетинг в моде',
        'Продвижение бренда',
        'Работа с производством'
      ]
    }
  ],
  features: [
    'Создание собственной коллекции',
    'Работа с реальными материалами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат fashion-дизайнера'
  ]
};