import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sales_manager',
  title: 'Руководитель отдела продаж',
  description: 'Курс «Руководитель отдела продаж» от Нетологии: научитесь управлять командой и внедрять стратегии продаж за 5,5 месяцев. Практические задания и реальные кейсы помогут вам повысить квалификацию.',
  school: 'netology',
  category: 'management',
  price: 117900,
  oldPrice: 196500,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1552581234-26160f608093?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsales-manager-online&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3275,
  discountPercent: 40,
  skills: [
    'Управление продажами',
    'Построение отдела',
    'Мотивация команды',
    'Стратегическое планирование',
    'Аналитика продаж'
  ],
  program: [
    {
      title: 'Управление отделом',
      topics: [
        'Структура отдела',
        'Найм персонала',
        'Обучение команды',
        'Мотивация'
      ]
    },
    {
      title: 'Процессы продаж',
      topics: [
        'Воронка продаж',
        'CRM-системы',
        'Скрипты продаж',
        'Работа с возражениями'
      ]
    },
    {
      title: 'Аналитика и развитие',
      topics: [
        'KPI и метрики',
        'Прогнозирование',
        'Оптимизация процессов',
        'Стратегия роста'
      ]
    }
  ],
  features: [
    '105 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в карьерном росте',
    'Диплом о профессиональной переподготовке'
  ]
};