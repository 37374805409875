import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_operations_pro',
  title: 'Операционный менеджер',
  description: 'Профессия «Операционный менеджмент» от Нетологии: научитесь повышать рентабельность бизнеса, оптимизировать процессы и финансы. Пройдите 12 практических занятий и получите поддержку экспертов для карьерного роста.',
  school: 'netology',
  category: 'management',
  price: 133260,
  oldPrice: 222100,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Foperations_management&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3701,
  skills: [
    'Операционный менеджмент',
    'Оптимизация процессов',
    'Управление командой',
    'Финансовое планирование',
    'Управление качеством'
  ],
  program: [
    {
      title: 'Основы операционки',
      topics: [
        'Операционная стратегия',
        'Бизнес-процессы',
        'KPI и метрики',
        'Управление ресурсами'
      ]
    },
    {
      title: 'Оптимизация процессов',
      topics: [
        'Lean management',
        'Управление качеством',
        'Автоматизация',
        'Снижение издержек'
      ]
    },
    {
      title: 'Управление командой',
      topics: [
        'Организационная структура',
        'Мотивация персонала',
        'Развитие команды',
        'Управление изменениями'
      ]
    }
  ],
  features: [
    '65 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};