import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_network',
  title: 'Сетевой инженер',
  description: 'Профессия «Сетевой инженер» от Нетологии: вы освоите IT-профессию, научитесь создавать корпоративные сети и работать с оборудованием. Практика в Cisco Packet Tracer и работа с реальными задачами сетевого инженера помогут вам развить навыки для успешной карьеры.',
  school: 'netology',
  category: 'programming',
  price: 88800,
  oldPrice: 148000,
  currency: 'RUB',
  duration: '13 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1544197150-b99a580bb7a8?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fnetwork-engineer&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3700,
  skills: [
    'Сетевые технологии',
    'Cisco оборудование',
    'Маршрутизация',
    'Безопасность сетей',
    'Мониторинг',
    'Troubleshooting'
  ],
  program: [
    {
      title: 'Основы сетей',
      topics: [
        'Модель OSI',
        'TCP/IP',
        'Коммутация',
        'Маршрутизация'
      ]
    },
    {
      title: 'Сетевое оборудование',
      topics: [
        'Cisco IOS',
        'Конфигурация устройств',
        'VLAN',
        'Протоколы маршрутизации'
      ]
    },
    {
      title: 'Безопасность и мониторинг',
      topics: [
        'Сетевая безопасность',
        'Мониторинг сетей',
        'Troubleshooting',
        'Документирование'
      ]
    }
  ],
  features: [
    '9 практических занятий',
    'Работа в Cisco Packet Tracer',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};