import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_medical_boundaries_pro',
  title: 'Как врачу выстраивать личные границы с пациентом',
  description: 'Программа для тех, кому тяжело отстаивать личные границы и не получается отказываться от лишней нагрузки. Узнайте о моделях отношений врач-пациент, станьте увереннее в себе и выйдите на новый уровень дохода.',
  school: 'netology',
  category: 'medicine',
  price: 6200,
  oldPrice: 6200,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Flichnii-granici-vracha&m=4&erid=LdtCKCxkP',
  monthlyPayment: 6200,
  skills: [
    'Личные границы',
    'Коммуникация с пациентами',
    'Профессиональная уверенность',
    'Управление нагрузкой',
    'Профилактика выгорания'
  ],
  program: [
    {
      title: 'Основы личных границ',
      topics: [
        'Модели отношений врач-пациент',
        'Профессиональные границы',
        'Ассертивное поведение',
        'Работа с манипуляциями'
      ]
    },
    {
      title: 'Практика коммуникации',
      topics: [
        'Техники отказа',
        'Управление временем',
        'Работа со сложными пациентами',
        'Профилактика выгорания'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Разбор реальных ситуаций',
    'Работа с экспертами',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};