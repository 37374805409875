import { Course } from '../../../../types';

export const course: Course = {
  id: 'anecole_english_individual',
  title: 'Английский с преподавателем',
  description: 'Английский язык с преподавателем',
  school: 'anecole',
  category: 'english',
  price: 19120,
  currency: 'RUB',
  duration: '8 часов',
  imageUrl: 'https://images.unsplash.com/photo-1544776193-352d25ca82cd?q=80&w=1200',
  url: 'https://go.redav.online/4f106e4f2bb25c71?dl=https%3A%2F%2Fanecole.com%2Fenglish%2Findividual&m=5&erid=2Vfnxwa5gNL',
  monthlyPayment: 4780,
  skills: [
    'Индивидуальная программа',
    'Разговорная практика',
    'Грамматика',
    'Аудирование',
    'Письменная речь'
  ],
  program: [
    {
      title: 'Базовый курс',
      topics: [
        'Вводный урок',
        'Фонетика',
        'Грамматика',
        'Лексика'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разговорная речь',
        'Аудирование',
        'Письмо',
        'Чтение'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивные тренажеры',
    'Гибкий график',
    'Персональная программа',
    'Сертификат по окончании'
  ]
};