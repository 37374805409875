import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_stylist',
  title: 'Персональный стилист',
  description: 'Онлайн курс «Персональный стилист» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 4 месяца. Помощь в трудоустройстве! Обучение на персонального стилиста с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 51477,
  oldPrice: 102953,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/wFQ7CnLC4aSgsn3veH6JvrnhI0c1MkmASCUmhRgu.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Fpersonal-stylist&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Анализ внешности',
    'Подбор гардероба',
    'Колористика',
    'Стилевые направления',
    'Шопинг-сопровождение',
    'Работа с клиентами'
  ],
  program: [
    {
      title: 'Основы стилистики',
      topics: [
        'История моды',
        'Теория стиля',
        'Анализ внешности',
        'Цветотипы'
      ]
    },
    {
      title: 'Работа с гардеробом',
      topics: [
        'Базовый гардероб',
        'Капсульный гардероб',
        'Разбор гардероба',
        'Составление комплектов'
      ]
    },
    {
      title: 'Практическая стилистика',
      topics: [
        'Шопинг-сопровождение',
        'Работа с клиентами',
        'Стилевые консультации',
        'Имидж-консалтинг'
      ]
    },
    {
      title: 'Развитие бизнеса',
      topics: [
        'Поиск клиентов',
        'Ценообразование',
        'Продвижение услуг',
        'Личный бренд'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными клиентами',
    'Персональный наставник',
    'Помощь в запуске карьеры',
    'Сертификат стилиста'
  ]
};