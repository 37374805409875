import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_go_advanced',
  title: 'Продвинутый Go-разработчик',
  description: 'Сможете вырасти до мидла: пройдёте 54 урока, сделаете 3 учебных проекта и получите 12 индивидуальных консультаций.',
  school: 'yandex',
  category: 'programming',
  price: 152000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1600132806370-bf17e65e942f?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fgo-advanced&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продвинутый Go',
    'Микросервисы',
    'Высоконагруженные системы',
    'Оптимизация производительности',
    'Распределенные системы'
  ],
  program: [
    {
      title: 'Продвинутые концепции Go',
      topics: [
        'Конкурентность',
        'Паттерны проектирования',
        'Профилирование',
        'Оптимизация'
      ]
    },
    {
      title: 'Микросервисная архитектура',
      topics: [
        'gRPC',
        'REST API',
        'Очереди сообщений',
        'Service Discovery'
      ]
    },
    {
      title: 'Производительность',
      topics: [
        'Кэширование',
        'Масштабирование',
        'Мониторинг',
        'Отказоустойчивость'
      ]
    }
  ],
  features: [
    '54 урока',
    '3 учебных проекта',
    '12 индивидуальных консультаций',
    'Помощь в карьерном росте',
    'Сертификат Go-разработчика'
  ]
};