import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_targeting',
  title: 'Таргетолог',
  description: 'Курс «Таргетолог» от Нетологии: обучение таргетированной рекламе с нуля за 4 месяца. Продвигайте бизнес в социальных сетях и получите индивидуальные консультации с экспертами. Практические задания и разбор ваших проектов помогут достичь результата уже через несколько месяцев.',
  school: 'netology',
  category: 'marketing',
  price: 72000,
  oldPrice: 120000,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1533750349088-cd871a92f312?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ftarget-smm&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3000,
  skills: [
    'Таргетированная реклама',
    'Работа с аудиторией',
    'Создание креативов',
    'Аналитика рекламы',
    'Оптимизация кампаний'
  ],
  program: [
    {
      title: 'Основы таргетинга',
      topics: [
        'Рекламные кабинеты',
        'Целевая аудитория',
        'Создание креативов',
        'Настройка рекламы'
      ]
    },
    {
      title: 'Продвинутый таргетинг',
      topics: [
        'Ретаргетинг',
        'Look-alike',
        'Автоматизация',
        'Масштабирование'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Анализ результатов',
        'A/B тестирование',
        'Оптимизация бюджета',
        'Отчетность'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};