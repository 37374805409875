import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_critical_thinking',
  title: 'Критическое мышление и принятие решений',
  description: 'Онлайн-курс «Критическое мышление и принятие решений» от Нетологии: обучение навыкам работы с информацией, умению выделять главное и грамотно аргументировать свою позицию. Примите участие в воркшопах и дебатах для отработки полученных знаний.',
  school: 'netology',
  category: 'management',
  price: 29700,
  oldPrice: 49500,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1606326608606-aa0b62935f2b?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fcritical_thinking_new&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2475,
  discountPercent: 40,
  skills: [
    'Критическое мышление',
    'Принятие решений',
    'Аргументация',
    'Анализ информации',
    'Логическое мышление'
  ],
  program: [
    {
      title: 'Основы критического мышления',
      topics: [
        'Логика и аргументация',
        'Когнитивные искажения',
        'Анализ информации',
        'Работа с источниками'
      ]
    },
    {
      title: 'Принятие решений',
      topics: [
        'Методы принятия решений',
        'Анализ альтернатив',
        'Оценка рисков',
        'Групповые решения'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Дебаты',
        'Кейс-стади',
        'Решение проблем',
        'Презентация идей'
      ]
    }
  ],
  features: [
    'Практические воркшопы',
    'Работа в группах',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};