import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { articles } from '../data/blog';

export const ArticlePage: React.FC = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const article = articles.find(a => a.id === articleId);

  if (!article) {
    return <Navigate to="/blog" replace />;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <article className="max-w-3xl mx-auto px-4">
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <img 
            src={article.imageUrl} 
            alt={article.title}
            className="w-full h-72 object-cover"
          />
          
          <div className="p-8">
            <div className="flex items-center gap-2 mb-4">
              <span className="text-sm text-gray-500">{article.date}</span>
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                {article.category}
              </span>
            </div>

            <h1 className="text-3xl font-bold text-gray-900 mb-6">
              {article.title}
            </h1>

            <div className="prose prose-blue max-w-none">
              {article.content}
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};