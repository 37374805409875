import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_motion_designer',
  title: 'Моушн-дизайнер',
  description: 'Разберётесь в основах дизайна, научитесь работать с графикой и анимацией, освоите Adobe After Effects, Adobe Photoshop, Adobe Illustrator, Adobe Premiere Pro и Cinema 4D.',
  school: 'yandex',
  category: 'design',
  price: 139000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551269901-5c5e14c25df7?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmotion-designer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Motion Design',
    'After Effects',
    'Cinema 4D',
    'Анимация',
    'Композитинг'
  ],
  program: [
    {
      title: 'Основы анимации',
      topics: [
        'Принципы анимации',
        'Композиция',
        'Цвет и свет',
        'Типографика'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'After Effects',
        'Cinema 4D',
        'Illustrator',
        'Premiere Pro'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание роликов',
        'Анимация логотипов',
        'Спецэффекты',
        'Портфолио'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа над реальными задачами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат моушн-дизайнера'
  ]
};