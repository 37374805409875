import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_python_analytics',
  title: 'Python для анализа данных',
  description: 'Изучите Python для анализа данных на онлайн-курсе в Нетологии. Освойте Pandas, NumPy и Matplotlib за 4 месяца. Обучение включает практические задания и проект для портфолио. Станьте аналитиком данных, используя Python.',
  school: 'netology',
  category: 'analytics',
  price: 51870,
  oldPrice: 74100,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1526379095098-d400fd0bf935?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fpython-for-analytics&m=5&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2881,
  skills: [
    'Python для анализа',
    'Pandas',
    'NumPy',
    'Matplotlib',
    'Визуализация данных',
    'Статистический анализ'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис Python',
        'Типы данных',
        'Функции',
        'Библиотеки'
      ]
    },
    {
      title: 'Инструменты анализа',
      topics: [
        'Pandas',
        'NumPy',
        'Matplotlib',
        'Seaborn'
      ]
    },
    {
      title: 'Практика анализа',
      topics: [
        'Обработка данных',
        'Визуализация',
        'Статистика',
        'Реальные кейсы'
      ]
    }
  ],
  features: [
    '45 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};