import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_history_pro',
  title: 'История [ПРО]',
  description: 'Мастер группа [ПРО] - это углубленный формат подготовки с расширенной программой и дополнительными материалами для достижения максимального результата.',
  school: '99ballov',
  category: 'history',
  price: 4290,
  oldPrice: 4790,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1461360370896-922624d12aa1?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 10,
  skills: [
    'Углубленный анализ источников',
    'Историография',
    'Работа с документами',
    'Исследовательская работа',
    'Историческая аналитика'
  ],
  program: [
    {
      title: 'Расширенная программа',
      topics: [
        'Историография',
        'Источниковедение',
        'Методология истории',
        'Дискуссионные вопросы'
      ]
    },
    {
      title: 'Практикум',
      topics: [
        'Анализ источников',
        'Исследовательские проекты',
        'Исторические дебаты',
        'Научная работа'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Углубленные материалы',
    'Олимпиадная подготовка',
    'Личный куратор',
    'Дополнительные консультации'
  ]
};