import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_project_manager',
  title: 'Менеджер проектов',
  description: 'Профессия «Менеджер проектов» от Нетологии: комплексное обучение управлению проектами для начинающих, без опыта в менеджменте и IT. Освойте навыки планирования, работы с финансами и командой на практике с реальными кейсами.',
  school: 'netology',
  category: 'management',
  price: 101520,
  oldPrice: 169200,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fproject&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2820,
  discountPercent: 40,
  skills: [
    'Управление проектами',
    'Agile методологии',
    'Работа с командой',
    'Управление рисками',
    'Планирование'
  ],
  program: [
    {
      title: 'Основы управления проектами',
      topics: [
        'Методологии управления',
        'Жизненный цикл проекта',
        'Планирование',
        'Управление рисками'
      ]
    },
    {
      title: 'Работа с командой',
      topics: [
        'Формирование команды',
        'Коммуникации',
        'Мотивация',
        'Разрешение конфликтов'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с документацией',
        'Управление изменениями',
        'Отчетность',
        'Завершение проекта'
      ]
    }
  ],
  features: [
    '45 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};