import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_cpp',
  title: 'Разработчик на C++',
  description: 'За 5 месяцев научитесь писать код на C++. Интенсивный формат — вебинары, воркшопы, видеоматериалы, практические задания.',
  school: 'brunoyam',
  category: 'programming',
  price: 54900,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1629654297299-c8506221ca97?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Frazrabotka-na-c-plus-plus&m=5&erid=LdtCK4uLs',
  skills: [
    'C++ разработка',
    'Алгоритмы и структуры данных',
    'ООП',
    'STL',
    'Многопоточность'
  ],
  program: [
    {
      title: 'Основы C++',
      topics: [
        'Синтаксис языка',
        'Типы данных',
        'Указатели и ссылки',
        'Функции'
      ]
    },
    {
      title: 'Продвинутый C++',
      topics: [
        'ООП',
        'STL',
        'Шаблоны',
        'Исключения'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с памятью',
        'Многопоточность',
        'Оптимизация',
        'Создание приложений'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Поддержка куратора',
    'Работа над задачами',
    'Доступ к материалам',
    'Сертификат разработчика'
  ]
};