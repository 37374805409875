import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_qa',
  title: 'Инженер по тестированию',
  description: 'Освоите IT-профессию, в которой не нужно программировать. Научитесь тестировать сервисы, чтобы находить в них ошибки и прогнозировать сбои.',
  school: 'yandex',
  category: 'programming',
  price: 75000,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fqa-engineer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Ручное тестирование',
    'Тест-дизайн',
    'Тест-кейсы',
    'Работа с багами',
    'API тестирование'
  ],
  program: [
    {
      title: 'Основы тестирования',
      topics: [
        'Теория тестирования',
        'Виды тестирования',
        'Тестовая документация',
        'Техники тест-дизайна'
      ]
    },
    {
      title: 'Инструменты тестировщика',
      topics: [
        'Charles Proxy',
        'Postman',
        'DevTools',
        'Git'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Основы Python',
        'Selenium',
        'API тестирование',
        'CI/CD'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о профессии'
  ]
};