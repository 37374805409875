import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_ios_dev_plus',
  title: 'iOS-разработчик расширенный',
  description: 'Расширенный курс по разработке iOS приложений',
  school: 'yandex',
  category: 'programming',
  price: 198000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://mobile-review.com/articles/2021/image/skillfactory/4.jpg?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fios-developer%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'iOS разработка',
    'Swift',
    'SwiftUI',
    'Архитектура приложений',
    'Работа с данными'
  ],
  program: [
    {
      title: 'Продвинутый Swift',
      topics: [
        'Concurrency',
        'SwiftUI',
        'Combine',
        'Core Data'
      ]
    },
    {
      title: 'Архитектура',
      topics: [
        'MVVM',
        'Clean Architecture',
        'Dependency Injection',
        'Design Patterns'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с API',
        'Тестирование',
        'CI/CD',
        'App Store'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};