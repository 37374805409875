import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_eq_pro',
  title: 'Эмоциональный интеллект',
  description: 'Расскажем, как развить эмоциональный интеллект. Научим принимать эмоции и управлять ими, лучше понимать окружающих и строить крепкие деловые отношения.',
  school: 'netology',
  category: 'psychology',
  price: 7860,
  oldPrice: 13100,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1516585427167-9f4af9627e6c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Femocionalnyj-intellekt&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 7860,
  skills: [
    'Эмоциональный интеллект',
    'Управление эмоциями',
    'Эмпатия',
    'Социальные навыки',
    'Самопознание'
  ],
  program: [
    {
      title: 'Основы эмоционального интеллекта',
      topics: [
        'Понимание эмоций',
        'Самоосознание',
        'Эмпатия',
        'Социальные навыки'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Управление эмоциями',
        'Работа с триггерами',
        'Коммуникация',
        'Стресс-менеджмент'
      ]
    }
  ],
  features: [
    '12 практических занятий',
    'Работа с психологом',
    'Практические упражнения',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};