import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_accountant',
  title: 'Бухгалтер',
  description: 'Онлайн-курс «Бухгалтер» от Нетологии: освоите учет, налогообложение и работу с 1С за 6 месяцев. Получите диплом бухгалтера и сертификат «1С» для старта карьеры или повышения в сфере финансов с поддержкой экспертов и возможностью трудоустройства в Т-Банк.',
  school: 'netology',
  category: 'finance',
  price: 85260,
  oldPrice: 142100,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Faccountant&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3552,
  skills: [
    'Бухгалтерский учет',
    'Налогообложение',
    'Работа в 1С',
    'Финансовая отчетность',
    'Кадровый учет'
  ],
  program: [
    {
      title: 'Основы бухучета',
      topics: [
        'Теория бухучета',
        'План счетов',
        'Первичные документы',
        'Учетная политика'
      ]
    },
    {
      title: 'Налоги и отчетность',
      topics: [
        'Налоговый учет',
        'Виды налогов',
        'Отчетность',
        'Оптимизация'
      ]
    },
    {
      title: 'Практика в 1С',
      topics: [
        'Работа в 1С',
        'Ведение учета',
        'Формирование отчетов',
        'Закрытие периода'
      ]
    }
  ],
  features: [
    '46 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};