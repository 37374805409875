import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_seo_specialist',
  title: 'SEO-специалист',
  description: 'Курс «SEO-специалист» от Нетологии: обучение SEO-продвижению сайтов с нуля. Привлекайте посетителей из поисковых систем и берите первые проекты через 5 месяцев. Практическое обучение и поддержка экспертов.',
  school: 'netology',
  category: 'marketing',
  price: 107085,
  oldPrice: 194700,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://avatars.mds.yandex.net/get-altay/5308697/2a0000017c08260b0c1730401d3dda5e670b/XXL_height?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fseo-specialist&m=4&erid=LdtCKCxkP',
  discountPercent: 45,
  monthlyPayment: 2974,
  skills: [
    'SEO-оптимизация',
    'Технический аудит',
    'Контент-маркетинг',
    'Ссылочное продвижение',
    'Веб-аналитика'
  ],
  program: [
    {
      title: 'Основы SEO',
      topics: [
        'Поисковые системы',
        'Факторы ранжирования',
        'Семантическое ядро',
        'Техническая оптимизация'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Контент-маркетинг',
        'Ссылочное продвижение',
        'Локальное SEO',
        'Мобильная оптимизация'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'Веб-аналитика',
        'Отслеживание позиций',
        'A/B тестирование',
        'Отчетность'
      ]
    }
  ],
  features: [
    '63 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};