import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_marketplace_manager', // Changed to ensure uniqueness
  title: 'Менеджер маркетплейсов',
  description: 'Научитесь запускать продажи и управлять существующими бизнесами на Wildberries, Яндекс Маркете, Ozon, СберМегаМаркете и Lamoda.',
  school: 'yandex',
  category: 'marketing',
  price: 77000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmarketplace-manager&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление магазином',
    'Работа с маркетплейсами',
    'Аналитика продаж',
    'Оптимизация карточек',
    'Продвижение товаров'
  ],
  program: [
    {
      title: 'Основы работы с маркетплейсами',
      topics: [
        'Регистрация магазина',
        'Настройка кабинета',
        'Работа с каталогом',
        'Управление остатками'
      ]
    },
    {
      title: 'Продвижение и продажи',
      topics: [
        'Оптимизация карточек',
        'Реклама на площадках',
        'Акции и скидки',
        'Работа с отзывами'
      ]
    },
    {
      title: 'Аналитика и развитие',
      topics: [
        'Анализ продаж',
        'Работа с поставщиками',
        'Финансовый учет',
        'Масштабирование бизнеса'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными магазинами',
    'Персональный наставник',
    'Помощь в запуске',
    'Сертификат специалиста'
  ]
};