import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_3d_artist',
  title: '3D-дизайнер',
  description: 'Онлайн-курс «3D-дизайнер» от сервиса Яндекс Практикум. Обучение профессии 3D-дизайнера для начинающих с нуля. Освоите основные программы 3D-дизайнера и соберете портфолио для старта карьеры.',
  school: 'yandex',
  category: 'design',
  price: 139000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2F3d-artist&m=5&erid=2Vfnxx6kDRz',
  skills: [
    '3D моделирование',
    'Текстурирование',
    'Анимация',
    'Визуализация',
    'Работа со светом'
  ],
  program: [
    {
      title: 'Основы 3D',
      topics: [
        'Введение в 3D графику',
        'Базовое моделирование',
        'Работа с материалами',
        'Основы анимации'
      ]
    },
    {
      title: 'Продвинутое моделирование',
      topics: [
        'Hard Surface моделирование',
        'Органическое моделирование',
        'Скульптинг',
        'Ретопология'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Текстурирование',
        'Освещение',
        'Рендеринг',
        'Постобработка'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными задачами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат дизайнера'
  ]
};