import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_ae',
  title: 'Курс After Effects',
  description: 'Базовый курс по Adobe After Effects. Научитесь создавать анимацию, визуальные эффекты и моушн-дизайн.',
  school: 'contented',
  category: 'software',
  price: 30600,
  oldPrice: 51000,
  currency: 'RUR',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1535016120720-40c646be5580?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fafter-effects%2F&m=5&erid=LdtCKHL5u',
  discountPercent: 40,
  skills: [
    'Анимация',
    'Визуальные эффекты',
    'Композитинг',
    'Моушн-дизайн',
    'Работа с текстом',
    'Цветокоррекция'
  ],
  program: [
    {
      title: 'Основы After Effects',
      topics: [
        'Интерфейс программы',
        'Базовая анимация',
        'Работа со слоями',
        'Таймлайн'
      ]
    },
    {
      title: 'Анимация и эффекты',
      topics: [
        'Ключевые кадры',
        'Визуальные эффекты',
        'Маски и маскирование',
        'Трекинг'
      ]
    },
    {
      title: 'Композитинг',
      topics: [
        'Работа с композициями',
        'Цветокоррекция',
        'Кеинг',
        'Стабилизация'
      ]
    },
    {
      title: 'Продвинутые техники',
      topics: [
        'Экспрессии',
        'Скрипты',
        'Рендеринг',
        'Оптимизация'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Обратная связь от преподавателя',
    'Доступ к учебным материалам',
    'Сертификат о прохождении'
  ]
};