import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_hr',
  title: 'HR-менеджер',
  description: 'Профессия «HR-менеджер» от Нетологии: освоите подбор, управление и мотивацию персонала за 8 месяцев. Практическое обучение с современными инструментами и поддержкой в трудоустройстве.',
  school: 'netology',
  category: 'management',
  price: 105000,
  oldPrice: 175000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fhr-manager&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2916,
  skills: [
    'Рекрутинг',
    'Управление персоналом',
    'HR-аналитика',
    'Развитие сотрудников',
    'Кадровое делопроизводство'
  ],
  program: [
    {
      title: 'Основы HR',
      topics: [
        'HR-стратегия',
        'Подбор персонала',
        'Адаптация',
        'Оценка персонала'
      ]
    },
    {
      title: 'Развитие персонала',
      topics: [
        'Обучение',
        'Мотивация',
        'Карьерное развитие',
        'Корпоративная культура'
      ]
    },
    {
      title: 'HR-процессы',
      topics: [
        'Кадровое делопроизводство',
        'HR-аналитика',
        'Автоматизация процессов',
        'Управление эффективностью'
      ]
    }
  ],
  features: [
    '20 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};