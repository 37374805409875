import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_product',
  title: 'Product Manager',
  description: 'Онлайн-курс «Продакт-менеджер» от Нетологии: пройдите через все этапы работы над продуктом, участвуйте в бизнес-играх и прожарках. Получите диплом и навыки для уверенного карьерного роста.',
  school: 'netology',
  category: 'management',
  price: 158160,
  oldPrice: 263600,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fprofession-product&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4393,
  skills: [
    'Управление продуктом',
    'Анализ рынка',
    'UX-исследования',
    'Метрики продукта',
    'Развитие продукта',
    'Работа с командой'
  ],
  program: [
    {
      title: 'Основы продакт-менеджмента',
      topics: [
        'Продуктовое мышление',
        'Анализ рынка',
        'Customer Development',
        'Метрики продукта'
      ]
    },
    {
      title: 'Развитие продукта',
      topics: [
        'Формирование гипотез',
        'A/B тестирование',
        'Приоритизация задач',
        'Roadmap продукта'
      ]
    },
    {
      title: 'Работа с командой',
      topics: [
        'Управление разработкой',
        'Коммуникация с дизайном',
        'Agile-процессы',
        'Запуск продукта'
      ]
    }
  ],
  features: [
    '18 практических занятий',
    'Бизнес-игры',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};