import React from 'react';
import { useCourseStore } from '../store/courseStore';

export const CourseFilters: React.FC = () => {
  const { 
    schools, 
    categories, 
    selectedSchool,
    selectedCategory,
    setSelectedSchool,
    setSelectedCategory 
  } = useCourseStore();

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Школы</h3>
        <div className="space-y-2">
          {schools.map(school => (
            <div key={school.id} className="flex items-center">
              <input
                type="checkbox"
                id={`school-${school.id}`}
                checked={selectedSchool === school.id}
                onChange={() => setSelectedSchool(selectedSchool === school.id ? null : school.id)}
                className="w-4 h-4 text-blue-600 rounded border-gray-300"
              />
              <label htmlFor={`school-${school.id}`} className="ml-2 text-gray-700">
                {school.name}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Категории</h3>
        <div className="space-y-2">
          {categories.map(category => (
            <div key={category.id} className="flex items-center">
              <input
                type="checkbox"
                id={`category-${category.id}`}
                checked={selectedCategory === category.id}
                onChange={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}
                className="w-4 h-4 text-blue-600 rounded border-gray-300"
              />
              <label htmlFor={`category-${category.id}`} className="ml-2 text-gray-700">
                {category.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};