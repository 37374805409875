import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_copywriter',
  title: 'Копирайтер',
  description: 'Курс «Копирайтер» от Нетологии: научитесь писать и редактировать тексты, работать с нейросетями и получите официальный сертификат. Освойте специализацию редактора и ускорьте свой карьерный рост.',
  school: 'netology',
  category: 'marketing',
  price: 46500,
  oldPrice: 77500,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fultimate-copywriter-full&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2583,
  discountPercent: 40,
  skills: [
    'Копирайтинг',
    'Редактура текстов',
    'Работа с нейросетями',
    'SEO-копирайтинг',
    'Создание контента'
  ],
  program: [
    {
      title: 'Основы копирайтинга',
      topics: [
        'Типы текстов',
        'Структура текста',
        'Стилистика',
        'Редактура'
      ]
    },
    {
      title: 'Специализации',
      topics: [
        'SEO-копирайтинг',
        'Рекламные тексты',
        'Контент-маркетинг',
        'Работа с нейросетями'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание портфолио',
        'Работа с заказчиками',
        'Продвижение услуг',
        'Фриланс'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с редактором',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат копирайтера'
  ]
};