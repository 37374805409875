import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_1c_analyst',
  title: 'Онлайн-курс 1С‑аналитик',
  description: 'За 8 месяцев научитесь анализировать рабочие процессы на базе 1С. Сможете начать работать сразу после обучения. Интенсивный формат — вебинары, видеоматериалы и практические задания',
  school: 'brunoyam',
  category: 'analytics',
  price: 74900,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2F1c-analyst&m=5&erid=LdtCK4uLs',
  skills: [
    'Анализ бизнес-процессов',
    'Работа с 1С',
    'Оптимизация процессов',
    'Управление изменениями',
    'Проектирование решений'
  ],
  program: [
    {
      title: 'Основы анализа в 1С',
      topics: [
        'Архитектура 1С',
        'Методологии анализа',
        'Работа с данными',
        'Моделирование процессов'
      ]
    },
    {
      title: 'Проектирование решений',
      topics: [
        'Разработка ТЗ',
        'Проектирование изменений',
        'Тестирование решений',
        'Внедрение изменений'
      ]
    },
    {
      title: 'Управление проектами',
      topics: [
        'Планирование работ',
        'Управление командой',
        'Контроль качества',
        'Документирование'
      ]
    }
  ],
  features: [
    '3 проекта в портфолио',
    'Практические задания',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};