import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_financial_management',
  title: 'Финансовый менеджмент',
  description: 'Это совместный курс с НИУ ВШЭ. Научитесь управлять финансами компании: анализировать отчётность, оценивать метрики и оптимизировать затраты.',
  school: 'yandex',
  category: 'management',
  price: 92000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Ffinancial-management&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление финансами',
    'Финансовый анализ',
    'Оптимизация затрат',
    'Бюджетирование',
    'Управленческий учет'
  ],
  program: [
    {
      title: 'Финансовый менеджмент',
      topics: [
        'Финансовое планирование',
        'Управление капиталом',
        'Оценка эффективности',
        'Риск-менеджмент'
      ]
    },
    {
      title: 'Управление затратами',
      topics: [
        'Анализ затрат',
        'Оптимизация расходов',
        'Контроллинг',
        'Бюджетирование'
      ]
    },
    {
      title: 'Практика управления',
      topics: [
        'Принятие решений',
        'Финансовая стратегия',
        'Инвестиционный анализ',
        'Отчетность'
      ]
    }
  ],
  features: [
    'Совместная программа с ВШЭ',
    'Практические кейсы',
    'Персональный наставник',
    'Помощь в развитии',
    'Сертификат о повышении квалификации'
  ]
};