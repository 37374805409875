import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_it_designer',
  title: 'Дизайнер в IT',
  description: 'Первые 3 месяца студенты учатся основам дизайна и профориентируются, после чего проходят ассессмент и продолжают обучение следующие 6 месяцев, уже погружаясь в одну из четырех профессий в дизайне.',
  school: 'yandex',
  category: 'design',
  price: 139000,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fit-designer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Основы дизайна',
    'UX/UI дизайн',
    'Графический дизайн',
    'Веб-дизайн',
    'Проектирование интерфейсов'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Типографика',
        'Цветоведение'
      ]
    },
    {
      title: 'Специализация',
      topics: [
        'UX/UI дизайн',
        'Веб-дизайн',
        'Графический дизайн',
        'Моушн-дизайн'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Создание портфолио',
        'Работа с заказчиками',
        'Презентация работ'
      ]
    }
  ],
  features: [
    'Выбор специализации',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат дизайнера'
  ]
};