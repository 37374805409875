import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_excel_base',
  title: 'Онлайн-курс Excel. База',
  description: 'Вводный курс для тех, кто никогда не работал в Microsoft Excel. На практике разберете интерфейс, основные возможности, работу с формулами, диаграммами и графиками',
  school: 'brunoyam',
  category: 'analytics',
  price: 5900,
  currency: 'RUB',
  duration: '31 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1543286386-713bdd548da4?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fexcel-base&m=5&erid=LdtCK4uLs',
  skills: [
    'Основы Excel',
    'Работа с формулами',
    'Создание таблиц',
    'Построение диаграмм',
    'Базовая аналитика'
  ],
  program: [
    {
      title: 'Основы работы',
      topics: [
        'Интерфейс Excel',
        'Ввод данных',
        'Форматирование',
        'Работа с листами'
      ]
    },
    {
      title: 'Формулы и функции',
      topics: [
        'Базовые формулы',
        'Математические функции',
        'Логические функции',
        'Ссылки'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Создание таблиц',
        'Построение диаграмм',
        'Графики',
        'Форматирование данных'
      ]
    }
  ],
  features: [
    '4 практических проекта',
    'Поддержка куратора',
    'Интерактивные задания',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};