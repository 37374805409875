import { Course } from '../../../../types';

// Import all courses
import { course as accountant } from './accountant';
import { course as accountantPro } from './accountant-pro';
import { course as advancedMethodologist } from './advanced-methodologist';
import { course as agileManagement } from './agile-management';
import { course as agileManagementPro } from './agile-management-pro';
import { course as aiMedicine } from './ai-medicine';
import { course as animatorMultiplikator } from './animator-multiplikator';
import { course as argumentationBasics } from './argumentation';
import { course as aromatherapyBasics } from './aromatherapy-basics';
import { course as automationEngineer } from './automation-engineer';
import { course as avitoManager } from './avito-manager';
import { course as bachelorMedia } from './bachelor-media';
import { course as bachelorProjectManagement } from './bachelor-project-management';
import { course as biAnalyst } from './bi-analyst';
import { course as bitrixDeveloper } from './bitrix-developer';
import { course as bloodFormula } from './blood-formula';
import { course as bpmn } from './bpmn-modeling';
import { course as brandManager } from './brand-manager';
import { course as businessAnalyst } from './business-analyst';
import { course as businessProcessManager } from './business-process-manager';
import { course as businessProcessManagerPro } from './business-process-manager-pro';
import { course as businessSimulation } from './business-simulation';
import { course as businessTracker } from './business-tracker';
import { course as businessTrackerPro } from './business-tracker-pro';
import { course as clinicAdministrator } from './clinic-administrator';
import { course as commercialIllustrator } from './commercial-illustrator';
import { course as contextAdvertising } from './context-advertising';
import { course as copywriter } from './copywriter';
import { course as criticalThinking } from './critical-thinking';
import { course as cppDeveloper } from './cpp-developer';
import { course as dataAnalyst } from './data-analyst';
import { course as dataAnalystPro } from './data-analyst-pro';
import { course as dataAnalyticsMfti } from './data-analytics-mfti';
import { course as dataDrivenProduct } from './data-driven-product';
import { course as dataEngineer } from './data-engineer';
import { course as deepLearning } from './deep-learning';
import { course as designBasics } from './design-basics';
import { course as designVision } from './design-vision';
import { course as devopsBasic } from './devops-basic';
import { course as devopsEngineer } from './devops-engineer';
import { course as digitalPr } from './digital-pr';
import { course as djangoBackend } from './django-backend';
import { course as doctorCareer } from './doctor-career';
import { course as drawingBasics } from './drawing-basics';
import { course as educationalMethodologist } from './educational-methodologist';
import { course as effectiveManager } from './effective-manager';
import { course as emotionalBurnout } from './emotional-burnout';
import { course as emotionalIntelligence } from './emotional-intelligence';
import { course as emotionalIntelligenceCourse } from './emotional-intelligence-course';
import { course as emotionalIntelligencePro } from './emotional-intelligence-pro';
import { course as environmentDesigner } from './environment-designer';
import { course as excelAdvanced } from './excel-advanced';
import { course as excelBasic } from './excel-basic';
import { course as figmaBasics } from './figma-basics';
import { course as financialAnalyst } from './financial-analyst';
import { course as financialDirector } from './financial-director';
import { course as financialManagerBasic } from './financial-manager-basic';
import { course as financialManagerPro } from './financial-manager-pro';
import { course as frontendDeveloper } from './frontend-developer';
import { course as frontendDeveloperPro } from './frontend-developer-pro';
import { course as fullstackDevops } from './fullstack-devops';
import { course as fullstackPython } from './fullstack-python';
import { course as gameDesignBasic } from './game-design-basic';
import { course as gameDesignBasics } from './game-design-basics';
import { course as goalSetting } from './goal-setting';
import { course as graphicDesigner } from './graphic-designer';
import { course as graphicDesignerPro } from './graphic-designer-pro';
import { course as healthyNutrition } from './healthy-nutrition';
import { course as healthcareManagement } from './healthcare-management';
import { course as hrManager } from './hr-manager';
import { course as illustrationBasics } from './illustration-basics';
import { course as illustratorCommercial } from './illustrator-commercial';
import { course as informationSecurity } from './information-security';
import { course as interiorDesigner } from './interior-designer';
import { course as internetMarketer } from './internet-marketer';
import { course as internetMarketingDirector } from './internet-marketing-director';
import { course as internetMarketingSpecialist } from './internet-marketing-specialist';
import { course as iosDeveloper } from './ios-developer';
import { course as itProfessions } from './it-professions';
import { course as itProjectManagementMba } from './it-project-management-mba';
import { course as itSpecialist } from './it-specialist';
import { course as javaDeveloper } from './java-developer';
import { course as landscapeDesigner } from './landscape-designer';
import { course as landscapeDesignerPro } from './landscape-designer-pro';
import { course as machineLearning } from './machine-learning';
import { course as marketingAnalyst } from './marketing-analyst';
import { course as marketplaceManager } from './marketplace-manager';
import { course as marketplaceManagerPro } from './marketplace-manager-pro';
import { course as marketplacePromotion } from './marketplace-promotion';
import { course as medicalBoundaries } from './medical-boundaries';
import { course as medicalBoundariesPro } from './medical-boundaries-pro';
import { course as medicalCommunication } from './medical-communication';
import { course as medicalCommunicationPro } from './medical-communication-pro';
import { course as medicalMarketing } from './medical-marketing';
import { course as methodist } from './methodist';
import { course as miniMba } from './mini-mba';
import { course as mobileAppDesigner } from './mobile-app-designer';
import { course as motionDesign } from './motion-design';
import { course as networkEngineer } from './network-engineer';
import { course as neuralNetworks } from './neural-networks';
import { course as nodejsBackend } from './nodejs-backend';
import { course as onlineSchoolCurator } from './online-school-curator';
import { course as onlineSchoolCuratorPro } from './online-school-curator-pro';
import { course as operationsManager } from './operations-manager';
import { course as operationsManagerBasic } from './operations-manager-basic';
import { course as operationsManagerPro } from './operations-manager-pro';
import { course as personalFinance } from './personal-finance';
import { course as personalFinancePro } from './personal-finance-pro';
import { course as powerBi } from './power-bi';
import { course as powerBiExcel } from './power-bi-excel';
import { course as presentationDesign } from './presentation-design';
import { course as productManager } from './product-manager';
import { course as productManagerPro } from './product-manager-pro';
import { course as productMarketingFinance } from './product-marketing-finance';
import { course as projectManager } from './project-manager';
import { course as psychologyBasics } from './psychology-basics';
import { course as publicSpeaking } from './public-speaking';
import { course as publicSpeakingIndividual } from './public-speaking-individual';
import { course as publicSpeakingPro } from './public-speaking-pro';
import { course as pythonAnalytics } from './python-analytics';
import { course as pythonBasic } from './python-basic';
import { course as pythonDeveloper } from './python-developer';
import { course as pythonDeveloperBasic } from './python-developer-basic';
import { course as qaEngineer } from './qa-engineer';
import { course as reactFrontend } from './react-frontend';
import { course as relationshipPsychology } from './relationship-psychology';
import { course as salesManager } from './sales-manager';
import { course as salesManagerPro } from './sales-manager-pro';
import { course as screenwriter } from './screenwriter';
import { course as seoBasics } from './seo-basics';
import { course as seoSpecialist } from './seo-specialist';
import { course as softSkillsManager } from './soft-skills-manager';
import { course as soundDesigner } from './sound-designer';
import { course as soundDesignPro } from './sound-design-pro';
import { course as speechTechnique } from './speech-technique';
import { course as sportsManagement } from './sports-management';
import { course as sqlAdvanced } from './sql-advanced';
import { course as sqlAndData } from './sql-and-data';
import { course as sqlBasics } from './sql-basics';
import { course as stylist } from './stylist';
import { course as systemAdministrator } from './system-administrator';
import { course as systemAnalyst } from './system-analyst';
import { course as targetingSpecialist } from './targeting-specialist';
import { course as targetingSpecialistPro } from './targeting-specialist-pro';
import { course as teamLeader } from './team-leader';
import { course as telegramPromotion } from './telegram-promotion';
import { course as telegramPromotionSelf } from './telegram-promotion-self';
import { course as timeManagement } from './time-management';
import { course as trafficManager } from './traffic-manager';
import { course as trafficManagerBasic } from './traffic-manager-basic';
import { course as trafficManagerPro } from './traffic-manager-pro';
import { course as unitEconomics } from './unit-economics';
import { course as unrealEngineDeveloper } from './unreal-engine-developer';
import { course as uxResearch } from './ux-research';
import { course as uxResearchPro } from './ux-research-pro';
import { course as uxuiDesigner } from './uxui-designer';
import { course as uxuiDesignerBasic } from './uxui-designer-basic';
import { course as uxuiDesignerPro } from './uxui-designer-pro';
import { course as videoEditing } from './video-editing';
import { course as voiceSpeech } from './voice-speech';
import { course as voiceSpeechPro } from './voice-speech-pro';
import { course as webDesigner } from './web-designer';
import { course as webDeveloperSpecialization } from './web-developer-specialization';
import { course as workLifeBalance } from './work-life-balance';
import { course as yandexDirect } from './yandex-direct';
import { course as yogaBasics } from './yoga-basics';

// Export all courses
export const netologyCourses: Course[] = [
  accountant,
  accountantPro,
  advancedMethodologist,
  agileManagement,
  agileManagementPro,
  aiMedicine,
  animatorMultiplikator,
  argumentationBasics,
  aromatherapyBasics,
  automationEngineer,
  avitoManager,
  bachelorMedia,
  bachelorProjectManagement,
  biAnalyst,
  bitrixDeveloper,
  bloodFormula,
  bpmn,
  brandManager,
  businessAnalyst,
  businessProcessManager,
  businessProcessManagerPro,
  businessSimulation,
  businessTracker,
  businessTrackerPro,
  clinicAdministrator,
  commercialIllustrator,
  contextAdvertising,
  copywriter,
  criticalThinking,
  cppDeveloper,
  dataAnalyst,
  dataAnalystPro,
  dataAnalyticsMfti,
  dataDrivenProduct,
  dataEngineer,
  deepLearning,
  designBasics,
  designVision,
  devopsBasic,
  devopsEngineer,
  digitalPr,
  djangoBackend,
  doctorCareer,
  drawingBasics,
  educationalMethodologist,
  effectiveManager,
  emotionalBurnout,
  emotionalIntelligence,
  emotionalIntelligenceCourse,
  emotionalIntelligencePro,
  environmentDesigner,
  excelAdvanced,
  excelBasic,
  figmaBasics,
  financialAnalyst,
  financialDirector,
  financialManagerBasic,
  financialManagerPro,
  frontendDeveloper,
  frontendDeveloperPro,
  fullstackDevops,
  fullstackPython,
  gameDesignBasic,
  gameDesignBasics,
  goalSetting,
  graphicDesigner,
  graphicDesignerPro,
  healthyNutrition,
  healthcareManagement,
  hrManager,
  illustrationBasics,
  illustratorCommercial,
  informationSecurity,
  interiorDesigner,
  internetMarketer,
  internetMarketingDirector,
  internetMarketingSpecialist,
  iosDeveloper,
  itProfessions,
  itProjectManagementMba,
  itSpecialist,
  javaDeveloper,
  landscapeDesigner,
  landscapeDesignerPro,
  machineLearning,
  marketingAnalyst,
  marketplaceManager,
  marketplaceManagerPro,
  marketplacePromotion,
  medicalBoundaries,
  medicalBoundariesPro,
  medicalCommunication,
  medicalCommunicationPro,
  medicalMarketing,
  methodist,
  miniMba,
  mobileAppDesigner,
  motionDesign,
  networkEngineer,
  neuralNetworks,
  nodejsBackend,
  onlineSchoolCurator,
  onlineSchoolCuratorPro,
  operationsManager,
  operationsManagerBasic,
  operationsManagerPro,
  personalFinance,
  personalFinancePro,
  powerBi,
  powerBiExcel,
  presentationDesign,
  productManager,
  productManagerPro,
  productMarketingFinance,
  projectManager,
  psychologyBasics,
  publicSpeaking,
  publicSpeakingIndividual,
  publicSpeakingPro,
  pythonAnalytics,
  pythonBasic,
  pythonDeveloper,
  pythonDeveloperBasic,
  qaEngineer,
  reactFrontend,
  relationshipPsychology,
  salesManager,
  salesManagerPro,
  screenwriter,
  seoBasics,
  seoSpecialist,
  softSkillsManager,
  soundDesigner,
  soundDesignPro,
  speechTechnique,
  sportsManagement,
  sqlAdvanced,
  sqlAndData,
  sqlBasics,
  stylist,
  systemAdministrator,
  systemAnalyst,
  targetingSpecialist,
  targetingSpecialistPro,
  teamLeader,
  telegramPromotion,
  telegramPromotionSelf,
  timeManagement,
  trafficManager,
  trafficManagerBasic,
  trafficManagerPro,
  unitEconomics,
  unrealEngineDeveloper,
  uxResearch,
  uxResearchPro,
  uxuiDesigner,
  uxuiDesignerBasic,
  uxuiDesignerPro,
  videoEditing,
  voiceSpeech,
  voiceSpeechPro,
  webDesigner,
  webDeveloperSpecialization,
  workLifeBalance,
  yandexDirect,
  yogaBasics
];