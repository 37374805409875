import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_ai_design',
  title: 'Онлайн-курс Нейросети для дизайнеров',
  description: 'Научитесь работать с нейросетями, чтобы с их помощью генерировать больше идей и референсов, экономить время на рутине, и находить решения для дизайн-проектов',
  school: 'brunoyam',
  category: 'design',
  price: 15900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://sh-bucket.storage.yandexcloud.net/blog/6686b9b049444500013562c2.webp?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fneural-networks-for-designers&m=5&erid=LdtCK4uLs',
  skills: [
    'Работа с AI-инструментами',
    'Генерация изображений',
    'Обработка графики',
    'Создание концепций',
    'Оптимизация процессов'
  ],
  program: [
    {
      title: 'Основы AI в дизайне',
      topics: [
        'Обзор инструментов',
        'Принципы работы',
        'Prompt Engineering',
        'Ограничения систем'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Генерация идей',
        'Создание визуалов',
        'Обработка изображений',
        'Работа со стилями'
      ]
    },
    {
      title: 'Интеграция в рабочий процесс',
      topics: [
        'Оптимизация работы',
        'Автоматизация задач',
        'Совместное использование',
        'Оценка результатов'
      ]
    }
  ],
  features: [
    '10 практических проектов',
    'Работа с AI-инструментами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат по окончании'
  ]
};