import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_aromatherapy',
  title: 'Основы ароматерапии: полезные свойства эфирных масел',
  description: 'Курс «Основы ароматерапии» от Нетологии: обучение безопасному и эффективному использованию эфирных масел за 2 месяца. Применяйте ароматерапию для улучшения здоровья и благополучия с консультациями сертифицированного клинического ароматерапевта. Практические задания и поддержка эксперта помогут освоить техники для личного и профессионального использования.',
  school: 'netology',
  category: 'medicine',
  price: 18700,
  oldPrice: 18700,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1608571423902-eed4a5ad8108?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Faromaterapiya&m=4&erid=LdtCKCxkP',
  monthlyPayment: 1558,
  skills: [
    'Ароматерапия',
    'Работа с маслами',
    'Безопасное применение',
    'Составление смесей',
    'Оздоровительные практики'
  ],
  program: [
    {
      title: 'Основы ароматерапии',
      topics: [
        'Свойства масел',
        'Безопасность применения',
        'Методы использования',
        'Базовые масла'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Составление смесей',
        'Ароматические композиции',
        'Лечебные программы',
        'Профилактика'
      ]
    },
    {
      title: 'Специальные техники',
      topics: [
        'Массаж с маслами',
        'Ароматические ванны',
        'Компрессы',
        'Ингаляции'
      ]
    }
  ],
  features: [
    'Практические занятия',
    'Работа с маслами',
    'Консультации эксперта',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};