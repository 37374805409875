import { Course } from '../../../../types';

export const course: Course = {
  id: 'anecole_spanish_business',
  title: 'Бизнес испанский',
  description: 'Для тех, кто хочет освоить испанский для работы, общения с иностранными коллегами или ведения бизнеса в другой стране.',
  school: 'anecole',
  category: 'spanish',
  price: 19120,
  currency: 'RUB',
  duration: '8 часов',
  imageUrl: 'https://images.unsplash.com/photo-1596386461350-326ccb383e9f?q=80&w=1200',
  url: 'https://go.redav.online/4f106e4f2bb25c71?dl=https%3A%2F%2Fanecole.com%2Fspanish-business&m=5&erid=2Vfnxwa5gNL',
  monthlyPayment: 4780,
  skills: [
    'Деловая коммуникация',
    'Бизнес-лексика',
    'Ведение переговоров',
    'Деловая переписка',
    'Презентации на испанском'
  ],
  program: [
    {
      title: 'Вводный блок',
      topics: [
        'Вводный урок',
        'Фонетика',
        'Базовая грамматика',
        'Основы делового общения'
      ]
    },
    {
      title: 'Основной курс',
      topics: [
        'Бизнес-лексика',
        'Деловая переписка',
        'Телефонные переговоры',
        'Проведение презентаций'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивные тренажеры',
    'Разговорная практика',
    'Проверка домашних заданий',
    'Сертификат по окончании'
  ]
};