import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_business_analytics',
  title: 'Бизнес-аналитика',
  description: 'Программа повышения квалификации Бизнес-аналитика с присвоением квалификации Бизнес-аналитик (800 ч.) в дистанционном формате на базе высшего или среднего профессионального образования, 2024 год.',
  school: 'appkk',
  category: 'management',
  price: 33600,
  currency: 'RUR',
  duration: '800 часов',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fbiznes_analitika_s_prisvoeniem_kvalifikatsii_biznes_analitik%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Бизнес-анализ',
    'Управление требованиями',
    'Моделирование процессов',
    'Анализ данных',
    'Проектное управление'
  ],
  program: [
    {
      title: 'Основы бизнес-анализа',
      topics: [
        'Роль бизнес-аналитика',
        'Методологии анализа',
        'Сбор требований',
        'Документация'
      ]
    },
    {
      title: 'Практические инструменты',
      topics: [
        'Моделирование процессов',
        'Анализ данных',
        'Управление изменениями',
        'Оптимизация процессов'
      ]
    },
    {
      title: 'Проектная работа',
      topics: [
        'Управление проектами',
        'Работа с заказчиками',
        'Презентация результатов',
        'Внедрение изменений'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с кейсами',
    'Проектная работа',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};