import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_psychoanalysis_counseling_full',
  title: 'Психолог-консультант с дополнительной специализацией в области психоанализа',
  description: 'Дистанционное обучение по программе Психолог-консультант с дополнительной специализацией в области психоанализа. Методы и технологии оказания психологических услуг населению и организациям с присвоением квалификации Психолог-консультант (1650 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 110000,
  currency: 'RUR',
  duration: '1650 часов',
  imageUrl: 'https://images.unsplash.com/photo-1590650153855-d9e808231d41?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpsikholog_konsultant_s_dopolnitelnoy_spetsializatsiey_v_oblasti_psikhoanaliza_metody_i_tekhnologii_o%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Психоаналитическое консультирование',
    'Работа с бессознательным',
    'Анализ сопротивления',
    'Работа с переносом',
    'Интерпретация'
  ],
  program: [
    {
      title: 'Основы психоанализа',
      topics: [
        'Теория психоанализа',
        'Структура психики',
        'Механизмы защиты',
        'Развитие личности'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Психоаналитическое интервью',
        'Работа со сновидениями',
        'Анализ сопротивления',
        'Работа с переносом'
      ]
    },
    {
      title: 'Практика консультирования',
      topics: [
        'Ведение случая',
        'Супервизия',
        'Работа с разными запросами',
        'Этические вопросы'
      ]
    }
  ],
  features: [
    'Углубленная специализация',
    'Практические навыки',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};