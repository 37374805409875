import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_datascience',
  title: 'Профессия Data Scientist с нуля до Junior',
  description: 'Онлайн курс «Data Scientist» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 9 месяцев. Помощь в трудоустройстве! Обучение Data Science с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 105008,
  oldPrice: 210015,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/OyNACgm2RkMX83IaD1odgdwevkKqBUsgCwmHBvju.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fanalyst%2Fdata-science-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Анализ данных на Python',
    'Машинное обучение',
    'Нейронные сети',
    'Статистика и математика',
    'Big Data',
    'Визуализация данных'
  ],
  program: [
    {
      title: 'Основы Data Science',
      topics: [
        'Python для анализа данных',
        'Pandas и NumPy',
        'Математическая статистика',
        'Визуализация в Python'
      ]
    },
    {
      title: 'Машинное обучение',
      topics: [
        'Классификация и регрессия',
        'Кластеризация',
        'Ансамбли моделей',
        'Оценка качества моделей'
      ]
    },
    {
      title: 'Нейронные сети',
      topics: [
        'Deep Learning',
        'Computer Vision',
        'NLP',
        'TensorFlow и PyTorch'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Big Data',
        'Apache Spark',
        'MLOps',
        'Развертывание моделей'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат Data Scientist'
  ]
};