import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_wildberries',
  title: 'Как продавать на Wildberries',
  description: 'Онлайн курс «Как продавать на Wildberries» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 3 месяца. Помощь в трудоустройстве! Обучение продажам на Wildberries с нуля онлайн.',
  school: 'geekbrains',
  category: 'marketing',
  price: 30477,
  oldPrice: 60953,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/lcNkMTMKcRSOwo0T5veqGMIX4ZXOL9XuBQRSGqH3.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmarketing%2Fwildberries-sales&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Работа с маркетплейсом',
    'Управление магазином',
    'Аналитика продаж',
    'Продвижение товаров',
    'Оптимизация карточек',
    'Работа с остатками'
  ],
  program: [
    {
      title: 'Основы работы с WB',
      topics: [
        'Регистрация магазина',
        'Настройка кабинета',
        'Работа с каталогом',
        'Управление товарами'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Оптимизация карточек',
        'Работа с фото',
        'Описания товаров',
        'Реклама на площадке'
      ]
    },
    {
      title: 'Аналитика и финансы',
      topics: [
        'Анализ продаж',
        'Ценообразование',
        'Работа с остатками',
        'Финансовая отчетность'
      ]
    },
    {
      title: 'Масштабирование',
      topics: [
        'Расширение ассортимента',
        'Работа с поставщиками',
        'Автоматизация процессов',
        'Управление командой'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальным магазином',
    'Персональный наставник',
    'Помощь в запуске бизнеса',
    'Сертификат о прохождении'
  ]
};