import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_graphintcd',
  title: 'Интенсив по графическому дизайну',
  description: 'Интенсивное обучение графическому дизайну. Освойте ключевые навыки и инструменты для создания профессиональных дизайн-проектов в сжатые сроки.',
  school: 'contented',
  category: 'design',
  price: 127079,
  oldPrice: 231053,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://thumb.tildacdn.com/tild3231-3336-4035-b138-376234353734/-/resize/900x/-/format/webp/___.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fgraphic-designer-intentsive&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3530,
  discountPercent: 45,
  skills: [
    'Работа в Adobe Creative Suite',
    'Создание брендинга',
    'Дизайн полиграфии',
    'Типографика',
    'Композиция и цвет',
    'Подготовка к печати',
    'Презентация проектов'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Работа с композицией',
        'Цветовые решения',
        'Типографика'
      ]
    },
    {
      title: 'Инструменты дизайнера',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe InDesign',
        'Figma'
      ]
    },
    {
      title: 'Практические проекты',
      topics: [
        'Создание логотипа',
        'Разработка брендбука',
        'Дизайн упаковки',
        'Рекламные материалы'
      ]
    },
    {
      title: 'Подготовка к работе',
      topics: [
        'Создание портфолио',
        'Работа с заказчиками',
        'Презентация проектов',
        'Подготовка к печати'
      ]
    }
  ],
  features: [
    'Интенсивный формат обучения',
    'Практика на реальных проектах',
    'Персональный куратор',
    'Помощь в создании портфолио',
    'Сертификат о прохождении курса'
  ]
};