import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_yoga',
  title: 'Йога для начинающих',
  description: 'Курс йоги для начинающих — обучение в Нетологии онлайн. Погрузитесь в эффективные и доступные практики йоги: научитесь расслабляться, укрепите мышцы и наполнитесь энергией.',
  school: 'netology',
  category: 'lifestyle',
  price: 4140,
  oldPrice: 6900,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fyoga&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4140,
  skills: [
    'Основы йоги',
    'Правильное дыхание',
    'Базовые асаны',
    'Медитация',
    'Расслабление'
  ],
  program: [
    {
      title: 'Введение в йогу',
      topics: [
        'Философия йоги',
        'Техника безопасности',
        'Дыхательные практики',
        'Базовые позы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Последовательности асан',
        'Медитация',
        'Расслабление',
        'Домашняя практика'
      ]
    }
  ],
  features: [
    '17 практических занятий',
    'Индивидуальный подход',
    'Поддержка инструктора',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};