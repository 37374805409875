import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_social_oge',
  title: 'Годовой курс по обществознанию ОГЭ «Сила Коржа ОГЭ 2024/2025»',
  description: 'Годовой курс «Сила Коржа» - это подготовка к ОГЭ по обществу с нуля на отлично. Комплексная программа с опытными преподавателями.',
  school: '100points',
  category: 'social',
  price: 26890,
  oldPrice: 31390,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1577036421869-7c8d388d2123?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 2990,
  discountPercent: 15,
  skills: [
    'Анализ общественных явлений',
    'Работа с документами',
    'Решение задач',
    'Написание эссе',
    'Аргументация',
    'Работа с понятиями'
  ],
  program: [
    {
      title: 'Человек и общество',
      topics: [
        'Общество как система',
        'Человек и его деятельность',
        'Духовная сфера',
        'Познание'
      ]
    },
    {
      title: 'Социальная сфера',
      topics: [
        'Социальная структура',
        'Семья',
        'Образование',
        'Нации и межнациональные отношения'
      ]
    },
    {
      title: 'Экономика',
      topics: [
        'Экономические системы',
        'Рынок',
        'Деньги',
        'Государство в экономике'
      ]
    },
    {
      title: 'Политика и право',
      topics: [
        'Государство',
        'Политические режимы',
        'Права человека',
        'Конституция РФ'
      ]
    }
  ],
  features: [
    '2 вебинара в неделю',
    'Проверка работ',
    'Разбор заданий',
    'Система мотивации',
    'Доступ к записям уроков'
  ]
};