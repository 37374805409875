import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_ai_design',
  title: 'Нейросети для дизайна',
  description: 'Курс поможет понять, какие типы нейросетей (txt2txt, txt2img, img2img) можно использовать для задач дизайнера. Мы не стремились охватить все популярные технологии, но изучим основные из них. Также вы научитесь выбирать альтернативные инструменты, если потеряете доступ к какой-то нейросети. Программа учитывает, как устроен дизайн-процесс, чтобы у студентов сложилось комплексное понимание, где и как можно применять нейросетевые технологии.',
  school: 'yandex',
  category: 'design',
  price: 60000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://s0.rbk.ru/v6_top_pics/media/img/5/37/756723963353375.jpg?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fai-tools-for-designers&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Работа с нейросетями',
    'Генерация изображений',
    'Prompt Engineering',
    'Обработка изображений',
    'AI в дизайне'
  ],
  program: [
    {
      title: 'Основы AI для дизайна',
      topics: [
        'Типы нейросетей',
        'Принципы работы',
        'Prompt Engineering',
        'Ограничения технологий'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Генерация изображений',
        'Обработка графики',
        'Создание вариаций',
        'Стилизация'
      ]
    },
    {
      title: 'Интеграция в рабочий процесс',
      topics: [
        'Workflow с AI',
        'Автоматизация задач',
        'Оптимизация процессов',
        'Этические аспекты'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с AI-инструментами',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат специалиста'
  ]
};