import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_history_std',
  title: 'История [Стандарт]',
  description: 'Мастер группа - это проверенный годами формат подготовки, который позволит тебе пройти и закрепить программу, а также закрыть все проблемные темы в размеренном темпе!',
  school: '99ballov',
  category: 'history',
  price: 3790,
  oldPrice: 4290,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1461360370896-922624d12aa1?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 12,
  skills: [
    'Анализ исторических событий',
    'Работа с источниками',
    'Исторические даты',
    'Причинно-следственные связи',
    'Исторические личности'
  ],
  program: [
    {
      title: 'Теория и практика',
      topics: [
        '3-4 занятия в неделю',
        'Каждое занятие 1.5-2 часа',
        'Двухуровневые домашние задания',
        'Работа над ошибками'
      ]
    },
    {
      title: 'Материалы и поддержка',
      topics: [
        'Рабочие тетради и конспекты',
        'Доступ к образовательной платформе',
        'Записи занятий с тайм-кодами',
        'Онлайн-куратор'
      ]
    }
  ],
  features: [
    'Образовательная платформа',
    'Неограниченная практика',
    'Проверка домашних заданий',
    'Персональный менеджер',
    'Зачеты и пробники'
  ]
};