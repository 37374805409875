import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_frontend',
  title: 'Фронтенд-разработчик',
  description: 'Научитесь с нуля разрабатывать сайты и веб-сервисы на HTML, CSS и JavaScript.',
  school: 'yandex',
  category: 'programming',
  price: 144000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1593720213428-28a5b9e94613?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Ffrontend-developer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'HTML и CSS',
    'JavaScript',
    'React',
    'Адаптивная верстка',
    'Работа с API'
  ],
  program: [
    {
      title: 'Основы веб-разработки',
      topics: [
        'HTML5',
        'CSS3',
        'JavaScript',
        'Git'
      ]
    },
    {
      title: 'JavaScript и React',
      topics: [
        'ES6+',
        'React',
        'Redux',
        'TypeScript'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с API',
        'Тестирование',
        'Оптимизация',
        'Деплой'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Code review',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};