import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_time_management',
  title: 'Тайм-менеджмент',
  description: 'Обучение эффективному планированию временем: полный онлайн-курс тайм-менеджмента с сертификатом от «Нетологии»!',
  school: 'netology',
  category: 'management',
  price: 8880,
  oldPrice: 14800,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ftajm-menedzhment-i-upravlenie-resursami&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 8880,
  skills: [
    'Планирование времени',
    'Целеполагание',
    'Продуктивность',
    'Приоритизация',
    'Делегирование'
  ],
  program: [
    {
      title: 'Основы тайм-менеджмента',
      topics: [
        'Анализ времени',
        'Постановка целей',
        'Планирование',
        'Инструменты'
      ]
    },
    {
      title: 'Практика управления временем',
      topics: [
        'Продуктивность',
        'Борьба с прокрастинацией',
        'Энергоменеджмент',
        'Привычки'
      ]
    }
  ],
  features: [
    '20 практических занятий',
    'Личный тайм-трекер',
    'Практические инструменты',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};