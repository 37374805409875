import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_landscape',
  title: 'Профессия Ландшафтный дизайнер',
  description: 'Онлайн курс «Ландшафтный дизайнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 8 месяцев. Помощь в трудоустройстве! Обучение на ландшафтного дизайнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'design',
  price: 121974,
  oldPrice: 243947,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/1og3SCcoJeHrBn9XvN6D2zMnz6ZyJt6YgAdElKDH.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2Flandscape-spec-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Проектирование ландшафта',
    'Работа с растениями',
    'Создание генплана',
    'Визуализация проектов',
    'Дендрология',
    'Организация пространства'
  ],
  program: [
    {
      title: 'Основы ландшафтного дизайна',
      topics: [
        'История ландшафтного искусства',
        'Стили в ландшафтном дизайне',
        'Композиция',
        'Колористика'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Создание генплана',
        'Разбивочный чертеж',
        'Дендроплан',
        'План освещения'
      ]
    },
    {
      title: 'Работа с растениями',
      topics: [
        'Дендрология',
        'Подбор растений',
        'Уход за растениями',
        'Сезонные работы'
      ]
    },
    {
      title: 'Визуализация и документация',
      topics: [
        '3D-визуализация',
        'Чертежи и планы',
        'Смета проекта',
        'Авторский надзор'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат ландшафтного дизайнера'
  ]
};