import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_literature',
  title: 'Годовой курс по подготовке к ЕГЭ по литературе «Литфак 2024/2025»',
  description: 'Если хочешь 100 баллов за ЕГЭ по литературе, этот курс для тебя! Комплексная подготовка с фокусом на написание сочинений и анализ художественных текстов.',
  school: '100points',
  category: 'literature',
  price: 39490,
  oldPrice: 45990,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1516979187457-637abb4f9353?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 4490,
  discountPercent: 15,
  skills: [
    'Анализ художественных текстов',
    'Написание сочинений',
    'Работа со стихотворениями',
    'Знание теории литературы',
    'Аргументация',
    'Работа с критикой'
  ],
  program: [
    {
      title: 'Древнерусская литература и XVIII век',
      topics: [
        'Слово о полку Игореве',
        'Классицизм',
        'Сентиментализм',
        'Литература XVIII века'
      ]
    },
    {
      title: 'Литература XIX века',
      topics: [
        'Романтизм',
        'Реализм',
        'Золотой век поэзии',
        'Великие романы'
      ]
    },
    {
      title: 'Литература XX века',
      topics: [
        'Серебряный век',
        'Советская литература',
        'Военная проза',
        'Современная литература'
      ]
    },
    {
      title: 'Теория литературы',
      topics: [
        'Роды и жанры',
        'Художественные средства',
        'Стихосложение',
        'Анализ текста'
      ]
    }
  ],
  features: [
    '3 веба в неделю',
    'Проверка сочинений',
    'Разбор произведений',
    'Работа с критикой',
    'Психологическая поддержка'
  ]
};