import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_adphoto',
  title: 'Курс «Авторская фотография»',
  description: 'Профессиональный курс по фотографии для тех, кто хочет научиться создавать уникальные художественные снимки и развить свой авторский стиль.',
  school: 'contented',
  category: 'photo-video',
  price: 35802,
  oldPrice: 79560,
  currency: 'RUR',
  duration: '24 месяца',
  imageUrl: 'https://static.tildacdn.com/stor3636-3931-4639-a563-343864323338/59662164.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fphotography&m=5&erid=LdtCKHL5u',
  monthlyPayment: 2984,
  discountPercent: 55,
  skills: [
    'Работа с профессиональной фототехникой',
    'Композиция и построение кадра',
    'Работа со светом и цветом',
    'Портретная фотография',
    'Пейзажная съемка',
    'Обработка фотографий',
    'Создание фотопроектов'
  ],
  program: [
    {
      title: 'Основы фотографии',
      topics: [
        'Устройство фотокамеры',
        'Экспозиция и режимы съемки',
        'Фокусировка и глубина резкости'
      ]
    },
    {
      title: 'Композиция',
      topics: [
        'Правила композиции',
        'Работа с пространством',
        'Ритм и баланс в кадре'
      ]
    },
    {
      title: 'Свет в фотографии',
      topics: [
        'Естественное освещение',
        'Студийный свет',
        'Световые схемы'
      ]
    },
    {
      title: 'Жанры фотографии',
      topics: [
        'Портрет',
        'Пейзаж',
        'Репортаж',
        'Предметная съемка'
      ]
    }
  ],
  features: [
    'Практические задания с обратной связью',
    'Индивидуальные консультации',
    'Доступ к онлайн-библиотеке',
    'Сертификат о прохождении курса',
    'Помощь в формировании портфолио'
  ]
};