import { Course } from '../../../../types';
import { course as artTherapy } from './art-therapy';
import { course as cognitiveTherapy } from './cognitive-therapy';
import { course as metaphoricCards } from './metaphoric-cards';
import { course as childNeuropsychology } from './child-neuropsychology';
import { course as neurofitness } from './neurofitness';
import { course as coachingBasics } from './coaching-basics';
import { course as eatingDisorders } from './eating-disorders';
import { course as psychosomatic } from './psychosomatic';
import { course as hypnosis } from './hypnosis';
import { course as sexology } from './sexology';
import { course as cbtPractice1 } from './cbt-practice-1';
import { course as cbtPractice2 } from './cbt-practice-2';
import { course as psychoanalyticTechniques } from './psychoanalytic-techniques';
import { course as individualCounseling } from './individual-counseling';
import { course as crisisWork } from './crisis-work';
import { course as perinatalPsychology } from './perinatal-psychology';
import { course as metaphoricCardsPractice } from './metaphoric-cards-practice';
import { course as counselingSkills } from './counseling-skills';
import { course as gelotology } from './gelotology';
import { course as communicationSkills } from './communication-skills';
import { course as clinicalPsychology } from './clinical-psychology';
import { course as psychologistConsultant } from './psychologist-consultant';
import { course as clinicalPsychologyPathopsychology } from './clinical-psychology-pathopsychology';
import { course as crisisPsychology } from './crisis-psychology';
import { course as coachingBusiness } from './coaching-business';
import { course as neuropsychology } from './neuropsychology';
import { course as practicalNeuropsychology } from './practical-neuropsychology';
import { course as psychologyArtTherapy } from './psychology-art-therapy';
import { course as familyPsychology } from './family-psychology';
import { course as socialPsychology } from './social-psychology';
import { course as abaTherapy } from './aba-therapy';
import { course as psychosomaticTherapy } from './psychosomatic-therapy';
import { course as perinatalPsychologyFull } from './perinatal-psychology-full';
import { course as businessAnalytics } from './business-analytics';
import { course as hotelManagement } from './hotel-management';
import { course as journalism } from './journalism';
import { course as radioHost } from './radio-host';
import { course as textEditing } from './text-editing';
import { course as logistics } from './logistics';
import { course as hrManagement } from './hr-management';
import { course as hrDirector } from './hr-director';
import { course as hrManagementSpecialist } from './hr-management-specialist';
import { course as psychologyArtTherapyFull } from './psychology-art-therapy-full';
import { course as crisisPsychologyFull } from './crisis-psychology-full';
import { course as artTherapyFull } from './art-therapy-full';
import { course as cognitiveTherapyFull } from './cognitive-therapy-full';
import { course as childPsychology } from './child-psychology';
import { course as sportsPsychology } from './sports-psychology';
import { course as educationalPsychology } from './educational-psychology';
import { course as psychoanalysis } from './psychoanalysis';
import { course as positiveTherapy } from './positive-psychotherapy';
import { course as clinicalPsychologyFull } from './clinical-psychology-full';
import { course as sexologyFull } from './sexology-full';
import { course as psychoanalysisCounseling } from './psychoanalysis-counseling';
import { course as artTherapyCounseling } from './art-therapy-counseling';
import { course as bodyTherapy } from './body-therapy';
import { course as gestaltTherapy } from './gestalt-therapy';
import { course as psychoanalysisCounselingFull } from './psychoanalysis-counseling-full';
import { course as artTherapyCounselingFull } from './art-therapy-counseling-full';
import { course as bodyTherapyFull } from './body-therapy-full';
import { course as gestaltTherapyFull } from './gestalt-therapy-full';
import { course as artTherapyFullQualification } from './art-therapy-full-qualification';
import { course as crisisPsychologyFullQualification } from './crisis-psychology-full-qualification';
import { course as metaphoricCardsFull } from './metaphoric-cards-full';
import { course as familyPsychologyFull } from './family-psychology-full';
import { course as socialPsychologyFull } from './social-psychology-full';
import { course as abaTherapyFull } from './aba-therapy-full';
import { course as psychosomaticTherapyFull } from './psychosomatic-therapy-full';
import { course as perinatalPsychologyFullQualification } from './perinatal-psychology-full-qualification';

export const appkkCourses: Course[] = [
  artTherapy,
  cognitiveTherapy,
  metaphoricCards,
  childNeuropsychology,
  neurofitness,
  coachingBasics,
  eatingDisorders,
  psychosomatic,
  hypnosis,
  sexology,
  cbtPractice1,
  cbtPractice2,
  psychoanalyticTechniques,
  individualCounseling,
  crisisWork,
  perinatalPsychology,
  metaphoricCardsPractice,
  counselingSkills,
  gelotology,
  communicationSkills,
  clinicalPsychology,
  psychologistConsultant,
  clinicalPsychologyPathopsychology,
  crisisPsychology,
  coachingBusiness,
  neuropsychology,
  practicalNeuropsychology,
  psychologyArtTherapy,
  familyPsychology,
  socialPsychology,
  abaTherapy,
  psychosomaticTherapy,
  perinatalPsychologyFull,
  businessAnalytics,
  hotelManagement,
  journalism,
  radioHost,
  textEditing,
  logistics,
  hrManagement,
  hrDirector,
  hrManagementSpecialist,
  psychologyArtTherapyFull,
  crisisPsychologyFull,
  artTherapyFull,
  cognitiveTherapyFull,
  childPsychology,
  sportsPsychology,
  educationalPsychology,
  psychoanalysis,
  positiveTherapy,
  clinicalPsychologyFull,
  sexologyFull,
  psychoanalysisCounseling,
  artTherapyCounseling,
  bodyTherapy,
  gestaltTherapy,
  psychoanalysisCounselingFull,
  artTherapyCounselingFull,
  bodyTherapyFull,
  gestaltTherapyFull,
  artTherapyFullQualification,
  crisisPsychologyFullQualification,
  metaphoricCardsFull,
  familyPsychologyFull,
  socialPsychologyFull,
  abaTherapyFull,
  psychosomaticTherapyFull,
  perinatalPsychologyFullQualification
];