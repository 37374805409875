import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_soft_skills',
  title: 'Soft skills: ключевые навыки руководителя',
  description: 'Развивайте ключевые soft skills руководителя с помощью курса в Нетологии. Освойте навыки эффективной коммуникации, лидерства и управления командами. Научитесь решать конфликты, делегировать задачи и принимать стратегические решения. Курс включает практические задания и поддержку экспертов. Учитесь онлайн в удобное время и получите удостоверение о повышении квалификации.',
  school: 'netology',
  category: 'management',
  price: 75045,
  oldPrice: 115453,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fpraktika-soft-skills&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3126,
  discountPercent: 35,
  skills: [
    'Лидерство',
    'Коммуникация',
    'Управление конфликтами',
    'Делегирование',
    'Принятие решений'
  ],
  program: [
    {
      title: 'Лидерство и коммуникация',
      topics: [
        'Стили лидерства',
        'Эффективная коммуникация',
        'Эмоциональный интеллект',
        'Мотивация команды'
      ]
    },
    {
      title: 'Управление командой',
      topics: [
        'Делегирование',
        'Обратная связь',
        'Развитие сотрудников',
        'Конфликт-менеджмент'
      ]
    },
    {
      title: 'Принятие решений',
      topics: [
        'Стратегическое мышление',
        'Анализ ситуации',
        'Управление рисками',
        'Планирование'
      ]
    }
  ],
  features: [
    '59 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Работа в группах',
    'Сертификат о повышении квалификации'
  ]
};