import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_java_basics',
  title: 'Программирование на Java',
  description: 'Для всех, кто хочет получить навык программирования на Java. За 6 месяцев научитесь писать код для простых проектов',
  school: 'brunoyam',
  category: 'programming',
  price: 39900,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fprogrammirovanie-na-java&m=5&erid=LdtCK4uLs',
  skills: [
    'Java Core',
    'ООП',
    'Коллекции',
    'Работа с файлами',
    'Базы данных'
  ],
  program: [
    {
      title: 'Основы Java',
      topics: [
        'Синтаксис Java',
        'Типы данных',
        'Управляющие конструкции',
        'Функции'
      ]
    },
    {
      title: 'ООП и коллекции',
      topics: [
        'Классы и объекты',
        'Наследование',
        'Collections Framework',
        'Обработка исключений'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с файлами',
        'Базы данных',
        'Создание приложений',
        'Тестирование'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Поддержка куратора',
    'Работа над проектами',
    'Доступ к материалам',
    'Сертификат разработчика'
  ]
};