import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_python',
  title: 'Профессия Python-разработчик',
  description: 'Онлайн курс «Python-разработчик» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 10 месяцев. Помощь в трудоустройстве! Обучение на Python-разработчика с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 118255,
  oldPrice: 236509,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/ODl2hWLpnj98vCpvSG0EpfJe9wNJR3MXlhw1xMXs.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fprogrammer%2Fpython-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Разработка на Python',
    'Работа с базами данных',
    'Web-разработка',
    'Алгоритмы и структуры данных',
    'Git и командная работа',
    'Фреймворки Django и Flask'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис Python',
        'Типы данных',
        'Функции и модули',
        'ООП в Python'
      ]
    },
    {
      title: 'Базы данных',
      topics: [
        'SQL и реляционные БД',
        'PostgreSQL',
        'MongoDB',
        'ORM SQLAlchemy'
      ]
    },
    {
      title: 'Web-разработка',
      topics: [
        'HTML и CSS',
        'Django Framework',
        'Flask Framework',
        'REST API'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Асинхронное программирование',
        'Тестирование кода',
        'Деплой приложений',
        'CI/CD'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Код-ревью от специалистов',
    'Помощь в составлении резюме',
    'Поддержка в чате с преподавателем',
    'Сертификат по окончании курса'
  ]
};