import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_backend',
  title: 'Python-разработчик',
  description: 'С нуля научитесь разрабатывать бэкенд на Python для сайтов и веб-сервисов: продумывать логику, управлять данными и взаимодействием с другими приложениями.',
  school: 'yandex',
  category: 'programming',
  price: 155000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fbackend-developer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Python разработка',
    'Django',
    'REST API',
    'SQL',
    'Git'
  ],
  program: [
    {
      title: 'Основы Python',
      topics: [
        'Синтаксис Python',
        'ООП',
        'Работа с файлами',
        'Алгоритмы'
      ]
    },
    {
      title: 'Веб-разработка',
      topics: [
        'Django Framework',
        'REST API',
        'Базы данных',
        'Тестирование'
      ]
    },
    {
      title: 'DevOps практики',
      topics: [
        'Git',
        'Docker',
        'CI/CD',
        'Деплой'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Code review',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о профессии'
  ]
};