import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_it_intro',
  title: 'IT-профессии: как выбрать направление и реализовать себя',
  description: 'Бесплатное обучение по выбору профессии в цифровой сфере (digital) от Нетологии. На курсе вы поймете, как выбрать диджитал специализацию и какие есть направления развития.',
  school: 'netology',
  category: 'programming',
  price: 0,
  oldPrice: 0,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdigital-padavan&m=5&erid=LdtCKCxkP',
  skills: [
    'Обзор IT-профессий',
    'Выбор направления',
    'Построение карьеры',
    'Soft skills в IT',
    'Технические навыки'
  ],
  program: [
    {
      title: 'Введение в IT',
      topics: [
        'Обзор профессий',
        'Требуемые навыки',
        'Карьерные траектории',
        'Рынок труда'
      ]
    },
    {
      title: 'Практические аспекты',
      topics: [
        'Выбор специализации',
        'План развития',
        'Обучение',
        'Поиск работы'
      ]
    }
  ],
  features: [
    '13 практических занятий',
    'Карьерные консультации',
    'Тестирование навыков',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};