import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_psychoanalytic',
  title: 'Практические навыки психоаналитических техник работы с клиентом',
  description: 'Дистанционное обучение по программе Практические навыки психоаналитических техник работы с клиентом(38 часов) в ЦАППКК. Освоение основных психоаналитических техник для работы с клиентами.',
  school: 'appkk',
  category: 'psychology',
  price: 17700,
  currency: 'RUR',
  duration: '38 часов',
  imageUrl: 'https://images.unsplash.com/photo-1590650153855-d9e808231d41?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_psikhoanaliticheskikh_tekhnik_raboty_s_klientom%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Психоаналитическая диагностика',
    'Работа с переносом',
    'Анализ сопротивления',
    'Интерпретация сновидений',
    'Работа с бессознательным'
  ],
  program: [
    {
      title: 'Основы психоанализа',
      topics: [
        'Диагностическое интервью',
        'Психоаналитический сеттинг',
        'Терапевтический альянс',
        'Динамика процесса'
      ]
    },
    {
      title: 'Техники работы',
      topics: [
        'Анализ переноса',
        'Контрперенос',
        'Сопротивление',
        'Интерпретация'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Работа со сновидениями',
        'Анализ оговорок',
        'Свободные ассоциации',
        'Проработка'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};