import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_traffic_manager',
  title: 'Трафик-менеджер',
  description: 'Курс «Трафик-менеджер» от Нетологии: разберитесь в контекстной и таргетированной рекламе, соберите портфолио из 8 проектов и начните получать заказы через 2 месяца обучения.',
  school: 'netology',
  category: 'marketing',
  price: 108000,
  oldPrice: 180000,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ftarget-smm-full&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3000,
  skills: [
    'Управление трафиком',
    'Контекстная реклама',
    'Таргетированная реклама',
    'Аналитика',
    'Оптимизация кампаний'
  ],
  program: [
    {
      title: 'Основы трафика',
      topics: [
        'Источники трафика',
        'Настройка рекламы',
        'Аналитика',
        'Бюджетирование'
      ]
    },
    {
      title: 'Каналы продвижения',
      topics: [
        'Яндекс.Директ',
        'Google Ads',
        'Таргетированная реклама',
        'Медийная реклама'
      ]
    },
    {
      title: 'Оптимизация',
      topics: [
        'A/B тестирование',
        'Анализ конверсии',
        'Оптимизация бюджета',
        'Масштабирование'
      ]
    }
  ],
  features: [
    'Практические проекты',
    '8 проектов в портфолио',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};