import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_team_leader',
  title: 'Руководитель: команда и бизнес-процессы',
  description: 'Курс «Руководитель: команда и бизнес-процессы» от Нетологии: освоите soft skills для управления процессами и командой за 3,5 месяца. Практические кейсы помогут повысить эффективность работы и бизнес-процессов.',
  school: 'netology',
  category: 'management',
  price: 73640,
  oldPrice: 105200,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsoft-skills-dlya-optimizacii-biznes-processov&m=4&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 3068,
  skills: [
    'Управление командой',
    'Оптимизация процессов',
    'Soft skills',
    'Лидерство',
    'Коммуникация'
  ],
  program: [
    {
      title: 'Управление командой',
      topics: [
        'Формирование команды',
        'Мотивация',
        'Делегирование',
        'Обратная связь'
      ]
    },
    {
      title: 'Бизнес-процессы',
      topics: [
        'Анализ процессов',
        'Оптимизация',
        'KPI',
        'Автоматизация'
      ]
    },
    {
      title: 'Soft skills',
      topics: [
        'Коммуникация',
        'Конфликт-менеджмент',
        'Переговоры',
        'Презентации'
      ]
    }
  ],
  features: [
    '73 практических занятия',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в развитии карьеры',
    'Сертификат о повышении квалификации'
  ]
};