import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_vba',
  title: 'Офлайн-курс VBA в Microsoft Excel',
  description: 'Практический курс, который подойдет аналитикам, маркетологам и финансистам. На курсе научитесь работать с большим количеством данных и программировать макросы на Visual Basic',
  school: 'brunoyam',
  category: 'analytics',
  price: 12900,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-analitika-dannyh%2Fvba&m=5&erid=LdtCK4uLs',
  skills: [
    'VBA программирование',
    'Создание макросов',
    'Автоматизация Excel',
    'Работа с данными',
    'Оптимизация процессов'
  ],
  program: [
    {
      title: 'Основы VBA',
      topics: [
        'Синтаксис VBA',
        'Переменные и типы данных',
        'Условия и циклы',
        'Функции и процедуры'
      ]
    },
    {
      title: 'Работа с Excel',
      topics: [
        'Объектная модель',
        'Работа с ячейками',
        'Управление листами',
        'Форматирование'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Создание макросов',
        'Пользовательские формы',
        'Обработка событий',
        'Отладка кода'
      ]
    }
  ],
  features: [
    '24 академических часа',
    '6 занятий',
    'Практические задания',
    'Работа с реальными данными',
    'Сертификат специалиста'
  ]
};