import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_physics_ebonit',
  title: 'Годовой курс подготовки по физике ЕГЭ "Эбонит"',
  description: 'Хочешь сдать физику на 100? Тогда наш годовой курс точно для тебя! Комплексная подготовка к ЕГЭ по физике с опытными преподавателями и персональным куратором.',
  school: '100points',
  category: 'physics',
  price: 33890,
  oldPrice: 39490,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1636466497217-26a8cbeaf0aa?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3690,
  discountPercent: 15,
  skills: [
    'Решение задач по физике',
    'Понимание физических законов',
    'Работа с формулами',
    'Анализ физических процессов',
    'Решение экспериментальных задач',
    'Подготовка к ЕГЭ'
  ],
  program: [
    {
      title: 'Механика',
      topics: [
        'Кинематика',
        'Динамика',
        'Законы сохранения',
        'Статика и гидростатика'
      ]
    },
    {
      title: 'Молекулярная физика и термодинамика',
      topics: [
        'Молекулярно-кинетическая теория',
        'Термодинамика',
        'Агрегатные состояния',
        'Тепловые процессы'
      ]
    },
    {
      title: 'Электродинамика',
      topics: [
        'Электростатика',
        'Постоянный ток',
        'Магнитное поле',
        'Электромагнитная индукция'
      ]
    },
    {
      title: 'Квантовая физика',
      topics: [
        'Фотоэффект',
        'Атомная физика',
        'Ядерная физика',
        'Радиоактивность'
      ]
    }
  ],
  features: [
    '3 веба в неделю',
    'Двухуровневая проверка ДЗ',
    '8 пробников',
    'Дополнительные материалы',
    'Помощь с апелляцией',
    'Психологическая поддержка'
  ]
};