import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_financial_director',
  title: 'Финансовый директор',
  description: 'Онлайн-курс «Финансовый директор» от Нетологии: принимайте стратегические решения в финансах, работайте с реальными кейсами и получите диплом для карьерного роста. Освойте управление бюджетами, снижение рисков и инвестиционный анализ.',
  school: 'netology',
  category: 'management',
  price: 126300,
  oldPrice: 210500,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-6726b3ff858f?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ffinancial_director&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3508,
  skills: [
    'Финансовая стратегия',
    'Управление бюджетом',
    'Инвестиционный анализ',
    'Риск-менеджмент',
    'Финансовое планирование'
  ],
  program: [
    {
      title: 'Стратегическое управление',
      topics: [
        'Финансовая стратегия',
        'Бюджетирование',
        'Управление рисками',
        'Инвестиции'
      ]
    },
    {
      title: 'Операционное управление',
      topics: [
        'Управленческий учет',
        'Финансовый анализ',
        'Оптимизация затрат',
        'Контроллинг'
      ]
    },
    {
      title: 'Практика управления',
      topics: [
        'Принятие решений',
        'Работа с инвесторами',
        'Антикризисное управление',
        'Развитие бизнеса'
      ]
    }
  ],
  features: [
    '80 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в карьерном росте',
    'Диплом о профессиональной переподготовке'
  ]
};