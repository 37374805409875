import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { CourseDetails } from '../components/CourseDetails';
import { useCourseStore } from '../store/courseStore';

export const CoursePage: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { courses } = useCourseStore();
  
  const course = courses.find(c => c.id === courseId);
  
  if (!course) {
    return <Navigate to="/" replace />;
  }
  
  return <CourseDetails course={course} />;
};