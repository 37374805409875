import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_3d_generalist',
  title: 'Профессия 3D-дженералист',
  description: 'Онлайн курс «3D-дженералист» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 13 месяцев. Помощь в трудоустройстве! Обучение на 3D-дженералиста с нуля онлайн.',
  school: 'geekbrains',
  category: 'game-dev',
  price: 210234,
  oldPrice: 420467,
  currency: 'RUB',
  duration: '13 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/nuQYM9CAt7auCgMJuXbsqkBHw8BOZs1sFOesuEXb.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fgames%2F3d-generalist-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    '3D-моделирование',
    'Текстурирование',
    'Анимация',
    'Визуальные эффекты',
    'Рендеринг',
    'Композитинг',
    'Работа со светом'
  ],
  program: [
    {
      title: 'Основы 3D',
      topics: [
        'Введение в 3D-графику',
        'Основы моделирования',
        'Работа с материалами',
        'Базовая анимация'
      ]
    },
    {
      title: 'Продвинутое моделирование',
      topics: [
        'Hard Surface моделирование',
        'Органическое моделирование',
        'Скульптинг',
        'Ретопология'
      ]
    },
    {
      title: 'Материалы и текстуры',
      topics: [
        'UV-развертка',
        'PBR-материалы',
        'Substance Painter',
        'Процедурные текстуры'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Настройка освещения',
        'Рендеринг',
        'Постобработка',
        'Композитинг'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Персональный наставник',
    'Помощь в создании портфолио',
    'Стажировка в студии',
    'Сертификат 3D-специалиста'
  ]
};