import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_photoshop',
  title: 'Офлайн-курс Adobe Photoshop',
  description: 'За 1 месяц научитесь работать с композицией, цветом, типографикой, ретушью и анимацией. Занятия в мини-группе, поддержка преподавателя и доступ к онлайн-курсу. Результат — работы в портфолио и навыки для работы с заказчиками',
  school: 'brunoyam',
  category: 'design',
  price: 11400,
  currency: 'RUB',
  duration: '7 недель',
  imageUrl: 'https://images.unsplash.com/photo-1572044162444-ad60f128bdea?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-grafiki-dizaina%2Fadobe-photoshop&m=5&erid=LdtCK4uLs',
  skills: [
    'Adobe Photoshop',
    'Ретушь',
    'Цветокоррекция',
    'Работа со слоями',
    'Композиция'
  ],
  program: [
    {
      title: 'Основы Photoshop',
      topics: [
        'Интерфейс программы',
        'Инструменты',
        'Работа со слоями',
        'Маски'
      ]
    },
    {
      title: 'Обработка изображений',
      topics: [
        'Ретушь',
        'Цветокоррекция',
        'Фильтры',
        'Эффекты'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание коллажей',
        'Работа с текстом',
        'Подготовка к печати',
        'Анимация'
      ]
    }
  ],
  features: [
    '32 академических часа',
    '8 занятий',
    'Практические проекты',
    'Создание портфолио',
    'Сертификат специалиста'
  ]
};