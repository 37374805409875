import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_interior',
  title: 'Дизайнер интерьера',
  description: 'Онлайн-курс Дизайнер интерьеров от Нетологии: станьте дизайнером интерьеров с нуля! Черная Пятница в Нетологии - двойные скидки на курсы!',
  school: 'netology',
  category: 'design',
  price: 150527,
  oldPrice: 250877,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdizajner-intererov&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4181,
  skills: [
    'Проектирование интерьеров',
    'Работа с материалами',
    '3D-визуализация',
    'Планировочные решения',
    'Работа с заказчиками'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Стили интерьера',
        'Композиция',
        'Цветоведение'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Планировка помещений',
        'Эргономика',
        'Освещение',
        'Визуализация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Подбор материалов',
        'Авторский надзор',
        'Ведение проектов'
      ]
    }
  ],
  features: [
    '36 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};