import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_office',
  title: 'Офлайн-курс Microsoft Office: Word, Excel',
  description: 'Научитесь создавать и редактировать документы, работать с диаграммами и таблицами. Результат — ускорите свою работу в Microsoft Office',
  school: 'brunoyam',
  category: 'analytics',
  price: 12900,
  currency: 'RUB',
  duration: '3 недели',
  imageUrl: 'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-analitika-dannyh%2Fword-excel&m=5&erid=LdtCK4uLs',
  skills: [
    'Microsoft Word',
    'Microsoft Excel',
    'Работа с документами',
    'Анализ данных',
    'Создание отчетов'
  ],
  program: [
    {
      title: 'Microsoft Word',
      topics: [
        'Форматирование текста',
        'Работа с таблицами',
        'Создание документов',
        'Шаблоны'
      ]
    },
    {
      title: 'Microsoft Excel',
      topics: [
        'Основы работы',
        'Формулы и функции',
        'Диаграммы',
        'Анализ данных'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с документами',
        'Создание отчетов',
        'Автоматизация',
        'Оптимизация работы'
      ]
    }
  ],
  features: [
    '24 академических часа',
    'Практические задания',
    'Работа с реальными документами',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};