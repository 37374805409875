import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_marketplace_pro',
  title: 'Менеджер маркетплейсов расширенный',
  description: 'Менеджер маркетплейсов: расширенный',
  school: 'yandex',
  category: 'marketing',
  price: 129000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmarketplace-manager%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление магазином',
    'Аналитика продаж',
    'Оптимизация карточек',
    'Работа с маркетплейсами',
    'Продвижение товаров'
  ],
  program: [
    {
      title: 'Управление магазином',
      topics: [
        'Настройка магазина',
        'Работа с каталогом',
        'Управление остатками',
        'Ценообразование'
      ]
    },
    {
      title: 'Продвижение и продажи',
      topics: [
        'Оптимизация карточек',
        'Реклама на площадках',
        'Акции и скидки',
        'Работа с отзывами'
      ]
    },
    {
      title: 'Аналитика и развитие',
      topics: [
        'Анализ продаж',
        'Работа с метриками',
        'Оптимизация процессов',
        'Масштабирование бизнеса'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Работа с реальными магазинами',
    'Персональный наставник',
    'Помощь в развитии бизнеса',
    'Сертификат специалиста'
  ]
};