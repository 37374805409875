import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_methodist',
  title: 'Профессия Методист с нуля до PRO',
  description: 'Онлайн курс «Методист» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 6 месяцев. Помощь в трудоустройстве! Обучение на методиста с нуля онлайн.',
  school: 'geekbrains',
  category: 'management',
  price: 60165,
  oldPrice: 120330,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/CMGIFrLBhGcOpvDLPhRXq5kKadyxcsFsvZxDSmj9.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmanagement%2Fmethodist&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Разработка учебных программ',
    'Создание образовательного контента',
    'Организация учебного процесса',
    'Оценка эффективности обучения',
    'Работа с преподавателями',
    'Управление образовательными проектами'
  ],
  program: [
    {
      title: 'Основы методической работы',
      topics: [
        'Педагогический дизайн',
        'Разработка программ',
        'Учебные материалы',
        'Форматы обучения'
      ]
    },
    {
      title: 'Создание контента',
      topics: [
        'Структура курса',
        'Учебные материалы',
        'Оценочные средства',
        'Интерактивные элементы'
      ]
    },
    {
      title: 'Организация обучения',
      topics: [
        'Управление группами',
        'Работа с преподавателями',
        'Контроль качества',
        'Обратная связь'
      ]
    },
    {
      title: 'Оценка и развитие',
      topics: [
        'Методы оценки',
        'Анализ результатов',
        'Развитие программ',
        'Управление проектами'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат методиста'
  ]
};