import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_internet_marketing_specialist',
  title: 'Интернет-маркетолог: с нуля до cпециалиста',
  description: 'Курс «Интернет-маркетолог» от Нетологии: с нуля освоите профессию, выполните 20 практических заданий, изучите веб-аналитику и выберите специализацию. Обучение с поддержкой экспертов.',
  school: 'netology',
  category: 'marketing',
  price: 115200,
  oldPrice: 192000,
  currency: 'RUB',
  duration: '11 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdistance-course-internet-marketing&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3200,
  skills: [
    'Digital-маркетинг',
    'SEO-оптимизация',
    'Контекстная реклама',
    'SMM',
    'Email-маркетинг'
  ],
  program: [
    {
      title: 'Основы интернет-маркетинга',
      topics: [
        'Маркетинговая стратегия',
        'Целевая аудитория',
        'Каналы продвижения',
        'Аналитика'
      ]
    },
    {
      title: 'Инструменты продвижения',
      topics: [
        'SEO',
        'Контекстная реклама',
        'Таргетированная реклама',
        'Email-маркетинг'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разработка стратегии',
        'Создание кампаний',
        'Оптимизация',
        'Анализ результатов'
      ]
    }
  ],
  features: [
    '66 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};