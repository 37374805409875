import React from 'react';
import { Link } from 'react-router-dom';
import { Course } from '../types';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useCourseStore } from '../store/courseStore';

interface CourseDetailsProps {
  course: Course;
}

export const CourseDetails: React.FC<CourseDetailsProps> = ({ course }) => {
  const { schools } = useCourseStore();
  const school = schools.find(s => s.id === course.school);

  return (
    <div className="min-h-screen bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Main Content */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
              <img 
                src={course.imageUrl} 
                alt={course.title}
                className="w-full h-72 object-cover rounded-lg mb-8"
              />
              
              <h1 className="text-2xl font-bold mb-3 text-gray-900">{course.title}</h1>
              {school && (
                <Link 
                  to={`/school/${school.id}`} 
                  className="inline-block text-blue-600 hover:text-blue-700 font-semibold mb-6 text-base"
                >
                  {school.name}
                </Link>
              )}
              
              <p className="text-base text-gray-700 leading-relaxed mb-12">{course.description}</p>
              
              {course.skills && (
                <div className="mb-12">
                  <h2 className="text-xl font-bold mb-8 text-gray-900">Чему вы научитесь</h2>
                  <div className="space-y-4">
                    {course.skills.map((skill, index) => (
                      <div key={index} className="flex items-start gap-3">
                        <CheckCircleIcon className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                        <span className="text-base text-gray-700">{skill}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              {course.program && (
                <div className="mb-12">
                  <h2 className="text-xl font-bold mb-8 text-gray-900">Программа курса</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {course.program.map((module, index) => (
                      <div key={index} className="bg-white border-2 border-gray-100 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow">
                        <h3 className="text-lg font-bold mb-4 text-gray-900">{module.title}</h3>
                        <ul className="space-y-3 text-gray-700">
                          {module.topics.map((topic, topicIndex) => (
                            <li key={topicIndex} className="flex items-start gap-3">
                              <span className="inline-block w-2 h-2 bg-blue-600 rounded-full flex-shrink-0 mt-2"></span>
                              <span className="text-base">{topic}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          
          {/* Sticky Sidebar */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-xl shadow-lg p-6 sticky top-8">
              <div className="space-y-6">
                {/* Price Section */}
                <div>
                  <div className="text-3xl font-bold text-gray-900 mb-2">
                    {course.price.toLocaleString()} ₽
                  </div>
                  {course.oldPrice && (
                    <div className="text-gray-400 line-through text-base font-medium">
                      {course.oldPrice.toLocaleString()} ₽
                    </div>
                  )}
                  {course.monthlyPayment && (
                    <div className="text-base text-gray-700 mt-2 font-medium">
                      или {course.monthlyPayment.toLocaleString()} ₽/мес
                    </div>
                  )}
                  {course.discountPercent && (
                    <div className="mt-3 inline-block bg-red-100 text-red-700 px-4 py-2 rounded-lg text-base font-semibold">
                      Скидка {course.discountPercent}%
                    </div>
                  )}
                </div>

                {/* Features Section */}
                {course.features && (
                  <div className="space-y-4 bg-gray-50 p-6 rounded-xl">
                    {course.features.map((feature, index) => (
                      <div key={index} className="flex items-center gap-3">
                        <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-600" />
                        <span className="text-base text-gray-700 font-medium">{feature}</span>
                      </div>
                    ))}
                  </div>
                )}
                
                <a
                  href={course.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block w-full bg-blue-600 text-white text-center py-4 rounded-xl font-bold text-base hover:bg-blue-700 transition-colors shadow-sm hover:shadow-md"
                >
                  Подробнее о курсе
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};