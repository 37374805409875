import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_context_ads',
  title: 'Курс по контекстной рекламе с нуля',
  description: 'На курсе вы узнаете, как настраивать контекстную рекламу в Яндекс и Google, отслеживать результаты кампаний и оптимизировать расходы',
  school: 'brunoyam',
  category: 'marketing',
  price: 29900,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-internet-marketing%2Fkontekstnoy-reklamy-direct-adwords&m=5&erid=LdtCK4uLs',
  skills: [
    'Настройка Яндекс.Директ',
    'Настройка Google Ads',
    'Аналитика рекламных кампаний',
    'Оптимизация бюджета',
    'Работа с аудиториями'
  ],
  program: [
    {
      title: 'Основы контекстной рекламы',
      topics: [
        'Принципы работы',
        'Типы кампаний',
        'Структура аккаунта',
        'Подбор ключевых слов'
      ]
    },
    {
      title: 'Настройка рекламы',
      topics: [
        'Создание объявлений',
        'Настройка таргетинга',
        'Стратегии показов',
        'Управление ставками'
      ]
    },
    {
      title: 'Аналитика и оптимизация',
      topics: [
        'Отслеживание конверсий',
        'Анализ статистики',
        'А/B тестирование',
        'Оптимизация кампаний'
      ]
    }
  ],
  features: [
    '32 академических часа',
    'Практические задания',
    'Работа с реальными проектами',
    'Персональный наставник',
    'Сертификат специалиста'
  ]
};