import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_frontend',
  title: 'Frontend-разработчик',
  description: 'Профессия «Frontend-разработчик» от Нетологии: вы научитесь проектировать интерфейсы сайтов и приложений, работать с HTML-вёрсткой и JavaScript. Выполните совместные проекты с дизайнерами, что поможет вам развить ключевые навыки для успешной карьеры в frontend-разработке.',
  school: 'netology',
  category: 'programming',
  price: 124260,
  oldPrice: 207100,
  currency: 'RUB',
  duration: '11 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1593720213428-28a5b9e94613?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ffront-end-basic&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3451,
  skills: [
    'HTML и CSS',
    'JavaScript',
    'React',
    'TypeScript',
    'Git',
    'Адаптивная верстка'
  ],
  program: [
    {
      title: 'Основы frontend',
      topics: [
        'HTML5 и CSS3',
        'JavaScript',
        'Адаптивная верстка',
        'Git'
      ]
    },
    {
      title: 'JavaScript и React',
      topics: [
        'ES6+',
        'React',
        'Redux',
        'TypeScript'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные проекты',
        'Работа с API',
        'Оптимизация',
        'Тестирование'
      ]
    }
  ],
  features: [
    '34 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};