import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_data_analyst_plus',
  title: 'Аналитик данных расширенный',
  description: 'Расширенный курс по анализу данных, в котором на 1,5 месяца больше практики по Python, SQL, математической статистике и извлечению данных.',
  school: 'yandex',
  category: 'analytics',
  price: 172000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fdata-analyst%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продвинутый Python',
    'Сложный SQL',
    'Математическая статистика',
    'A/B-тестирование',
    'Визуализация данных'
  ],
  program: [
    {
      title: 'Продвинутый анализ',
      topics: [
        'Углубленный Python',
        'Сложные SQL запросы',
        'Статистический анализ',
        'Машинное обучение'
      ]
    },
    {
      title: 'Аналитические инструменты',
      topics: [
        'BI системы',
        'ETL процессы',
        'Автоматизация',
        'Отчетность'
      ]
    },
    {
      title: 'Практика и проекты',
      topics: [
        'Реальные кейсы',
        'A/B-тесты',
        'Продуктовая аналитика',
        'Построение дашбордов'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Больше практики',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат аналитика'
  ]
};