import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_curator',
  title: 'Куратор и технический администратор онлайн-школы',
  description: 'Курс «Куратор и технический администратор онлайн-школы» от Нетологии: научитесь управлять учебным сообществом и технически администрировать курсы. Закрепите навыки на стажировке и получите возможность трудоустройства.',
  school: 'netology',
  category: 'education',
  price: 66000,
  oldPrice: 110000,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fkurator-onlajn-obucheniya-s-0-do-pro&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2750,
  skills: [
    'Управление обучением',
    'Техническое администрирование',
    'Работа с учащимися',
    'Организация процессов',
    'Поддержка студентов'
  ],
  program: [
    {
      title: 'Основы кураторства',
      topics: [
        'Роль куратора',
        'Коммуникация',
        'Мотивация студентов',
        'Управление группой'
      ]
    },
    {
      title: 'Техническая часть',
      topics: [
        'Образовательные платформы',
        'Системы управления обучением',
        'Техническая поддержка',
        'Администрирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с группой',
        'Решение проблем',
        'Контроль успеваемости',
        'Организация мероприятий'
      ]
    }
  ],
  features: [
    '53 практических занятия',
    'Стажировка',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат специалиста'
  ]
};