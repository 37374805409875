import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_learn',
  title: 'Онлайн-курс Учись учиться',
  description: 'Бесплатный курс для тех, кто часто бросает любое обучение на полпути. 5 уроков о том, как ставить цели и учиться системно, чтобы получать результат.',
  school: 'brunoyam',
  category: 'management',
  price: 0,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fkak-uchitsya&m=5&erid=LdtCK4uLs',
  skills: [
    'Целеполагание',
    'Тайм-менеджмент',
    'Методики обучения',
    'Работа с информацией',
    'Самомотивация'
  ],
  program: [
    {
      title: 'Основы эффективного обучения',
      topics: [
        'Постановка целей',
        'Планирование обучения',
        'Работа с мотивацией',
        'Преодоление препятствий'
      ]
    },
    {
      title: 'Методики и инструменты',
      topics: [
        'Техники запоминания',
        'Методы концентрации',
        'Организация информации',
        'Управление временем'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Создание системы обучения',
        'Отслеживание прогресса',
        'Работа с прокрастинацией',
        'Поддержание мотивации'
      ]
    }
  ],
  features: [
    'Практические упражнения',
    'Работа с реальными задачами',
    'Поддержка куратора',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};