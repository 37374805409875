import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_sports_psychology',
  title: 'Спортивная психология. Методики психологической помощи спортсменам',
  description: 'Дистанционное обучение по программе Спортивная психология. Методики психологической помощи спортсменам с присвоением квалификации Спортивный психолог(540 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 45000,
  currency: 'RUR',
  duration: '540 часов',
  imageUrl: 'https://images.unsplash.com/photo-1517649763962-0c623066013b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fsportivnaya_psikhologiya_metodiki_psikhologicheskoy_pomoshchi_sportsmenam_s_prisvoeniem_kvalifikatsi%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Спортивная психология',
    'Психология достижений',
    'Работа со стрессом',
    'Мотивационные техники',
    'Командная работа'
  ],
  program: [
    {
      title: 'Основы спортивной психологии',
      topics: [
        'Психология спорта',
        'Личность спортсмена',
        'Мотивация',
        'Стресс-менеджмент'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Психодиагностика',
        'Ментальный тренинг',
        'Работа с командой',
        'Соревновательная подготовка'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Работа с травмами',
        'Реабилитация',
        'Карьерное консультирование',
        'Профилактика выгорания'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом спортивного психолога'
  ]
};