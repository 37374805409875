import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_product_design',
  title: 'Продуктовый подход для дизайнеров',
  description: 'Курс для действующих дизайнеров. Разберётесь, из чего состоит продуктовый цикл, как через дизайн влиять на метрики и эффективно взаимодействовать с командой.',
  school: 'yandex',
  category: 'design',
  price: 105000,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fproduct-approach-for-designers&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продуктовый дизайн',
    'UX-метрики',
    'Работа с данными',
    'Продуктовое мышление',
    'Командная работа'
  ],
  program: [
    {
      title: 'Основы продуктового подхода',
      topics: [
        'Продуктовое мышление',
        'Метрики продукта',
        'Анализ данных',
        'Принятие решений'
      ]
    },
    {
      title: 'Дизайн и метрики',
      topics: [
        'UX-метрики',
        'A/B тестирование',
        'Аналитика дизайна',
        'Оптимизация интерфейсов'
      ]
    },
    {
      title: 'Работа в команде',
      topics: [
        'Взаимодействие с разработкой',
        'Коммуникация с менеджментом',
        'Презентация решений',
        'Управление процессами'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными метриками',
    'Персональный наставник',
    'Развитие в профессии',
    'Сертификат о повышении квалификации'
  ]
};