import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_doctor_career',
  title: 'Карьера врача: как развиваться онлайн и офлайн',
  description: 'Курс для врачей от Нетологии поможет вам развить навыки консультаций как в онлайн, так и в офлайн-форматах. Узнайте, как эффективно работать с пациентами, используя передовые медицинские технологии.',
  school: 'netology',
  category: 'medicine',
  price: 12900,
  oldPrice: 12900,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fvrach-onlain-consultion&m=4&erid=LdtCKCxkP',
  monthlyPayment: 1075,
  skills: [
    'Онлайн-консультации',
    'Коммуникация с пациентами',
    'Цифровые инструменты',
    'Развитие практики',
    'Личный бренд врача'
  ],
  program: [
    {
      title: 'Онлайн-практика',
      topics: [
        'Телемедицина',
        'Цифровые инструменты',
        'Онлайн-консультации',
        'Документооборот'
      ]
    },
    {
      title: 'Развитие карьеры',
      topics: [
        'Личный бренд',
        'Продвижение услуг',
        'Работа с отзывами',
        'Монетизация'
      ]
    },
    {
      title: 'Коммуникации',
      topics: [
        'Работа с пациентами',
        'Профилактика выгорания',
        'Конфликтные ситуации',
        'Этика общения'
      ]
    }
  ],
  features: [
    '13 практических занятий',
    'Реальные кейсы',
    'Работа с экспертами',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};