import { Course } from '../../../../types';
import { course as frenchBusiness } from './french-business';
import { course as frenchTravel } from './french-travel';
import { course as frenchExams } from './french-exams';
import { course as frenchMove } from './french-move';
import { course as frenchChildren } from './french-children';
import { course as frenchIndividual } from './french-individual';

import { course as spanishBusiness } from './spanish-business';
import { course as spanishTravel } from './spanish-travel';
import { course as spanishExams } from './spanish-exams';
import { course as spanishMove } from './spanish-move';
import { course as spanishChildren } from './spanish-children';
import { course as spanishIndividual } from './spanish-individual';

import { course as englishBusiness } from './english-business';
import { course as englishTravel } from './english-travel';
import { course as englishExams } from './english-exams';
import { course as englishMove } from './english-move';
import { course as englishChildren } from './english-children';
import { course as englishIndividual } from './english-individual';

import { course as germanBusiness } from './german-business';
import { course as germanTravel } from './german-travel';
import { course as germanExams } from './german-exams';
import { course as germanMove } from './german-move';
import { course as germanChildren } from './german-children';
import { course as germanIndividual } from './german-individual';

import { course as chineseBusiness } from './chinese-business';
import { course as chineseTravel } from './chinese-travel';
import { course as chineseExams } from './chinese-exams';
import { course as chineseMove } from './chinese-move';
import { course as chineseChildren } from './chinese-children';
import { course as chineseIndividual } from './chinese-individual';

import { course as italianBusiness } from './italian-business';
import { course as italianTravel } from './italian-travel';
import { course as italianExams } from './italian-exams';
import { course as italianMove } from './italian-move';
import { course as italianChildren } from './italian-children';
import { course as italianIndividual } from './italian-individual';

import { course as portugueseBusiness } from './portuguese-business';
import { course as portugueseTravel } from './portuguese-travel';
import { course as portugueseExams } from './portuguese-exams';
import { course as portugueseMove } from './portuguese-move';
import { course as portugueseChildren } from './portuguese-children';
import { course as portugueseIndividual } from './portuguese-individual';

export const anecoleCourses: Course[] = [
  frenchBusiness,
  frenchTravel,
  frenchExams,
  frenchMove,
  frenchChildren,
  frenchIndividual,
  
  spanishBusiness,
  spanishTravel,
  spanishExams,
  spanishMove,
  spanishChildren,
  spanishIndividual,
  
  englishBusiness,
  englishTravel,
  englishExams,
  englishMove,
  englishChildren,
  englishIndividual,
  
  germanBusiness,
  germanTravel,
  germanExams,
  germanMove,
  germanChildren,
  germanIndividual,
  
  chineseBusiness,
  chineseTravel,
  chineseExams,
  chineseMove,
  chineseChildren,
  chineseIndividual,
  
  italianBusiness,
  italianTravel,
  italianExams,
  italianMove,
  italianChildren,
  italianIndividual,
  
  portugueseBusiness,
  portugueseTravel,
  portugueseExams,
  portugueseMove,
  portugueseChildren,
  portugueseIndividual
];