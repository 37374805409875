import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_hr_director',
  title: 'Начальник отдела кадров',
  description: 'Начальник отдела кадров с присвоением квалификации Менеджер по кадровому администрированию и документообороту (640 ч.) в дистанционном формате на базе высшего или среднего профессионального образования, 2024 год.',
  school: 'appkk',
  category: 'management',
  price: 25500,
  currency: 'RUR',
  duration: '640 часов',
  imageUrl: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fnachalnik_otdela_kadrov_s_prisvoeniem_kvalifikatsii_menedzher_po_kadrovomu_administrirovaniyu%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Кадровое делопроизводство',
    'Трудовое законодательство',
    'Управление персоналом',
    'Документооборот',
    'Отчетность'
  ],
  program: [
    {
      title: 'Кадровое администрирование',
      topics: [
        'Трудовое право',
        'Кадровые документы',
        'Воинский учет',
        'Проверки ГИТ'
      ]
    },
    {
      title: 'Управление персоналом',
      topics: [
        'Подбор персонала',
        'Адаптация',
        'Оценка',
        'Развитие'
      ]
    },
    {
      title: 'Организация работы',
      topics: [
        'Управление отделом',
        'Автоматизация процессов',
        'Отчетность',
        'Аудит кадровых документов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с документами',
    'Разбор кейсов',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};