import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_motion_design',
  title: 'Моушн-дизайн в 2D и 3D',
  description: 'Онлайн-курс «Профессия Моушн-дизайнер» — Нетология: пройдите обучение с нуля и освоите 2D и 3D-анимацию. Изучите After Effects, Cinema 4D и Blender, создайте проекты для портфолио, получите диплом и начните работать в кино, рекламе или на фрилансе.',
  school: 'netology',
  category: 'design',
  price: 146843,
  oldPrice: 244737,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551269901-5c5e14c25df7?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fvfx&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4078,
  skills: [
    'Motion Design',
    '2D анимация',
    '3D анимация',
    'After Effects',
    'Cinema 4D',
    'Blender'
  ],
  program: [
    {
      title: 'Основы анимации',
      topics: [
        'Принципы анимации',
        'Композиция',
        'Тайминг',
        'Работа с кривыми'
      ]
    },
    {
      title: '2D и 3D',
      topics: [
        'After Effects',
        'Cinema 4D',
        'Blender',
        'Интеграция'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание роликов',
        'Спецэффекты',
        'Портфолио',
        'Работа с клиентами'
      ]
    }
  ],
  features: [
    '3 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};