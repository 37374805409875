import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_hr_specialist',
  title: 'Управление персоналом организации. Обеспечение эффективного функционирования системы управления персоналом',
  description: 'Курс повышения квалификации Управление персоналом организации с присвоением квалификации Специалист по управлению персоналом (540 ч.) в онлайн формате, 2024 год.',
  school: 'appkk',
  category: 'management',
  price: 38700,
  currency: 'RUR',
  duration: '540 часов',
  imageUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fupravlenie_personalom_organizatsii_obespechenie_effektivnogo_funktsionirovaniya_sistemy_upravleniya%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Управление персоналом',
    'Кадровое администрирование',
    'Развитие персонала',
    'Мотивация',
    'HR-аналитика'
  ],
  program: [
    {
      title: 'Основы управления персоналом',
      topics: [
        'HR-стратегия',
        'Кадровая политика',
        'Организационная структура',
        'Корпоративная культура'
      ]
    },
    {
      title: 'Процессы и процедуры',
      topics: [
        'Подбор персонала',
        'Адаптация',
        'Оценка эффективности',
        'Развитие и обучение'
      ]
    },
    {
      title: 'Аналитика и развитие',
      topics: [
        'HR-метрики',
        'Анализ данных',
        'Управление талантами',
        'Организационные изменения'
      ]
    }
  ],
  features: [
    'Практические инструменты',
    'Разбор кейсов',
    'Проектная работа',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};