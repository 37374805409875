import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_methodist',
  title: 'Методист онлайн-курсов',
  description: 'Обучение на методистов образовательных программ в Нетологии онлайн с сертификатом.',
  school: 'netology',
  category: 'education',
  price: 53100,
  oldPrice: 88500,
  currency: 'RUB',
  duration: '4 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmetodist-online-obucheniya&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2950,
  skills: [
    'Разработка курсов',
    'Педагогический дизайн',
    'Создание контента',
    'Оценка эффективности',
    'Управление обучением'
  ],
  program: [
    {
      title: 'Основы методической работы',
      topics: [
        'Педагогический дизайн',
        'Структура курсов',
        'Создание программ',
        'Оценочные средства'
      ]
    },
    {
      title: 'Разработка контента',
      topics: [
        'Учебные материалы',
        'Интерактивные элементы',
        'Проверка знаний',
        'Обратная связь'
      ]
    },
    {
      title: 'Управление обучением',
      topics: [
        'Организация процесса',
        'Работа с преподавателями',
        'Оценка результатов',
        'Развитие программ'
      ]
    }
  ],
  features: [
    '21 практическое занятие',
    'Создание курсов',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат методиста'
  ]
};