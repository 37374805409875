import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_qa_auto',
  title: 'Онлайн-курс Инженер по тестированию',
  description: 'За 7 месяцев получите все необходимые навыки и знания для старта работы в Тестировании ПО (Junior Test Engineer). Будете учиться вместе с личным наставником',
  school: 'brunoyam',
  category: 'programming',
  price: 62500,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Ftestirovsshik-qa&m=5&erid=LdtCK4uLs',
  skills: [
    'Ручное тестирование',
    'Автоматизация тестирования',
    'Python для тестирования',
    'Selenium WebDriver',
    'API тестирование'
  ],
  program: [
    {
      title: 'Основы тестирования',
      topics: [
        'Теория тестирования',
        'Тест-дизайн',
        'Тест-кейсы',
        'Баг-репорты'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Python основы',
        'Selenium WebDriver',
        'PyTest',
        'Page Object'
      ]
    },
    {
      title: 'API и CI/CD',
      topics: [
        'REST API',
        'Postman',
        'Git',
        'Jenkins'
      ]
    }
  ],
  features: [
    '2 проекта в портфолио',
    'Поддержка куратора 1 год',
    'Практические задания',
    'Работа с реальными проектами',
    'Помощь в трудоустройстве'
  ]
};