import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_qa_plus',
  title: 'Инженер по тестированию расширенный',
  description: 'Расширенный курс для тех, кто хочет учиться тестированию в спокойном темпе и больше практиковаться: сделаете 4 автотеста на Python и изучите Git, PyCharm и Pytest.',
  school: 'yandex',
  category: 'programming',
  price: 145000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fqa-engineer-plus&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Ручное тестирование',
    'Автоматизация на Python',
    'Git и PyCharm',
    'Pytest',
    'API тестирование'
  ],
  program: [
    {
      title: 'Расширенное тестирование',
      topics: [
        'Тест-дизайн',
        'Тестовая документация',
        'Техники тестирования',
        'Работа с багами'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Python для тестирования',
        'Pytest',
        'Selenium',
        'API тесты'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Git',
        'PyCharm',
        'CI/CD',
        'Docker'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    '4 автотеста',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат тестировщика'
  ]
};