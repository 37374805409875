import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_uxs',
  title: 'Профессия UX/UI-дизайнер',
  description: 'Комплексный курс по проектированию пользовательских интерфейсов. Научитесь создавать удобные и красивые цифровые продукты.',
  school: 'contented',
  category: 'design',
  price: 139888,
  oldPrice: 215212,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fux-ui-designer&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3886,
  discountPercent: 35,
  skills: [
    'UX исследования',
    'Проектирование интерфейсов',
    'Прототипирование',
    'Визуальный дизайн',
    'Работа с Figma',
    'Анимация интерфейсов'
  ],
  program: [
    {
      title: 'Основы UX/UI',
      topics: [
        'Введение в UX/UI',
        'Исследования пользователей',
        'Информационная архитектура',
        'Прототипирование'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Wireframes',
        'User Flow',
        'Интерактивные прототипы',
        'Юзабилити-тестирование'
      ]
    },
    {
      title: 'Визуальный дизайн',
      topics: [
        'Работа в Figma',
        'Дизайн-системы',
        'Компонентный подход',
        'Адаптивный дизайн'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Создание портфолио',
        'Презентация работ',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Стажировка в компании',
    'Сертификат UX/UI дизайнера'
  ]
};