import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_ai_medicine',
  title: 'ИИ в медицине: как использовать в работе каждый день',
  description: 'Онлайн-курс по применению ИИ в медицине: обучение для врачей и руководителей. Узнайте, как ИИ улучшает диагностику и автоматизирует процессы. Повышение квалификации без навыков программирования!',
  school: 'netology',
  category: 'medicine',
  price: 39900,
  oldPrice: 39900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fii-v-medicine-vrach&m=4&erid=LdtCKCxkP',
  monthlyPayment: 3325,
  skills: [
    'Применение ИИ в медицине',
    'Автоматизация процессов',
    'Анализ медицинских данных',
    'Работа с ИИ-системами',
    'Принятие решений'
  ],
  program: [
    {
      title: 'Основы ИИ в медицине',
      topics: [
        'Возможности ИИ',
        'Типы систем',
        'Области применения',
        'Ограничения технологий'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Диагностика',
        'Анализ изображений',
        'Поддержка решений',
        'Автоматизация'
      ]
    },
    {
      title: 'Внедрение технологий',
      topics: [
        'Оценка эффективности',
        'Интеграция систем',
        'Безопасность данных',
        'Этические аспекты'
      ]
    }
  ],
  features: [
    '18 практических занятий',
    'Реальные кейсы',
    'Работа с системами ИИ',
    'Консультации экспертов',
    'Сертификат о повышении квалификации'
  ]
};