import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_c4d',
  title: 'Курс Cinema 4D',
  description: 'Базовый курс по Cinema 4D',
  school: 'contented',
  category: 'software',
  price: 30600,
  oldPrice: 51000,
  currency: 'RUR',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1616161560417-66d4db5892ec?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fcinema4d&m=5&erid=LdtCKHL5u',
  discountPercent: 40,
  skills: [
    '3D моделирование',
    'Анимация',
    'Визуализация',
    'Динамика',
    'Моушн-дизайн',
    'Создание материалов'
  ],
  program: [
    {
      title: 'Основы Cinema 4D',
      topics: [
        'Интерфейс программы',
        'Базовые инструменты',
        'Примитивы и деформеры',
        'Навигация'
      ]
    },
    {
      title: 'Моделирование',
      topics: [
        'Полигональное моделирование',
        'Сплайновое моделирование',
        'Параметрические объекты',
        'Модификаторы'
      ]
    },
    {
      title: 'Анимация',
      topics: [
        'Ключевые кадры',
        'Анимация параметров',
        'Динамика',
        'Эффекторы'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Материалы и текстуры',
        'Освещение',
        'Рендеринг',
        'Постобработка'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Доступ к учебным материалам',
    'Сертификат о прохождении'
  ]
};