import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_wbds2',
  title: 'Профессия Веб-дизайнер',
  description: 'Комплексный курс по веб-дизайну. Научитесь создавать современные сайты и веб-приложения, работать с инструментами дизайна и взаимодействовать с разработчиками.',
  school: 'contented',
  category: 'design',
  price: 96310,
  oldPrice: 175109,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fweb-designer%2F&m=5&erid=LdtCKHL5u',
  monthlyPayment: 2675,
  discountPercent: 45,
  skills: [
    'Веб-дизайн',
    'UX/UI дизайн',
    'Прототипирование',
    'Адаптивный дизайн',
    'Работа с Figma',
    'Анимация интерфейсов'
  ],
  program: [
    {
      title: 'Основы веб-дизайна',
      topics: [
        'Принципы веб-дизайна',
        'Композиция',
        'Типографика',
        'Цвет в интерфейсах'
      ]
    },
    {
      title: 'Инструменты дизайнера',
      topics: [
        'Figma',
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Protopie'
      ]
    },
    {
      title: 'UX/UI дизайн',
      topics: [
        'Проектирование интерфейсов',
        'Пользовательский опыт',
        'Прототипирование',
        'Тестирование'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Создание портфолио',
        'Работа с заказчиками',
        'Защита проектов'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Стажировка в компании',
    'Сертификат веб-дизайнера'
  ]
};