import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_interface_designer_plus',
  title: 'Дизайнер интерфейсов расширенный',
  description: 'В дополнение к базовому курсу получите проверку 3 личных проектов, личные консультации с ментором и дополнительный кейс от реального заказчика.',
  school: 'yandex',
  category: 'design',
  price: 199000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Finterface-designer%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'UI/UX дизайн',
    'Проектирование интерфейсов',
    'Работа с реальными проектами',
    'Продвинутая Figma',
    'Управление проектами'
  ],
  program: [
    {
      title: 'Расширенный UX/UI',
      topics: [
        'Продвинутые исследования',
        'Сложные интерфейсы',
        'Дизайн-системы',
        'Анимация'
      ]
    },
    {
      title: 'Работа с заказчиками',
      topics: [
        'Ведение проектов',
        'Презентация решений',
        'Защита дизайна',
        'Работа с командой'
      ]
    },
    {
      title: 'Личные проекты',
      topics: [
        'Проверка работ',
        'Консультации ментора',
        'Реальные кейсы',
        'Портфолио'
      ]
    }
  ],
  features: [
    'Проверка личных проектов',
    'Индивидуальные консультации',
    'Работа с реальными заказчиками',
    'Расширенная программа',
    'Сертификат дизайнера'
  ]
};