import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_figma',
  title: 'Курс по основам Figma',
  description: 'Освоите возможности самого популярного графического онлайн-редактора в короткие сроки. Научитесь создавать графические элементы, макеты и анимированные прототипы',
  school: 'brunoyam',
  category: 'design',
  price: 19900,
  currency: 'RUB',
  duration: '2 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fonline-kursy%2Fosnovy-figma&m=5&erid=LdtCK4uLs',
  skills: [
    'Работа в Figma',
    'Создание интерфейсов',
    'Прототипирование',
    'Компоненты',
    'Auto-layout'
  ],
  program: [
    {
      title: 'Основы Figma',
      topics: [
        'Интерфейс программы',
        'Инструменты',
        'Работа с объектами',
        'Стили и цвета'
      ]
    },
    {
      title: 'Компоненты и прототипы',
      topics: [
        'Создание компонентов',
        'Auto-layout',
        'Прототипирование',
        'Анимация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Создание макетов',
        'Работа с клиентами',
        'Передача в разработку',
        'Организация файлов'
      ]
    }
  ],
  features: [
    '3 проекта',
    'Практические задания',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат по окончании'
  ]
};