import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_intnew',
  title: 'Профессия «Дизайнер интерьеров»',
  description: 'Обучение дизайну интерьеров',
  school: 'contented',
  category: 'design',
  price: 129249,
  oldPrice: 234999,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Finterior-design&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3590,
  discountPercent: 45,
  skills: [
    'Проектирование интерьеров',
    'Работа с материалами',
    'Планировочные решения',
    '3D-визуализация',
    'Работа со светом',
    'Декорирование'
  ],
  program: [
    {
      title: 'Основы дизайна интерьера',
      topics: [
        'История стилей',
        'Композиция',
        'Цветоведение',
        'Эргономика'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Обмерный план',
        'Планировочные решения',
        'Строительные чертежи',
        'Визуализация'
      ]
    },
    {
      title: 'Материалы и технологии',
      topics: [
        'Отделочные материалы',
        'Мебель и декор',
        'Освещение',
        'Инженерные системы'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Авторский надзор',
        'Ведение проекта',
        'Создание портфолио'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Стажировка в студии',
    'Сертификат дизайнера'
  ]
};