import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_hypnosis',
  title: 'Техники гипноза в психологическом консультировании',
  description: 'Дистанционное обучение по программе Техники гипноза в психологическом консультировании (144 часа) в ЦАППКК. Освоение современных методов гипнотерапии для профессиональной практики.',
  school: 'appkk',
  category: 'psychology',
  price: 12900,
  currency: 'RUR',
  duration: '144 часа',
  imageUrl: 'https://images.unsplash.com/photo-1606761568499-6d2451b23c66?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Ftekhniki_gipnoza_v_psikhologicheskom_konsultirovanii_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Техники гипноза',
    'Наведение транса',
    'Терапевтические метафоры',
    'Работа с сопротивлением',
    'Самогипноз'
  ],
  program: [
    {
      title: 'Основы гипноза',
      topics: [
        'История гипноза',
        'Теории гипноза',
        'Виды транса',
        'Показания и противопоказания'
      ]
    },
    {
      title: 'Техники гипноза',
      topics: [
        'Наведение транса',
        'Углубление транса',
        'Терапевтические внушения',
        'Выход из транса'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Работа с тревогой',
        'Лечение фобий',
        'Управление болью',
        'Работа с зависимостями'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};