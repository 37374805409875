import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_bachelor_project_management',
  title: 'Бакалавриат Проектный менеджмент',
  description: 'Обучение на бакалавра проектного менеджера в Нетологии с дипломом государственного образца РГГУ. Получите высшее образование онлайн по востребованной профессии проджект-менеджера.',
  school: 'netology',
  category: 'management',
  price: 1220000,
  oldPrice: 1220000,
  currency: 'RUB',
  duration: '47 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmenedzhment&m=4&erid=LdtCKCxkP',
  monthlyPayment: 1220000,
  skills: [
    'Управление проектами',
    'Стратегический менеджмент',
    'Управление командой',
    'Бизнес-планирование',
    'Риск-менеджмент'
  ],
  program: [
    {
      title: 'Основы менеджмента',
      topics: [
        'Теория управления',
        'Организационное поведение',
        'Экономика',
        'Право'
      ]
    },
    {
      title: 'Проектное управление',
      topics: [
        'Методологии управления',
        'Планирование проектов',
        'Управление рисками',
        'Agile-подходы'
      ]
    },
    {
      title: 'Практика управления',
      topics: [
        'Управление командой',
        'Финансовый менеджмент',
        'Стратегическое планирование',
        'Бизнес-аналитика'
      ]
    }
  ],
  features: [
    'Государственный диплом',
    'Дистанционное обучение',
    'Практические проекты',
    'Преподаватели-практики',
    'Помощь в трудоустройстве'
  ]
};