import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_react',
  title: 'React-разработчик',
  description: 'Курс для тех, кто уже знает основы JavaScript. Научитесь создавать приложения на React и использовать дополнительные инструменты: Redux, TypeScript, Jest.',
  school: 'yandex',
  category: 'programming',
  price: 64000,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Freact&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'React разработка',
    'Redux',
    'TypeScript',
    'Тестирование',
    'Оптимизация приложений'
  ],
  program: [
    {
      title: 'Основы React',
      topics: [
        'Компоненты',
        'Хуки',
        'Маршрутизация',
        'Формы'
      ]
    },
    {
      title: 'Продвинутый React',
      topics: [
        'Redux',
        'TypeScript',
        'Jest и тестирование',
        'Оптимизация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с API',
        'Авторизация',
        'Деплой',
        'Реальные проекты'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Code review',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};