import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_business_analyst_pro',
  title: 'Бизнес-аналитик. Расширенный',
  description: 'Бизнес-аналитик. Продвинутый',
  school: 'yandex',
  category: 'analytics',
  price: 146000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fbusiness-analyst%2F&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Бизнес-анализ',
    'Управление требованиями',
    'Моделирование процессов',
    'Проектирование решений',
    'Работа с заинтересованными сторонами'
  ],
  program: [
    {
      title: 'Продвинутый анализ',
      topics: [
        'Системный анализ',
        'Процессный подход',
        'Методологии анализа',
        'Управление изменениями'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Архитектура решений',
        'Прототипирование',
        'Документирование',
        'Валидация требований'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Управление проектами',
        'Внедрение решений',
        'Оценка эффективности'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат бизнес-аналитика'
  ]
};