import { Course } from '../../../../types';
import { course as artist3d } from './3d-artist';
import { course as authorPhoto } from './author-photography';
import { course as designSystems } from './design-systems';
import { course as graphicDesigner } from './graphic-designer';
import { course as graphicDesignerPro } from './graphic-designer-pro';
import { course as interiorDesigner } from './interior-designer';
import { course as interiorDesignerPro } from './interior-designer-pro';
import { course as interactiveMedia } from './interactive-media';
import { course as motionDesigner } from './motion-designer';
import { course as motionDesignerPro } from './motion-designer-pro';
import { course as uxuiDesigner } from './uxui-designer';
import { course as uxuiDesignerPro } from './uxui-designer-pro';
import { course as uxuiDesignerBootcamp } from './uxui-designer-bootcamp';
import { course as webDesigner } from './web-designer';
import { course as photoshop } from './photoshop';
import { course as afterEffects } from './after-effects';
import { course as blender } from './blender';
import { course as cinema4d } from './cinema4d';
import { course as figma } from './figma';
import { course as illustrator } from './illustrator';
import { course as indesign } from './indesign';
import { course as gameDesigner } from './game-designer';
import { course as videoEditor } from './video-editor';
import { course as neuralNetworks } from './neural-networks';
import { course as commercialIllustrator } from './commercial-illustrator';
import { course as graphicDesignerIntensive } from './graphic-designer-intensive';
import { course as designStart } from './design-start';

export const contentedCourses: Course[] = [
  artist3d,
  authorPhoto,
  designSystems,
  graphicDesigner,
  graphicDesignerPro,
  interiorDesigner,
  interiorDesignerPro,
  interactiveMedia,
  motionDesigner,
  motionDesignerPro,
  uxuiDesigner,
  uxuiDesignerPro,
  uxuiDesignerBootcamp,
  webDesigner,
  photoshop,
  afterEffects,
  blender,
  cinema4d,
  figma,
  illustrator,
  indesign,
  gameDesigner,
  videoEditor,
  neuralNetworks,
  commercialIllustrator,
  graphicDesignerIntensive,
  designStart
];