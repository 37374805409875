import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_art_therapy_full_base', // Changed ID to be unique
  title: 'Арт-терапия в практике психолога',
  description: 'Дистанционное обучение по программе Арт-терапия в практике психолога с присвоением квалификации Арт-терапевт(510 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 31000,
  currency: 'RUR',
  duration: '510 часов',
  imageUrl: 'https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fgeshtalt_terapiya_v_praktike_psikhologa_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Арт-терапевтические методы',
    'Гештальт-подход',
    'Работа с образами',
    'Групповая терапия',
    'Проективные методики'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'История арт-терапии',
        'Направления арт-терапии',
        'Гештальт-подход',
        'Теория творчества'
      ]
    },
    {
      title: 'Методы работы',
      topics: [
        'Изотерапия',
        'Музыкотерапия',
        'Драматерапия',
        'Фототерапия'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Индивидуальная работа',
        'Групповая терапия',
        'Семейная арт-терапия',
        'Работа с детьми'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом арт-терапевта'
  ]
};