import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_nutrition',
  title: 'Основы нутрициологии: как правильно питаться',
  description: 'Курс нутрициологии с сертификатом, обучение с нуля в Нетологии. Научим составлять сбалансированный рацион для себя и своих близких при любом бюджете.',
  school: 'netology',
  category: 'health',
  price: 18000,
  oldPrice: 30000,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1490645935967-10de6ba17061?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fhealthy-nutrition&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 1500,
  skills: [
    'Основы нутрициологии',
    'Составление рациона',
    'Работа с БЖУ',
    'Выбор продуктов',
    'Здоровое питание'
  ],
  program: [
    {
      title: 'Основы питания',
      topics: [
        'Макро и микронутриенты',
        'Энергетический баланс',
        'Режим питания',
        'Выбор продуктов'
      ]
    },
    {
      title: 'Практика питания',
      topics: [
        'Составление рациона',
        'Планирование меню',
        'Контроль порций',
        'Здоровые привычки'
      ]
    }
  ],
  features: [
    '24 практических занятия',
    'Составление рационов',
    'Работа с экспертами',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};