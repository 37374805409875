import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_digital_pr',
  title: 'PR-менеджер в digital',
  description: 'Профессия «PR-менеджер в digital» от Нетологии: научитесь управлять репутацией и строить бренд. Через 5 месяцев обучения сможете претендовать на должность PR-менеджера с практическими навыками.',
  school: 'netology',
  category: 'marketing',
  price: 58980,
  oldPrice: 98300,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdigital-pr&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2457,
  discountPercent: 40,
  skills: [
    'Digital PR',
    'Управление репутацией',
    'Работа со СМИ',
    'Контент-стратегия',
    'Кризисные коммуникации'
  ],
  program: [
    {
      title: 'Основы Digital PR',
      topics: [
        'PR-стратегия',
        'Репутационный менеджмент',
        'Работа со СМИ',
        'Digital-инструменты'
      ]
    },
    {
      title: 'Контент и коммуникации',
      topics: [
        'Контент-стратегия',
        'Социальные сети',
        'Работа с лидерами мнений',
        'Медиарилейшнз'
      ]
    },
    {
      title: 'Практика PR',
      topics: [
        'Кризисные коммуникации',
        'PR-кампании',
        'Оценка эффективности',
        'Работа с брендом'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};