import { Course } from '../../../../types';

export const course: Course = {
  id: 'abakus_math_48',
  title: 'Ментальная арифметика онлайн - обучение и курсы для детей | 48 уроков',
  description: 'Индивидуальные онлайн-занятия ментальной арифметикой с опытным педагогом на интерактивной платформе Абакус',
  school: 'abakus',
  category: 'math',
  price: 52530,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1518133910546-b6c2fb7d79e3?q=80&w=1200',
  url: 'https://go.redav.online/86fdf99551e92c21?dl=https%3A%2F%2Fabakus-center.ru%2Feducation%2Fonline&m=5&erid=2Vfnxx8cgzL',
  monthlyPayment: 8755,
  skills: [
    'Продвинутый устный счет',
    'Высокая концентрация внимания',
    'Развитая память',
    'Системное мышление',
    'Развитое воображение',
    'Работа с большими числами'
  ],
  program: [
    {
      title: 'Углубленный курс',
      topics: [
        'Сложные вычисления',
        'Многозначные числа',
        'Комбинированные операции',
        'Ментальные вычисления'
      ]
    },
    {
      title: 'Практический блок',
      topics: [
        'Скоростные вычисления',
        'Решение олимпиадных задач',
        'Развивающие игры',
        'Работа с числовыми рядами'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Расширенная программа',
    'Опытные преподаватели',
    'Сложные задания',
    'Мониторинг успеваемости'
  ]
};