import { Course } from '../../../../types';

export const course: Course = {
  id: 'anecole_italian_individual',
  title: 'Итальянский с преподавателем',
  description: 'Итальянский язык с преподавателем',
  school: 'anecole',
  category: 'italian',
  price: 19120,
  currency: 'RUB',
  duration: '8 часов',
  imageUrl: 'https://images.unsplash.com/photo-1454391304352-2bf4678b1a7a?q=80&w=1200',
  url: 'https://go.redav.online/4f106e4f2bb25c71?dl=https%3A%2F%2Fanecole.com%2Fitalian%2Findividual&m=5&erid=2Vfnxwa5gNL',
  monthlyPayment: 4780,
  skills: [
    'Индивидуальная программа',
    'Разговорная практика',
    'Грамматика',
    'Аудирование',
    'Письменная речь'
  ],
  program: [
    {
      title: 'Базовый курс',
      topics: [
        'Вводный урок',
        'Фонетика',
        'Грамматика',
        'Лексика'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Разговорная речь',
        'Аудирование',
        'Письмо',
        'Чтение'
      ]
    }
  ],
  features: [
    'Индивидуальные занятия',
    'Интерактивные тренажеры',
    'Гибкий график',
    'Персональная программа',
    'Сертификат по окончании'
  ]
};