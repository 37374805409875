import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_goals',
  title: 'Цели: как ставить и достигать',
  description: 'Определите убеждения, которые мешают вам двигаться вперёд, и начнёте их прорабатывать. Освоите специальные техники, чтобы ставить цели, формулировать гипотезы их достижения и выстраивать дорожную карту движения к результату.',
  school: 'netology',
  category: 'management',
  price: 4140,
  oldPrice: 6900,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1553034545-32d4cd2168f1?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fkouching&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4140,
  skills: [
    'Целеполагание',
    'Планирование',
    'Мотивация',
    'Преодоление препятствий',
    'Достижение результатов'
  ],
  program: [
    {
      title: 'Основы целеполагания',
      topics: [
        'Анализ желаний',
        'Постановка целей',
        'Планирование',
        'Мотивация'
      ]
    },
    {
      title: 'Практика достижения',
      topics: [
        'Работа с препятствиями',
        'Управление ресурсами',
        'Отслеживание прогресса',
        'Корректировка планов'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Индивидуальный коучинг',
    'Практические инструменты',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};