import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_head_of_sales',
  title: 'Руководитель отдела продаж',
  description: 'Руководитель отдела продаж',
  school: 'yandex',
  category: 'management',
  price: 98000,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fhead-of-sales&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Управление продажами',
    'Построение отдела',
    'Мотивация команды',
    'Аналитика продаж',
    'Стратегическое планирование'
  ],
  program: [
    {
      title: 'Управление отделом',
      topics: [
        'Структура отдела',
        'Найм персонала',
        'Обучение команды',
        'Мотивация'
      ]
    },
    {
      title: 'Процессы продаж',
      topics: [
        'Воронка продаж',
        'CRM-системы',
        'Скрипты продаж',
        'Работа с возражениями'
      ]
    },
    {
      title: 'Аналитика и развитие',
      topics: [
        'KPI и метрики',
        'Прогнозирование',
        'Оптимизация процессов',
        'Стратегия роста'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в развитии',
    'Сертификат руководителя'
  ]
};