import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_dessys',
  title: 'Дизайн-системы',
  description: 'Профессиональный курс по созданию и управлению дизайн-системами. Научитесь создавать масштабируемые решения для продуктовых команд.',
  school: 'contented',
  category: 'design',
  price: 28050,
  oldPrice: 51000,
  currency: 'RUR',
  duration: '3 месяца',
  imageUrl: 'https://static.tildacdn.com/stor6237-3732-4535-a430-336463323133/81986619.png',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fdesign-systems&m=5&erid=LdtCKHL5u',
  discountPercent: 45,
  skills: [
    'Создание компонентной библиотеки',
    'Разработка дизайн-токенов',
    'Управление версиями дизайн-системы',
    'Документирование компонентов',
    'Организация процессов в команде',
    'Внедрение дизайн-системы в продукт'
  ],
  program: [
    {
      title: 'Основы дизайн-систем',
      topics: [
        'Что такое дизайн-система',
        'Компоненты и паттерны',
        'Атомарный дизайн'
      ]
    },
    {
      title: 'Компонентная библиотека',
      topics: [
        'Создание базовых компонентов',
        'Вариативность компонентов',
        'Состояния и взаимодействия'
      ]
    },
    {
      title: 'Дизайн-токены',
      topics: [
        'Система типографики',
        'Цветовые палитры',
        'Сетки и отступы',
        'Иконография'
      ]
    },
    {
      title: 'Документация и процессы',
      topics: [
        'Структура документации',
        'Гайдлайны использования',
        'Версионирование',
        'Процессы обновления'
      ]
    }
  ],
  features: [
    'Практические задания на реальных кейсах',
    'Работа с Figma и Storybook',
    'Доступ к материалам курса',
    'Обратная связь от экспертов',
    'Сертификат о прохождении'
  ]
};