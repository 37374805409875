import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_ml',
  title: 'Машинное обучение: фундаментальные инструменты и практики',
  description: 'Онлайн-курс машинного обучения для начинающих и продолжающих: обучение нейронным сетям, реализация моделей на Python, подготовка к карьере в Data Science с помощью реальных проектов и стажировки.',
  school: 'netology',
  category: 'analytics',
  price: 56700,
  oldPrice: 81000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmachine-learn&m=5&erid=LdtCKCxkP',
  discountPercent: 30,
  monthlyPayment: 2362,
  skills: [
    'Машинное обучение',
    'Python для ML',
    'Нейронные сети',
    'Data Science',
    'Анализ данных',
    'Deep Learning'
  ],
  program: [
    {
      title: 'Основы ML',
      topics: [
        'Математика для ML',
        'Python для ML',
        'Алгоритмы ML',
        'Работа с данными'
      ]
    },
    {
      title: 'Нейронные сети',
      topics: [
        'Deep Learning',
        'Computer Vision',
        'NLP',
        'Transfer Learning'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные проекты',
        'Стажировка',
        'Работа с данными',
        'Оптимизация моделей'
      ]
    }
  ],
  features: [
    '24 практических занятия',
    'Стажировка',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};