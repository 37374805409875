import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_family_psychology_full',
  title: 'Психолог-консультант с дополнительной специализацией по семейной психологии',
  description: 'Дистанционное обучение по программе Психолог-консультант с дополнительной специализацией по семейной психологии. Семейное и детское психологическое консультирование. Системная семейная психотерапия с присвоением квалификации Психолог-консультант. Семейный психолог (1720 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 95000,
  currency: 'RUR',
  duration: '1720 часов',
  imageUrl: 'https://images.unsplash.com/photo-1511895426328-dc8714191300?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpsikholog_konsultant_s_dopolnitelnoy_spetsializatsiey_po_semeynoy_psikhologii_semeynoe_i_detskoe_psi%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Семейное консультирование',
    'Детская психология',
    'Системная терапия',
    'Работа с парами',
    'Семейная диагностика'
  ],
  program: [
    {
      title: 'Основы семейной психологии',
      topics: [
        'Теория семейных систем',
        'Жизненный цикл семьи',
        'Семейная диагностика',
        'Методы работы'
      ]
    },
    {
      title: 'Практика консультирования',
      topics: [
        'Работа с парами',
        'Детско-родительские отношения',
        'Семейные кризисы',
        'Системная терапия'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Развод и утраты',
        'Сложные семейные ситуации',
        'Работа с зависимостями',
        'Семейная травма'
      ]
    }
  ],
  features: [
    'Углубленная специализация',
    'Практическая подготовка',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};