import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_hr',
  title: 'Профессия HR-бизнес-партнёр',
  description: 'Онлайн курс «HR-бизнес-партнер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 5 месяцев. Помощь в трудоустройстве! Обучение на HR-бизнес-партнера с нуля онлайн.',
  school: 'geekbrains',
  category: 'management',
  price: 71820,
  oldPrice: 143639,
  currency: 'RUB',
  duration: '5 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/wv30e0WWF5xFSMqUnW0MEryEzEbASCLLEeKpcnUl.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmanagement%2Fhr-business-partner&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'HR-стратегия',
    'Управление персоналом',
    'Рекрутинг',
    'Развитие сотрудников',
    'HR-аналитика',
    'Организационное развитие'
  ],
  program: [
    {
      title: 'Основы HR-BP',
      topics: [
        'Роль HR-BP',
        'Бизнес-процессы',
        'Организационная структура',
        'HR-метрики'
      ]
    },
    {
      title: 'Управление персоналом',
      topics: [
        'Подбор персонала',
        'Адаптация',
        'Оценка эффективности',
        'Развитие талантов'
      ]
    },
    {
      title: 'HR-стратегия',
      topics: [
        'Разработка стратегии',
        'Управление изменениями',
        'Организационное развитие',
        'HR-бюджет'
      ]
    },
    {
      title: 'Практика HR-BP',
      topics: [
        'Работа с руководителями',
        'Управление проектами',
        'Конфликт-менеджмент',
        'HR-аналитика'
      ]
    }
  ],
  features: [
    'Практические кейсы',
    'Работа с реальными ситуациями',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат HR-BP'
  ]
};