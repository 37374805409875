import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_access',
  title: 'Офлайн-курс Microsoft Access',
  description: 'Курс Microsoft Access предназначен для экономистов, маркетологов и аналитиков. Вы сможете структурировать и организовывать обмен информацией, эффективно контролировать данные, создавать отчеты.',
  school: 'brunoyam',
  category: 'analytics',
  price: 14900,
  currency: 'RUB',
  duration: '2 недели',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-analitika-dannyh%2Faccess&m=5&erid=LdtCK4uLs',
  skills: [
    'Работа с базами данных',
    'Создание таблиц',
    'Построение запросов',
    'Создание форм',
    'Генерация отчетов'
  ],
  program: [
    {
      title: 'Основы Access',
      topics: [
        'Структура базы данных',
        'Создание таблиц',
        'Типы данных',
        'Связи между таблицами'
      ]
    },
    {
      title: 'Работа с данными',
      topics: [
        'Построение запросов',
        'Создание форм',
        'Фильтрация данных',
        'Сортировка'
      ]
    },
    {
      title: 'Отчетность',
      topics: [
        'Создание отчетов',
        'Группировка данных',
        'Форматирование',
        'Экспорт данных'
      ]
    }
  ],
  features: [
    '24 академических часа',
    '6 занятий',
    'Практические задания',
    'Работа с реальными данными',
    'Сертификат по окончании'
  ]
};