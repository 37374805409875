import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_marketplace',
  title: 'Продвижение на маркетплейсах',
  description: 'Пройдите курсы по продвижению на маркетплейсах в Нетологии. Изучите SEO оптимизацию карточек, рекламу товаров и стратегии эффективного продвижения для достижения лучших результатов.',
  school: 'netology',
  category: 'marketing',
  price: 55440,
  oldPrice: 92400,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmarketing-internet-magazinov&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2310,
  skills: [
    'Работа с маркетплейсами',
    'SEO оптимизация',
    'Управление товарами',
    'Реклама на площадках',
    'Аналитика продаж'
  ],
  program: [
    {
      title: 'Основы работы',
      topics: [
        'Выбор площадки',
        'Регистрация магазина',
        'Настройка каталога',
        'Управление ценами'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'SEO карточек',
        'Реклама',
        'Акции и скидки',
        'Работа с отзывами'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'Анализ продаж',
        'Конкурентный анализ',
        'Оптимизация карточек',
        'Отчетность'
      ]
    }
  ],
  features: [
    '30 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в запуске',
    'Сертификат о повышении квалификации'
  ]
};