import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_software_architect',
  title: 'Архитектура программного обеспечения',
  description: 'Это курс для разработчиков с опытом. Научитесь строить архитектуру приложений под конкретные бизнес-задачи и проектировать сложные системы.',
  school: 'yandex',
  category: 'programming',
  price: 150000,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fsoftware-architect&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Проектирование архитектуры',
    'Микросервисы',
    'Масштабирование систем',
    'Паттерны проектирования',
    'Оптимизация производительности'
  ],
  program: [
    {
      title: 'Основы архитектуры',
      topics: [
        'Архитектурные стили',
        'Паттерны проектирования',
        'Принципы SOLID',
        'Модульная архитектура'
      ]
    },
    {
      title: 'Микросервисы',
      topics: [
        'Проектирование микросервисов',
        'API Gateway',
        'Service Discovery',
        'Event-driven архитектура'
      ]
    },
    {
      title: 'Масштабирование',
      topics: [
        'Высокая доступность',
        'Отказоустойчивость',
        'Кэширование',
        'Балансировка нагрузки'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными системами',
    'Персональный наставник',
    'Помощь в развитии',
    'Сертификат архитектора'
  ]
};