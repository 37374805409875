import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_qa_full_stack',
  title: 'Инженер по тестированию: от новичка до автоматизатора',
  description: 'Научитесь проводить ручное тестирование и писать автотесты. Создадите 10 проектов для портфолио.',
  school: 'yandex',
  category: 'programming',
  price: 156000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fqa-engineer-full-stack&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Ручное тестирование',
    'Автоматизация тестирования',
    'Python для тестирования',
    'Selenium WebDriver',
    'API тестирование'
  ],
  program: [
    {
      title: 'Ручное тестирование',
      topics: [
        'Теория тестирования',
        'Тест-дизайн',
        'Тестовая документация',
        'Работа с багами'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Python основы',
        'Selenium WebDriver',
        'API тестирование',
        'Фреймворки'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные проекты',
        'CI/CD',
        'Git',
        'Командная работа'
      ]
    }
  ],
  features: [
    '10 проектов в портфолио',
    'Практика автоматизации',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат тестировщика'
  ]
};