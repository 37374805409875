import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_web_dev_spec',
  title: 'Веб-разработчик с нуля: профессия с выбором специализации',
  description: 'Профессия «Веб-разработчик с нуля» от Нетологии: научитесь создавать сайты и веб-приложения с нуля. Освоите фронтенд-разработку и выберете бэкенд-специализацию: PHP, Node.js или Python. Практика с реальными проектами поможет вам начать карьеру в веб-разработке.',
  school: 'netology',
  category: 'programming',
  price: 150708,
  oldPrice: 264400,
  currency: 'RUB',
  duration: '14 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fveb-razrabotchik-s-nulya-professiya-s-vyborom-specializacii&m=4&erid=LdtCKCxkP',
  discountPercent: 43,
  monthlyPayment: 4186,
  skills: [
    'Frontend разработка',
    'Backend разработка',
    'Базы данных',
    'JavaScript',
    'PHP/Python/Node.js'
  ],
  program: [
    {
      title: 'Frontend разработка',
      topics: [
        'HTML и CSS',
        'JavaScript',
        'React',
        'Адаптивная верстка'
      ]
    },
    {
      title: 'Backend специализация',
      topics: [
        'PHP/Python/Node.js',
        'Базы данных',
        'REST API',
        'Архитектура'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные проекты',
        'Git',
        'Деплой',
        'Тестирование'
      ]
    }
  ],
  features: [
    '21 практическое занятие',
    'Выбор специализации',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};