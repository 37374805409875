import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_art_therapy',
  title: 'Арт-терапия в индивидуальном психологическом консультировании',
  description: 'Дистанционное обучение по программе Арт-терапия в индивидуальном психологическом консультировании(144 часа) в ЦАППКК. Комплексная программа по использованию методов арт-терапии в психологическом консультировании.',
  school: 'appkk',
  category: 'psychology',
  price: 8300,
  currency: 'RUR',
  duration: '144 часа',
  imageUrl: 'https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fart_terapiya_v_individualnom_psikhologicheskom_konsultirovanii%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Основы арт-терапии',
    'Методы арт-терапевтической работы',
    'Диагностика в арт-терапии',
    'Индивидуальное консультирование',
    'Работа с разными возрастными группами'
  ],
  program: [
    {
      title: 'Введение в арт-терапию',
      topics: [
        'История развития арт-терапии',
        'Основные направления',
        'Принципы работы',
        'Показания к применению'
      ]
    },
    {
      title: 'Методы арт-терапии',
      topics: [
        'Изотерапия',
        'Музыкотерапия',
        'Сказкотерапия',
        'Песочная терапия'
      ]
    },
    {
      title: 'Практика консультирования',
      topics: [
        'Диагностические методики',
        'Построение сессии',
        'Работа с сопротивлением',
        'Анализ результатов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор кейсов',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};