import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_java',
  title: 'Java-разработчик',
  description: 'Освоите с нуля Java Core, Spring Boot, SQL, Git и сделаете портфолио из 5 проектов, с которыми можно искать новую работу.',
  school: 'yandex',
  category: 'programming',
  price: 150000,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fjava-developer&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Java Core',
    'Spring Boot',
    'SQL',
    'Git',
    'Разработка веб-приложений'
  ],
  program: [
    {
      title: 'Основы Java',
      topics: [
        'Синтаксис Java',
        'ООП',
        'Коллекции',
        'Многопоточность'
      ]
    },
    {
      title: 'Spring Framework',
      topics: [
        'Spring Core',
        'Spring MVC',
        'Spring Boot',
        'Spring Security'
      ]
    },
    {
      title: 'Базы данных и веб',
      topics: [
        'SQL',
        'JDBC',
        'REST API',
        'Микросервисы'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Code review',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};