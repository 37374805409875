import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_financial_analyst',
  title: 'Финансовый аналитик',
  description: 'Курс «Финансовый аналитик» от Нетологии: систематизируйте знания в финансах, научитесь строить финансовые модели, анализировать отчётность и визуализировать данные. Пройдите обучение с 6 реальными кейсами и получите необходимые навыки для успешной карьеры.',
  school: 'netology',
  category: 'finance',
  price: 94770,
  oldPrice: 145800,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fprofessiya_financial_analyst&m=4&erid=LdtCKCxkP',
  discountPercent: 35,
  monthlyPayment: 3948,
  skills: [
    'Финансовый анализ',
    'Построение моделей',
    'Работа с отчетностью',
    'Визуализация данных',
    'Оценка инвестиций'
  ],
  program: [
    {
      title: 'Основы финансового анализа',
      topics: [
        'Финансовая отчетность',
        'Анализ показателей',
        'Финансовое моделирование',
        'Оценка эффективности'
      ]
    },
    {
      title: 'Инвестиционный анализ',
      topics: [
        'Оценка проектов',
        'Риск-менеджмент',
        'DCF модель',
        'Инвестиционные решения'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с реальными данными',
        'Построение моделей',
        'Подготовка отчетов',
        'Презентация результатов'
      ]
    }
  ],
  features: [
    '80 практических занятий',
    '6 реальных кейсов',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};