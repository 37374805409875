import { Course } from '../../../../types';

export const course: Course = {
  id: 'contented_graph',
  title: 'Профессия Графический дизайнер',
  description: 'Комплексное обучение графическому дизайну',
  school: 'contented',
  category: 'design',
  price: 127079,
  oldPrice: 231054,
  currency: 'RUR',
  duration: '36 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1200',
  url: 'https://go.redav.online/db34ab33aba2adf1?dl=https%3A%2F%2Fcontented.ru%2Fedu%2Fgraph-design%2F&m=5&erid=LdtCKHL5u',
  monthlyPayment: 3530,
  discountPercent: 45,
  skills: [
    'Графический дизайн',
    'Работа с Adobe Creative Suite',
    'Типографика',
    'Создание брендинга',
    'Дизайн упаковки',
    'Веб-графика'
  ],
  program: [
    {
      title: 'Основы дизайна',
      topics: [
        'Теория дизайна',
        'Композиция',
        'Цветоведение',
        'Типографика'
      ]
    },
    {
      title: 'Инструменты дизайнера',
      topics: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe InDesign',
        'Figma'
      ]
    },
    {
      title: 'Брендинг',
      topics: [
        'Создание логотипов',
        'Фирменный стиль',
        'Брендбук',
        'Упаковка'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа над проектами',
        'Подготовка к печати',
        'Создание портфолио',
        'Работа с заказчиками'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Доступ к онлайн-библиотеке',
    'Сертификат дизайнера'
  ]
};