import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_fullstack_python',
  title: 'Fullstack-разработчик на Python',
  description: 'Профессия «Fullstack-разработчик на Python» от Нетологии: вы научитесь создавать сайты и веб-приложения с использованием Python и JavaScript. Курс включает много практики — 42 проекта, хакатоны и реальные задачи от партнёра ГК Самолет, что позволит вам развить ключевые навыки для успешной карьеры.',
  school: 'netology',
  category: 'programming',
  price: 178020,
  oldPrice: 296700,
  currency: 'RUB',
  duration: '21 месяц',
  imageUrl: 'https://images.unsplash.com/photo-1587620962725-abab7fe55159?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ffullstack-python-dev&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 4945,
  skills: [
    'Python Backend',
    'JavaScript Frontend',
    'Django',
    'React',
    'Базы данных',
    'API разработка'
  ],
  program: [
    {
      title: 'Backend разработка',
      topics: [
        'Python',
        'Django',
        'REST API',
        'Базы данных'
      ]
    },
    {
      title: 'Frontend разработка',
      topics: [
        'JavaScript',
        'React',
        'HTML/CSS',
        'Redux'
      ]
    },
    {
      title: 'DevOps практики',
      topics: [
        'Docker',
        'CI/CD',
        'Тестирование',
        'Деплой'
      ]
    }
  ],
  features: [
    '16 практических занятий',
    '42 проекта',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};