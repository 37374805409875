import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_finance_manager_pro',
  title: 'Финансовый менеджер',
  description: 'Профессия «Финансовый менеджер» от Нетологии: научитесь управлять финансовыми потоками, строить финансовые модели, оценивать инвестиционные ресурсы и работать в Google Таблицах, Excel, 1C и Power BI на продвинутом уровне.',
  school: 'netology',
  category: 'finance',
  price: 90480,
  oldPrice: 150800,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ffinancial_manager&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3770,
  skills: [
    'Финансовый анализ',
    'Управление финансами',
    'Бюджетирование',
    'Инвестиционный анализ',
    'Работа с отчетностью'
  ],
  program: [
    {
      title: 'Основы финансов',
      topics: [
        'Финансовый анализ',
        'Управленческий учет',
        'Бюджетирование',
        'Отчетность'
      ]
    },
    {
      title: 'Инструменты и анализ',
      topics: [
        'Excel и Power BI',
        'Финансовые модели',
        'Оценка инвестиций',
        'Риск-менеджмент'
      ]
    },
    {
      title: 'Практика управления',
      topics: [
        'Управление финансами',
        'Стратегическое планирование',
        'Оптимизация затрат',
        'Принятие решений'
      ]
    }
  ],
  features: [
    '84 практических занятия',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};