import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_bi_analyst',
  title: 'Аналитик BI',
  description: 'Профессия «Аналитик BI» от Нетологии: изучите анализ и визуализацию данных на SQL, Power BI, Tableau и Yandex DataLens. Индивидуальные консультации и подготовка к трудоустройству помогут вам развить ключевые навыки для успешной карьеры в аналитике.',
  school: 'netology',
  category: 'analytics',
  price: 95700,
  oldPrice: 159500,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fanalyst-bi&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3987,
  skills: [
    'SQL',
    'Power BI',
    'Tableau',
    'DataLens',
    'Визуализация данных',
    'Аналитика'
  ],
  program: [
    {
      title: 'Основы BI',
      topics: [
        'SQL и базы данных',
        'Анализ данных',
        'Визуализация',
        'Дашборды'
      ]
    },
    {
      title: 'Инструменты',
      topics: [
        'Power BI',
        'Tableau',
        'DataLens',
        'Excel'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Построение отчетов',
        'Аналитика данных',
        'Оптимизация',
        'Реальные проекты'
      ]
    }
  ],
  features: [
    '46 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};