import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_3d_artist',
  title: 'Профессия 3D-художник',
  description: 'Онлайн курс «3D-художник» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 10 месяцев. Помощь в трудоустройстве! Обучение на 3d художника с нуля онлайн.',
  school: 'geekbrains',
  category: 'game-dev',
  price: 117726,
  oldPrice: 235451,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/9DnCtExO1iHxbXOl1xnWyyvzUmOqcdRQpsmJyfGS.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdesign%2F3d-artist-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    '3D-моделирование',
    'Текстурирование',
    'Анимация',
    'Визуализация',
    'Работа со светом',
    'Композитинг'
  ],
  program: [
    {
      title: 'Основы 3D',
      topics: [
        'Введение в 3D-графику',
        'Базовое моделирование',
        'Работа с материалами',
        'Основы анимации'
      ]
    },
    {
      title: 'Продвинутое моделирование',
      topics: [
        'Hard Surface моделирование',
        'Органическое моделирование',
        'Скульптинг',
        'Ретопология'
      ]
    },
    {
      title: 'Материалы и текстуры',
      topics: [
        'UV-развертка',
        'PBR-материалы',
        'Текстурирование',
        'Шейдинг'
      ]
    },
    {
      title: 'Визуализация',
      topics: [
        'Освещение',
        'Рендеринг',
        'Постобработка',
        'Композитинг'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Персональный наставник',
    'Помощь в создании портфолио',
    'Стажировка в студии',
    'Сертификат 3D-художника'
  ]
};