import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_telegram',
  title: 'Продвижение в Телеграме',
  description: 'Узнайте все секреты продвижения Telegram-каналов на нашем курсе. Научитесь управлять рекламными кампаниями, создавать контент и анализировать результаты. Курс включает вебинары, практические занятия и видеолекции. По окончании вы получите сертификат и ценные навыки для работы в digital-сфере.',
  school: 'netology',
  category: 'marketing',
  price: 45000,
  oldPrice: 75000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1611606063065-ee7946f0787a?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ftg&m=5&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2500,
  skills: [
    'Telegram маркетинг',
    'Создание контента',
    'Управление каналом',
    'Реклама в Telegram',
    'Аналитика'
  ],
  program: [
    {
      title: 'Основы Telegram',
      topics: [
        'Создание канала',
        'Оформление',
        'Контент-стратегия',
        'Форматы контента'
      ]
    },
    {
      title: 'Продвижение',
      topics: [
        'Реклама в каналах',
        'Работа с блогерами',
        'Кросс-промо',
        'Органический рост'
      ]
    },
    {
      title: 'Аналитика',
      topics: [
        'Метрики канала',
        'Анализ аудитории',
        'Оценка эффективности',
        'Оптимизация'
      ]
    }
  ],
  features: [
    '37 практических занятий',
    'Реальные кейсы',
    'Персональный наставник',
    'Помощь в продвижении',
    'Сертификат о повышении квалификации'
  ]
};