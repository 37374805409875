import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_russian_std',
  title: 'Русский язык [Стандарт]',
  description: 'Мастер группа - это проверенный годами формат подготовки, который позволит тебе пройти и закрепить программу, а также закрыть все проблемные темы в размеренном темпе!',
  school: '99ballov',
  category: 'russian',
  price: 3790,
  oldPrice: 4290,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 12,
  skills: [
    'Орфография и пунктуация',
    'Работа с текстом',
    'Написание сочинений',
    'Анализ текста',
    'Практика выполнения заданий'
  ],
  program: [
    {
      title: 'Теория и практика',
      topics: [
        '3-4 занятия в неделю',
        'Каждое занятие 1.5-2 часа',
        'Двухуровневые домашние задания',
        'Работа над ошибками'
      ]
    },
    {
      title: 'Материалы и поддержка',
      topics: [
        'Рабочие тетради и конспекты',
        'Доступ к образовательной платформе',
        'Записи занятий с тайм-кодами',
        'Онлайн-куратор'
      ]
    }
  ],
  features: [
    'Образовательная платформа',
    'Неограниченная практика',
    'Проверка домашних заданий',
    'Персональный менеджер',
    'Зачеты и пробники'
  ]
};