import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_ux_research_designers',
  title: 'UX-исследования для дизайнеров',
  description: 'Короткий курс-специализация в продуктовом дизайне для опытных дизайнеров (UX/UI, веб), которые хотят освоить навык UX-исследований.',
  school: 'yandex',
  category: 'design',
  price: 85000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fux-research-for-designers&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'UX исследования',
    'Качественные методы',
    'Количественные методы',
    'Юзабилити-тестирование',
    'Анализ данных'
  ],
  program: [
    {
      title: 'Основы исследований',
      topics: [
        'Методология исследований',
        'Планирование',
        'Гипотезы',
        'Метрики'
      ]
    },
    {
      title: 'Методы исследований',
      topics: [
        'Интервью',
        'Опросы',
        'Тестирование',
        'Анализ данных'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Проведение исследований',
        'Анализ результатов',
        'Презентация выводов',
        'Принятие решений'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными кейсами',
    'Персональный наставник',
    'Помощь в развитии',
    'Сертификат специалиста'
  ]
};