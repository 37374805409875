import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_art_therapy_counseling',
  title: 'Психолог-консультант. Методы и технологии оказания психологических услуг населению и организациям с расширенной подготовкой в области арт-терапии',
  description: 'Дистанционное обучение по программе Психолог-консультант. Методы и технологии оказания психологических услуг населению и организациям с расширенной подготовкой в области арт-терапии с присвоением квалификации Психолог-консультант. Арт-терапевт (1800 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 95000,
  currency: 'RUR',
  duration: '1800 часов',
  imageUrl: 'https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fpsikholog_konsultant_metody_i_tekhnologii_okazaniya_psikhologicheskikh_uslug_naseleniyu_i_organizats%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Психологическое консультирование',
    'Арт-терапевтические методы',
    'Диагностика',
    'Групповая работа',
    'Индивидуальная терапия'
  ],
  program: [
    {
      title: 'Основы консультирования',
      topics: [
        'Теория консультирования',
        'Методы диагностики',
        'Построение контакта',
        'Ведение случая'
      ]
    },
    {
      title: 'Арт-терапия',
      topics: [
        'Методы арт-терапии',
        'Работа с образами',
        'Проективные методики',
        'Групповая работа'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Индивидуальное консультирование',
        'Групповая терапия',
        'Работа с разными запросами',
        'Супервизия'
      ]
    }
  ],
  features: [
    'Комплексная подготовка',
    'Практические навыки',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};