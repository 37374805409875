import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_data_driven',
  title: 'Data driven подход для управления продуктом',
  description: 'Курс Data-driven подход: как управлять продуктом и принимать решения на основе данных от Нетологии. Узнайте, как с помощью данных улучшить продукт и увеличить его успех на рынке. Практические кейсы, опытные преподаватели и востребованные навыки для карьеры в продакт-менеджменте.',
  school: 'netology',
  category: 'analytics',
  price: 55200,
  oldPrice: 92000,
  currency: 'RUB',
  duration: '7 недель',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdata-driven&m=4&erid=LdtCKCxkP',
  monthlyPayment: 2300,
  discountPercent: 40,
  skills: [
    'Data-driven подход',
    'Продуктовая аналитика',
    'Работа с метриками',
    'A/B тестирование',
    'Принятие решений'
  ],
  program: [
    {
      title: 'Основы data-driven подхода',
      topics: [
        'Метрики продукта',
        'Сбор данных',
        'Анализ данных',
        'Формирование гипотез'
      ]
    },
    {
      title: 'Тестирование и эксперименты',
      topics: [
        'A/B тестирование',
        'Проверка гипотез',
        'Анализ результатов',
        'Принятие решений'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с реальными данными',
        'Построение дашбордов',
        'Оптимизация продукта',
        'Презентация результатов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными данными',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};