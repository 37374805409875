import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_speech',
  title: 'Голос и речь: практикум',
  description: 'Постановка голоса и речи на онлайн-курсе. Вы научитесь говорить свободно и уверенно, раскроете возможности своего голоса, улучшите дикцию и звучание. Обучение с сертификатом в Нетологии.',
  school: 'netology',
  category: 'lifestyle',
  price: 15420,
  oldPrice: 25700,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1478737270239-2f02b77fc618?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Ftekhnika-rechi&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 1285,
  skills: [
    'Техника речи',
    'Постановка голоса',
    'Дикция',
    'Интонация',
    'Публичные выступления'
  ],
  program: [
    {
      title: 'Основы речи',
      topics: [
        'Постановка голоса',
        'Дыхательные техники',
        'Артикуляция',
        'Дикция'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с текстом',
        'Выразительность речи',
        'Импровизация',
        'Публичные выступления'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Индивидуальный подход',
    'Работа с педагогом',
    'Доступ к материалам',
    'Сертификат о прохождении'
  ]
};