import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_child_psychology',
  title: 'Детская психология. Практическая психологическая помощь детям и подросткам',
  description: 'Дистанционное обучение по программе Детская психология. Практическая психологическая помощь детям и подросткам с присвоением квалификации Детский психолог(450 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 33400,
  currency: 'RUR',
  duration: '450 часов',
  imageUrl: 'https://images.unsplash.com/photo-1503919545889-aef636e10ad4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fdetskaya_psikhologiya_prakticheskaya_psikhologicheskaya_pomoshch_detyam_i_podrostkam_s_prisvoeniem_k%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Детская психология',
    'Возрастная психология',
    'Диагностика развития',
    'Коррекционная работа',
    'Консультирование родителей'
  ],
  program: [
    {
      title: 'Основы детской психологии',
      topics: [
        'Возрастная психология',
        'Теории развития',
        'Диагностика',
        'Нормы развития'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Консультирование',
        'Коррекция',
        'Работа с родителями',
        'Групповая работа'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Кризисы развития',
        'Школьная психология',
        'Работа с травмой',
        'Семейное консультирование'
      ]
    }
  ],
  features: [
    'Практические техники',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Диплом детского психолога'
  ]
};