import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_event',
  title: 'Профессия Event-менеджер',
  description: 'Онлайн курс «Event-менеджер» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 6 месяцев. Помощь в трудоустройстве! Обучение на Event-менеджера с нуля онлайн.',
  school: 'geekbrains',
  category: 'marketing',
  price: 62222,
  oldPrice: 124444,
  currency: 'RUB',
  duration: '6 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/uf56cacA3a3GYBxwR3ZfmdLZuJnukBJf1vZczGDz.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmarketing%2Fevent-manager&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Организация мероприятий',
    'Управление проектами',
    'Работа с подрядчиками',
    'Бюджетирование',
    'Маркетинг мероприятий',
    'Работа с клиентами'
  ],
  program: [
    {
      title: 'Основы event-менеджмента',
      topics: [
        'Виды мероприятий',
        'Планирование событий',
        'Концепция мероприятия',
        'Управление проектами'
      ]
    },
    {
      title: 'Организация мероприятий',
      topics: [
        'Площадки и локации',
        'Работа с подрядчиками',
        'Техническое обеспечение',
        'Логистика'
      ]
    },
    {
      title: 'Маркетинг и продвижение',
      topics: [
        'Продвижение мероприятий',
        'PR и реклама',
        'Работа со спонсорами',
        'Social Media'
      ]
    },
    {
      title: 'Управление и контроль',
      topics: [
        'Бюджетирование',
        'Риск-менеджмент',
        'Координация команды',
        'Оценка эффективности'
      ]
    }
  ],
  features: [
    'Практика на реальных мероприятиях',
    'Работа с действующими event-агентствами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат event-менеджера'
  ]
};