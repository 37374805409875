import { Course } from '../../../../types';

export const course: Course = {
  id: 'brunoyam_react',
  title: 'Офлайн-курс Профессия React-разработчик',
  description: 'Практический курс по React.js — самая популярная JS-библиотека для Frontend-разработки. Курс подойдёт тем, кто умеет верстать и знает основы JavaScript',
  school: 'brunoyam',
  category: 'programming',
  price: 31900,
  currency: 'RUB',
  duration: '5 недель',
  imageUrl: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?q=80&w=1200',
  url: 'https://go.redav.online/953f9c51c2f68ba1?dl=https%3A%2F%2Fbrunoyam.com%2Fkursy-programmirovaniya%2Freact&m=5&erid=LdtCK4uLs',
  skills: [
    'React.js',
    'Redux',
    'TypeScript',
    'React Router',
    'Тестирование'
  ],
  program: [
    {
      title: 'Основы React',
      topics: [
        'Компоненты',
        'Props и State',
        'Хуки',
        'Жизненный цикл'
      ]
    },
    {
      title: 'Продвинутый React',
      topics: [
        'Redux',
        'TypeScript',
        'React Router',
        'Styled Components'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с API',
        'Тестирование',
        'Оптимизация',
        'Деплой'
      ]
    }
  ],
  features: [
    '48 академических часов',
    '12 занятий',
    'Практические проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве'
  ]
};