import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_film_editor',
  title: 'Профессия Режиссёр монтажа',
  description: 'Онлайн курс «Режиссёр монтажа» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 9 месяцев. Помощь в трудоустройстве! Обучение на режиссера монтажа с нуля онлайн.',
  school: 'geekbrains',
  category: 'multimedia',
  price: 123300,
  oldPrice: 246599,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/rldv3b3L29Nsp28a7FDQDRhUiELuV4KsmRA5GBRz.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fmultimedia%2Ffilm-editor-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'Монтаж видео',
    'Работа со звуком',
    'Цветокоррекция',
    'Визуальные эффекты',
    'Режиссура монтажа',
    'Работа с форматами'
  ],
  program: [
    {
      title: 'Основы монтажа',
      topics: [
        'Теория монтажа',
        'Монтажные программы',
        'Форматы видео',
        'Композиция кадра'
      ]
    },
    {
      title: 'Технические аспекты',
      topics: [
        'Работа со звуком',
        'Цветокоррекция',
        'Визуальные эффекты',
        'Титры и графика'
      ]
    },
    {
      title: 'Режиссура монтажа',
      topics: [
        'Драматургия',
        'Темпоритм',
        'Стилистика',
        'Работа с материалом'
      ]
    },
    {
      title: 'Профессиональная практика',
      topics: [
        'Работа над проектами',
        'Взаимодействие с командой',
        'Сдача проекта',
        'Портфолио'
      ]
    }
  ],
  features: [
    'Практика на реальных проектах',
    'Работа с профессиональным ПО',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат режиссера монтажа'
  ]
};