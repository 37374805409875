import { Course } from '../../../../types';

export const course: Course = {
  id: '99ballov_chemistry_pro',
  title: 'Химия [ПРО]',
  description: 'Мастер группа [ПРО] - это углубленный формат подготовки с расширенной программой и дополнительными материалами для достижения максимального результата.',
  school: '99ballov',
  category: 'chemistry',
  price: 4290,
  oldPrice: 4790,
  currency: 'RUR',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1603126857599-f6e157fa2fe6?q=80&w=1200',
  url: 'https://go.redav.online/51acd746c00f0cc1?dl=https%3A%2F%2Flk.99ballov.ru%2Fcourse%2Fmaster-group&m=5&erid=2VfnxwpX1dz',
  discountPercent: 10,
  skills: [
    'Решение олимпиадных задач',
    'Углубленная органическая химия',
    'Сложные реакции',
    'Исследовательская работа',
    'Экспериментальные задачи'
  ],
  program: [
    {
      title: 'Расширенная программа',
      topics: [
        'Олимпиадная химия',
        'Физическая химия',
        'Органический синтез',
        'Аналитическая химия'
      ]
    },
    {
      title: 'Практикум',
      topics: [
        'Сложные задачи',
        'Лабораторные работы',
        'Исследовательские проекты',
        'Химические турниры'
      ]
    }
  ],
  features: [
    'Расширенная программа',
    'Углубленные материалы',
    'Олимпиадная подготовка',
    'Личный куратор',
    'Дополнительные консультации'
  ]
};