import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_middle_python',
  title: 'Мидл Python-разработчик',
  description: 'Продвинутый курс для тех, кто знаком с основами Python, базами данных и API. Углубите знания в бэкенде, чтобы выйти на новый профессиональный уровень.',
  school: 'yandex',
  category: 'programming',
  price: 153000,
  currency: 'RUB',
  duration: '7 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fmiddle-python&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продвинутый Python',
    'Микросервисы',
    'Высоконагруженные системы',
    'DevOps практики',
    'Архитектура приложений'
  ],
  program: [
    {
      title: 'Продвинутый Python',
      topics: [
        'Асинхронное программирование',
        'Многопоточность',
        'Метаклассы',
        'Декораторы'
      ]
    },
    {
      title: 'Архитектура',
      topics: [
        'Микросервисы',
        'Event-driven архитектура',
        'CQRS',
        'DDD'
      ]
    },
    {
      title: 'DevOps и инфраструктура',
      topics: [
        'Docker',
        'Kubernetes',
        'CI/CD',
        'Мониторинг'
      ]
    }
  ],
  features: [
    'Сложные проекты',
    'Code review',
    'Персональный наставник',
    'Помощь в карьерном росте',
    'Сертификат Middle разработчика'
  ]
};