import React from 'react';
import { Link } from 'react-router-dom';
import { Course } from '../types';
import { StarIcon, ClockIcon } from '@heroicons/react/24/solid';
import { useCourseStore } from '../store/courseStore';

interface CourseCardCompactProps {
  course: Course;
}

export const CourseCardCompact: React.FC<CourseCardCompactProps> = ({ course }) => {
  const { schools } = useCourseStore();
  const school = schools.find(s => s.id === course.school);
  
  return (
    <Link 
      to={`/course/${course.id}`} 
      className="block w-[300px] flex-shrink-0"
      onClick={() => window.scrollTo(0, 0)} // Add scroll to top
    >
      <div className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow border border-gray-100 h-full">
        <img 
          src={course.imageUrl} 
          alt={course.title}
          className="w-full h-40 object-cover"
        />
        
        <div className="p-4">
          <div className="flex items-center gap-2 mb-2">
            <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
              {school?.name || course.school}
            </span>
            <div className="flex items-center text-yellow-400 text-xs">
              <StarIcon className="h-4 w-4" />
              <StarIcon className="h-4 w-4" />
              <StarIcon className="h-4 w-4" />
              <StarIcon className="h-4 w-4" />
              <StarIcon className="h-4 w-4" />
            </div>
          </div>
          
          <h3 className="text-base font-bold mb-2 line-clamp-2 hover:text-blue-600 min-h-[48px]">
            {course.title}
          </h3>
          
          <div className="flex items-center gap-4 mb-3 text-xs text-gray-600">
            <div className="flex items-center gap-1">
              <ClockIcon className="h-4 w-4" />
              {course.duration}
            </div>
          </div>
          
          <div className="flex items-center justify-between">
            <div>
              <div className="text-lg font-bold text-blue-600">
                {course.price.toLocaleString()} ₽
              </div>
              {course.monthlyPayment && (
                <div className="text-xs text-gray-500">
                  {course.monthlyPayment.toLocaleString()} ₽/мес
                </div>
              )}
            </div>
            {course.discountPercent && (
              <div className="text-xs font-medium text-red-600 bg-red-50 px-2 py-1 rounded">
                -{course.discountPercent}%
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};