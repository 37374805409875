import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_metaphoric_cards_full',
  title: 'Метафорические ассоциативные карты в практике работы психолога',
  description: 'Дистанционное обучение по программе Метафорические ассоциативные карты в практике работы психолога (600 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 43000,
  currency: 'RUR',
  duration: '600 часов',
  imageUrl: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fmetaforicheskie_assotsiativnye_karty_v_praktike_raboty_psikhologa_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Работа с МАК',
    'Проективные методики',
    'Психологическое консультирование',
    'Групповая работа',
    'Индивидуальная работа'
  ],
  program: [
    {
      title: 'Основы работы с МАК',
      topics: [
        'История создания МАК',
        'Принципы работы',
        'Виды колод',
        'Базовые техники'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'Индивидуальное консультирование',
        'Групповая работа',
        'Диагностика',
        'Коррекция'
      ]
    },
    {
      title: 'Специальные техники',
      topics: [
        'Работа с травмой',
        'Семейное консультирование',
        'Работа с детьми',
        'Профориентация'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Супервизия',
    'Разбор случаев',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};