import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_cbt_1',
  title: 'Практические навыки когнитивно-поведенческой терапии (ступень 1)',
  description: 'Дистанционное обучение по программе Практические навыки когнитивно-поведенческой терапии (ступень 1)(62 часа) в ЦАППКК. Освоение базовых навыков КПТ для профессиональной практики.',
  school: 'appkk',
  category: 'psychology',
  price: 29900,
  currency: 'RUR',
  duration: '62 часа',
  imageUrl: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fprakticheskie_navyki_kognitivno_povedencheskoy_terapii_stupen_1_%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Структурирование сессии',
    'Работа с автоматическими мыслями',
    'Когнитивное реструктурирование',
    'Поведенческие техники',
    'Работа с тревогой'
  ],
  program: [
    {
      title: 'Основы КПТ',
      topics: [
        'Структура сессии',
        'Когнитивная модель',
        'Автоматические мысли',
        'Когнитивные искажения'
      ]
    },
    {
      title: 'Практические техники',
      topics: [
        'Выявление мыслей',
        'Работа с убеждениями',
        'Поведенческие эксперименты',
        'Экспозиция'
      ]
    },
    {
      title: 'Работа с тревогой',
      topics: [
        'Модель тревоги',
        'Диагностика',
        'Техники работы',
        'Профилактика рецидивов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};