import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_landscape_pro',
  title: 'Ландшафтный дизайнер. Расширенный курс',
  description: 'Онлайн-курс «Ландшафтный дизайнер» — Нетология: Пройдите обучение по проектированию садовых и городских пространств с нуля. Освойте программы ArchiCAD, AutoCAD и SketchUp, создайте 5 проектов для портфолио. Получите навыки озеленения, планирования освещения и работы с зелёными технологиями. Диплом и помощь в трудоустройстве включены!',
  school: 'netology',
  category: 'design',
  price: 128948,
  oldPrice: 214912,
  currency: 'RUB',
  duration: '10 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1558904541-efa843a96f01?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Flandshaftnyj-dizajner-ultimate&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3581,
  skills: [
    'Ландшафтное проектирование',
    'Работа с 3D программами',
    'Озеленение',
    'Освещение',
    'Зеленые технологии'
  ],
  program: [
    {
      title: 'Проектирование',
      topics: [
        'Генеральный план',
        'Дендроплан',
        'Разбивочный чертеж',
        'Визуализация'
      ]
    },
    {
      title: '3D и визуализация',
      topics: [
        'ArchiCAD',
        'AutoCAD',
        'SketchUp',
        'Рендеринг'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с заказчиками',
        'Подбор растений',
        'Системы полива',
        'Реализация проектов'
      ]
    }
  ],
  features: [
    '23 практических занятия',
    '5 проектов в портфолио',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};