import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sound_design_pro',
  title: 'Саунд-дизайнер PRO',
  description: 'Профессиональный курс по саунд-дизайну от Нетологии. Научитесь создавать профессиональное звуковое оформление для фильмов, игр и рекламы. Освойте работу со звуком на продвинутом уровне.',
  school: 'netology',
  category: 'multimedia',
  price: 127894,
  oldPrice: 213157,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1520523839897-bd0b52f945a0?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsound-design-pro&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3553,
  skills: [
    'Профессиональный саунд-дизайн',
    'Звуковые эффекты',
    'Звуковое оформление',
    'Работа со звуком',
    'Post-production'
  ],
  program: [
    {
      title: 'Продвинутый саунд-дизайн',
      topics: [
        'Сложные звуковые эффекты',
        'Многослойный звук',
        'Пространственный звук',
        'Звуковые ландшафты'
      ]
    },
    {
      title: 'Специализация',
      topics: [
        'Звук для кино',
        'Звук для игр',
        'Звук для рекламы',
        'Музыкальное оформление'
      ]
    },
    {
      title: 'Продакшн',
      topics: [
        'Студийная запись',
        'Post-production',
        'Сведение',
        'Мастеринг'
      ]
    }
  ],
  features: [
    '85 практических занятий',
    'Работа над реальными проектами',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};