import { Course } from '../../../../types';

export const course: Course = {
  id: '100points_math_krug',
  title: 'Годовой курс по подготовке к ЕГЭ по профильной математике 90 баллов «Кругосветка 2024/2025»',
  description: 'Если ты хочешь набрать высокие баллы и поступить в вуз мечты, то этот курс для тебя, будущий высокобалльник! Комплексная подготовка к профильной математике с опытными преподавателями.',
  school: '100points',
  category: 'math',
  price: 33890,
  oldPrice: 39490,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1596495578065-6e0763fa1178?q=80&w=1200',
  url: 'https://go.redav.online/58cf8c8331af9071?dl=https%3A%2F%2F100points.ru%2F%23courses&m=5',
  monthlyPayment: 3690,
  discountPercent: 15,
  skills: [
    'Решение профильных задач',
    'Работа с параметрами',
    'Стереометрия',
    'Планиметрия',
    'Экономические задачи',
    'Теория вероятностей'
  ],
  program: [
    {
      title: 'Алгебра',
      topics: [
        'Уравнения и неравенства',
        'Функции и графики',
        'Производная и интеграл',
        'Задачи с параметрами'
      ]
    },
    {
      title: 'Геометрия',
      topics: [
        'Планиметрия',
        'Стереометрия',
        'Векторы',
        'Координатный метод'
      ]
    },
    {
      title: 'Прикладные задачи',
      topics: [
        'Экономические задачи',
        'Теория вероятностей',
        'Комбинаторика',
        'Текстовые задачи'
      ]
    },
    {
      title: 'Подготовка к экзамену',
      topics: [
        'Пробные работы',
        'Разбор типичных ошибок',
        'Стратегия выполнения',
        'Работа над скоростью'
      ]
    }
  ],
  features: [
    '2 веба в неделю',
    'Система мотивации',
    'Психологическая поддержка',
    'Помощь с апелляцией',
    'Разбор домашних заданий'
  ]
};