import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_it_specialist',
  title: 'IT-профессия с нуля: курс с выбором специализации',
  description: 'Профессия «IT-специалист с нуля» от Нетологии: курс с комплексной профориентацией в IT. Вы попробуете 10 востребованных IT-профессий, выберете подходящую и освоите её с нуля. Поддержка карьерных консультантов поможет вам выявить сильные стороны и уверенно начать карьеру в IT.',
  school: 'netology',
  category: 'programming',
  price: 136800,
  oldPrice: 228000,
  currency: 'RUB',
  duration: '4 недели',
  imageUrl: 'https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fit-specialist&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 3800,
  skills: [
    'Основы программирования',
    'Веб-разработка',
    'Тестирование',
    'Аналитика',
    'DevOps'
  ],
  program: [
    {
      title: 'IT-профориентация',
      topics: [
        'Обзор профессий',
        'Тестирование навыков',
        'Выбор направления',
        'Построение карьеры'
      ]
    },
    {
      title: 'Базовые навыки',
      topics: [
        'Программирование',
        'Базы данных',
        'Сети',
        'Операционные системы'
      ]
    },
    {
      title: 'Специализация',
      topics: [
        'Углубленное изучение',
        'Практические проекты',
        'Работа с технологиями',
        'Подготовка к работе'
      ]
    }
  ],
  features: [
    '39 практических занятий',
    'Выбор специализации',
    'Карьерное консультирование',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};