import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_sql_data',
  title: 'SQL и получение данных',
  description: 'Изучите SQL на онлайн-курсе в Нетологии. Освойте основы работы с базами данных, создание запросов и анализ данных за 2 месяца. Обучение с практическими заданиями и сертификатом. Начните карьеру аналитика данных с нуля.',
  school: 'netology',
  category: 'analytics',
  price: 28088,
  oldPrice: 56175,
  currency: 'RUB',
  duration: '9 недель',
  imageUrl: 'https://images.unsplash.com/photo-1633412802994-5c058f151b66?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fsql-lessons&m=5&erid=LdtCKCxkP',
  discountPercent: 50,
  monthlyPayment: 2340,
  skills: [
    'SQL запросы',
    'Работа с базами данных',
    'Анализ данных',
    'Оптимизация запросов',
    'Агрегация данных'
  ],
  program: [
    {
      title: 'Основы SQL',
      topics: [
        'Базы данных',
        'SELECT запросы',
        'Фильтрация',
        'Сортировка'
      ]
    },
    {
      title: 'Продвинутый SQL',
      topics: [
        'JOIN операции',
        'Подзапросы',
        'Агрегатные функции',
        'Оптимизация'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с данными',
        'Анализ данных',
        'Создание отчетов',
        'Реальные кейсы'
      ]
    }
  ],
  features: [
    '24 практических занятия',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Сертификат о повышении квалификации'
  ]
};