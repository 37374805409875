import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_environment',
  title: 'Дизайн среды: от интерьера до города',
  description: 'Онлайн-курс «Дизайн среды» от Нетологии: Освойте дизайн пространства с нуля! Создавайте интерьеры и общественные зоны, работайте с реальными проектами и получайте поддержку экспертов. Превратите свою страсть к дизайну в успешную карьеру!',
  school: 'netology',
  category: 'design',
  price: 203016,
  oldPrice: 338360,
  currency: 'RUB',
  duration: '18 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fdizayn_sredy&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 5639,
  skills: [
    'Дизайн интерьеров',
    'Городская среда',
    'Проектирование пространств',
    'Визуализация',
    'Работа с материалами'
  ],
  program: [
    {
      title: 'Основы дизайна среды',
      topics: [
        'Теория дизайна',
        'Эргономика',
        'Композиция',
        'Материаловедение'
      ]
    },
    {
      title: 'Проектирование',
      topics: [
        'Интерьеры',
        'Общественные пространства',
        'Городская среда',
        'Ландшафт'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Работа с проектами',
        'Визуализация',
        'Документация',
        'Реализация'
      ]
    }
  ],
  features: [
    '36 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};