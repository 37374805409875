import { Course } from '../../../../types';

export const course: Course = {
  id: 'geekbrains_frontend',
  title: 'Профессия Фронтенд-разработчик',
  description: 'Онлайн курс «Frontend-разработчик» от GeekBrains: получи новую профессию дистанционно! Курс ориентирован на уровень: Junior. Длительность обучения: 9 месяцев. Помощь в трудоустройстве! Обучение на Frontend-разработчика с нуля онлайн.',
  school: 'geekbrains',
  category: 'programming',
  price: 118255,
  oldPrice: 236509,
  currency: 'RUB',
  duration: '9 месяцев',
  imageUrl: 'https://cdn.skillbox.pro/nomenclature/jZjFtb1Tvrm4UVopqclfaUbNMPWAZKdmYS5JmrDx.jpg',
  url: 'https://go.redav.online/87e55c259e814801?dl=https%3A%2F%2Fgb.ru%2Fgeek_university%2Fdeveloper%2Fprogrammer%2Ffrontend-gb&m=4&erid=LdtCK7b7u',
  discountPercent: 50,
  skills: [
    'HTML и CSS',
    'JavaScript',
    'React',
    'TypeScript',
    'Современные инструменты разработки',
    'Адаптивная верстка'
  ],
  program: [
    {
      title: 'Основы веб-разработки',
      topics: [
        'HTML5',
        'CSS3',
        'JavaScript',
        'Git'
      ]
    },
    {
      title: 'JavaScript и TypeScript',
      topics: [
        'ES6+',
        'TypeScript',
        'DOM API',
        'Асинхронность'
      ]
    },
    {
      title: 'React',
      topics: [
        'Компоненты',
        'Хуки',
        'Redux',
        'React Router'
      ]
    },
    {
      title: 'Продвинутые темы',
      topics: [
        'Webpack',
        'Testing',
        'Performance',
        'CI/CD'
      ]
    }
  ],
  features: [
    'Современные технологии',
    'Практические проекты',
    'Код-ревью',
    'Помощь в трудоустройстве',
    'Сертификат разработчика'
  ]
};