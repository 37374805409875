import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_perinatal_full',
  title: 'Перинатальная психология',
  description: 'Дистанционное обучение по программе Перинатальная психология с присвоением квалификации Перинатальный психолог(1020 часов) в ЦАППКК.',
  school: 'appkk',
  category: 'psychology',
  price: 38000,
  currency: 'RUR',
  duration: '1020 часов',
  imageUrl: 'https://images.unsplash.com/photo-1584714268709-c3dd9c92b378?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fperinatalnaya_psikhologiya_s_prisvoeniem_kvalifikatsii_perinatalnyy_psikholog%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Перинатальная психология',
    'Работа с беременными',
    'Сопровождение родов',
    'Послеродовая поддержка',
    'Работа с семьей'
  ],
  program: [
    {
      title: 'Теоретические основы',
      topics: [
        'Перинатальная психология',
        'Физиология беременности',
        'Психология родов',
        'Развитие ребенка'
      ]
    },
    {
      title: 'Практическая работа',
      topics: [
        'Консультирование беременных',
        'Подготовка к родам',
        'Послеродовое сопровождение',
        'Работа с семьей'
      ]
    },
    {
      title: 'Специальные темы',
      topics: [
        'Осложнения беременности',
        'Перинатальные потери',
        'Послеродовая депрессия',
        'Раннее развитие'
      ]
    }
  ],
  features: [
    'Комплексная подготовка',
    'Практические навыки',
    'Супервизия',
    'Методические материалы',
    'Диплом о профессиональной переподготовке'
  ]
};