import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_product_manager',
  title: 'Продакт-менеджмент для специалистов с опытом',
  description: 'Курс для тех, у кого уже есть опыт работы в продуктовых IT-компаниях. Научитесь оптимизировать метрики продукта и составлять стратегию его развития.',
  school: 'yandex',
  category: 'management',
  price: 132000,
  currency: 'RUB',
  duration: '8 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fproduct-manager&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Продуктовая стратегия',
    'Управление метриками',
    'Развитие продукта',
    'A/B тестирование',
    'Работа с командой'
  ],
  program: [
    {
      title: 'Продуктовая стратегия',
      topics: [
        'Анализ рынка',
        'Продуктовые метрики',
        'Формирование гипотез',
        'Приоритизация'
      ]
    },
    {
      title: 'Развитие продукта',
      topics: [
        'A/B тестирование',
        'Продуктовые исследования',
        'Работа с данными',
        'Принятие решений'
      ]
    },
    {
      title: 'Управление командой',
      topics: [
        'Работа с разработкой',
        'Взаимодействие с дизайном',
        'Управление бэклогом',
        'Коммуникация'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными продуктами',
    'Персональный наставник',
    'Помощь в карьерном росте',
    'Сертификат продакт-менеджера'
  ]
};