import { Course } from '../../../../types';

export const course: Course = {
  id: 'appkk_counseling',
  title: 'Виды индивидуальной психологической помощи. Практические навыки психологического консультирования',
  description: 'Дистанционное обучение по программе Виды индивидуальной психологической помощи. Практические навыки психологического консультирования(38 часов) в ЦАППКК. Освоение различных методов и техник индивидуального консультирования.',
  school: 'appkk',
  category: 'psychology',
  price: 33900,
  currency: 'RUR',
  duration: '38 часов',
  imageUrl: 'https://images.unsplash.com/photo-1573497620053-ea5300f94f21?q=80&w=1200',
  url: 'https://go.redav.online/160f54a9e9732641?dl=https%3A%2F%2Fappkk.ru%2F%2Fdist_ob%2Fvidy_individualnoy_psikhologicheskoy_pomoshchi_prakticheskie_navyki_psikhologicheskogo_konsultirovan%2F&m=5&erid=2VfnxxntFWG',
  skills: [
    'Индивидуальное консультирование',
    'Работа с самооценкой',
    'Преодоление кризисов',
    'Работа с эмоциями',
    'Коррекция поведения'
  ],
  program: [
    {
      title: 'Основы консультирования',
      topics: [
        'Принятие и самооценка',
        'Работа с внутренним критиком',
        'Эмоциональные кризисы',
        'Пограничные состояния'
      ]
    },
    {
      title: 'Практические методы',
      topics: [
        'Техники консультирования',
        'Работа с зависимостями',
        'Преодоление застенчивости',
        'Коррекция поведения'
      ]
    },
    {
      title: 'Специальные техники',
      topics: [
        'Когнитивные методы',
        'Поведенческие техники',
        'Работа с травмой',
        'Профилактика рецидивов'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Разбор случаев',
    'Супервизия',
    'Методические материалы',
    'Сертификат по окончании'
  ]
};