import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_sql_dev',
  title: 'SQL для разработки',
  description: 'Это курс для разработчиков, которые хотят расширить стек. Научитесь писать и оптимизировать запросы или улучшите эти навыки.',
  school: 'yandex',
  category: 'analytics',
  price: 60000,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fsql-for-developers&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'SQL запросы',
    'Оптимизация запросов',
    'Работа с базами данных',
    'Проектирование схем',
    'Индексы и производительность'
  ],
  program: [
    {
      title: 'Продвинутый SQL',
      topics: [
        'Сложные запросы',
        'Оконные функции',
        'Подзапросы',
        'Оптимизация'
      ]
    },
    {
      title: 'Работа с данными',
      topics: [
        'Проектирование БД',
        'Индексы',
        'Транзакции',
        'Производительность'
      ]
    },
    {
      title: 'Практика',
      topics: [
        'Реальные кейсы',
        'Отладка запросов',
        'Миграции',
        'Масштабирование'
      ]
    }
  ],
  features: [
    'Практические задания',
    'Работа с реальными БД',
    'Персональный наставник',
    'Помощь в обучении',
    'Сертификат разработчика'
  ]
};