import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_neural_networks',
  title: 'Нейросети для каждого: как решать рабочие задачи быстрее',
  description: 'Начните обучение нейросетям и автоматизируйте рутинные задачи с помощью ИИ. Черная Пятница в Нетологии - двойные скидки на курсы!',
  school: 'netology',
  category: 'analytics',
  price: 27720,
  oldPrice: 46200,
  currency: 'RUB',
  duration: '6 недель',
  imageUrl: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fchat-gpt&m=4&erid=LdtCKCxkP',
  discountPercent: 40,
  monthlyPayment: 2310,
  skills: [
    'Работа с нейросетями',
    'Автоматизация задач',
    'Prompt Engineering',
    'Генерация контента',
    'Оптимизация процессов'
  ],
  program: [
    {
      title: 'Основы работы с ИИ',
      topics: [
        'Введение в нейросети',
        'Типы моделей',
        'Возможности ИИ',
        'Ограничения'
      ]
    },
    {
      title: 'Практическое применение',
      topics: [
        'ChatGPT',
        'Midjourney',
        'Stable Diffusion',
        'Claude'
      ]
    },
    {
      title: 'Автоматизация',
      topics: [
        'Оптимизация процессов',
        'Интеграция в работу',
        'Prompt Engineering',
        'Анализ результатов'
      ]
    }
  ],
  features: [
    '16 практических занятий',
    'Работа с реальными задачами',
    'Персональный наставник',
    'Доступ к материалам',
    'Сертификат о повышении квалификации'
  ]
};