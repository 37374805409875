import { Course } from '../../../../types';

export const course: Course = {
  id: 'yandex_data_visualization',
  title: 'Визуализация данных и введение в BI-инструменты',
  description: 'Научитесь работать с данными, строить графики и дашборды в Datawrapper, DataLens и Tableau.',
  school: 'yandex',
  category: 'analytics',
  price: 40200,
  currency: 'RUB',
  duration: '3 месяца',
  imageUrl: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1200',
  url: 'https://go.redav.online/4723c428cc8eb3d1?dl=https%3A%2F%2Fpracticum.yandex.ru%2Fdatavis-and-bi-tools&m=5&erid=2Vfnxx6kDRz',
  skills: [
    'Визуализация данных',
    'BI инструменты',
    'Построение дашбордов',
    'Анализ данных',
    'Работа с графиками'
  ],
  program: [
    {
      title: 'Основы визуализации',
      topics: [
        'Типы визуализаций',
        'Принципы дизайна',
        'Работа с цветом',
        'Интерактивность'
      ]
    },
    {
      title: 'BI инструменты',
      topics: [
        'Datawrapper',
        'DataLens',
        'Tableau',
        'Power BI'
      ]
    },
    {
      title: 'Создание дашбордов',
      topics: [
        'Архитектура дашборда',
        'Интерактивные элементы',
        'Фильтрация данных',
        'Публикация'
      ]
    }
  ],
  features: [
    'Практические проекты',
    'Работа с реальными данными',
    'Персональный наставник',
    'Помощь в обучении',
    'Сертификат аналитика'
  ]
};