import { Course } from '../../../../types';

export const course: Course = {
  id: 'netology_educational_methodologist',
  title: 'Методолог образовательных программ',
  description: 'Курс «Методолог образовательных программ» от Нетологии: научитесь создавать и адаптировать методики для успешного запуска образовательных продуктов. Практика с реальными кейсами поможет вам освоить современные методы и инструменты проектирования курсов.',
  school: 'netology',
  category: 'education',
  price: 162400,
  oldPrice: 290000,
  currency: 'RUB',
  duration: '12 месяцев',
  imageUrl: 'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?q=80&w=1200',
  url: 'https://go.redav.online/28198ab5fef1f580?dl=https%3A%2F%2Fnetology.ru%2Fprograms%2Fmetodolog-obrazovatelnyh-programm-sinhron&m=4&erid=LdtCKCxkP',
  monthlyPayment: 4511,
  discountPercent: 44,
  skills: [
    'Проектирование программ',
    'Методическая работа',
    'Оценка эффективности',
    'Разработка контента',
    'Управление обучением'
  ],
  program: [
    {
      title: 'Основы методологии',
      topics: [
        'Педагогический дизайн',
        'Анализ потребностей',
        'Целеполагание',
        'Структурирование контента'
      ]
    },
    {
      title: 'Разработка программ',
      topics: [
        'Проектирование курсов',
        'Создание материалов',
        'Оценочные средства',
        'Методические рекомендации'
      ]
    },
    {
      title: 'Управление обучением',
      topics: [
        'Организация процесса',
        'Контроль качества',
        'Обратная связь',
        'Развитие программ'
      ]
    }
  ],
  features: [
    '11 практических занятий',
    'Реальные проекты',
    'Персональный наставник',
    'Помощь в трудоустройстве',
    'Диплом о профессиональной переподготовке'
  ]
};